import React from 'react';

interface DotsMenuProps {
    className?: string
}

const DotsMenuIcon = ({ className }: DotsMenuProps) => (
    <svg className={`shrink-0 size-3 ${className || ''}`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
         fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <circle cx="12" cy="12" r="1"/>
        <circle cx="12" cy="5" r="1"/>
        <circle cx="12" cy="19" r="1"/>
    </svg>
);

export default DotsMenuIcon;
