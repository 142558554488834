import React from 'react';
import {
  AddressInputElement,
  AddressInputElementClass,
  CurrencyInputElement,
  CurrencyInputElementClass,
  DateInputElement,
  DateInputElementClass,
  DateTimeInputElement,
  DateTimeInputElementClass,
  DocumentElement,
  DropdownElement,
  DropdownElementClass,
  EmailInputElement,
  EmailInputElementClass,
  MultiLineTextInputElement,
  MultiLineTextInputElementClass,
  PhoneNumberInputElement,
  PhoneNumberInputElementClass,
  RatingInputElement,
  RatingInputElementClass,
  SignatureInputElement,
  SignatureInputElementClass,
  SsnInputElement,
  SsnInputElementClass,
  TableInputElement,
  TableInputElementClass,
  TextBlockElement,
  TextBlockElementClass,
  TextInputElement,
  TextInputElementClass,
  TimeInputElement,
  TimeInputElementClass,
  UrlInputElement,
  UrlInputElementClass,
  YesNoInputElement,
  YesNoInputElementClass,
} from 'types/documentTemplateElement';
import TextInputElementComponent from './elementComponent/TextInputElementComponent';
import MultiLineTextInputElementComponent from './elementComponent/MultiLineTextInputElementComponent';
import PhoneNumberInputElementComponent from './elementComponent/PhoneNumberInputElementComponent';
import YesNoInputElementComponent from './elementComponent/YesNoInputElementComponent';
import SsnInputElementComponent from './elementComponent/SsnInputElementComponent';
import EmailInputElementComponent from './elementComponent/EmailInputElementComponent';
import DateInputElementComponent from './elementComponent/DateInputElementComponent';
import DropdownElementComponent from './elementComponent/DropdownElementComponent';
import AddressInputElementComponent from './elementComponent/AddressInputElementComponent';
import CurrencyInputElementComponent from './elementComponent/CurrencyInputElementComponent';
import DateTimeInputElementComponent from './elementComponent/DateTimeInputElementComponent';
import TimeInputElementComponent from './elementComponent/TimeInputElementComponent';
import RatingInputElementComponent from './elementComponent/RatingInputElementComponent';
import SignatureInputElementComponent from './elementComponent/SignatureInputElementComponent';
import UrlInputElementComponent from './elementComponent/UrlInputElementComponent';
import TableInputElementComponent from './elementComponent/TableInputElementComponent';
import {useSelector} from 'react-redux';
import {RootState} from '../../store/store';
import TextBlockElementComponent from './elementComponent/TextBlockElementComponent';
import {useIsDocumentElementDisabled} from '../../hooks/useIsDocumentElementDisabled';

interface RenderElementViewProps {
  element: DocumentElement;
  updateElement: (updatedElement: DocumentElement) => void;
  mode: 'view' | 'preview' | 'edit' | 'admin';
}

const replaceTemplateTags = (
  html: string,
  tagValues: Record<string, string>,
): string => {
  return html.replace(
    /<span([^>]*data-tag-value="(\{([^}]+)\})"[^>]*)>(.*?)<\/span>/g,
    (match, spanAttributes, fullTag, tagName, innerContent) => {
      const replacement = tagValues[tagName] || innerContent;
      return `<span${spanAttributes}>${replacement}</span>`;
    },
  );
};

const RenderElementView: React.FC<RenderElementViewProps> = ({
  element,
  updateElement,
  mode,
}) => {
  const userApplication = useSelector(
    (state: RootState) => state.userApplication.userApplication,
  );
  const userAuth = useSelector((state: RootState) => state.auth.user);
  const signatureBase64 = useSelector(
    (state: RootState) => state.auth.base64Signature,
  );
  const disabled = useIsDocumentElementDisabled(element, mode);

  switch (element.elementType) {
    case 'TextBlock':
      switch (mode) {
        case 'preview':
          return (
            <div
              className="prose max-w-none w-full p-3 "
              dangerouslySetInnerHTML={{
                __html: element.properties.content || '',
              }}
            />
          );
        case 'edit':
          return (
            <TextBlockElementComponent
              element={element as TextBlockElementClass}
              updateElement={
                updateElement as (updatedElement: TextBlockElement) => void
              }
            />
          );
        case 'admin':
          return (
            <div
              className="prose max-w-none w-full px-3 pb-2"
              dangerouslySetInnerHTML={{__html: element.properties.value || ''}}
            />
          );
        default:
          const tagValues = {
            EMPLOYEE_FIRST_NAME: userApplication?.user?.firstName || '',
            EMPLOYEE_LAST_NAME: userApplication?.user?.lastName || '',
            EMPLOYEE_FULL_NAME: `${userApplication?.user?.firstName || ''} ${userApplication?.user?.lastName || ''}`,
            EMPLOYEE_SUPERVISOR:
              userApplication?.user?.supervisors
                ?.map(
                  (supervisor: {firstName: string; lastName: string}) =>
                    `${supervisor.firstName} ${supervisor.lastName}`,
                )
                .join(', ') || '',
            EMPLOYEE_ROLE:
              userApplication?.user?.jobRoles
                ?.map((role: {name: string}) => role.name)
                .join(', ') || '',
            EMPLOYEE_LOCATION:
              userApplication?.user?.locations
                ?.map((location: {name: string}) => location.name)
                .join(', ') || '',
            CURRENT_DATE: new Date().toLocaleDateString(),
            COMPANY_NAME: 'New Hope',
          };

          const processedContent = replaceTemplateTags(
            element.properties.content || '',
            tagValues,
          );

          if (processedContent !== element.properties.value) {
            updateElement(
              new TextBlockElementClass({
                ...element,
                properties: {...element.properties, value: processedContent},
              }),
            );
          }

          return (
            <div
              className="prose max-w-none w-full px-3 pb-2"
              dangerouslySetInnerHTML={{__html: processedContent}}
            />
          );
      }

    case 'TextInput':
      return (
        <TextInputElementComponent
          element={element as TextInputElementClass}
          updateElement={
            updateElement as (updatedElement: TextInputElement) => void
          }
          mode={mode}
        />
      );
    case 'MultiLineTextInput':
      return (
        <MultiLineTextInputElementComponent
          element={element as MultiLineTextInputElementClass}
          updateElement={
            updateElement as (updatedElement: MultiLineTextInputElement) => void
          }
          mode={mode}
        />
      );
    case 'PhoneNumberInput':
      return (
        <PhoneNumberInputElementComponent
          element={element as PhoneNumberInputElementClass}
          updateElement={
            updateElement as (updatedElement: PhoneNumberInputElement) => void
          }
          mode={mode}
        />
      );
    case 'YesNoInput':
      return (
        <YesNoInputElementComponent
          element={element as YesNoInputElementClass}
          updateElement={
            updateElement as (updatedElement: YesNoInputElement) => void
          }
          mode={mode}
        />
      );
    case 'SsnInput':
      return (
        <SsnInputElementComponent
          element={element as SsnInputElementClass}
          updateElement={
            updateElement as (updatedElement: SsnInputElement) => void
          }
          mode={mode}
        />
      );
    case 'EmailInput':
      return (
        <EmailInputElementComponent
          element={element as EmailInputElementClass}
          updateElement={
            updateElement as (updatedElement: EmailInputElement) => void
          }
          mode={mode}
        />
      );
    case 'DateInput':
      return (
        <DateInputElementComponent
          element={element as DateInputElementClass}
          updateElement={
            updateElement as (updatedElement: DateInputElement) => void
          }
          mode={mode}
        />
      );
    case 'Dropdown':
      return (
        <DropdownElementComponent
          element={element as DropdownElementClass}
          updateElement={
            updateElement as (updatedElement: DropdownElement) => void
          }
          mode={mode}
        />
      );
    case 'AddressInput':
      return (
        <AddressInputElementComponent
          element={element as AddressInputElementClass}
          updateElement={
            updateElement as (updatedElement: AddressInputElement) => void
          }
          mode={mode}
        />
      );
    case 'CurrencyInput':
      return (
        <CurrencyInputElementComponent
          element={element as CurrencyInputElementClass}
          updateElement={
            updateElement as (updatedElement: CurrencyInputElement) => void
          }
          mode={mode}
        />
      );
    case 'DateTimeInput':
      return (
        <DateTimeInputElementComponent
          element={element as DateTimeInputElementClass}
          updateElement={
            updateElement as (updatedElement: DateTimeInputElement) => void
          }
          mode={mode}
        />
      );
    case 'TimeInput':
      return (
        <TimeInputElementComponent
          element={element as TimeInputElementClass}
          updateElement={
            updateElement as (updatedElement: TimeInputElement) => void
          }
          mode={mode}
        />
      );
    case 'RatingInput':
      return (
        <RatingInputElementComponent
          element={element as RatingInputElementClass}
          updateElement={
            updateElement as (updatedElement: RatingInputElement) => void
          }
          mode={mode}
        />
      );
    case 'SignatureInput':
      switch (mode) {
        case 'preview':
          return (
            <div className="w-full mx-3">
              <SignatureInputElementComponent
                element={element as SignatureInputElementClass}
                updateElement={
                  updateElement as (
                    updatedElement: SignatureInputElement,
                  ) => void
                }
                mode={mode}
              />
            </div>
          );
        case 'edit':
          return (
            <div className="w-full mx-3">
              <SignatureInputElementComponent
                element={element as SignatureInputElementClass}
                updateElement={
                  updateElement as (
                    updatedElement: SignatureInputElement,
                  ) => void
                }
                mode={mode}
              />
            </div>
          );
        case 'admin': {
          const hasSavedSignature =
            element.properties.value && element.properties.value !== '';
          const isCurrentAdmin =
            userAuth && element.properties.supervisorIds?.includes(userAuth.id);
          const shouldShowSignature = hasSavedSignature || isCurrentAdmin;

          const finalValue = shouldShowSignature
            ? hasSavedSignature
              ? element.properties.value
              : isCurrentAdmin
                ? signatureBase64 || ''
                : ''
            : '';

          if (isCurrentAdmin || finalValue) {
            return (
              <div className="prose max-w-none w-full px-3 pb-2">
                {element.properties.showLabel && element.properties.label && (
                  <label className="font-semibold">
                    {element.properties.label}
                  </label>
                )}
                <div className="px-3 pb-2 border-b border-slate-200">
                  {finalValue && <img src={finalValue} alt="Signature" />}
                  {!finalValue && isCurrentAdmin && (
                    <span>No signature available</span>
                  )}
                </div>
                {element.properties.subText && (
                  <div className="flex justify-between items-center text-sm text-slate-800 mt-1">
                    <div>{element.properties.subText}</div>
                  </div>
                )}
              </div>
            );
          } else {
            return (
              <div className="w-full mx-3">
                <SignatureInputElementComponent
                  element={element as SignatureInputElementClass}
                  updateElement={
                    updateElement as (
                      updatedElement: SignatureInputElement,
                    ) => void
                  }
                  mode={mode}
                />
              </div>
            );
          }
        }

        case 'view': {
          if (!disabled) {
            return (
              <div className="prose max-w-none w-full px-3 pb-2">
                {element.properties.showLabel && element.properties.label && (
                  <label className="font-semibold">
                    {element.properties.label}
                  </label>
                )}
                <div className="px-3 pb-2 border-b border-slate-200">
                  {element.properties.value || signatureBase64 ? (
                    <img
                      src={element.properties.value || signatureBase64}
                      alt="Signature"
                    />
                  ) : (
                    <span>No signature available</span>
                  )}
                </div>
                {element.properties.subText && (
                  <div className="flex justify-between items-center text-sm text-slate-800 mt-1">
                    <div>{element.properties.subText}</div>
                  </div>
                )}
              </div>
            );
          }
          return (
            <div className="w-full mx-3">
              <SignatureInputElementComponent
                element={element as SignatureInputElementClass}
                updateElement={
                  updateElement as (
                    updatedElement: SignatureInputElement,
                  ) => void
                }
                mode={mode}
              />
            </div>
          );
        }

        default:
          return null;
      }

    case 'UrlInput':
      return (
        <UrlInputElementComponent
          element={element as UrlInputElementClass}
          updateElement={
            updateElement as (updatedElement: UrlInputElement) => void
          }
          mode={mode}
        />
      );
    case 'TableInput':
      return (
        <TableInputElementComponent
          element={element as TableInputElementClass}
          updateElement={
            updateElement as (updatedElement: TableInputElement) => void
          }
          mode={mode}
        />
      );

    default:
      return null;
  }
};
export default RenderElementView;
