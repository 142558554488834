import React, {useEffect} from 'react';
import DocumentTemplateFillOutComponent from "features/documentTemplate/DocumentTemplateFillOutComponent";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store/store";
import {DocumentTemplateThunks} from "../../features/documentTemplate/documentTemplateThunks";
import {useParams} from "react-router-dom";
import {UserApplicationThunks} from "../../features/userApplication/userApplicationThunks";

const DocumentTemplateUserFillOutPage: React.FC = () => {
    const {id} = useParams<{id: string}>();
    const dispatch = useDispatch<AppDispatch>();
    const documentTemplate = useSelector(
        (state: RootState) => state.documentTemplate.documentTemplate,
    );
    useEffect(() => {
        if (id) {
            dispatch(UserApplicationThunks.show(id)).then(action => {
                if (action.payload?.documentTemplate.id) {
                    dispatch(
                        DocumentTemplateThunks.show(
                            action.payload.documentTemplate.id,
                        ),
                    );
                }
            });
        }
    }, [id, dispatch]);
    return (
        <div>
            <DocumentTemplateFillOutComponent
            mode={'view'}
            buttonText={'Complete'}
            documentTemplate={documentTemplate}
            />
        </div>
    );
}
export default DocumentTemplateUserFillOutPage;