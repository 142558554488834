import {useMemo} from 'react';
import {DocumentTemplateElement} from 'types/documentTemplateElement';
import {useSelector} from 'react-redux';
import {RootState} from '../store/store';
import {isElementDisabled} from '../utils/elementUtils';

export function useIsDocumentElementDisabled<T extends DocumentTemplateElement>(
  element: T,
  mode?: string,
): boolean {
  const user = useSelector((state: RootState) => state.auth.user);
  return useMemo(() => {
    return isElementDisabled(element, mode, user);
  }, [mode, element]);
}
