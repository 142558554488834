export interface HrDocumentInterface {
    id: string;
    name: string;
    documentType: 'file' | 'template';
    description: string;
    status?: string;
    requireIssueDate?: boolean;
    requireExpirationDate?: boolean;
    documentTemplateId?: string;
    appliesToAllRoles?: boolean;
    jobRoleIds?: string[];
}

export class HrDocument implements HrDocumentInterface {
    id: string;
    name: string;
    documentType: 'file' | 'template';
    description: string;
    status?: any;
    requireIssueDate?: boolean;
    requireExpirationDate?: boolean;
    documentTemplateId?: string;
    appliesToAllRoles?: boolean;
    jobRoleIds?: string[];

    constructor(data: Partial<HrDocumentInterface>) {
        this.id = data.id!;
        this.name = data.name!;
        this.documentType = data.documentType!;
        this.description = data.description!;
        this.status = data.status || 'active';
        this.requireIssueDate = data.requireIssueDate || false;
        this.requireExpirationDate = data.requireExpirationDate || false;
        this.documentTemplateId = data.documentTemplateId;
        this.appliesToAllRoles = data.appliesToAllRoles || false;
        this.jobRoleIds = data.jobRoleIds || [];
    }

    toJson(): HrDocumentInterface {
        return {
            id: this.id,
            name: this.name,
            documentType: this.documentType,
            description: this.description,
            status: this.status.toLowerCase(),
            requireIssueDate: this.requireIssueDate,
            requireExpirationDate: this.requireExpirationDate,
            documentTemplateId: this.documentTemplateId,
            appliesToAllRoles: this.appliesToAllRoles,
            jobRoleIds: this.jobRoleIds,
        };
    }
}
