import React from 'react';
import {
  ElementProps,
  YesNoInputElementClass,
} from 'types/documentTemplateElement';
import RadioInput from '../../inputs/RadioInput';
import {buildLabel, checkError} from 'utils/elementUtils';
import {useIsDocumentElementDisabled} from '../../../hooks/useIsDocumentElementDisabled';

interface YesNoInputElementProps extends ElementProps<YesNoInputElementClass> {}

const YesNoInputElementComponent: React.FC<YesNoInputElementProps> = ({
  element,
  updateElement,
  mode,
}) => {
  const disabled = useIsDocumentElementDisabled(element, mode);
  const handleChange = (newValue: boolean) => {
    if (mode === 'view' || mode === 'admin') {
      const newUpdatedElement = new YesNoInputElementClass({
        ...element,
        properties: {
          ...element.properties,
          value: newValue,
        },
      });
      updateElement(newUpdatedElement);
    }
    return;
  };

  let containerClass = 'w-full m-8';
  if (mode === 'view' || mode === 'preview') {
    containerClass = element.properties.showLabel
      ? 'w-full mx-3 mb-2'
      : 'w-full mt-5 mx-3 mb-2';
  }

  return (
    <div className={containerClass}>
      <RadioInput
        error={checkError(element, mode)}
        label={buildLabel(element)}
        options={[
          {id: 'Yes', label: 'Yes', value: true},
          {id: 'No', label: 'No', value: false},
        ]}
        selectedValue={element.properties.value}
        onChange={handleChange}
        required={element.properties.required}
        disabled={disabled}
      />
    </div>
  );
};

export default YesNoInputElementComponent;
