import React from 'react';
import {
  ElementProps,
  DropdownElementClass,
} from 'types/documentTemplateElement';
import CustomSelect from '../../inputs/CustomSelect';
import {buildLabel, checkError} from '../../../utils/elementUtils';
import {useIsDocumentElementDisabled} from '../../../hooks/useIsDocumentElementDisabled';

interface DropdownElementProps extends ElementProps<DropdownElementClass> {}

const DropdownElementComponent: React.FC<DropdownElementProps> = ({
  element,
  updateElement,
  mode,
}) => {
  const handleChange = (selected: any) => {
    if (mode === 'view' || mode === 'admin') {
      let newValue: string | string[] = '';

      if (element.properties.isMulti) {
        newValue = Array.isArray(selected)
          ? selected.map((opt: any) => opt.value)
          : [];
      } else {
        newValue = selected ? selected.value : '';
      }

      const newElement = new DropdownElementClass({
        ...element,
        properties: {
          ...element.properties,
          value: newValue,
        },
      });

      updateElement(newElement);
    }
  };

  const options = element.properties.options.map(opt => ({
    label: opt,
    value: opt,
  }));

  const disabled = useIsDocumentElementDisabled(element, mode);

  

  let containerClass = 'w-full m-8';
  if (mode === 'view' || mode === 'preview' || mode === 'admin') {
    containerClass = element.properties.showLabel
      ? 'w-full mx-3 mb-2'
      : 'w-full mt-[30px] mx-3 mb-2';
  }

  return (
    <div className={containerClass}>
      <div className="flex flex-col gap-[0.625rem] w-full">
        {element.properties.showLabel && (
          <label className="w-full block grow shrink basis-0 text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
            {buildLabel(element)}
          </label>
        )}
        <CustomSelect
          options={options}
          error={checkError(element, mode)}
          isMulti={element.properties.isMulti}
          onChange={handleChange}
          value={element.properties.value}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default DropdownElementComponent;
