import { createEntityService } from 'utils/createEntityService';
import { UserJobRole } from 'types/userJobRole';

export const UserJobRoleService = createEntityService<UserJobRole>({
    baseUrl: 'api/v1/user_JobRoles',
    entityName: 'userJobRole',
    methodConfigs: {
        create: {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
        update: {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    }
});
