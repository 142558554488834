import React from 'react';
import { RatingInputElement } from 'types/documentTemplateElement';
import { DocumentTemplate } from '../../../types/documentTemplate';
import BaseInputProperties from './BaseInputProperties';
import CustomSelect from "../../inputs/CustomSelect";

interface RatingInputPropertiesProps {
    element: RatingInputElement;
    template: DocumentTemplate;
    updateElement: (updatedElement: RatingInputElement) => void;
}

const RatingInputProperties: React.FC<RatingInputPropertiesProps> = ({
    element,
    template,
    updateElement,
}) => {
    const handleMaxRatingChange = (value: number) => {
        updateElement({...element, properties: {...element.properties, maxRating: value}});
    }

    const additionalFields = (
        <div>
            <label
                htmlFor="maxRating"
                className="block text-sm font-semibold text-slate-800 dark:text-white mb-2"
            >
                Rating Range
            </label>
            <CustomSelect
                options={[
                    {label: '1-5', value: 5},
                    {label: '1-10', value: 10},
                ]}
                value={element.properties.maxRating}
                onChange={(value) => handleMaxRatingChange(value.value)}
            />
        </div>
    );

    return (
        <BaseInputProperties
            element={element}
            template={template}
            updateElement={updateElement}
            additionalFields={additionalFields}
            hiddePlaceholder={true}
        />
    );
};

export default RatingInputProperties;
