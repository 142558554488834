import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from 'store/store';
import React, {useEffect, useState} from 'react';
import TextInput from 'components/inputs/TextInput';
import {UserMedicalDocument} from 'types/userMedicalDocument';
import SubmitButton from 'components/SubmitButton';
import {UserMedicalDocumentThunks} from 'features/userMedicalDocument/userMedicalDocumentThunks';
import XIcon from 'assets/images/icons/XIcon';
import DateInput from 'components/inputs/DateInput';
import DescriptionInput from 'components/inputs/DescriptionInput';
import RadioInput from 'components/inputs/RadioInput';
import {Training} from 'types/training';
import FileInput from 'components/inputs/FileInput';
import FileDetailBox from 'components/FileDetailBox';

interface UserMedicalDocumentFormProps {
  id?: string;
  closeDrawer: () => void;
  isDrawerOpen?: boolean;
}

const UserMedicalDocumentForm: React.FC<UserMedicalDocumentFormProps> = ({
  closeDrawer,
  isDrawerOpen,
}) => {
  const userMedicalDocument = useSelector(
    (state: RootState) => state.userMedicalDocument.userMedicalDocument,
  );
  const dispatch = useDispatch<AppDispatch>();
  const [updatedUserMedicalDocument, setUpdatedUserMedicalDocument] = useState({
    ...userMedicalDocument,
  });
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setUpdatedUserMedicalDocument(userMedicalDocument);
    setIsSaveDisabled(!userMedicalDocument?.name);
  }, [userMedicalDocument]);

  const handleFileChange = (files: FileList | null) => {
    if (files && files.length > 0) {
      const attachment = files[0];
      setUploadedFile(attachment);
      setUpdatedUserMedicalDocument((prevState: UserMedicalDocument) => ({
        ...prevState,
        attachment: attachment,
      }));
      setIsLoadingFile(true);
    }
  };

  useEffect(() => {
    if (!isLoadingFile) return;

    const loadingDuration = 2000;
    const timer = setTimeout(() => {
      setIsLoadingFile(false);
    }, loadingDuration);

    return () => clearTimeout(timer);
  }, [isLoadingFile]);

  useEffect(() => {
    const isFormValid =
      (uploadedFile !== null || userMedicalDocument?.attachmentUrl) &&
      !isLoadingFile;

    setIsSaveDisabled(!isFormValid);
  }, [uploadedFile, updatedUserMedicalDocument, isLoadingFile]);

  useEffect(() => {
    if (!isDrawerOpen) handleClose();
  }, [isDrawerOpen]);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const {id, value} = e.target;
    setUpdatedUserMedicalDocument({...updatedUserMedicalDocument, [id]: value});
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setIsSaveDisabled(true);

    if (userMedicalDocument?.id) {
      await dispatch(
        UserMedicalDocumentThunks.update(
          new UserMedicalDocument(updatedUserMedicalDocument),
        ),
      ).then(() => closeDrawer());
    } else {
      await dispatch(
        UserMedicalDocumentThunks.create(
          new UserMedicalDocument(updatedUserMedicalDocument),
        ),
      ).then(() => closeDrawer());
    }
  };

  const handleClose = () => {
    setUpdatedUserMedicalDocument({
      id: '',
      notes: '',
        name: '',
        agency: '',
        expirationDate: '',

    });

    setUploadedFile(null);
    setIsSaveDisabled(true);
    setIsSubmitting(false);
  };

  const handleDelete = async (attachmentId?: string) => {
    setUploadedFile(null);
    const newDoc = {
      ...updatedUserMedicalDocument,
      attachment: null,
      deleteAttachment: true,
      attachmentUrl: null,
      deleteAttachmentId: attachmentId
    };
    setUpdatedUserMedicalDocument(newDoc);
    setIsSubmitting(true);
    setIsSaveDisabled(true);
    if (newDoc.id) {
      await dispatch(
        UserMedicalDocumentThunks.update(new UserMedicalDocument(newDoc))
      );
    }
    setIsSubmitting(false);
  };

  const handleRadioChange = (field: string, value: boolean) => {
    setUpdatedUserMedicalDocument((prevState: Training) => ({
      ...prevState,
      [field]: value,
    }));
  };

  return (
    <div className="flex flex-col h-screen">
      <div className="flex-1 flex flex-col">
        <div className="h-auto p-6 bg-sky-50 border-b border-sky-200 flex justify-between items-start gap-2 w-full">
          <div className="flex flex-col justify-start items-start gap-1">
            <div className="text-lg font-semibold tracking-normal text-cyan-800">
              {`Upload ${userMedicalDocument?.name}`}
            </div>
            <div className="text-sm font-light tracking-normal leading-5 text-zinc-400">
              {`Add an employees required document so you can keep track of their status and remain compliant.`}
            </div>
          </div>
          <button
            onClick={closeDrawer}
            className="text-gray-500 hover:text-gray-700 focus:outline-none pt-2">
            <XIcon />
          </button>
        </div>
        <div className="h-full px-6 pb-6  ">
          <div className="mt-5">
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col space-y-6 items-center mt-7">
                <div className="w-full">
                  <FileInput
                    onFileChange={handleFileChange}
                    label="Drop your files here or"
                    labelBlue="browse"
                    subtitle="Maximum size: 50MB"
                    disabled={isLoadingFile}
                  />
                </div>
                {uploadedFile && (
                  <div className="w-full">
                    <FileDetailBox
                      uploadedFile={uploadedFile}
                      handleDelete={() => handleDelete()}
                      isLoading={isLoadingFile}
                      loadingTime={2000}
                    />
                  </div>
                )}
                {userMedicalDocument?.attachment?.url &&
                  uploadedFile === null &&
                  updatedUserMedicalDocument.attachment?.url !== null && (
                    <div className="w-full">
                      <FileDetailBox
                        uploadedFile={userMedicalDocument?.attachment}
                        handleDelete={() => handleDelete(userMedicalDocument.attachment.id)}
                        fileCurrent={true}
                      />
                    </div>
                  )}
                {userMedicalDocument?.previousAttachments &&
                  userMedicalDocument?.previousAttachments.map(
                    (file: any, index: number) => (
                      <div className="w-full" key={index}>
                        <FileDetailBox
                          uploadedFile={file}
                          handleDelete={() => handleDelete(file.id)}
                          fileCurrent={false}
                        />
                      </div>
                    ),
                  )}
                <div className="w-full">
                  <TextInput
                    id="name"
                    type="text"
                    label="Name"
                    value={updatedUserMedicalDocument?.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="w-full">
                  <TextInput
                    id="agency"
                    type="text"
                    label="Agency"
                    value={updatedUserMedicalDocument?.agency}
                    onChange={handleChange}
                  />
                </div>
                <div className="w-full">
                  <DescriptionInput
                    id="notes"
                    type="text"
                    label="Notes"
                    value={updatedUserMedicalDocument?.notes}
                    placeholder=""
                    onChange={handleChange}
                    optional="optional"
                  />
                </div>
                <div className="w-full">
                  <DateInput
                    id="expirationDate"
                    type="text"
                    label="Expiration date"
                    value={updatedUserMedicalDocument?.expirationDate}
                    placeholder="MM/DD/YYYY"
                    onChange={handleChange}
                  />
                </div>
                <div className="w-full">
                  <RadioInput
                    label="Reminder"
                    options={[
                      {id: 'remindUserYes', label: 'Yes', value: true},
                      {id: 'remindUserNo', label: 'No', value: false},
                    ]}
                    selectedValue={updatedUserMedicalDocument?.remindUser}
                    onChange={value => handleRadioChange('remindUser', value)}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <footer className="flex-shrink-0 px-6 pb-4 pt-5 flex justify-end gap-5">
        <div className="border-t border-slate-200 w-full">
          <div className="flex w-fulljustify-center items-center gap-x-2 pb-4">
            <SubmitButton
              onClick={handleSubmit}
              label={`${isSubmitting ? 'Uploading' : 'Upload'}`}
              disabled={isSaveDisabled || isSubmitting}
            />
          </div>
          <div className="flex w-full justify-center items-center gap-x-2 pb-4">
            <button
              type="submit"
              onClick={handleClose}
              className="self-center text-sm font-light tracking-normal leading-5 text-center text-zinc-400 hover:text-cyan-400">
              Never mind
            </button>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default UserMedicalDocumentForm;
