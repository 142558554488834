import {RoleService} from 'services/roleService';
import {createEntityThunks} from 'utils/createEntityThunks';
import {Role} from 'types/role';

const roleFactory = (data: any) => new Role(data);

export const RoleThunks = createEntityThunks<Role>(
  'role',
  RoleService,
  {activate: true, deactivate: true},
  roleFactory,
);
