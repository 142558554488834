import React, {ChangeEvent} from 'react';
import InputMask from 'react-input-mask';

interface SSNInputProps {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  id: string;
  label: string;
  placeholder?: string;
  error?: boolean;
  ssn?: string;
  setSSN?: (ssn: string) => void;
  disabled?: boolean;
  isClickDisabled?: boolean;
  required?: boolean;
}

const SSNInput: React.FC<SSNInputProps> = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  value,
  ssn,
  setSSN,
  onChange,
  id,
  label,
  placeholder,
  error,
  disabled,
  isClickDisabled,
  required,
  ...props
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const formattedValue = e.target.value;
    if (setSSN) setSSN(formattedValue);
    onChange(e);
  };

  return (
    <div className="flex flex-col items-start gap-[0.625rem]">
      <label
        htmlFor={id}
        className="w-full block grow shrink basis-0 text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
        {label}
      </label>
      {!isClickDisabled && (
        <InputMask
          id={id}
          disabled={disabled}
          mask="999-99-9999"
          className={`w-full py-2.5 px-3 block focus:outline-cyan-400 border rounded-lg text-sm font-light  text-gray-500  placeholder:text-gray-400 placeholder-gray-400
                                disabled:bg-slate-100  disabled:text-slate-300 disabled:border-0 disabled:pointer-events-none
                                dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60
                                dark:focus:ring-neutral-600 ${error ? "border-red-500" : "border-slate-200"}`}
          placeholder={placeholder}
          type="text"
          value={ssn}
          onChange={handleChange}
          {...props}
        />
      )}
      {isClickDisabled && (
        <input
          id={id}
          disabled={disabled}
          className="w-full py-2.5 px-3 block focus:outline-cyan-400 border  text-gray-500 rounded-lg  text-sm font-light placeholder:text-gray-400
                        disabled:bg-slate-100  disabled:text-slate-300 disabled:border-0 disabled:pointer-events-none
                        dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60
                        dark:focus:ring-neutral-600 border-gray-200"
          placeholder={placeholder}
          type="text"
          value={''}
          required={required}
          onChange={handleChange}
          {...props}
        />
      )}
    </div>
  );
};

export default SSNInput;
