import { createEntityThunks } from 'utils/createEntityThunks';
import { UserEvaluation } from 'types/userEvaluation';
import {UserEvaluationService} from "services/userEvaluationServices";


const UserEvaluationFactory = (data: any) => new UserEvaluation(data);

export const UserEvaluationThunks = createEntityThunks<UserEvaluation>(
    'userEvaluation',
    UserEvaluationService,
    {activate: false, deactivate: false},
    UserEvaluationFactory,
); 