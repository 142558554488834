import React, {useEffect, useState} from 'react';
import {IconType} from 'react-icons';
import {IoIosArrowDown} from 'react-icons/io';
import {Link} from 'react-router-dom';
import usePermissions from 'hooks/usePermissions';

interface SidebarItemProps {
  id: string;
  label?: string;
  Icon?: IconType;
  subItems?: {label: string; href: string; permission: any}[];
  Item?: {href: string};
  onSubItemClick?: (id: string) => void;
  currentPath?: string;
}

const SidebarItem: React.FC<SidebarItemProps> = ({
  id,
  label,
  Icon,
  subItems,
  Item,
  currentPath,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const {checkPermission} = usePermissions();

  useEffect(() => {
    if (subItems?.some(subItem => subItem.href === currentPath)) {
      setIsOpen(true);
    }
  }, [currentPath, subItems]);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const isSelected =
    Item?.href === currentPath ||
    subItems?.some(subItem => subItem.href === currentPath);

  return (
    <li className="mb-1.5">
      {Item !== undefined ? (
        <Link
          to={Item.href}
          className={`w-full text-start flex gap-x-3 py-2 px-3 text-sm font-light
                                text-slate-600 rounded-lg hover:bg-cyan-50 focus:outline-none 
                                focus:bg-cyan-100 focus:text-slate-800 dark:hover:bg-neutral-700 
                                dark:text-neutral-300 dark:focus:bg-neutral-700 ${
                                  isSelected ? 'bg-cyan-100 text-slate-800' : ''
                                }`}>
          {Icon && <Icon className="flex-shrink-0 mt-0.5 size-4" />}
          {label}
        </Link>
      ) : (
        <button
          type="button"
          onClick={() => {
            toggleAccordion();
          }}
          className={`w-full text-start flex gap-x-3 py-2 px-3 text-sm font
                               font-light text-slate-600 rounded-lg hover:bg-cyan-50 disabled:opacity-50 disabled:pointer-events-none
                               dark:bg-neutral-700 focus:outline-none focus:text-slate-800 focus:bg-cyan-100 
                               dark:hover:bg-neutral-700 dark:text-neutral-300 dark:focus:bg-neutral-700 
                               ${isSelected ? 'bg-cyan-100 text-slate-800' : ''}`}>
          {Icon && <Icon className=" flex-shrink-0 mt-0.5 size-4" />}
          {label}
          {subItems && (
            <IoIosArrowDown
              className={`flex-shrink-0 mt-1 size-3.5 ms-auto transition ${isOpen ? '-rotate-180' : ''}`}
            />
          )}
        </button>
      )}
      {subItems && (
        <div
          id={`${id}-accordion-sub`}
          className={`hs-accordion-content pl-2 w-full overflow-hidden transition-[height] duration-300 ${isOpen ? 'block' : 'hidden'}`}>
          <ul className="ps-7 mt-1.5 space-y-1.5 relative before:absolute before:top-0 before:start-[18px] before:w-0.5 before:h-full before:bg-gray-100 dark:before:bg-neutral-700">
            {subItems.map(subItem => {
              if (
                !checkPermission(
                  subItem.permission.actions,
                  subItem.permission.subjectClasses,
                )
              ) {
                return null;
              }
              const isSubItemSelected = subItem.href === currentPath;
              return (
                <li key={subItem.label}>
                  <Link
                    to={subItem.href}
                    className={`flex gap-x-4 py-2 px-3 text-sm font-light text-gray-800 
                                        rounded-lg hover:bg-cyan-50 focus:outline-none focus:bg-cyan-100 
                                        focus:text-slate-800 dark:hover:bg-neutral-700 dark:text-neutral-300 
                                        dark:focus:bg-neutral-700 ${
                                          isSubItemSelected
                                            ? 'bg-cyan-100 text-slate-800'
                                            : ''
                                        }`}>
                    {subItem.label}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </li>
  );
};

export default SidebarItem;
