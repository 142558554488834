import React from 'react';
import SignatureCanvas from 'react-signature-canvas';

interface SignatureInputProps {
  signatureCanvasRef: React.RefObject<SignatureCanvas>;
  error?: string | undefined;
  onEnd?: () => void;
  width?: number;
  height?: number;
  disabled?: boolean;
  id?: string;
  label?: string;
  isClickDisabled?: boolean;
}

const SignatureInput: React.FC<SignatureInputProps> = ({
  signatureCanvasRef,
  disabled = false,
  error,
  onEnd,
  isClickDisabled,
  width = 320,
  height = 130,
  id,
  label,
}) => {
  return (
    <div className="flex flex-col items-start gap-[0.625rem]">
      <label
        htmlFor={id}
        className="block grow shrink basis-0 text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
        {label}
      </label>
      <div
        className={` flex w-full  h-[147px]  focus:outline-cyan-400 border border-gray-200 rounded-lg text-sm font-light placeholder:text-gray-400
                            ${disabled ? 'pointer-events-none bg-slate-100 border-0 ' : ''} ${isClickDisabled ? 'pointer-events-none' : ''}
                            ${error !== undefined ? 'border-red-500' : 'border-slate-200'}
                            dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60 dark:focus:ring-neutral-600`}>
        <div className="flex w-full justify-center items-center  h-full">
          <SignatureCanvas
            ref={signatureCanvasRef}
            penColor="black"
            onEnd={onEnd}
            canvasProps={{width, height, className: 'signature-canvas'}}
          />
        </div>
      </div>
      {error && <div className="text-red-500 text-sm">{error}</div>}
    </div>
  );
};

export default SignatureInput;
