import React from 'react';
import {
  DateInputElement,
  DocumentTemplateElement,
  MultiLineTextInputElement,
  TextInputElement,
  YesNoInputElement,
  PhoneNumberInputElement,
  DropdownElement,
  SsnInputElement,
  EmailInputElement,
  AddressInputElement,
  CurrencyInputElement,
  DateTimeInputElement,
  TimeInputElement,
  RatingInputElement,
  SignatureInputElement,
  UrlInputElement,
  TableInputElement,
} from 'types/documentTemplateElement';

import TextInputProperties from './properties/TextInputProperties';
import MultiLineTextInputProperties from './properties/MultiLineTextInputProperties';
import DropdownProperties from './properties/DropdownProperties';
import PhoneNumberInputProperties from './properties/PhoneNumberInputProperties';
import YesNoInputProperties from './properties/YesNoInputProperties';
import DateInputProperties from './properties/DateInputProperties';
import EmailInputProperties from './properties/EmailInputProperties';
import AddressInputProperties from './properties/AddressInputProperties';
import CurrencyInputProperties from './properties/CurrencyInputProperties';
import DateTimeInputProperties from './properties/DateTimeInputProperties';
import TimeInputProperties from './properties/TimeInputProperties';
import RatingInputProperties from './properties/RatingInputProperties';
import SignatureInputProperties from './properties/SignatureInputProperties';
import UrlInputProperties from './properties/UrlInputProperties';
import SsnInputProperties from './properties/SsnInputProperties';
import TableInputProperties from './properties/TableInputProperties';
import {DocumentTemplate} from '../../types/documentTemplate';
import TextBlockInputProperties from './properties/TextBlockInputProperties';

interface RenderElementPropertiesProps {
  element: DocumentTemplateElement;
  template: DocumentTemplate;
  updateElement: (updatedElement: DocumentTemplateElement) => void;
}

const RenderElementProperties: React.FC<RenderElementPropertiesProps> = ({
  element,
  template,
  updateElement,
}) => {
  switch (element.elementType) {
    case 'TextBlock':
      return (
        <TextBlockInputProperties
          template={template}
          element={element as TextInputElement}
          updateElement={updateElement}
        />
      );

    case 'TextInput':
      return (
        <TextInputProperties
          template={template}
          element={element as TextInputElement}
          updateElement={updateElement}
        />
      );
    case 'MultiLineTextInput':
      return (
        <MultiLineTextInputProperties
          template={template}
          element={element as MultiLineTextInputElement}
          updateElement={updateElement}
        />
      );
    case 'Dropdown':
      return (
        <DropdownProperties
          template={template}
          element={element as DropdownElement}
          updateElement={updateElement}
        />
      );
    case 'PhoneNumberInput':
      return (
        <PhoneNumberInputProperties
          template={template}
          element={element as PhoneNumberInputElement}
          updateElement={updateElement}
        />
      );
    case 'YesNoInput':
      return (
        <YesNoInputProperties
          template={template}
          element={element as YesNoInputElement}
          updateElement={updateElement}
        />
      );
    case 'SsnInput':
      return (
        <SsnInputProperties
          template={template}
          element={element as SsnInputElement}
          updateElement={updateElement}
        />
      );
    case 'DateInput':
      return (
        <DateInputProperties
          template={template}
          element={element as DateInputElement}
          updateElement={updateElement}
        />
      );
    case 'EmailInput':
      return (
        <EmailInputProperties
          template={template}
          element={element as EmailInputElement}
          updateElement={updateElement}
        />
      );
    case 'AddressInput':
      return (
        <AddressInputProperties
          template={template}
          element={element as AddressInputElement}
          updateElement={updateElement}
        />
      );
    case 'CurrencyInput':
      return (
        <CurrencyInputProperties
          template={template}
          element={element as CurrencyInputElement}
          updateElement={updateElement}
        />
      );
    case 'DateTimeInput':
      return (
        <DateTimeInputProperties
          template={template}
          element={element as DateTimeInputElement}
          updateElement={updateElement}
        />
      );
    case 'TimeInput':
      return (
        <TimeInputProperties
          template={template}
          element={element as TimeInputElement}
          updateElement={updateElement}
        />
      );
    case 'RatingInput':
      return (
        <RatingInputProperties
          element={element as RatingInputElement}
          template={template}
          updateElement={updateElement}
        />
      );
    case 'SignatureInput':
      return (
        <SignatureInputProperties
          element={element as SignatureInputElement}
          updateElement={updateElement}
          template={template}
        />
      );
    case 'UrlInput':
      return (
        <UrlInputProperties
          template={template}
          element={element as UrlInputElement}
          updateElement={updateElement}
        />
      );
    case 'TableInput':
      return (
        <TableInputProperties
          template={template}
          element={element as TableInputElement}
          updateElement={updateElement}
        />
      );
    default:
      return (
        <p className="text-slate-400 text-sm font-medium">
          This element doesn't have any properties
        </p>
      );
  }
};

export default RenderElementProperties;
