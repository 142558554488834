import React, {useEffect} from 'react';
import {FaUser} from 'react-icons/fa';
import PencilSquareIcon from 'assets/images/icons/PencilSquareIcon';
import SubmitButton from 'components/SubmitButton';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from 'store/store';
import {User} from 'types/user';
import LoadingSpinner from 'components/LoadingSpinner';
import ChevronRightIcon from 'assets/images/icons/ChevronRightIcon';
import {UserThunks} from 'features/user/userThunks';
import {setIsEditing} from 'features/user/userSlice';
import PhoneNumberDisplay from 'components/PhoneNumberDisplay';
import SSNDisplay from 'components/SSNDisplay';
import {Credential} from 'types/credential';
import XIcon from 'assets/images/icons/XIcon';

interface UserDetailsProps {
  onClose: () => void;
}

const UserDetails: React.FC<UserDetailsProps> = ({onClose}) => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: RootState) => state.user?.user as User);

  const handleUnlock = () => {
    dispatch(UserThunks.unlock(user?.id) as any);
  };

  const handleUnblock = () => {
    dispatch(UserThunks.unblock(user?.id) as any);
  };

  const handleEditing = () => () => {
    dispatch(setIsEditing(true));
  };

  const handleDeactivate = (userId: string) => {
    const user = new User({id: userId, status: 'inactive'});
    dispatch(UserThunks.update(user) as any);
  };

  const handleReactivate = (userId: string) => {
    const user = new User({id: userId, status: 'active'});
    dispatch(UserThunks.update(user) as any);
  };

  useEffect(() => {
    dispatch(setIsEditing(false));
  });

  return (
    <div className="flex flex-col h-screen">
      {user !== null && (
        <div className="flex-1 flex flex-col">
          <div className="flex-1 ">
            <div className="h-auto p-6 bg-sky-50 border-b border-sky-200 flex justify-between items-start gap-2 w-full">
              <div className=" self-stretch flex flex-row  gap-3">
                <div className="flex-shrink-0 w-14 h-14 mt-1.5 bg-cyan-400 rounded-lg flex justify-center items-center">
                  <FaUser className="text-white size-8" />
                </div>
                <div className="flex flex-col w-full">
                  <div className="w-full text-lg font-semibold tracking-normal text-cyan-800">
                    {user?.firstName} {user?.lastName}
                  </div>
                  <div className="text-sm font-light tracking-normal leading-tight text-zinc-400">
                    Employee ID: {user?.id}
                  </div>
                  <div className="text-sm w-max font-light tracking-normal leading-tight text-zinc-400">
                    Last Login: {user?.lastLogin}
                  </div>
                </div>
              </div>
              <button
                onClick={onClose}
                className="text-gray-500 hover:text-gray-700 focus:outline-none pt-2">
                <XIcon />
              </button>
            </div>
            <div className="py-2 px-4 sm:px-6">
              <div className="h-full ">
                <div>
                  <dl className="py-4 grid grid-cols-3 gap-x-4 dark:border-neutral-700">
                    <dt className="col-span-1">
                      <p className="text-sm text-gray-500 dark:text-neutral-500">
                        Company email:
                      </p>
                    </dt>
                    <dd className="col-span-2  flex items-center">
                      <p className="text-sm text-gray-800 dark:text-neutral-200">
                        {user?.email}
                      </p>
                    </dd>
                  </dl>
                  <dl className="py-4 grid grid-cols-3 gap-x-4 border-t border-gray-200 dark:border-neutral-700">
                    <dt className="col-span-1 items-center">
                      <p className="text-sm text-gray-500 dark:text-neutral-500">
                        Personal email:
                      </p>
                    </dt>
                    <dd className="col-span-2 flex items-center">
                      <p className="text-sm text-gray-800 dark:text-neutral-200">
                        {user?.personalEmail}
                      </p>
                    </dd>
                  </dl>
                  <dl className="py-4 grid grid-cols-3 gap-x-4 border-t border-gray-200 dark:border-neutral-700">
                    <dt className="col-span-1 items-center">
                      <p className="text-sm text-gray-500 dark:text-neutral-500">
                        SSN:
                      </p>
                    </dt>
                    <dd className="col-span-2">
                      <div className="flex items-center gap-x-2">
                        <span className="text-sm text-gray-800 dark:text-neutral-200">
                          <SSNDisplay value={user?.ssn} />
                        </span>
                      </div>
                    </dd>
                  </dl>
                  <dl className="py-4 grid grid-cols-3 gap-x-4 border-t border-gray-200 dark:border-neutral-700">
                    <dt className="col-span-1 items-center">
                      <p className="text-sm text-gray-500 dark:text-neutral-500">
                        Phone number:
                      </p>
                    </dt>
                    <dd className="col-span-2 flex items-center">
                      <p className="text-sm text-gray-800 dark:text-neutral-200">
                        <PhoneNumberDisplay value={user?.phoneNumber} />
                      </p>
                    </dd>
                  </dl>
                  <dl className="py-4 grid grid-cols-3 items-center gap-x-4 border-t border-gray-200 dark:border-neutral-700">
                    <dt className="col-span-1 items-center">
                      <p className="text-sm text-gray-500 dark:text-neutral-500">
                        Role:
                      </p>
                    </dt>
                    <dd className="col-span-2 items-center">
                      <p className="text-sm text-gray-800 dark:text-neutral-200">
                        {user?.role?.nameAlias}
                      </p>
                    </dd>
                  </dl>
                  <dl className="py-4 grid grid-cols-3 items-center gap-x-4 border-t border-gray-200 dark:border-neutral-700">
                    <dt className="col-span-1 items-center">
                      <p className="text-sm text-gray-500 dark:text-neutral-500">
                        Status:
                      </p>
                    </dt>
                    <dd className="col-span-2 items-center">
                      <p className="text-sm text-gray-800 dark:text-neutral-200">
                        {user?.status}
                      </p>
                    </dd>
                  </dl>
                  {user?.supervisors?.map(supervisor => (
                    <dl
                      key={supervisor.id}
                      className="py-4 grid grid-cols-3 items-center gap-x-4 border-t border-gray-200 dark:border-neutral-700">
                      <dt className="col-span-1 items-center">
                        <p className="text-sm text-gray-500 dark:text-neutral-500">
                          {supervisor.role?.nameAlias}:
                        </p>
                      </dt>
                      <dd className="col-span-2 items-center">
                        <p className="text-sm text-gray-800 dark:text-neutral-200">
                          {supervisor.user?.name}
                        </p>
                      </dd>
                    </dl>
                  ))}
                  <dl className="py-4 grid grid-cols-3 gap-x-4 border-t border-gray-200 dark:border-neutral-700">
                    <dt className="col-span-1 items-center">
                      <p className="text-sm text-gray-500 dark:text-neutral-500">
                        Location:
                      </p>
                    </dt>
                    <dd className="col-span-2 items-center">
                      <p className="text-sm text-gray-800 dark:text-neutral-200">
                        {user?.locations
                          ?.map(location => location.name)
                          .join(', ')}
                      </p>
                    </dd>
                  </dl>
                  <dl className="py-4 grid grid-cols-3 gap-x-4 border-t border-gray-200 dark:border-neutral-700">
                    <dt className="col-span-1 items-center">
                      <p className="text-sm text-gray-500 dark:text-neutral-500">
                        Credentials:
                      </p>
                    </dt>
                    <dd className="col-span-2 items-center">
                      <p className="text-sm text-gray-800 dark:text-neutral-200">
                        {user?.credentials
                          ?.map((credential: Credential) => credential.name)
                          .join(', ')}
                      </p>
                    </dd>
                  </dl>
                  <dl className="py-4 grid grid-cols-3 items-center gap-x-4 border-t border-gray-200 dark:border-neutral-700">
                    <dt className="col-span-1 items-center">
                      <p className="text-sm text-gray-500 dark:text-neutral-500">
                        Signature:
                      </p>
                    </dt>
                    <dd className="col-span-2 items-center">
                      {user?.signature ? (
                        <img
                          src={user?.signature}
                          alt="User Signature"
                          className="w-32"
                        />
                      ) : (
                        <p className="text-sm text-gray-500 dark:text-neutral-500">
                          No signature available
                        </p>
                      )}
                    </dd>
                  </dl>
                </div>
                <dl className="py-2 justify-start gap-2 border-t border-gray-200 dark:border-neutral-700">
                  {user?.status === 'Active' && (
                    <div
                      className="mt-6 mb-6 gap-1 flex items-center text-sm font-light tracking-normal leading-5 underline text-zinc-400 hover:text-cyan-400"
                      style={{cursor: 'pointer'}}
                      onClick={() => handleDeactivate(user?.id)}>
                      Deactivate <ChevronRightIcon />
                    </div>
                  )}
                  {user?.status === 'Disabled' && (
                    <div
                      className="mt-6 mb-6 gap-1 flex items-center text-sm font-light tracking-normal leading-5 underline text-zinc-400 hover:text-cyan-400"
                      style={{cursor: 'pointer'}}
                      onClick={() => handleReactivate(user?.id)}>
                      Reactivate <ChevronRightIcon />
                    </div>
                  )}
                  {user?.locked && (
                    <div
                      className="mt-6 mb-6 gap-1 flex items-center text-sm font-light tracking-normal leading-5 underline text-zinc-400 hover:text-cyan-400"
                      style={{cursor: 'pointer'}}
                      onClick={handleUnlock}>
                      Unlock <ChevronRightIcon />
                    </div>
                  )}
                  {user?.blocked && (
                    <div
                      className="mt-6 mb-6 gap-1 flex items-center text-sm font-light tracking-normal leading-5 underline text-zinc-400 hover:text-cyan-400"
                      style={{cursor: 'pointer'}}
                      onClick={handleUnblock}>
                      Unblock <ChevronRightIcon />
                    </div>
                  )}
                </dl>
              </div>
            </div>
          </div>
          <footer className="flex-shrink-0 px-7 pb-4 flex justify-end gap-5">
            <div className="border-t border-slate-200 w-full">
              <div className="flex justify-center items-center gap-x-2 pb-4">
                <SubmitButton
                  onClick={handleEditing()}
                  label={
                    <>
                      <PencilSquareIcon /> {'Edit User'}{' '}
                    </>
                  }
                  disabled={false}
                />
              </div>
              <div className="flex  justify-center items-center gap-x-2 pb-4">
                <button
                  type="submit"
                  onClick={onClose}
                  className="self-center text-sm font-light tracking-normal leading-5 text-center text-zinc-400 hover:text-cyan-400">
                  Close
                </button>
              </div>
            </div>
          </footer>
        </div>
      )}
      {user === null && (
        <div className="h-full">
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
};

export default UserDetails;
