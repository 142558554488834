import React from 'react';

interface XCircleProps {
    className?: string
}

const XCircleIcon = ({ className }: XCircleProps) => (
    <svg width="14" height="14" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
        <g id="x-circle" clipPath="url(#clip0_684_771)">
            <path
                d="M5.00004 9.16667C7.30123 9.16667 9.16671 7.30119 9.16671 5C9.16671 2.69882 7.30123 0.833336 5.00004 0.833336C2.69885 0.833336 0.833374 2.69882 0.833374 5C0.833374 7.30119 2.69885 9.16667 5.00004 9.16667Z"
                stroke="currentColor" strokeWidth="0.8"
            />
            <path
                d="M6.25 3.75L3.75 6.25"
                stroke="currentColor" strokeWidth="0.8"
            />
            <path
                d="M3.75 3.75L6.25 6.25"
                stroke="currentColor" strokeWidth="0.8"
            />
        </g>
        <defs>
            <clipPath id="clip0_684_771">
                <rect width="10" height="10" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);
export default XCircleIcon;
