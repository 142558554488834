import {TrainingService} from 'services/trainingService';
import {createEntityThunks} from 'utils/createEntityThunks';
import {Training} from 'types/training';

const trainingFactory = (data: any) => new Training(data);

export const TrainingThunks = createEntityThunks<Training>(
  'training',
  TrainingService,
  {activate: true, deactivate: true},
  trainingFactory,
);
