import React from 'react';
import PhoneNumberInput from 'components/inputs/PhoneNumberInput';
import {
  ElementProps,
  PhoneNumberInputElementClass,
} from 'types/documentTemplateElement';
import {buildLabel, checkError} from 'utils/elementUtils';
import {useIsDocumentElementDisabled} from "../../../hooks/useIsDocumentElementDisabled";


interface PhoneNumberInputElementProps
  extends ElementProps<PhoneNumberInputElementClass> {}

const PhoneNumberInputElementComponent: React.FC<
  PhoneNumberInputElementProps
> = ({element, updateElement, mode}) => {
  const handleSetPhoneNumber = (newNumber: string) => {
    if (mode === 'view' || mode === 'admin') {
      const newUpdatedElement = new PhoneNumberInputElementClass({
        ...element,
        properties: {
          ...element.properties,
          phoneNumber: newNumber,
          value: newNumber,
        },
      });
      updateElement(newUpdatedElement);
    }
  };
  const disabled = useIsDocumentElementDisabled(element, mode);

  


  let containerClass = 'w-full m-8';
  if (mode === 'view' || mode === 'preview' || mode === 'admin') {
    containerClass = element.properties.showLabel
      ? 'w-full mx-3 mb-2'
      : 'w-full mt-5 mx-3 mb-2';
  }


  return (
    <div className={containerClass}>
      <PhoneNumberInput
        id={element.id}
        error={checkError(element, mode) === ''}
        label={buildLabel(element)}
        value={element.properties?.value || ''}
        placeholder={element.properties.placeholder}
        required={element.properties.required}
        onChange={() => {}}
        phoneNumber={element.properties.phoneNumber || ''}
        setPhoneNumber={handleSetPhoneNumber}
        isClickDisabled={mode === 'preview'}
        disabled={disabled}
      />
    </div>
  );
};

export default PhoneNumberInputElementComponent;
