import {createEntitySlice} from 'utils/createEntitySlice';
import {Role} from 'types/role';
import {RoleThunks} from 'features/role/roleThunks';
import {PermissionSchemeGroupThunks} from './permissionSchemeGroupThunks';

export interface RoleState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  roles?: any;
  permissionSchemeGroups: any;
  pagy?: any;
  role?: any;
  activeTab?: string;
}

const initialState: RoleState = {
  status: 'idle',
  error: null,
  roles: [],
  role: {name: '', nameAlias: ''},
  permissionSchemeGroups: [],
  pagy: {},
  activeTab: 'All',
};

const roleSlice = createEntitySlice<Role>({
  name: 'role',
  initialState,
  thunks: RoleThunks,
  entityKey: 'role',
  entitiesKey: 'roles',
  reducers: {},
  extraReducers: {
    [PermissionSchemeGroupThunks.index.fulfilled.type]: (state, action) => {
      state.permissionSchemeGroups = action.payload;
    },
  },
});

export const {setEntity: setRole, setActiveTab} = roleSlice.actions;
export {initialState as roleInitialState};
export default roleSlice.reducer;
