import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import DocViewer, {DocViewerRenderers} from '@cyntler/react-doc-viewer';
import {AppDispatch} from 'store/store';
import WhiteButton from '../components/WhiteButton';
import XIcon from '../assets/images/icons/XIcon';
import DownloadIcon from '../assets/images/icons/DownloadIcon';
import '@cyntler/react-doc-viewer/dist/index.css';
import {DocumentTemplate} from '../types/documentTemplate';
import LogoNewHope from '../assets/images/LogoNewHope.png';
import {DocumentTemplateRow} from '../types/documentTemplateRow';
import {DocumentTemplateColumn} from '../types/documentTemplateColumn';
import RenderElementContent from '../components/documentTemplate/RenderElementContent';

interface UseViewerModalProps<_T> {
  openDrawer: () => void;
  fetchItemThunk: (id: string) => any;
  fetchDocumentTemplateThunk?: (args: any) => any;
}

const useViewerModal = <T,>({
  openDrawer,
  fetchItemThunk,
  fetchDocumentTemplateThunk,
}: UseViewerModalProps<T>) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [label, setLabel] = useState<string>('');
  const [selectedAttachmentUrl, setSelectedAttachmentUrl] = useState<
    string | null
  >(null);
  const [selectedItemType, setSelectedItemType] = useState<
    'file' | 'template' | null
  >(null);
  const [documentTemplateData, setDocumentTemplateData] = useState<any>(null);

  const dispatch = useDispatch<AppDispatch>();

  const openModalForTemplate = (docData: any, label: string) => {
    console.log('openModalForTemplate', docData, label);
    const documentTemplate = new DocumentTemplate(docData.snapshot);
    setDocumentTemplateData(documentTemplate);
    setLabel(label);
    setSelectedItemType('template');
    setIsModalOpen(true);
  };

  const openModalForFile = (attachmentUrl: string, label: string) => {
    setSelectedAttachmentUrl(attachmentUrl);
    setLabel(label);
    setSelectedItemType('file');
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedAttachmentUrl(null);
    setSelectedItemType(null);
    setDocumentTemplateData(null);
    setLabel('');
  };
  const handleDownload = (attachmentUrl: string) => {
    if (attachmentUrl) {
      window.open(attachmentUrl, '_blank');
    }
  };

  const handleNameClick = async (
    item: T & {
      id: string;
      status: string;
      attachment?: {url?: string};
      name?: string;
      docType?: 'file' | 'template';
    },
  ) => {
    if (item.docType === 'template') {
      if (fetchDocumentTemplateThunk) {
        const search = {
          where: {
            owner_id: {
              operator: '==',
              value: item.id,
            },
            owner_type: {
              operator: '==',
              value: 'UserApplication',
            },
          },
        };
        const response = await dispatch(
          fetchDocumentTemplateThunk({search: search}),
        );
        if (response.payload) {
          openModalForTemplate(response.payload.data[0], item.name || '');
        } else {
          alert('Could not fetch template data.');
        }
      } else {
        openDrawer();
      }
    } else {
      if (item.status === 'Complete' || item.attachment?.url != null) {
        if (item.attachment?.url) {
          openModalForFile(item.attachment.url, item.name || '');
        } else {
          alert('No attachment found.');
        }
      } else {
        await dispatch(fetchItemThunk(item.id));
        openDrawer();
      }
    }
  };

  const AttachmentModal = () => {
    if (!isModalOpen) return null;

    return (
      <div
        className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
        onClick={closeModal}>
        <div
          className="absolute bg-white rounded-xl w-4/6 py-6 h-[90vh] "
          onClick={e => e.stopPropagation()}>
          <div className="flex justify-between items-start gap-2 px-4">
            <div className="flex items-center gap-4">
              <div className="text-slate-600 text-xl font-semibold font-['Inter'] tracking-tight">
                {label}
              </div>
              {selectedItemType === 'file' && selectedAttachmentUrl && (
                <WhiteButton
                  onClick={() => handleDownload(selectedAttachmentUrl || '')}
                  icon={<DownloadIcon />}
                />
              )}
            </div>
            <button
              onClick={closeModal}
              className="text-gray-500 hover:text-gray-700 focus:outline-none pt-2">
              <XIcon />
            </button>
          </div>
          <div className="flex p-4 h-full scroll-auto">
            {selectedItemType === 'file' && selectedAttachmentUrl && (
              <DocViewer
                pluginRenderers={DocViewerRenderers}
                documents={[{uri: selectedAttachmentUrl || ''}]}
                style={{height: '78h', overflow: 'auto'}}
                config={{
                  header: {
                    disableHeader: false,
                    disableFileName: true,
                    retainURLParams: false,
                  },
                  csvDelimiter: ',',
                  pdfVerticalScrollByDefault: true,
                }}
              />
            )}
            {selectedItemType === 'template' && documentTemplateData && (
              <div className="flex justify-center w-full">
                <div className=" px-8 py-8 bg-white  w-full dark:bg-neutral-800 dark:border-neutral-700 overflow-auto">
                  {documentTemplateData.enableHeader && (
                    <div className="flex mb-4">
                      <div className={`flex-1 px-5 relative`}>
                        <div className="w-full h-full min-h-20  flex items-center justify-start">
                          <div className="text-slate-600 text-lg font-medium font-['Inter']">
                            <img
                              className="pl-3 w-24 h-auto"
                              src={LogoNewHope}
                              alt="Logo"
                            />
                          </div>
                          <div className="text-slate-400 text-md font-light font-['Inter'] leading-normal ml-1 mr-1">
                            |
                          </div>
                          <div className="text-slate-400 text-md font-light font-['Inter'] leading-normal">
                            {documentTemplateData.name}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {documentTemplateData.rows?.map(
                    (row: DocumentTemplateRow) => {
                      if (row._destroy) {
                        return null;
                      }
                      return (
                        <div key={row.id} className="flex mb-4">
                          {row.columns.map((col: DocumentTemplateColumn) => (
                            <div key={col.id} className={`flex-1 px-4`}>
                              {col.element && col.element._destroy !== true ? (
                                <div className="w-full h-full min-h-20 flex items-start justify-start">
                                  <RenderElementContent element={col.element} />
                                </div>
                              ) : (
                                <div className="w-full h-full min-h-20 flex items-center justify-center text-slate-400"></div>
                              )}
                            </div>
                          ))}
                        </div>
                      );
                    },
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return {
    openModalForFile,
    closeModal,
    AttachmentModal,
    handleNameClick,
  };
};

export default useViewerModal;
