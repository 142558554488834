import React, {useEffect, useState} from 'react';
import {AppDispatch, RootState} from 'store/store';
import {useDispatch, useSelector} from 'react-redux';
import TextInput from 'components/inputs/TextInput';
import {User} from 'types/user';
import {validateEmail} from 'utils/validators';
import {UserThunks} from 'features/user/userThunks';
import SSNInput from 'components/inputs/SSNInput';
import PhoneNumberInput from 'components/inputs/PhoneNumberInput';
import {showToast} from 'components/ToastContainer';
import {FaUser} from 'react-icons/fa';
import CustomSelect from 'components/inputs/CustomSelect';
import SubmitButton from 'components/SubmitButton';
import {JobRole} from 'types/jobRole';
import {JobRoleThunks} from '../jobRole/jobRoleThunks';
import {UserService} from 'services/userService';
import {SearchType} from 'types/search';
import XIcon from 'assets/images/icons/XIcon';
import {PermissionSchemeGroup} from 'types/permissionSchemeGroup';
import {Permission} from 'types/permission';
import {PermissionSchemeGroupThunks} from '../role/permissionSchemeGroupThunks';
import {PermissionScheme} from 'types/permissionScheme';
import RadioInputHorizontal from 'components/inputs/RadioInputHorizontal';
import {Location} from 'types/location';
import {LocationThunks} from '../location/locationThunks';
import {setUser} from "./userSlice";

interface UserEditFormProps {
  onClose: () => void;
}

const UserEditForm: React.FC<UserEditFormProps> = ({onClose}) => {
  const user = useSelector((state: RootState) => state.user.user);
  const dispatch = useDispatch<AppDispatch>();
  const [emailError, setEmailError] = useState<string | undefined>();
  const [personalEmailError, setPersonalEmailError] = useState<string | undefined>();
  const [ssn, setSSN] = useState(user?.ssn);
  const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber);
  const permissionSchemeGroups = useSelector(
    (state: RootState) => state.role?.permissionSchemeGroups,
  );
  const [activeTab] = useState('Profile');
  const jobRoles = useSelector((state: RootState) => state.jobRole.jobRoles);
  const [supervisorOptions, setSupervisorOptions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailToCheck, setEmailToCheck] = useState('');
  const debounceTimeoutRef = React.useRef<NodeJS.Timeout | null>(null);

  const jobRolesOptions = jobRoles.map((jobRole: JobRole) => ({
    value: jobRole.id,
    label: jobRole.name,
  }));
  const location = useSelector((state: RootState) => state.location.locations);
  const locationOptions = location.map((location: Location) => ({
    value: location.id,
    label: location.name,
  }));

  const supervisorSelectOptions = supervisorOptions.map((user: User) => ({
    value: user.id,
    label: `${user.firstName} ${user.lastName}`,
  }));

  const [formData, setFormData] = useState({
    ...user,
  });

  const handleClose = () => {
    dispatch(setUser(null));
    onClose();
  };

  useEffect(() => {
    setSSN(user?.ssn);
    setPhoneNumber(user?.phoneNumber);
    setFormData({
      ...user,
    });
  }, [user]);

  useEffect(() => {
    const loadSelectData = async () => {
      try {
        await dispatch(JobRoleThunks.index());
        await dispatch(LocationThunks.index());
        await dispatch(PermissionSchemeGroupThunks.index());
        const search: SearchType<{[key: string]: any}> = {
          where: {
            is_supervisor: {
              operator: '==',
              value: 'true',
            },
          },
        };

        const response = await UserService.index(1, search, '', '', 1000);
        setSupervisorOptions(response.data);
      } catch (err) {}
    };
    loadSelectData();
  }, [dispatch]);

  useEffect(() => {
    if (emailToCheck === '') {
      return;
    }
    if (emailToCheck === user?.email) {
      setEmailError(undefined);
      return;
    }
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = setTimeout(async () => {
      try {
        const response = await UserService.checkEmailExists(emailToCheck);

        if (response.data.exists) {
          setEmailError('This email is already in use.');
        } else {
          setEmailError(undefined);
        }
      } catch (error) {}
    }, 500);
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, [emailToCheck, user?.email]);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {id, checked} = e.target;
    const [subjectClass, _, action] = id.split('_');

    if (!subjectClass) return;
    const permissions = formData.role?.permissions
      ? [...formData.role?.permissions]
      : [];
    if (checked) {
      permissions.push({subjectClass, action} as Permission);
      if (action === 'manage') {
        const permissionSchemeGroup = permissionSchemeGroups.find(
          (permissionSchemeGroup: PermissionSchemeGroup) =>
            permissionSchemeGroup.subjectClass === subjectClass,
        );
        if (permissionSchemeGroup) {
          permissionSchemeGroup.permissionSchemes?.forEach(
            (permission: PermissionScheme) => {
              const index = permissions.findIndex(
                (p: Permission) =>
                  p.subjectClass === subjectClass &&
                  p.action === permission.action,
              );
              if (index === -1) {
                permissions.push({
                  subjectClass,
                  action: permission.action,
                } as Permission);
              }
            },
          );
        }
      }
    } else {
      const index = permissions.findIndex(
        (permission: Permission) =>
          permission.subjectClass === subjectClass &&
          permission.action === action,
      );
      if (index !== -1) {
        if (permissions[index].id) {
          const permission = {...permissions[index], _destroy: true};
          permissions.splice(index, 1);
          permissions.push(permission);
        } else {
          permissions.splice(index, 1);
        }
      }
    }
    setFormData((prevData: any) => ({
      ...prevData,
      role: {
        ...prevData.role,
        permissions,
      },
    }));
  };

  const handleRadioChange = (field: string, value: boolean) => {
    setFormData((prevState: any) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const {id, value} = e.target;
    if (id === 'email') {
      const strippedValue = value.trim();
      if (validateEmail(strippedValue)) {
        setEmailError(undefined);
        setEmailToCheck(strippedValue);
      } else {
        setEmailError('Please enter a valid email address.');
        setEmailToCheck('');
      }
    } else if (id === 'personalEmail') {
      const strippedValue = value.trim();
      if (validateEmail(strippedValue)) {
        setPersonalEmailError(undefined);
      } else {
        setPersonalEmailError('Please enter a valid email address.');
      }
    }
    setFormData((prevData: any) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    setIsSubmitting(true);
    e.preventDefault();
    if (emailError) {
      showToast('Please enter a valid email address.', 'error');
      setIsSubmitting(false);
      return;
    }

    try {
      await dispatch(UserThunks.update(new User(formData)))
        .unwrap()
        .then(() => {
          setIsSubmitting(false);
          onClose();
        });
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex flex-col h-screen">
      <div className="flex-1 flex flex-col">
        <div className="h-full flex flex-col overflow-y-auto overflow-hidden [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500">
          <div className="h-auto p-6 bg-sky-50 border-b border-sky-200 flex justify-between items-start gap-2 w-full">
            <div className=" self-stretch flex flex-row gap-3">
              <div className="flex-shrink-0 w-14 h-14 mt-1.5 bg-cyan-400 rounded-lg flex justify-center items-center">
                <FaUser className="text-white size-8" />
              </div>
              <div className="flex flex-col w-full">
                <div className="w-full text-lg font-semibold tracking-normal text-cyan-800">
                  {user?.firstName} {user?.lastName}
                </div>
                <div className="text-sm font-light tracking-normal leading-tight text-zinc-400">
                  Employee ID: {user?.id}
                </div>
                <div className="text-sm w-max font-light tracking-normal leading-tight text-zinc-400">
                  Last Login: {user?.lastLogin}
                </div>
              </div>
            </div>
            <button
              onClick={handleClose}
              className="text-gray-500 hover:text-gray-700 focus:outline-none pt-2">
              <XIcon />
            </button>
          </div>
          <div
            id="hs-pro-tabs-dut-Profile"
            className={activeTab === 'Profile' ? 'block' : 'hidden'}>
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col p-6 space-y-6 items-center">
                <div className="grid grid-cols-2 gap-4 w-full ">
                  <TextInput
                    id="firstName"
                    type="text"
                    label="First Name"
                    value={formData.firstName || ''}
                    onChange={handleChange}
                  />
                  <TextInput
                    id="lastName"
                    type="text"
                    label="Last Name"
                    value={formData.lastName || ''}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex flex-col gap-[0.625rem] w-full">
                  <label
                    htmlFor="roleId"
                    className="w-full block grow shrink basis-0 text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
                    Role(s)
                  </label>
                  <CustomSelect
                    options={jobRolesOptions}
                    isMulti={true}
                    onChange={selectedOptions => {
                      const selectedValues = selectedOptions.map(
                        (option: any) => option.value,
                      );
                      setFormData((prevData: any) => ({
                        ...prevData,
                        jobRoleIds: selectedValues,
                      }));
                    }}
                    value={formData.jobRoleIds}
                  />
                </div>
                <div className="flex flex-col gap-[0.625rem] w-full">
                  <label
                    htmlFor="roleId"
                    className="w-full block grow shrink basis-0 text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
                    Supervisor(s)
                  </label>
                  <CustomSelect
                    options={supervisorSelectOptions}
                    isMulti={true}
                    onChange={selectedOptions => {
                      const selectedValues = selectedOptions.map(
                        (option: any) => option.value,
                      );
                      setFormData((prevData: any) => ({
                        ...prevData,
                        supervisorIds: selectedValues,
                      }));
                    }}
                    value={formData.supervisorIds}
                  />
                </div>
                <div className="w-full">
                  <div className="flex flex-col gap-[0.625rem] w-full">
                    <label
                      htmlFor="roleId"
                      className="w-full block grow shrink basis-0 text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
                      Applicable location(s)
                    </label>
                    <CustomSelect
                      options={locationOptions}
                      isMulti={true}
                      onChange={selectedOptions => {
                        const selectedValues = selectedOptions.map(
                          (option: any) => option.value,
                        );
                        setFormData((prevData: User) => ({
                          ...prevData,
                          locationIds: selectedValues,
                        }));
                      }}
                      value={formData.locationIds}
                    />
                  </div>
                </div>
                <div className="w-full">
                  <TextInput
                    id="email"
                    type="text"
                    label="Company email"
                    value={formData.email || ''}
                    onChange={handleChange}
                    error={emailError}
                  />
                </div>
                <div className="w-full">
                  <TextInput
                    id="personalEmail"
                    type="text"
                    label="Personal email"
                    value={formData.personalEmail || ''}
                    onChange={handleChange}
                    error={personalEmailError}
                  />
                </div>
                <div className="w-full">
                  <PhoneNumberInput
                    id="phoneNumber"
                    label="Phone Number"
                    value={formData.phoneNumber || ''}
                    onChange={handleChange}
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                  />
                </div>
                <div className="w-full ">
                  <SSNInput
                    id="ssn"
                    label="SSN"
                    ssn={ssn}
                    setSSN={setSSN}
                    value={formData.ssn || ''}
                    onChange={handleChange}
                  />
                </div>
                <div className="w-full">
                  <TextInput
                    id="externalId"
                    type="text"
                    label="External ID"
                    value={formData.externalId || ''}
                    onChange={handleChange}
                    optional={'optional'}
                  />
                </div>
                {formData.userType === 'user' && (
                  <div className="w-full">
                    <div className="flex justify-between items-center mb-3">
                      <h3 className="text-slate-800 text-sm font-semibold dark:text-neutral-200">
                        Permissions
                      </h3>
                      <div className="flex justify-center items-center space-x-8 text-xs pr-3 font-normal text-slate-400 dark:text-neutral-200">
                        <span>View</span>
                        <span>Create</span>
                        <span>Update</span>
                        <span>Delete</span>
                      </div>
                    </div>
                    <div className="bg-white dark:bg-neutral-800  rounded-lg  border divide-y divide-slate-200 ">
                      {permissionSchemeGroups.map(
                        (permissionSchemeGroup: PermissionSchemeGroup) => (
                          <div
                            key={permissionSchemeGroup.subjectClass}
                            className="flex justify-between items-center mb-3 space-y-3 ">
                            <span className="text-xs font-normal pl-3 pt-3 text-slate-600 dark:text-neutral-200">
                              {permissionSchemeGroup.nameAlias}
                            </span>
                            <div className="flex space-x-14 pr-6  ">
                              {['view', 'create', 'update', 'delete'].map(
                                action => (
                                  <div
                                    key={action}
                                    className="flex items-center">
                                    <input
                                      type="checkbox"
                                      id={`${permissionSchemeGroup.subjectClass}_permission_${action}`}
                                      name={`permission_${action}`}
                                      checked={
                                        formData.role?.permissions?.find(
                                          (p: Permission) =>
                                            p.subjectClass ===
                                              permissionSchemeGroup.subjectClass &&
                                            p.action === action &&
                                            !p._destroy,
                                        ) || formData.role?.name === 'owner'
                                      }
                                      onChange={handleCheckboxChange}
                                      disabled={
                                        (formData.role?.permissions?.find(
                                          (p: Permission) =>
                                            p.subjectClass ===
                                              permissionSchemeGroup.subjectClass &&
                                            p.action === 'manage' &&
                                            !p._destroy,
                                        ) &&
                                          action !== 'manage') ||
                                        formData.role?.name === 'owner'
                                      }
                                      className="border-gray-200 rounded text-cyan-400 focus:ring-cyan-300 dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                                    />
                                  </div>
                                ),
                              )}
                            </div>
                          </div>
                        ),
                      )}
                    </div>
                  </div>
                )}
                <div className="w-full">
                  <RadioInputHorizontal
                    label="Mark as supervisor?"
                    options={[
                      {id: 'isSupervisorYes', label: 'Yes', value: true},
                      {id: 'isSupervisorNo', label: 'No', value: false},
                    ]}
                    selectedValue={formData.isSupervisor ?? false}
                    onChange={value => handleRadioChange('isSupervisor', value)}
                  />
                </div>
                <div className="w-full">
                  <RadioInputHorizontal
                    label="Hide from dashboard?"
                    options={[
                      {id: 'hiddenYes', label: 'Yes', value: true},
                      {id: 'hiddenNo', label: 'No', value: false},
                    ]}
                    selectedValue={formData.hidden ?? false}
                    onChange={value => handleRadioChange('hidden', value)}
                  />
                </div>
              </div>
            </form>
            <footer className="flex-shrink-0 px-6 pb-4  pt-5 flex justify-end gap-5">
              <div className="border-t border-slate-200 w-full">
                <div className="flex w-fulljustify-center items-center gap-x-2 pb-4">
                  <SubmitButton
                    onClick={handleSubmit}
                    label={'Update user profile'}
                    disabled={isSubmitting || emailError !== undefined}
                  />
                </div>
                <div className="flex w-full justify-center items-center gap-x-2 pb-4">
                  <button
                    type="submit"
                    onClick={handleClose}
                    className="self-center text-sm font-light tracking-normal leading-5 text-center text-zinc-400 hover:text-cyan-400">
                    Never mind
                  </button>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserEditForm;
