import React, {useState, useRef, useEffect} from 'react';
import {format, addMonths, subMonths, isBefore} from 'date-fns';
import ChevronLeftIcon from 'assets/images/icons/ChevronLeftIcon';
import ChevronRightIcon from 'assets/images/icons/ChevronRightIcon';
import DateTimeIcon from '../../assets/images/icons/DateTimeIcon';

interface DateTimeInputProps {
  label?: string;
  id: string;
  error?: string | undefined;
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  className?: string;
  optional?: string;
  required?: boolean;
  isClickDisabled?: boolean;
  allowPastDates?: boolean;
  disabled?: boolean;
}

const DateTimeInput: React.FC<DateTimeInputProps> = ({
  label,
  id,
  error,
  value,
  onChange,
  className = 'w-full',
  optional,
  disabled,
  isClickDisabled = false,
  allowPastDates = false,
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedHour, setSelectedHour] = useState<number | null>(null);
  const [selectedMinute, setSelectedMinute] = useState<number | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const today = new Date();

  const toggleDropdown = () => setIsDropdownOpen(prev => !prev);

  const handleDateClick = (day: number) => {
    const newDate = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth(),
      day,
    );
    if (!allowPastDates && isBefore(newDate, today)) {
      return;
    }
    setSelectedDate(newDate);
    updateInputValue(newDate, selectedHour, selectedMinute);
    onChange(format(newDate, 'MM/dd/yyyy'));
    if (selectedHour !== null && selectedMinute !== null) {
      setIsDropdownOpen(false);
    }
  };

  const handleTimeSelection = (hour: number, minute?: number) => {
    setSelectedHour(hour);
    if (minute !== undefined) {
      setSelectedMinute(minute);
    }
    if (selectedDate !== null && minute !== undefined) {
      updateInputValue(selectedDate, hour, minute);

      setIsDropdownOpen(false);
    }
  };

  const updateInputValue = (
    date: Date | null,
    hour: number | null,
    minute: number | null,
  ) => {
    if (date && hour !== null && minute !== null) {
      const formattedDateTime = `${format(
        date,
        'MM/dd/yyyy',
      )} ${hour.toString().padStart(2, '0')}:${minute
        .toString()
        .padStart(2, '0')}`;
      onChange(formattedDateTime);
    }
  };

  const daysInMonth = new Date(
    currentMonth.getFullYear(),
    currentMonth.getMonth() + 1,
    0,
  ).getDate();
  const firstDayOfMonth = new Date(
    currentMonth.getFullYear(),
    currentMonth.getMonth(),
    1,
  ).getDay();
  const days = [
    ...Array(firstDayOfMonth).fill(null),
    ...Array.from({length: daysInMonth}, (_, i) => i + 1),
  ];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="flex flex-col items-start gap-[0.625rem]">
      <div className="flex justify-between w-full">
        {label && (
          <label
            htmlFor={id}
            className="block grow shrink basis-0 text-slate-800 text-sm font-semibold dark:text-white">
            {label}
          </label>
        )}
        {optional && <span className="text-gray-400 text-sm">{optional}</span>}
      </div>
      <div className="relative w-full">
        <input
          type="text"
          id={id}
          value={
            selectedDate && selectedHour !== null && selectedMinute !== null
              ? `${format(selectedDate, 'MM/dd/yyyy')} ${selectedHour
                  .toString()
                  .padStart(
                    2,
                    '0',
                  )}:${selectedMinute.toString().padStart(2, '0')}`
              : value
          }
          readOnly
          placeholder="MM/DD/YYYY HH:MM"
          className={`py-2.5 px-3 w-full  pl-10 block border  rounded-lg text-sm text-gray-500 disabled:bg-slate-100  
                      disabled:text-slate-300 disabled:border-0 disabled:pointer-events-none disabled:cursor-not-allowed
                      ${error !== undefined ? 'border-red-500' : 'border-slate-200'} 
                      ${className}`}
          onClick={!isClickDisabled ? toggleDropdown : undefined}
          disabled={disabled}
        />
        <button
          type="button"
          className={`absolute inset-y-0 left-0 flex items-center px-3 text-gray-400 ${isClickDisabled ? 'pointer-events-none' : ''}`}
          onClick={!isClickDisabled ? toggleDropdown : undefined}>
          <DateTimeIcon />
        </button>

        {isDropdownOpen && (
          <div
            ref={dropdownRef}
            className="absolute bg-white border shadow rounded-lg mt-2 grid min-w-[500px] grid-cols-5 z-20  ">
            <div className="p-3 border-r border-r-slate-200 col-span-3 ">
              <div className="grid grid-cols-5 items-center gap-x-3 pb-3">
                <button
                  type="button"
                  className="size-8 flex justify-center items-center text-gray-800 hover:bg-gray-100 rounded-full focus:outline-none"
                  onClick={() => setCurrentMonth(subMonths(currentMonth, 1))}>
                  <ChevronLeftIcon />
                </button>
                <div className="col-span-3 text-center font-medium text-gray-800">
                  {format(currentMonth, 'MMMM yyyy')}
                </div>
                <button
                  type="button"
                  className="size-8 flex justify-center items-center text-gray-800 hover:bg-gray-100 rounded-full focus:outline-none"
                  onClick={() => setCurrentMonth(addMonths(currentMonth, 1))}>
                  <ChevronRightIcon />
                </button>
              </div>
              <div className="grid grid-cols-7 gap-1 text-center text-gray-500 text-sm">
                {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map(day => (
                  <span key={day}>{day}</span>
                ))}
                {days.map((day, index) => {
                  const currentDayDate = new Date(
                    currentMonth.getFullYear(),
                    currentMonth.getMonth(),
                    day,
                  );

                  const isPastDay = isBefore(currentDayDate, today);
                  const isDisabled = !day || (!allowPastDates && isPastDay);
                  const isSelected =
                    selectedDate &&
                    selectedDate.getDate() === day &&
                    selectedDate.getMonth() === currentMonth.getMonth() &&
                    selectedDate.getFullYear() === currentMonth.getFullYear();

                  return (
                    <button
                      key={index}
                      type="button"
                      className={`m-px h-10 w-10 flex justify-center items-center text-sm rounded-full
                                  ${isDisabled ? 'text-slate-200' : 'text-slate-800'}
                                  ${isSelected ? 'bg-cyan-400 text-white' : 'hover:bg-white hover:text-cyan-400 hover:border hover:border-cyan-400'}  `}
                      disabled={isDisabled}
                      onClick={() => day && handleDateClick(day)}>
                      {day}
                    </button>
                  );
                })}
              </div>
            </div>
            <div className="p-4 gap-4 col-span-2 ">
              <div className="grid grid-cols-2">
                <div>
                  <h4 className="text-center font-medium text-gray-800">
                    Hours
                  </h4>
                  <div className="max-h-60 pt-3 overflow-y-auto">
                    {Array.from({length: 24}).map((_, hour) => (
                      <button
                        key={hour}
                        type="button"
                        className={`py-1 px-2 text-sm rounded w-full ${
                          selectedHour === hour
                            ? 'bg-cyan-400 text-white'
                            : 'hover:bg-cyan-100 text-gray-800'
                        }`}
                        onClick={() =>
                          handleTimeSelection(hour, selectedMinute || 0)
                        }>
                        {hour.toString().padStart(2, '0')}
                      </button>
                    ))}
                  </div>
                </div>
                <div>
                  <h4 className="text-center font-medium text-gray-800 pb-3">
                    Minutes
                  </h4>
                  <div className="max-h-56 overflow-y-auto">
                    {Array.from({length: 60}).map((_, minute) => (
                      <button
                        key={minute}
                        type="button"
                        className={`py-1 px-2 text-sm rounded w-full ${
                          selectedMinute === minute
                            ? 'bg-cyan-400 text-white'
                            : 'hover:bg-cyan-100 text-gray-800'
                        }`}
                        onClick={() =>
                          selectedHour !== null &&
                          handleTimeSelection(selectedHour, minute)
                        }>
                        {minute.toString().padStart(2, '0')}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {error && <p className="text-red-500 text-xs">{error}</p>}
    </div>
  );
};

export default DateTimeInput;
