import React from 'react';
import TextInput from 'components/inputs/TextInput';
import {
  ElementProps,
  TextInputElementClass,
} from 'types/documentTemplateElement';
import {buildLabel, checkError} from 'utils/elementUtils';
import {useIsDocumentElementDisabled} from 'hooks/useIsDocumentElementDisabled';

interface TextInputElementProps extends ElementProps<TextInputElementClass> {}

const TextInputElementComponent: React.FC<TextInputElementProps> = ({
  element,
  updateElement,
  mode,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (mode === 'view' || mode === 'admin') {
      const newUpdatedElement = new TextInputElementClass({
        ...element,
        properties: {
          ...element.properties,
          value: e.target.value,
        },
      });
      updateElement(newUpdatedElement);
    }
    return;
  };

  const disabled = useIsDocumentElementDisabled(element, mode);

  let containerClass = 'w-full m-8';
  if (mode === 'view' || mode === 'preview' || mode === 'admin') {
    containerClass = element.properties.showLabel
      ? 'w-full mx-3 mb-2'
      : 'w-full mt-5 mx-3 mb-2';
  }

  return (
    <>
      <div className={containerClass}>
        <TextInput
          id={element.id}
          type="text"
          error={checkError(element, mode)}
          label={buildLabel(element)}
          value={element.properties?.value || ''}
          placeholder={element.properties.placeholder}
          required={element.properties.required}
          onChange={handleChange}
          disabled={disabled}
        />
      </div>
    </>
  );
};

export default TextInputElementComponent;
