import React from 'react';

interface CheckCircleProps {
    className?: string
}

const CheckCircleIcon = ({ className }: CheckCircleProps) => (
    <svg width="14" height="14" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
        <g id="check-circle-2" clipPath="url(#clip0_684_805)">
            <path id="Vector"
                  d="M5.00004 9.16668C7.30129 9.16668 9.16671 7.30126 9.16671 5.00001C9.16671 2.69876 7.30129 0.833344 5.00004 0.833344C2.69879 0.833344 0.833374 2.69876 0.833374 5.00001C0.833374 7.30126 2.69879 9.16668 5.00004 9.16668Z"
                  stroke="currentColor" strokeWidth="0.8" />
            <path id="Vector_2" d="M3.75 4.99999L4.58333 5.83332L6.25 4.16666" stroke="currentColor" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_684_805">
                <rect width="10" height="10" fill="white"/>
            </clipPath>
        </defs>
    </svg>
);
export default CheckCircleIcon;
