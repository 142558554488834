import {createAsyncThunk} from '@reduxjs/toolkit';
import {UserService} from 'services/userService';
import {createEntityThunks} from 'utils/createEntityThunks';
import {User} from 'types/user';

const userFactory = (data: any) => new User(data);

class BaseUserThunks extends createEntityThunks<User>(
  'user',
  UserService,
  {activate: true, deactivate: true},
  userFactory,
) {}

export class UserThunks extends BaseUserThunks {
  static register = createAsyncThunk(
    'user/register',
    async ({
      user,
      password,
      token,
      pinCode,
      signature,
      credentials,
    }: {
      user: User;
      password: string;
      token: string;
      pinCode: string;
      signature: string;
      credentials: any;
    }) => {
      const response = await UserService.register(
        user,
        token,
        password,
        pinCode,
        signature,
        credentials,
      );
      return {...response, userCredentials: {email: user.email, password}};
    },
  );

  static updateSignature = createAsyncThunk(
    'user/updateSignature',
    async (data: {id: string; signature: string}) => {
      return await UserService.updateSignature(data.id, data.signature);
    },
  );

  static updateAvatar = createAsyncThunk(
    'user/updateAvatar',
    async (data: {id: string; avatar: any}) => {
      return await UserService.updateAvatar(data.id, data.avatar);
    },
  );

  static deleteAvatar = createAsyncThunk(
    'user/deleteAvatar',
    async (id: string) => {
      return await UserService.deleteAvatar(id);
    },
  );

  static unlock = createAsyncThunk('user/unlock', async (id: string) => {
    return await UserService.unlock(id);
  });

  static unblock = createAsyncThunk('user/unblock', async (id: string) => {
    return await UserService.unblock(id);
  });
  static checkEmailExists = createAsyncThunk(
    'user/checkEmailExists',
    async (email: string) => {
      return await UserService.checkEmailExists(email);
    },
  );
  static changePassword = createAsyncThunk(
    'user/changePassword',
    async (data: {id: string; oldPassword: string; newPassword: string}) => {
      return await UserService.changePassword(
        data.id,
        data.oldPassword,
        data.newPassword,
      );
    },
  );
  static changePIN = createAsyncThunk(
    'user/changePIN',
    async (data: {id: string; password: string; newPIN: string}) => {
      return await UserService.changePIN(data.id, data.password, data.newPIN);
    },
  );
}
