import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '../../store/store';
import React, {useEffect, useState} from 'react';
import TextInput from 'components/inputs/TextInput';
import {MedicalDocument} from 'types/medicalDocument';
import SubmitButton from 'components/SubmitButton';
import {setIsEditing} from 'features/medicalDocument/medicalDocumentSlice';
import XIcon from 'assets/images/icons/XIcon';
import {MedicalDocumentThunks} from 'features/medicalDocument/medicalDocumentThunks';
import DescriptionInput from 'components/inputs/DescriptionInput';

interface MedicalDocumentFormProps {
  id?: string;
  closeDrawer: () => void;
}

const MedicalDocumentForm: React.FC<MedicalDocumentFormProps> = ({
  closeDrawer,
}) => {
  const medicalDocument = useSelector(
    (state: RootState) => state.medicalDocument.medicalDocument,
  );
  const dispatch = useDispatch<AppDispatch>();
  const [updatedMedicalDocument, setUpdatedMedicalDocument] = useState({
    ...medicalDocument,
  });
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  useEffect(() => {
    const isFormValid = updatedMedicalDocument.name;
    setIsSaveDisabled(!isFormValid);
  }, [updatedMedicalDocument.name]);
  useEffect(() => {
    setUpdatedMedicalDocument({...medicalDocument});
    setIsSaveDisabled(!medicalDocument?.name);
  }, [medicalDocument]);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const {id, value} = e.target;
    setUpdatedMedicalDocument({...updatedMedicalDocument, [id]: value});
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (medicalDocument?.id) {
      await dispatch(
        MedicalDocumentThunks.update(
          new MedicalDocument(updatedMedicalDocument),
        ),
      ).then(() => closeDrawer());
    } else {
      await dispatch(
        MedicalDocumentThunks.create(
          new MedicalDocument(updatedMedicalDocument),
        ),
      ).then(() => closeDrawer());
    }
  };

  const handleClose = () => {
    dispatch(setIsEditing(false));
    closeDrawer();
  };

  return (
    <div className="flex flex-col h-screen">
      <div className="flex-1 flex flex-col">
        <div className="h-auto p-6 bg-sky-50 border-b border-sky-200 flex justify-between items-start gap-2 w-full">
          <div className="flex flex-col justify-start items-start gap-1">
            <div className="text-lg font-semibold tracking-normal text-cyan-800">
              {medicalDocument?.id
                ? 'Edit Medical Document'
                : 'Create Medical Document'}
            </div>
            <div className="text-sm font-light tracking-normal leading-5 text-zinc-400">
              {medicalDocument?.id ? 'Edit' : 'Create a new '} Medical Document
            </div>
          </div>
          <button
            onClick={handleClose}
            className="text-gray-500 hover:text-gray-700 focus:outline-none pt-2">
            <XIcon />
          </button>
        </div>
        <div className="h-full px-6 pb-6 ">
          <div className="mt-5">
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col space-y-6 items-center mt-7">
                <div className="w-full">
                  <TextInput
                    id="name"
                    type="text"
                    label="Name"
                    value={updatedMedicalDocument?.name}
                    placeholder=""
                    onChange={handleChange}
                  />
                </div>
                <div className="w-full">
                  <DescriptionInput
                    id="description"
                    type="text"
                    label="Description"
                    value={updatedMedicalDocument?.description}
                    placeholder=""
                    onChange={handleChange}
                    optional="optional"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <footer className="flex-shrink-0 px-6 pb-4 pt-2 flex justify-end gap-5">
        <div className="border-t border-slate-200 w-full">
          <div className="flex w-full justify-center items-center gap-x-2 pb-4">
            <SubmitButton
              onClick={handleSubmit}
              label={
                (medicalDocument?.id ? 'Save' : 'Create new') +
                ' Medical Document'
              }
              disabled={isSaveDisabled}
            />
          </div>
          <div className="flex w-full justify-center items-center gap-x-2 pb-4">
            <button
              type="submit"
              onClick={handleClose}
              className="self-center text-sm font-light tracking-normal leading-5 text-center text-zinc-400 hover:text-cyan-400">
              Never mind
            </button>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default MedicalDocumentForm;
