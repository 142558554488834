import React, {useContext, useEffect, useState} from 'react';
import UserTable from 'features/user/UserTable';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'store/store';
import Drawer from 'components/Drawer';
import UserDetails from 'features/user/UserDetails';
import UserEditForm from 'features/user/UserEditForm';
import InvitationsForm from 'features/userInvitation/InvitationsForm';
import StaffInvitationTable from 'features/userInvitation/StaffInvitationTable';
import UserInvitationDetails from 'features/userInvitation/UserInvitationDetails';
import {TitleContext} from '../../components/TitleContext';
import {setIsEditing, setUser} from '../../features/user/userSlice';

const UserPage: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isNewDrawerOpen, setIsNewDrawerOpen] = useState(false);
  const openDrawer = () => setIsDrawerOpen(true);
  const closeDrawer = () => setIsDrawerOpen(false);
  const isEditing = useSelector((state: RootState) => state.user.isEditing);
  const activeTab = useSelector((state: RootState) => state.user.activeTab);
  const {setTitle} = useContext(TitleContext);
  const dispatch = useDispatch();

  const closeNewDrawer = () => {
    setIsNewDrawerOpen(false);
    dispatch(setUser(null));
    dispatch(setIsEditing(false));
  };

  const openNewDrawer = () => {
    dispatch(setIsEditing(false));
    setIsNewDrawerOpen(true);
  };

  useEffect(() => {
    setTitle(
      <div>
        <div className="text-slate-600 text-lg font-medium font-['Inter']  ">
          Users
        </div>
        <div className="text-slate-400 text-sm font-light font-['Inter'] leading-normal ">
          Manage the users and user access
        </div>
      </div>,
    );
  }, [setTitle]);

  return (
    <div className="">
      <div className="relative overflow-hidden">
        <div className="">
          <div className="relative overflow-hidden">
            {activeTab === 'Invitations' && (
              <>
                <StaffInvitationTable
                  openDrawer={openDrawer}
                  openNewDrawer={openNewDrawer}
                />
                <Drawer isOpen={isDrawerOpen} onClose={closeDrawer}>
                  <UserInvitationDetails onClose={closeDrawer} />
                </Drawer>
              </>
            )}

            {activeTab !== 'Invitations' && (
              <>
                <UserTable
                  openDrawer={openDrawer}
                  openNewDrawer={openNewDrawer}
                  isNewDrawerOpen={isNewDrawerOpen}
                />
                <Drawer isOpen={isDrawerOpen} onClose={closeDrawer}>
                  {isEditing ? (
                    <UserEditForm onClose={closeDrawer} />
                  ) : (
                    <UserDetails onClose={closeDrawer} />
                  )}
                </Drawer>
              </>
            )}
            <Drawer isOpen={isNewDrawerOpen} onClose={closeNewDrawer}>
              {isEditing ? (
                <UserEditForm onClose={closeNewDrawer} />
              ) : (
                <InvitationsForm
                  closeDrawer={closeNewDrawer}
                  isDrawerOpen={isNewDrawerOpen}
                  source={'UserTable'}
                />
              )}
            </Drawer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPage;
