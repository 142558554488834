import React, {useState} from 'react';
import {AddressInputElementClass} from 'types/documentTemplateElement';
import {ElementProps} from 'types/documentTemplateElement';
import TextInput from '../../inputs/TextInput';
import {Autocomplete, useLoadScript} from '@react-google-maps/api';
import {buildLabel, checkError} from 'utils/elementUtils';
import {useIsDocumentElementDisabled} from 'hooks/useIsDocumentElementDisabled';

const AddressInputElementComponent: React.FC<
  ElementProps<AddressInputElementClass>
> = ({element, updateElement, mode}) => {
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);
  const {isLoaded} = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
    libraries: ['places'],
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (mode === 'view') {
      const newUpdatedElement = new AddressInputElementClass({
        ...element,
        properties: {
          ...element.properties,
          value: e.target.value,
        },
      });
      updateElement(newUpdatedElement);
    }
    if (mode === 'admin') {
      const newUpdatedElement = new AddressInputElementClass({
        ...element,
        properties: {
          ...element.properties,
          value: e.target.value,
        },
      });
      updateElement(newUpdatedElement);
    }
    return;
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      const newUpdatedElement = new AddressInputElementClass({
        ...element,
        properties: {
          ...element.properties,
          value: place.formatted_address,
        },
      });
      updateElement(newUpdatedElement);
    }
  };

  const handleClickOutsideMaps = () => {
    const element = document.querySelector('.pac-container');
    if (element) {
      element.setAttribute('style', 'display: none');
    }
  };

  const disabled = useIsDocumentElementDisabled(element, mode);

  let containerClass = 'w-full m-8';
  if (mode === 'view' || mode === 'preview' || mode === 'admin') {
    containerClass = element.properties.showLabel
      ? 'w-full mx-3 mb-2'
      : 'w-full mt-5 mx-3 mb-8';
  }

  return (
    <div className={containerClass}>
      {isLoaded && mode === 'view' ? (
        <Autocomplete
          className="w-full"
          onLoad={autocomplete => setAutocomplete(autocomplete)}
          onPlaceChanged={onPlaceChanged}
          onUnmount={() => setAutocomplete(null)}>
          <TextInput
            id={element.id}
            type="text"
            error={checkError(element, mode)}
            label={buildLabel(element)}
            value={element.properties?.value || ''}
            placeholder={element.properties.placeholder}
            required={element.properties.required}
            onChange={handleChange}
            onBlur={handleClickOutsideMaps}
          />
        </Autocomplete>
      ) : (
        <TextInput
          id={element.id}
          type="text"
          error={checkError(element, mode)}
          label={buildLabel(element)}
          value={element.properties?.value || ''}
          placeholder={element.properties.placeholder}
          required={element.properties.required}
          onChange={handleChange}
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default AddressInputElementComponent;
