import React, {useContext, useEffect, useState} from "react";
import UserTable from "features/user/UserTable";
import {TitleContext} from "components/TitleContext";
import {SearchType} from "types/search";
import {UserThunks} from "features/user/userThunks";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "store/store";
import DashboardHrDocumentsTable from "features/dashboard/DashboardHrDocumentsTable";
import UserEditForm from "features/user/UserEditForm";
import Drawer from "components/Drawer";
import UserHrDocumentForm from "features/userHrDocument/UserHrDocumentForm";
import DashboardTrainingsTable from "features/dashboard/DashboardTrainingsTable";
import UserTrainingForm from "features/userTraining/UserTrainingForm";
import {DashboardService} from "services/dashboardService";
import DashboardPendingApprovalDocumentsTable from "../../features/dashboard/DashboardPendingApprovalDocumentsTable";

const DashboardPage: React.FC = () => {
    const {setTitle} = useContext(TitleContext);
    const dispatch = useDispatch<AppDispatch>();
    const activeTab = useSelector((state: RootState) => state.user.activeTab);
    const [isEditUserOpenDrawer, setIsEditUserOpenDrawer] = useState(false);
    const [isEditDocumentOpenDrawer, setIsEditDocumentOpenDrawer] = useState(false);
    const [isEditTrainingOpenDrawer, setIsEditTrainingOpenDrawer] = useState(false);
    const [userOptionsByTab, setUserOptionsByTab] = useState({});
    const [documentOptionsByTab, setDocumentOptionsByTab] = useState({});
    const [trainingOptionsByTab, setTrainingOptionsByTab] = useState({});

    const closeTrainingDocumentDrawer = () => setIsEditTrainingOpenDrawer(false);
    const openTrainingDrawer = () => setIsEditTrainingOpenDrawer(true);
    const closeEditTrainingDrawer = () => setIsEditTrainingOpenDrawer(false);
    const closeEditUserDrawer = () => setIsEditUserOpenDrawer(false);
    const closeEditDocumentDrawer = () => setIsEditDocumentOpenDrawer(false);
    const openDrawer = () => setIsEditUserOpenDrawer(true);
    const openDocumentDrawer = () => setIsEditDocumentOpenDrawer(true);

    useEffect(() => {
        const loadDashboardData = async () => {
            await DashboardService.index().then((response) => {
                setUserOptionsByTab(
                    {
                        'All': {'counter': response.data.user.combined},
                        'Onboarding': {'counter': response.data.user.onboarding},
                        'Non compliant': {'counter': response.data.user.nonCompliant},
                        'Inactive': {'counter': response.data.user.inactive}
                    }
                )
                setDocumentOptionsByTab(
                    {
                        'All': {'counter': response.data.userHrDocument.combined},
                        'Missing Documents': {'counter': response.data.userHrDocument.missing},
                        'Expiring Documents': {'counter': response.data.userHrDocument.expiringSoon},
                        'Expired Documents': {'counter': response.data.userHrDocument.expired},
                    }
                )
                setTrainingOptionsByTab(
                    {
                        'All': {'counter': response.data.userTraining.combined},
                        'Missing trainings': {'counter': response.data.userTraining.missing},
                        'Expiring trainings': {'counter': response.data.userTraining.expiringSoon},
                        'Expired trainings': {'counter': response.data.userTraining.expired},
                    }
                )
            });
        }
        loadDashboardData();
    }, []);


    const customFetchUserData = async ({page, search, sortField, sortDirection, rowsPerPage,}: {
        page: number;
        search: SearchType<{ [key: string]: { operator: string, value: string | string [] } }>;
        sortField: string;
        sortDirection: string;
        rowsPerPage: number
    }): Promise<void> => {
        if (activeTab === 'All') {
            search.where.status = {
                operator: 'in',
                valueArray: ['onboarding', 'non_compliant', 'inactive']
            };

        } else if (activeTab === 'Onboarding') {
            search.where.status = {
                operator: '==',
                value: 'onboarding'
            }

        } else if (activeTab === 'Non compliant') {
            search.where.status = {
                operator: '==',
                value: 'non_compliant'
            }

        } else if (activeTab === 'Inactive') {
            search.where.status = {
                operator: '==',
                value: 'inactive'
            }
        } else {
            delete search.where.status;
        }

        search.where.hidden = {
            operator: '==',
            value: 'false'
        }

        search.where.is_test = {
            operator: '==',
            value: 'false'
        }

        await dispatch(UserThunks.index({page, search, sortField, sortDirection, rowsPerPage,}));
    };

    useEffect(() => {
        setTitle(
            <div>
                <div className="text-slate-600 text-lg font-medium font-['Inter']  ">
                    Dashboard
                </div>
                <div
                    className="text-slate-400 text-sm font-light font-['Inter'] leading-normal ">
                    Quick access to most active features
                </div>
            </div>
        );
    }, [setTitle]);

    return (
        <div className="">
            <div className="relative">
                <div className="">
                    <div className="relative">

                        <UserTable showBlueButton={false}
                                   tabs={['All', 'Onboarding', 'Non compliant', 'Inactive']}
                                   searchByOptions={[
                                       {label: 'Employee', value: 'name', operator: 'like'},
                                       {label: 'Ext ID', value: 'external_id', operator: 'like'},
                                       {label: 'Email', value: 'email', operator: 'like'},
                                   ]}
                                   customFetchUserData={customFetchUserData}
                                   optionsByTab={userOptionsByTab}
                                   openDrawer={openDrawer}
                        />
                        <Drawer isOpen={isEditUserOpenDrawer} onClose={closeEditUserDrawer}>
                            <UserEditForm onClose={closeEditUserDrawer}/>
                        </Drawer>

                        <div className="my-8 ">
                            <DashboardHrDocumentsTable
                                optionsByTab={documentOptionsByTab}
                                openDrawer={openDocumentDrawer}
                            />
                            <Drawer isOpen={isEditDocumentOpenDrawer} onClose={closeEditDocumentDrawer}>
                                <UserHrDocumentForm closeDrawer={closeEditDocumentDrawer}
                                                    isDrawerOpen={isEditDocumentOpenDrawer}/>
                            </Drawer>
                        </div>
                        <div className="my-8 ">
                            <DashboardTrainingsTable
                                optionsByTab={trainingOptionsByTab}
                                openDrawer={openTrainingDrawer}
                            />
                            <Drawer isOpen={isEditTrainingOpenDrawer} onClose={closeEditTrainingDrawer}>
                                <UserTrainingForm closeDrawer={closeTrainingDocumentDrawer}
                                                  isDrawerOpen={isEditTrainingOpenDrawer}/>
                            </Drawer>
                        </div>
                        <div className="my-8 ">
                            <DashboardPendingApprovalDocumentsTable
                                optionsByTab={documentOptionsByTab}
                                openDrawer={openDocumentDrawer}
                            />
                            <Drawer isOpen={isEditDocumentOpenDrawer} onClose={closeEditDocumentDrawer}>
                                <UserHrDocumentForm closeDrawer={closeEditDocumentDrawer}
                                                    isDrawerOpen={isEditDocumentOpenDrawer}/>
                            </Drawer>
                        </div>


                    </div>
                </div>
            </div>
        </div>

    );
};

export default DashboardPage;
