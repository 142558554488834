import React, {useContext, useEffect, useState} from 'react';
import Drawer from 'components/Drawer';
import TrainingTable from 'features/training/TrainingTable';
import TrainingForm from 'features/training/TrainingForm';
import {TitleContext} from '../../components/TitleContext';

const TrainingsPage: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const openDrawer = () => setIsDrawerOpen(true);
  const closeDrawer = () => setIsDrawerOpen(false);
  const {setTitle} = useContext(TitleContext);

  useEffect(() => {
    setTitle(
      <div>
        <div className="text-slate-600 text-lg font-medium font-['Inter']  ">
          Trainings
        </div>
        <div className="text-slate-400 text-sm font-light font-['Inter'] leading-normal ">
          Manage your company's trainings
        </div>
      </div>,
    );
  }, [setTitle]);
  return (
    <div className="">
      <div className="relative overflow-hidden">
        <div>
          <div>
            <div className="relative">
              <TrainingTable openDrawer={openDrawer} />
              <Drawer isOpen={isDrawerOpen} onClose={closeDrawer}>
                <TrainingForm closeDrawer={closeDrawer} />
              </Drawer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainingsPage;
