import {createEntitySlice} from 'utils/createEntitySlice';
import {UserHrDocument} from 'types/userHrDocument';
import {UserHrDocumentThunks} from 'features/userHrDocument/userHrDocumentThunks';

export interface UserHrDocumentState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  userHrDocuments?: any;
  pagy?: any;
  userHrDocument?: any;
  isEditing?: boolean;
  activeTab?: string;
}

const initialState: UserHrDocumentState = {
  status: 'idle',
  error: null,
  userHrDocuments: [],
  userHrDocument: null,
  pagy: {},
  isEditing: false,
  activeTab: 'All',
};

const userHrDocumentSlice = createEntitySlice<UserHrDocument>({
  name: 'Document',
  initialState,
  thunks: UserHrDocumentThunks,
  entityKey: 'userHrDocument',
  entitiesKey: 'userHrDocuments',
  reducers: {},
});

export const {
  setEntity: setUserHrDocument,
  setIsEditing,
  setActiveTab,
} = userHrDocumentSlice.actions;
export {initialState as userHrDocumentInitialState};
export default userHrDocumentSlice.reducer;
