import {createEntitySlice} from 'utils/createEntitySlice';
import {UserThunks} from './userThunks';
import {User} from 'types/user';
import {showToast} from 'components/ToastContainer';

export interface UserState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  users: User[];
  user: User | null;
  isEditing: boolean;
  activeTab: string;
  pagy: any;
}

const initialState: Partial<UserState> = {
  users: [],
  user: null,
  isEditing: false,
  activeTab: 'All',
  pagy: {},
};

const userSlice = createEntitySlice<User>({
  name: 'user',
  initialState,
  thunks: UserThunks,
  entityKey: 'user',
  entitiesKey: 'users',
  reducers: {

  },
  extraReducers: {
    [UserThunks.unlock.fulfilled.type]: (state, action) => {
      state.status = 'succeeded';
      showToast('Successfully unlocked', 'success');
      const updatedUser = action.payload;
      state.user = updatedUser;
      const index = state.users.findIndex(
        (user: User) => user.id === updatedUser.id,
      );
      if (index !== -1) {
        state.users[index] = updatedUser;
      }
    },
    [UserThunks.unblock.fulfilled.type]: (state, action) => {
      state.status = 'succeeded';
      showToast('Successfully unblocked', 'success');
      const updatedUser = action.payload;
      state.user = updatedUser;
      const index = state.users.findIndex(
        (user: User) => user.id === updatedUser.id,
      );
      if (index !== -1) {
        state.users[index] = updatedUser;
      }
    },
    [UserThunks.register.pending.type]: state => {
      state.status = 'loading';
    },
    [UserThunks.register.fulfilled.type]: state => {
      state.status = 'succeeded';
    },
    [UserThunks.register.rejected.type]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    },
    [UserThunks.checkEmailExists.fulfilled.type]: (state, action) => {
      state.status = 'succeeded';
      state.error = action.payload;
    },
    [UserThunks.changePassword.fulfilled.type]: state => {
      state.status = 'succeeded';
      showToast('Password changed successfully', 'success');
    },
    [UserThunks.changePIN.fulfilled.type]: state => {
      state.status = 'succeeded';
      showToast('PIN changed successfully', 'success');
    },
    [UserThunks.updateSignature.fulfilled.type]: (state, action) => {
      state.status = 'succeeded';
      state.user = action.payload;
      showToast('Signature updated successfully', 'success');
    },
    [UserThunks.updateAvatar.fulfilled.type]: (state, action) => {
      state.status = 'succeeded';
      state.user = action.payload;
      showToast('Avatar updated successfully', 'success');
    },
    [UserThunks.deleteAvatar.fulfilled.type]: (state, action) => {
      state.status = 'succeeded';
      state.user = action.payload;
      showToast('Avatar deleted successfully', 'success');
    },
  },
});

export const {
  setEntity: setUser,
  setIsEditing,
  setActiveTab,
} = userSlice.actions;
export default userSlice.reducer;
