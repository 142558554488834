import React from 'react';

interface PageTabsProps {
  tabs: string[];
  activeTab: string;
  onTabChange: (tab: string) => void;
}

const PageTabs: React.FC<PageTabsProps> = ({tabs, activeTab, onTabChange}) => {
  return (
    <div className="mb-8 text-sm font-medium text-center text-slate-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
      <ul className="flex flex-wrap -mb-px">
        {tabs.map(tab => (
          <li
            key={tab}
            className={`me-2 cursor-pointer inline-block p-2 border-b-2 text-slate-400 ${
              activeTab === tab
                ? 'border-cyan-400 text-slate-600 dark:text-gray-200 dark:border-cyan-400'
                : 'border-transparent'
            } rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300`}
            onClick={() => onTabChange(tab)}
            aria-current={activeTab === tab ? 'page' : undefined}>
            {tab}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PageTabs; 