import {EditorContent, useEditor, Editor} from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Paragraph from '@tiptap/extension-paragraph';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TextAlign from '@tiptap/extension-text-align';
import {Extension} from '@tiptap/core';
import classNames from 'classnames';
import Tag from './Tag';
import {useEffect, useRef, useState} from 'react';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    customStyle: {
      setStyle: (style: 'title' | 'subtitle' | null) => ReturnType;
    };
    titleParagraph: {
      setTitleParagraph: () => ReturnType;
    };
    subtitleParagraph: {
      setSubtitleParagraph: () => ReturnType;
    };
  }
}

const TitleParagraph = Paragraph.extend({
  name: 'titleParagraph',
  group: 'block',
  content: 'inline*',
  addAttributes() {
    return {
      class: {
        default: 'title',
        renderHTML: (attributes: {class: string | null}) => {
          return {
            class: attributes.class,
          };
        },
      },
    };
  },
  addCommands() {
    return {
      setTitleParagraph:
        () =>
        ({chain}) => {
          return chain()
            .setParagraph()
            .updateAttributes('paragraph', {class: 'title'})
            .run();
        },
    };
  },
});

const SubtitleParagraph = Paragraph.extend({
  name: 'subtitleParagraph',
  group: 'block',
  content: 'inline*',
  addAttributes() {
    return {
      class: {
        default: 'subtitle',
        renderHTML: (attributes: {class: string | null}) => {
          return {
            class: attributes.class,
          };
        },
      },
    };
  },
  addCommands() {
    return {
      setSubtitleParagraph:
        () =>
        ({chain}) => {
          return chain()
            .setParagraph()
            .updateAttributes('paragraph', {class: 'subtitle'})
            .run();
        },
    };
  },
});

const CustomParagraph = Paragraph.extend({
  addAttributes() {
    return {
      class: {
        default: null,
        renderHTML: (attributes: {class: string | null}) => {
          return {
            class: attributes.class,
          };
        },
      },
    };
  },
});

const CustomStyle = Extension.create({
  name: 'customStyle',
  addGlobalAttributes() {
    return [
      {
        types: ['paragraph'],
        attributes: {
          class: {
            default: null,
            parseHTML: element => element.getAttribute('class'),
            renderHTML: attributes => {
              if (!attributes.class) {
                return {};
              }
              return {
                class: attributes.class,
              };
            },
          },
        },
      },
    ];
  },
  addCommands() {
    return {
      setStyle:
        (style: 'title' | 'subtitle' | null) =>
        ({chain}) => {
          return chain()
            .setParagraph()
            .updateAttributes('paragraph', {class: style})
            .run();
        },
    };
  },
});

interface MenuBarProps {
  editor: Editor | null;
}

const tagOptions = [
  {label: 'CURRENT DATE', value: '{CURRENT_DATE}'},
  {label: 'EMPLOYEE FIRST NAME', value: '{EMPLOYEE_FIRST_NAME}'},
  {label: 'EMPLOYEE LAST NAME', value: '{EMPLOYEE_LAST_NAME}'},
  {label: 'EMPLOYEE FULL NAME', value: '{EMPLOYEE_FULL_NAME}'},
  {label: 'EMPLOYEE SUPERVISOR', value: '{EMPLOYEE_SUPERVISOR}'},
  {label: 'EMPLOYEE LOCATION', value: '{EMPLOYEE_LOCATION}'},
  {label: 'COMPANY NAME', value: '{COMPANY_NAME}'},
  {label: 'EMPLOYEE ROLE', value: '{EMPLOYEE_ROLE}'},
  {label: 'COMPENSATION', value: '{COMPENSATION}'},
];

const MenuBar: React.FC<MenuBarProps> = ({editor}) => {
  const [isTagMenuOpen, setIsTagMenuOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState<{top: number; left: number}>(
    {top: 0, left: 0},
  );
  const tagButtonRef = useRef<HTMLButtonElement>(null);
  const tagMenuRef = useRef<HTMLDivElement>(null);

  const [isTableMenuOpen, setIsTableMenuOpen] = useState(false);
  const [tableMenuPosition, setTableMenuPosition] = useState<{
    top: number;
    left: number;
  }>({top: 0, left: 0});
  const tableButtonRef = useRef<HTMLButtonElement>(null);
  const tableMenuRef = useRef<HTMLDivElement>(null);

  const [isTextTypeMenuOpen, setIsTextTypeMenuOpen] = useState(false);
  const [textTypeMenuPosition, setTextTypeMenuPosition] = useState<{
    top: number;
    left: number;
  }>({top: 0, left: 0});
  const textTypeButtonRef = useRef<HTMLButtonElement>(null);
  const textTypeMenuRef = useRef<HTMLDivElement>(null);

  const [selectedRows, setSelectedRows] = useState(3);
  const [selectedCols, setSelectedCols] = useState(3);

  const textTypeOptions = [
    {
      label: 'Normal text',
      command: () =>
        editor
          ?.chain()
          .focus()
          .setParagraph()
          .updateAttributes('paragraph', {class: null})
          .run(),
      className: 'text-base',
    },
    {
      label: 'Title',
      command: () => editor?.chain().focus().setTitleParagraph().run(),
      className: 'text-4xl font-bold',
    },
    {
      label: 'Subtitle',
      command: () => editor?.chain().focus().setSubtitleParagraph().run(),
      className: 'text-xl font-semibold text-gray-400',
    },
    {
      label: 'Heading 1',
      command: () => editor?.chain().focus().toggleHeading({level: 1}).run(),
      className: 'text-3xl font-bold',
    },
    {
      label: 'Heading 2',
      command: () => editor?.chain().focus().toggleHeading({level: 2}).run(),
      className: 'text-2xl font-bold',
    },
    {
      label: 'Heading 3',
      command: () => editor?.chain().focus().toggleHeading({level: 3}).run(),
      className: 'text-xl font-bold',
    },
  ];

  const toggleTagMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsTagMenuOpen(prev => !prev);
    const buttonRect = event.currentTarget.getBoundingClientRect();
    setMenuPosition({
      top: buttonRect.top + buttonRect.height,
      left: buttonRect.left,
    });
  };

  const toggleTextTypeMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsTextTypeMenuOpen(prev => !prev);
    const buttonRect = event.currentTarget.getBoundingClientRect();
    setTextTypeMenuPosition({
      top: buttonRect.top + buttonRect.height,
      left: buttonRect.left,
    });
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      tagMenuRef.current &&
      !tagMenuRef.current.contains(event.target as Node) &&
      tagButtonRef.current &&
      !tagButtonRef.current.contains(event.target as Node)
    ) {
      setIsTagMenuOpen(false);
    }
    if (
      tableMenuRef.current &&
      !tableMenuRef.current.contains(event.target as Node) &&
      tableButtonRef.current &&
      !tableButtonRef.current.contains(event.target as Node)
    ) {
      setIsTableMenuOpen(false);
    }
    if (
      textTypeMenuRef.current &&
      !textTypeMenuRef.current.contains(event.target as Node) &&
      textTypeButtonRef.current &&
      !textTypeButtonRef.current.contains(event.target as Node)
    ) {
      setIsTextTypeMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleTableMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsTableMenuOpen(prev => !prev);
    const buttonRect = event.currentTarget.getBoundingClientRect();
    setTableMenuPosition({
      top: buttonRect.top + buttonRect.height,
      left: buttonRect.left,
    });
  };

  const handleDeleteTable = () => {
    editor?.chain().focus().deleteTable().run();
  };

  if (!editor) {
    return null;
  }

  return (
    <div className="align-middle gap-x-0.5 border-b border-gray-200 p-2 dark:border-neutral-700">
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={`size-8 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent ${
          editor.isActive('bold')
            ? 'bg-gray-100 text-gray-800 dark:bg-neutral-700'
            : 'text-gray-800 hover:bg-gray-100 focus:bg-gray-100 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700'
        }`}>
        <svg
          className="shrink-0 size-4"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round">
          <path d="M14 12a4 4 0 0 0 0-8H6v8" />
          <path d="M15 20a4 4 0 0 0 0-8H6v8Z" />
        </svg>
      </button>

      <button
        type="button"
        onClick={() => editor.chain().focus().toggleItalic().run()}
        className={`size-8 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent ${
          editor.isActive('italic')
            ? 'bg-gray-100 text-gray-800 dark:bg-neutral-700'
            : 'text-gray-800 hover:bg-gray-100 focus:bg-gray-100 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700'
        }`}>
        <svg
          className="shrink-0 size-4"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round">
          <line x1="19" x2="10" y1="4" y2="4" />
          <line x1="14" x2="5" y1="20" y2="20" />
          <line x1="15" x2="9" y1="4" y2="20" />
        </svg>
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        className={`size-8 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent ${
          editor.isActive('underline')
            ? 'bg-gray-100 text-gray-800 dark:bg-neutral-700'
            : 'text-gray-800 hover:bg-gray-100 focus:bg-gray-100 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700'
        }`}>
        <svg
          className="shrink-0 size-4"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round">
          <path d="M6 4v6a6 6 0 0 0 12 0V4" />
          <line x1="4" x2="20" y1="20" y2="20" />
        </svg>
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleStrike().run()}
        className={`size-8 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent ${
          editor.isActive('strike')
            ? 'bg-gray-100 text-gray-800 dark:bg-neutral-700'
            : 'text-gray-800 hover:bg-gray-100 focus:bg-gray-100 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700'
        }`}>
        <svg
          className="shrink-0 size-4"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round">
          <path d="M16 4H9a3 3 0 0 0-2.83 4" />
          <path d="M14 12a4 4 0 0 1 0 8H6" />
          <line x1="4" x2="20" y1="12" y2="12" />
        </svg>
      </button>
      <button
        type="button"
        onClick={() => {
          const url = prompt('Enter the URL');
          if (url) {
            editor
              .chain()
              .focus()
              .extendMarkRange('link')
              .setLink({href: url})
              .run();
          }
        }}
        className={`size-8 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent ${
          editor.isActive('link')
            ? 'bg-gray-100 text-gray-800 dark:bg-neutral-700'
            : 'text-gray-800 hover:bg-gray-100 focus:bg-gray-100 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700'
        }`}>
        <svg
          className="shrink-0 size-4"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round">
          <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71" />
          <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71" />
        </svg>
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={`size-8 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent ${
          editor.isActive('orderedList')
            ? 'bg-gray-100 text-gray-800 dark:bg-neutral-700'
            : 'text-gray-800 hover:bg-gray-100 focus:bg-gray-100 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700'
        }`}>
        <svg
          className="shrink-0 size-4"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round">
          <line x1="10" x2="21" y1="6" y2="6" />
          <line x1="10" x2="21" y1="12" y2="12" />
          <line x1="10" x2="21" y1="18" y2="18" />
          <path d="M4 6h1v4" />
          <path d="M4 10h2" />
          <path d="M6 18H4c0-1 2-2 2-3s-1-1.5-2-1" />
        </svg>
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={`size-8 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent ${
          editor.isActive('bulletList')
            ? 'bg-gray-100 text-gray-800 dark:bg-neutral-700'
            : 'text-gray-800 hover:bg-gray-100 focus:bg-gray-100 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700'
        }`}>
        <svg
          className="shrink-0 size-4"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round">
          <line x1="8" x2="21" y1="6" y2="6" />
          <line x1="8" x2="21" y1="12" y2="12" />
          <line x1="8" x2="21" y1="18" y2="18" />
          <line x1="3" x2="3.01" y1="6" y2="6" />
          <line x1="3" x2="3.01" y1="12" y2="12" />
          <line x1="3" x2="3.01" y1="18" y2="18" />
        </svg>
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        className={`size-8 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent ${
          editor.isActive('blockquote')
            ? 'bg-gray-100 text-gray-800 dark:bg-neutral-700'
            : 'text-gray-800 hover:bg-gray-100 focus:bg-gray-100 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700'
        }`}>
        <svg
          className="shrink-0 size-4"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round">
          <path d="M17 6H3" />
          <path d="M21 12H8" />
          <path d="M21 18H8" />
          <path d="M3 12v6" />
        </svg>
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        className={`size-8 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent ${
          editor.isActive('codeBlock')
            ? 'bg-gray-100 text-gray-800 dark:bg-neutral-700'
            : 'text-gray-800 hover:bg-gray-100 focus:bg-gray-100 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700'
        }`}>
        <svg
          className="shrink-0 size-4"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round">
          <path d="m18 16 4-4-4-4" />
          <path d="m6 8-4 4 4 4" />
          <path d="m14.5 4-5 16" />
        </svg>
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().setTextAlign('left').run()}
        className={`size-8 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent ${
          editor.isActive({textAlign: 'left'})
            ? 'bg-gray-100 text-gray-800 dark:bg-neutral-700'
            : 'text-gray-800 hover:bg-gray-100 focus:bg-gray-100 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700'
        }`}>
        <svg
          className="shrink-0 size-4"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round">
          <line x1="21" x2="3" y1="6" y2="6" />
          <line x1="15" x2="3" y1="12" y2="12" />
          <line x1="17" x2="3" y1="18" y2="18" />
        </svg>
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().setTextAlign('center').run()}
        className={`size-8 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent ${
          editor.isActive({textAlign: 'center'})
            ? 'bg-gray-100 text-gray-800 dark:bg-neutral-700'
            : 'text-gray-800 hover:bg-gray-100 focus:bg-gray-100 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700'
        }`}>
        <svg
          className="shrink-0 size-4"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round">
          <line x1="21" x2="3" y1="6" y2="6" />
          <line x1="17" x2="7" y1="12" y2="12" />
          <line x1="19" x2="5" y1="18" y2="18" />
        </svg>
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().setTextAlign('right').run()}
        className={`size-8 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent ${
          editor.isActive({textAlign: 'right'})
            ? 'bg-gray-100 text-gray-800 dark:bg-neutral-700'
            : 'text-gray-800 hover:bg-gray-100 focus:bg-gray-100 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700'
        }`}>
        <svg
          className="shrink-0 size-4"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round">
          <line x1="21" x2="3" y1="6" y2="6" />
          <line x1="21" x2="9" y1="12" y2="12" />
          <line x1="21" x2="5" y1="18" y2="18" />
        </svg>
      </button>
      <button
        type="button"
        onClick={event => toggleTextTypeMenu(event)}
        className="size-8 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 focus:bg-gray-100 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
        ref={textTypeButtonRef}>
        <svg
          className="shrink-0 size-4"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round">
          <path d="M4 19.5A2.5 2.5 0 0 1 6.5 17H20" />
          <path d="M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z" />
        </svg>
      </button>
      <button
        type="button"
        onClick={event => toggleTagMenu(event)}
        className="size-8 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 focus:bg-gray-100 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
        ref={tagButtonRef}>
        <svg
          className="shrink-0 size-4"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round">
          <path
            d="M12 2H2V12L11.29 21.29C12.23 22.23 13.77 22.23 14.71 21.29L21.29 14.71C22.23 13.77 22.23 12.23 21.29 11.29L12 2Z"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M7 7H7.01" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </button>
      <button
        type="button"
        onClick={event => toggleTableMenu(event)}
        className={`size-8 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent ${
          editor.isActive('table')
            ? 'bg-gray-100 text-gray-800 dark:bg-neutral-700'
            : 'text-gray-800 hover:bg-gray-100 focus:bg-gray-100 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700'
        }`}
        ref={tableButtonRef}>
        <svg
          className="shrink-0 size-4"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round">
          <rect x="3" y="3" width="7" height="7" />
          <rect x="14" y="3" width="7" height="7" />
          <rect x="3" y="14" width="7" height="7" />
          <rect x="14" y="14" width="7" height="7" />
        </svg>
      </button>
      {isTagMenuOpen && (
        <div
          ref={tagMenuRef}
          className="fixed z-10 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg dark:bg-neutral-800 dark:border-neutral-700"
          style={{
            top: `${menuPosition.top}px`,
            left: `${menuPosition.left}px`,
          }}>
          <ul className="py-1">
            {tagOptions.map(tag => (
              <li key={tag.value}>
                <button
                  type="button"
                  onClick={() => {
                    editor
                      .chain()
                      .focus()
                      .insertTag(tag.label, tag.value)
                      .run();
                    setIsTagMenuOpen(false);
                  }}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left dark:text-white dark:hover:bg-neutral-700">
                  {tag.label}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
      {isTableMenuOpen && (
        <div
          ref={tableMenuRef}
          className="fixed z-10 mt-2 w-56 bg-white border border-gray-200 rounded-md shadow-lg p-2 dark:bg-neutral-800 dark:border-neutral-700"
          style={{
            top: `${tableMenuPosition.top}px`,
            left: `${tableMenuPosition.left}px`,
          }}>
          <div className="flex flex-col space-y-2">
            <div>
              <label className="text-sm dark:text-white flex items-center justify-between mb-2">
                Rows:
                <select
                  className="ml-2 border rounded px-1 dark:bg-neutral-700 dark:text-white w-16"
                  value={selectedRows}
                  onChange={e => setSelectedRows(parseInt(e.target.value, 10))}>
                  {[1, 2, 3, 4, 5, 6, 7, 8].map(n => (
                    <option key={n} value={n}>
                      {n}
                    </option>
                  ))}
                </select>
              </label>

              <label className="text-sm dark:text-white flex items-center justify-between">
                Columns:
                <select
                  className="ml-2 border rounded px-1 dark:bg-neutral-700 dark:text-white w-16"
                  value={selectedCols}
                  onChange={e => setSelectedCols(parseInt(e.target.value, 10))}>
                  {[1, 2, 3, 4, 5, 6, 7, 8].map(n => (
                    <option key={n} value={n}>
                      {n}
                    </option>
                  ))}
                </select>
              </label>
            </div>

            <button
              type="button"
              onClick={() => {
                editor
                  .chain()
                  .focus()
                  .insertTable({
                    rows: selectedRows,
                    cols: selectedCols,
                    withHeaderRow: true,
                  })
                  .run();
                setIsTableMenuOpen(false);
              }}
              className="inline-flex justify-center items-center gap-x-2 text-sm font-semibold bg-gray-100 hover:bg-gray-200 p-1 rounded dark:bg-neutral-700 dark:hover:bg-neutral-600 dark:text-white">
              Insert
            </button>

            {editor.isActive('table') && (
              <div className="flex flex-col space-y-2 mt-2 pt-2 border-t border-gray-200 dark:border-neutral-600">
                <button
                  type="button"
                  onClick={() => {
                    handleDeleteTable();
                    setIsTableMenuOpen(false);
                  }}
                  className="inline-flex justify-center items-center gap-x-2 text-sm font-semibold bg-red-100 hover:bg-red-200 p-1 rounded text-red-700 dark:bg-red-900 dark:hover:bg-red-800 dark:text-red-100">
                  Delete Table
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      {isTextTypeMenuOpen && (
        <div
          ref={textTypeMenuRef}
          className="fixed z-10 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg dark:bg-neutral-800 dark:border-neutral-700"
          style={{
            top: `${textTypeMenuPosition.top}px`,
            left: `${textTypeMenuPosition.left}px`,
          }}>
          <ul className="py-1">
            {textTypeOptions.map((option, index) => (
              <li key={index}>
                <button
                  type="button"
                  onClick={() => {
                    option.command();
                    setIsTextTypeMenuOpen(false);
                  }}
                  className={`block px-4 py-2 text-sm hover:bg-gray-100 w-full text-left dark:hover:bg-neutral-700 ${option.className}`}>
                  {option.label}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

const extensions = [
  StarterKit.configure({
    paragraph: false,
  }),
  CustomParagraph,
  TitleParagraph,
  SubtitleParagraph,
  BulletList,
  OrderedList,
  Underline,
  Link,
  Tag,
  TextAlign.configure({
    types: ['heading', 'paragraph', 'titleParagraph', 'subtitleParagraph'],
    alignments: ['left', 'center', 'right'],
  }),
  CustomStyle,
  Table.configure({
    lastColumnResizable: false,
    HTMLAttributes: {
      class: 'my-table-class',
    },
  }),
  TableRow,
  TableCell,
  TableHeader,
];

interface WysiwygProps {
  content?: string;
  onContentChange?: (content: string) => void;
  preview?: boolean;
}

const Wysiwyg: React.FC<WysiwygProps> = ({
  content,
  onContentChange,
  preview,
}) => {
  const editor = useEditor({
    extensions: extensions,
    content: content,
    editable: !preview,
    editorProps: {
      attributes: {
        class: classNames(
          'prose max-w-none focus:outline-none',
          'h-[10rem] overflow-auto p-2',
          '[&_ol]:list-decimal [&_ul]:list-disc',
          '[&_.title]:text-4xl [&_.title]:font-bold [&_.title]:mb-4',
          '[&_.subtitle]:text-xl [&_.subtitle]:font-semibold [&_.subtitle]:mb-3 [&_.subtitle]:text-gray-400 dark:[&_.subtitle]:text-gray-500',
          '[&_h1]:text-3xl [&_h1]:font-bold [&_h1]:mb-4',
          '[&_h2]:text-2xl [&_h2]:font-bold [&_h2]:mb-3',
          '[&_h3]:text-xl [&_h3]:font-bold [&_h3]:mb-2',
        ),
        style: 'white-space: pre-wrap; height: 100%',
      },
    },
    onUpdate: ({editor}) => {
      const isInTableCell =
        editor.isActive('tableCell') || editor.isActive('tableHeader');
      let updatedContent = editor.getHTML();

      if (!isInTableCell) {
        updatedContent = updatedContent.replace(/ (\<\/p\>)/g, '&nbsp;$1');
      }

      if (onContentChange) {
        onContentChange(updatedContent);
      }
    },
  });

  useEffect(() => {
    if (!editor) return;

    const handleBlur = () => {
      let updatedContent = editor.getHTML();
      updatedContent = updatedContent.replace(/ (\<\/p\>)/g, '&nbsp;$1');
      if (onContentChange) {
        onContentChange(updatedContent);
      }
    };

    editor.on('blur', handleBlur);
    return () => {
      editor.off('blur', handleBlur);
    };
  }, [editor, onContentChange]);

  useEffect(() => {
    if (editor && content !== editor.getHTML()) {
      editor.commands.setContent(content || '');
    }
  }, [editor, content]);

  return (
    <div className="bg-white border border-gray-200 rounded-xl overflow-hidden dark:bg-neutral-800 dark:border-neutral-700">
      <div id="hs-editor-tiptap">
        <MenuBar editor={editor} />
        <EditorContent editor={editor} />
      </div>
    </div>
  );
};

export default Wysiwyg;
