export interface DocumentTemplateSolutionInterface {
    id: string;
    ownerId: string;
    ownerType: string;
    snapshot: any;
    documentTemplateId: string;
}

export class DocumentTemplateSolution implements DocumentTemplateSolutionInterface {
    id: string;
    ownerId: string;
    ownerType: string;
    snapshot: any;
    documentTemplateId: string;

    constructor(data: Partial<DocumentTemplateSolutionInterface>) {
        this.id = data.id!;
        this.ownerId = data.ownerId!;
        this.ownerType = data.ownerType!;
        this.snapshot = data.snapshot!;
        this.documentTemplateId = data.documentTemplateId!;
    }

    toJson(): DocumentTemplateSolutionInterface {
        return {
            id: this.id,
            ownerId: this.ownerId,
            ownerType: this.ownerType,
            snapshot: this.snapshot,
            documentTemplateId: this.documentTemplateId,
        };
    }
}