import {validateEmail, validateUrl} from './validators';

export function buildLabel(element: any): string {
  if (!element.properties.showLabel) return '';
  return element.properties.label + (element.properties.required ? ' *' : '');
}

export function checkError(
  element: any,
  mode: string | undefined,
): string | undefined {
  const value = element.properties.value || '';
  const isRequired = !!element.properties.required;

  if (element.elementType === 'UrlInput' && value && !validateUrl(value)) {
    return 'URL is invalid';
  }
  if (
    element.elementType === 'EmailInput' &&
    mode === 'view' &&
    value &&
    !validateEmail(value)
  ) {
    return 'Email is invalid';
  }
  if ((mode === 'view' || mode === 'admin') && isRequired && !value) {
    return '';
  }
  return undefined;
}

export function isElementDisabled(
  element: any,
  mode: string | undefined,
  user: any,
): boolean {
  if (mode === 'edit') {
    return true;
  }
  if (mode === 'admin' && !element.properties.adminOnly) {
    return true;
  }
  if (element.properties.adminOnly && mode === 'view') {
    return true;
  }
  if (
    mode === 'admin' &&
    element.properties.adminOnly &&
    element.properties.supervisorIds?.length > 0 &&
    !element.properties.supervisorIds.includes(user?.id)
  ) {
    return true;
  }
  return false;
}
