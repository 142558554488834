import { createEntityService } from 'utils/createEntityService';
import { DocumentTemplateSolution } from 'types/documentTemplateSolution';
import apiClient from "./apiClient";

export const BaseDocumentTemplateSolutionService = createEntityService<DocumentTemplateSolution>({
    baseUrl: 'api/v1/document_template_solutions',
    entityName: 'documentTemplateSolution',
});

export class DocumentTemplateSolutionService extends BaseDocumentTemplateSolutionService {
    static complete(entity: DocumentTemplateSolution) {
        const data = entity.toJson();
        return apiClient.patch(`api/v1/document_template_solutions/${data.id}/complete`, {
            document_template_solution: data,
        });
    }
}
