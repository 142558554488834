import React, {useContext, useEffect, useState} from 'react';
import {TitleContext} from 'components/TitleContext';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from 'store/store';
import Table from 'components/table/Table';
import Skeleton from 'react-loading-skeleton';
import {UserHrDocumentThunks} from 'features/userHrDocument/userHrDocumentThunks';
import {UserTrainingThunks} from 'features/userTraining/userTrainingThunks';
import {useDropdownActionMenu} from 'hooks/useDropdownActionMenu';
import useViewerModal from 'hooks/useViewerModal';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import TableCell from 'components/table/TableCell';
import WhiteButton from 'components/WhiteButton';
import DownloadIcon from 'assets/images/icons/DownloadIcon';
import DotsMenu from 'components/DotsMenu';
import {SearchType} from 'types/search';
import {UserHrDocument} from 'types/userHrDocument';
import {UserTraining} from 'types/userTraining';
import XCircleIcon from 'assets/images/icons/XCircleIcon';
import {setMyDashboardTab as setActiveTab} from 'features/settings/settingsSlice';
import Drawer from 'components/Drawer';
import UserHrDocumentForm from 'features/userHrDocument/UserHrDocumentForm';
import UserTrainingForm from 'features/userTraining/UserTrainingForm';
import {useNavigate} from 'react-router-dom';
import {UserApplicationThunks} from 'features/userApplication/userApplicationThunks';
import {UserApplication} from '../../types/userApplication';
import UserApplicationForm from '../../features/userApplication/UserApplicationForm';

function getTabType(
  activeTab?: string,
): 'documents' | 'trainings' | 'applications' | 'other' {
  if (!activeTab) return 'other';
  if (activeTab.includes('Documents')) return 'documents';
  if (activeTab.includes('Trainings')) return 'trainings';
  if (activeTab.includes('Applications')) return 'applications';
  return 'other';
}

const MyDashboardPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const activeTab = useSelector(
    (state: RootState) => state.settings.myDashboardTab,
  );
  const user = useSelector((state: RootState) => state.auth.user);
  const userHrDocuments = useSelector(
    (state: RootState) => state.userHrDocument.userHrDocuments,
  );
  const trainings = useSelector(
    (state: RootState) => state.userTraining.userTrainings,
  );
  const userApplications = useSelector(
    (state: RootState) => state.userApplication.userApplications,
  );
  const hrDocumentsPagy = useSelector(
    (state: RootState) => state.userHrDocument.pagy,
  );
  const trainingsPagy = useSelector(
    (state: RootState) => state.userTraining.pagy,
  );
  const applicationsPagy = useSelector(
    (state: RootState) => state.userApplication.pagy,
  );

  const tabType = getTabType(activeTab);

  let defaultPagy;
  switch (tabType) {
    case 'documents':
      defaultPagy = hrDocumentsPagy;
      break;
    case 'trainings':
      defaultPagy = trainingsPagy;
      break;
    case 'applications':
      defaultPagy = applicationsPagy;
      break;
    default:
      break;
  }
  const [pagy, setPagy] = useState<any>(defaultPagy);

  const loading = useSelector((state: RootState) => {
    switch (tabType) {
      case 'documents':
        return state.userHrDocument.status === 'loading';
      case 'trainings':
        return state.userTraining.status === 'loading';
      case 'applications':
        return state.userApplication.status === 'loading';
      default:
        return false;
    }
  });

  let initialRecordName = '';
  switch (tabType) {
    case 'documents':
      initialRecordName = 'Document';
      break;
    case 'trainings':
      initialRecordName = 'Training';
      break;
    case 'applications':
      initialRecordName = 'Application';
      break;
    default:
      break;
  }
  const [recordName, setRecordName] = useState<string>(initialRecordName);

  const [isDocumentDrawerOpen, setIsDocumentDrawerOpen] = useState(false);
  const [isTrainingDrawerOpen, setIsTrainingDrawerOpen] = useState(false);
  const [isApplicationDrawerOpen, setIsApplicationDrawerOpen] = useState(false);

  const {setTitle} = useContext(TitleContext);
  const {setDotsMenuIsOpen} = useDropdownActionMenu();
  const [selectedAttachmentUrl, _setSelectedAttachmentUrl] = useState<
    string | null
  >(null);
  const [records, setRecords] = useState<any[]>([]);
  const [refreshCounter, setRefreshCounter] = useState(0);

  interface VisibleColumnsType {
    name: boolean;
    status: boolean;
    notes: boolean;
  }

  const [visibleColumns, setVisibleColumns] = useState<VisibleColumnsType>({
    name: true,
    status: true,
    notes: true,
  });
  const [columnOptions, setColumnOptions] = useState<any[]>([
    {label: recordName, value: 'name', isVisible: visibleColumns.name},
    {label: 'Status', value: 'status', isVisible: visibleColumns.status},
    {label: 'Notes', value: 'notes', isVisible: visibleColumns.notes},
  ]);

  const openDrawer = () => {
    switch (tabType) {
      case 'documents':
        setIsDocumentDrawerOpen(true);
        setIsTrainingDrawerOpen(false);
        setIsApplicationDrawerOpen(false);
        break;
      case 'trainings':
        setIsDocumentDrawerOpen(false);
        setIsTrainingDrawerOpen(true);
        setIsApplicationDrawerOpen(false);
        break;
      case 'applications':
        setIsDocumentDrawerOpen(false);
        setIsTrainingDrawerOpen(false);
        setIsApplicationDrawerOpen(true);
        break;
      default:
        break;
    }
  };

  const {AttachmentModal, handleNameClick} = useViewerModal<any>({
    openDrawer,
    fetchItemThunk: activeTab?.includes('Documents')
      ? UserHrDocumentThunks.show
      : activeTab?.includes('Trainings')
        ? UserTrainingThunks.show
        : UserApplicationThunks.show,
  });

  const fetchData = async ({
    page,
    search,
    sortField,
    sortDirection,
    rowsPerPage,
  }: {
    page: number;
    search: SearchType<{[key: string]: {operator: string; value: string}}>;
    sortField: string;
    sortDirection: string;
    rowsPerPage: number;
  }): Promise<void> => {
    if (activeTab?.includes('Missing')) {
      search.where.status = {operator: '==', value: 'missing'};
    }
    if (activeTab?.includes('Expired')) {
      search.where.status = {operator: '==', value: 'expired'};
    }

    search.where.user_id = {
      operator: '==',
      value: user?.id as string,
    };

    switch (tabType) {
      case 'documents':
        await dispatch(
          UserHrDocumentThunks.index({
            page,
            search,
            sortField,
            sortDirection,
            rowsPerPage,
          }),
        );
        break;
      case 'trainings':
        await dispatch(
          UserTrainingThunks.index({
            page,
            search,
            sortField,
            sortDirection,
            rowsPerPage,
          }),
        );
        break;
      case 'applications':
        await dispatch(
          UserApplicationThunks.index({
            page,
            search,
            sortField,
            sortDirection,
            rowsPerPage,
          }),
        );
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (
      !isDocumentDrawerOpen &&
      !isTrainingDrawerOpen &&
      !isApplicationDrawerOpen
    ) {
      setRefreshCounter(prev => prev + 1);
    }
  }, [isDocumentDrawerOpen, isTrainingDrawerOpen, isApplicationDrawerOpen]);

  useEffect(() => {
    switch (tabType) {
      case 'documents':
        setRecords(userHrDocuments);
        setPagy(hrDocumentsPagy);
        setRecordName('Document');
        setColumnOptions([
          {label: 'Document', value: 'name', isVisible: visibleColumns.name},
          {label: 'Status', value: 'status', isVisible: visibleColumns.status},
          {label: 'Notes', value: 'notes', isVisible: visibleColumns.notes},
        ]);
        break;
      case 'trainings':
        setRecords(trainings);
        setPagy(trainingsPagy);
        setRecordName('Training');
        setColumnOptions([
          {label: 'Training', value: 'name', isVisible: visibleColumns.name},
          {label: 'Status', value: 'status', isVisible: visibleColumns.status},
          {label: 'Notes', value: 'notes', isVisible: visibleColumns.notes},
        ]);
        break;
      case 'applications':
        setRecords(userApplications);
        setPagy(applicationsPagy);
        setRecordName('Application');
        setColumnOptions([
          {label: 'Application', value: 'name', isVisible: visibleColumns.name},
          {label: 'Status', value: 'status', isVisible: visibleColumns.status},
        ]);
        break;
      default:
        break;
    }
  }, [
    userHrDocuments,
    trainings,
    userApplications,
    activeTab,
    hrDocumentsPagy,
    trainingsPagy,
    applicationsPagy,
    visibleColumns,
    tabType,
  ]);

  useEffect(() => {
    setTitle(
      <div>
        <div className="text-slate-600 text-lg font-medium">My Dashboard</div>
        <div className="text-slate-400 text-sm font-light">
          {user?.firstName} {user?.lastName}
        </div>
      </div>,
    );
  }, [user, setTitle]);

  const handleColumnToggle = (column: string) => {
    if (column in visibleColumns) {
      setVisibleColumns(prev => ({
        ...prev,
        [column]: !prev[column as keyof VisibleColumnsType],
      }));
    }
  };

  const handleEdit = async (
    record: UserHrDocument | UserTraining | UserApplication,
  ) => {
    switch (tabType) {
      case 'documents': {
        const doc = record as UserHrDocument;
        await dispatch(UserHrDocumentThunks.show(doc.id));
        if (doc.hrDocument?.documentType === 'file') {
          setIsDocumentDrawerOpen(true);
          setIsTrainingDrawerOpen(false);
          setIsApplicationDrawerOpen(false);
        } else {
          navigate(`/document-fill/${doc.id}`);
        }
        break;
      }
      case 'trainings': {
        await dispatch(UserTrainingThunks.show(record.id));
        setIsTrainingDrawerOpen(true);
        setIsDocumentDrawerOpen(false);
        setIsApplicationDrawerOpen(false);
        break;
      }
      case 'applications': {
        await dispatch(UserApplicationThunks.show(record.id));
        navigate(`/document-fill/${record.id}`);
        setIsDocumentDrawerOpen(false);
        setIsTrainingDrawerOpen(false);
        break;
      }
      default:
        break;
    }
  };

  const handleDelete = async (id: string) => {
    switch (tabType) {
      case 'documents': {
        const newHrDocument = {id, attachment: null, deleteAttachment: true};
        await dispatch(UserHrDocumentThunks.update(newHrDocument));
        break;
      }
      case 'trainings': {
        const newTrainings = new UserTraining({
          id,
          attachment: null,
          deleteAttachment: true,
        });
        await dispatch(UserTrainingThunks.update(newTrainings));
        break;
      }
      case 'applications': {
        const newApplication = new UserApplication({
          id,
          attachment: null,
        });
        await dispatch(UserApplicationThunks.update(newApplication));
        break;
      }
      default:
        break;
    }
    setDotsMenuIsOpen(null);
  };

  const handleDownload = (attachmentUrl: string) => {
    if (attachmentUrl) {
      window.open(attachmentUrl, '_blank');
    }
  };
  const handleSelectDownload = () => {
    if (selectedAttachmentUrl) {
      window.open(selectedAttachmentUrl, '_blank');
    }
  };

  return (
    <>
      <Table
        tabs={[
          'Missing Documents',
          'Expired Documents',
          'Missing Trainings',
          'Expired Trainings',
          'Missing Applications',
        ]}
        refreshData={refreshCounter}
        activeTab={activeTab}
        fetchData={fetchData}
        setActiveTab={(tab: string) => dispatch(setActiveTab(tab))}
        pagy={pagy}
        recordsName={`${recordName}s`}
        loading={loading}
        columns={true}
        optionsByTab={{
          'Missing Documents': {
            searchByOptions: [{label: 'Document', value: 'name'}],
          },
          'Expired Documents': {
            searchByOptions: [{label: 'Document', value: 'name'}],
          },
          'Missing Trainings': {
            searchByOptions: [{label: 'Training', value: 'name'}],
          },
          'Expired Trainings': {
            searchByOptions: [{label: 'Training', value: 'name'}],
          },
          'Missing Applications': {
            searchByOptions: [{label: 'Application', value: 'name'}],
          },
        }}
        columnOptions={columnOptions}
        searchByOptions={[
          {
            label: recordName,
            operator: 'like',
          },
        ]}
        onColumnToggle={handleColumnToggle}
        blueButton={
          <WhiteButton
            onClick={handleSelectDownload}
            label={''}
            icon={<DownloadIcon />}
          />
        }>
        <thead>
          <tr>
            <th scope="col" className="px-3 pt-3.5 flex justify-center">
              <input
                type="checkbox"
                className="shrink-0 border-stone-300 rounded disabled:opacity-50 disabled:pointer-events-none
                           dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500
                           dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"
              />
            </th>
            {visibleColumns.name && (
              <TableHeader label={recordName} sortBy="name" />
            )}
            {visibleColumns.status && (
              <TableHeader label="Status" sortBy="status" />
            )}
            {visibleColumns.notes && (
              <TableHeader label="Notes" sortBy="notes" />
            )}
            <TableHeader label="" />
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {records?.length !== 0 &&
            records?.map(
              (record: UserHrDocument | UserTraining | UserApplication) => {
                const userOptions = [
                  ...(record.status === 'Missing' && tabType !== 'applications'
                    ? [
                        {
                          label: 'Edit',
                          onClick: () => handleEdit(record),
                          hoverClass: 'hover:bg-cyan-100',
                        },
                      ]
                    : []),
                  ...(record.status !== 'Missing'
                    ? [
                        {
                          label: 'Download',
                          onClick: () =>
                            handleDownload(record.attachment?.url || ''),
                          hoverClass: 'hover:bg-cyan-100',
                        },
                        {
                          label: 'Remove attached document',
                          onClick: () => handleDelete(record.id),
                          hoverClass: 'hover:bg-red-100 hover:text-red-800',
                        },
                      ]
                    : []),
                  ...(record.status === 'Missing' &&
                  (!('hrDocument' in record) ||
                      record.hrDocument?.documentType === 'file') &&
                  tabType !== 'applications'
                      ? [
                        {
                          label: 'Upload',
                          onClick: () => handleEdit(record),
                          hoverClass: 'hover:bg-cyan-100',
                        },
                      ]
                    : []),
                  ...(record.status === 'Missing' && tabType === 'applications'
                      ? [
                        {
                          label: 'Sign',
                          onClick: () => handleEdit(record),
                          hoverClass: 'hover:bg-cyan-100',
                        },
                      ]
                      : []),
                ];

                if (loading) {
                  return (
                    <TableRow key={record.id}>
                      <td colSpan={6}>
                        <Skeleton count={1} height={40} />
                      </td>
                    </TableRow>
                  );
                } else {
                  return (
                    <TableRow key={record.id}>
                      <td className="px-3 py-2 flex justify-center">
                        <input
                          type="checkbox"
                          className="border border-gray-300 rounded"
                        />
                      </td>
                      {visibleColumns.name && (
                        <TableCell>
                          <span
                            className={`text-sm font-medium ${
                              record.status !== 'Missing'
                                ? 'underline text-cyan-600 cursor-pointer'
                                : ''
                            }`}
                            onClick={
                              record.status !== 'Missing'
                                ? () => handleNameClick(record)
                                : undefined
                            }>
                            {record.name}

                          </span>
                        </TableCell>
                      )}
                      {visibleColumns.status && (
                        <TableCell>
                          <span
                            className={`flex items-center text-xs font-medium px-2 py-0.5 rounded-full w-32
                            ${record.status === 'Missing' ? 'bg-red-100 text-red-800' : ''}
                            ${record.status === 'Expired' ? 'bg-red-100 text-red-800' : ''}
                          `}>
                            {(record.status === 'Missing' ||
                              record.status === 'Expired') && (
                              <XCircleIcon className="mr-2" />
                            )}
                            <div className="pl-1">{record.status}</div>
                          </span>
                        </TableCell>
                      )}
                      {visibleColumns.notes && (
                        <TableCell>{record.notes}</TableCell>
                      )}
                      <TableCell>
                        <DotsMenu options={userOptions} />
                      </TableCell>
                    </TableRow>
                  );
                }
              },
            )}
        </tbody>
      </Table>

      <AttachmentModal />

      <Drawer
        isOpen={isDocumentDrawerOpen}
        onClose={() => setIsDocumentDrawerOpen(false)}>
        <UserHrDocumentForm
          closeDrawer={() => setIsDocumentDrawerOpen(false)}
          isDrawerOpen={isDocumentDrawerOpen}
        />
      </Drawer>

      <Drawer
        isOpen={isTrainingDrawerOpen}
        onClose={() => setIsTrainingDrawerOpen(false)}>
        <UserTrainingForm
          closeDrawer={() => setIsTrainingDrawerOpen(false)}
          isDrawerOpen={isTrainingDrawerOpen}
        />
      </Drawer>

      <Drawer
        isOpen={isApplicationDrawerOpen}
        onClose={() => setIsApplicationDrawerOpen(false)}>
        <UserApplicationForm
          closeDrawer={() => setIsApplicationDrawerOpen(false)}
          isDrawerOpen={isApplicationDrawerOpen}
          userId={user?.id || ''}
        />
      </Drawer>
    </>
  );
};

export default MyDashboardPage;
