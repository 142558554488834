import React from 'react';
import TrashIcon from 'assets/images/icons/TrashIcon';
import DuplicateIcon from 'assets/images/icons/DuplicateIcon';
import ArrowTopIcon from 'assets/images/icons/ArrowTopIcon';
import ArrowBottomIcon from 'assets/images/icons/ArrowBottomIcon';
import {DocumentTemplateRow} from 'types/documentTemplateRow';
import {DocumentTemplateColumn} from 'types/documentTemplateColumn';
import {DocumentTemplateElement} from 'types/documentTemplateElement';
import {DocumentTemplate} from 'types/documentTemplate';
import DocumentColumn from './DocumentColumn';


interface DocumentRowProps {
  row: DocumentTemplateRow;
  rowIndex: number;
  template: DocumentTemplate;
  selectedElement: {
    element: DocumentTemplateElement;
    rowId: string;
    colId: string;
  } | null;
  selectedElementRef: React.RefObject<HTMLDivElement>;
  onSelectElement: (element: DocumentTemplateElement, rowId: string, colId: string) => void;
  onDeleteRow: (rowIndex: number) => void;
  onDuplicateRow: (rowIndex: number) => void;
  onUpRow: (rowIndex: number) => void;
  onDownRow: (rowIndex: number) => void;
  onDeleteItem: (rowIndex: number, colIndex: number) => void;
  onDuplicateItem: (rowIndex: number, colIndex: number) => void;
  onLeftItem: (rowIndex: number, colIndex: number) => void;
  onRightItem: (rowIndex: number, colIndex: number) => void;
  onUpdateTemplate: (updatedTemplate: DocumentTemplate) => void;
  onOpenDropdown: (rowId: string, colId: string) => void;
  openDropdown: {rowId: string; colId: string} | null;
  dropdownRef: React.RefObject<HTMLDivElement>;
  inputOptions: any;
  onSelectElementType: (rowId: string, colId: string, elementType: string) => void;
  setSelectedElement: React.Dispatch<
      React.SetStateAction<{
        element: DocumentTemplateElement;
        rowId: string;
        colId: string;
      } | null>
  >;
  propertiesElementRef: React.RefObject<HTMLDivElement>;

}

const DocumentRow: React.FC<DocumentRowProps> = ({
  row,
  rowIndex,
  template,
  selectedElement,
  selectedElementRef,
  onSelectElement,
  onDeleteRow,
  onDuplicateRow,
  onUpRow,
  onDownRow,
  onDeleteItem,
  onDuplicateItem,
  onLeftItem,
  onRightItem,
  onUpdateTemplate,
  onOpenDropdown,
  openDropdown,
  dropdownRef,
  inputOptions,
  onSelectElementType,
    setSelectedElement,
    propertiesElementRef,
}) => {
  if (row._destroy) {
    return null;
  }

  return (
    <div key={row.id} className="flex mb-4">
      <div className="grid grid-rows-4 justify-items-center gap-y-2 h-20">
        <div>
          <button
            className="text-slate-400 hover:text-slate-800 mb-2 items-center"
            onClick={() => onDeleteRow(rowIndex)}>
            <TrashIcon />
          </button>
        </div>
        <div>
          <button
            className="text-slate-400 hover:text-slate-800 mb-2 items-center"
            onClick={() => onDuplicateRow(rowIndex)}>
            <DuplicateIcon />
          </button>
        </div>
        {rowIndex > 0 && (
          <div>
            <button
              className="text-slate-400 hover:text-slate-800 justify-self-center items-center"
              onClick={() => onUpRow(rowIndex)}>
              <ArrowTopIcon />
            </button>
          </div>
        )}
        {rowIndex < template.rows!.length - 1 && (
          <div>
            <button
              className="text-slate-400 hover:text-slate-800 justify-self-center items-center"
              onClick={() => onDownRow(rowIndex)}>
              <ArrowBottomIcon />
            </button>
          </div>
        )}
      </div>
      {row.columns.map((col: DocumentTemplateColumn, colIndex: number) => {
        const isSelected =
          selectedElement &&
          selectedElement.rowId === row.id &&
          selectedElement.colId === col.id;
        return (
          <DocumentColumn
            key={col.id}
            rowId={row.id}
            colId={col.id}
            column={col}
            isSelected={isSelected ?? false}
            selectedElementRef={selectedElementRef}
            onSelectElement={onSelectElement}
            onDeleteItem={onDeleteItem}
            onDuplicateItem={onDuplicateItem}
            onLeftItem={onLeftItem}
            onRightItem={onRightItem}
            rowIndex={rowIndex}
            colIndex={colIndex}
            template={template}
            onUpdateTemplate={onUpdateTemplate}
            onOpenDropdown={onOpenDropdown}
            isDropdownOpen={openDropdown?.rowId === row.id && openDropdown?.colId === col.id}
            dropdownRef={dropdownRef}
            inputOptions={inputOptions}
            onSelectElementType={onSelectElementType}
            setSelectedElement={setSelectedElement}
            selectedElement={selectedElement}
            propertiesElementRef={propertiesElementRef}
          />
        );
      })}
    </div>
  );
};

export default DocumentRow; 