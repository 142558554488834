import React from 'react';

const FlagInputs: React.FC = () => {
  return (
    <div>
      <svg
        width="91"
        height="42"
        viewBox="0 0 91 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_1121_577)">
          <path d="M4 0H76.4043L87 17L76.4043 34H4V0Z" fill="#FDE047" />
        </g>
        <defs>
          <filter
            id="filter0_d_1121_577"
            x="0"
            y="0"
            width="91"
            height="42"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1121_577"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1121_577"
              result="shape"
            />
          </filter>
        </defs>
        <text
          x="45%"
          y="43%"
          textAnchor="middle"
          dominantBaseline="middle"
          fill="#1f2937"
          fontWeight="bold"
          fontSize="14px">
          NEXT
        </text>
      </svg>
    </div>
  );
};

export default FlagInputs;
