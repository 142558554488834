import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from 'store/store';
import {UserApplicationThunks} from './userApplicationThunks';
import Table from 'components/table/Table';
import BlueButton from 'components/BlueButton';
import StatusBadge from 'components/StatusBadge';
import {SearchType} from 'types/search';
import {UserApplication} from 'types/userApplication';
import {setActiveTab} from 'features/hr/hrSlice';
import {setIsEditing} from './userApplicationSlice';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import TableCell from 'components/table/TableCell';
import DotsMenu from 'components/DotsMenu';
import PlusIcon from '../../assets/images/icons/PlusIcon';
import useViewerModal from '../../hooks/useViewerModal';
import {DocumentTemplateSolutionThunks} from '../documentTemplateSolution/documentTemplateSolutionThunks';
import {setUserApplication} from './userApplicationSlice';

const UserApplicationTable: React.FC<{openDrawer: () => void}> = ({
  openDrawer,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const params = useParams<{userId: string}>();
  const userApplications = useSelector(
    (state: RootState) => state.userApplication.userApplications,
  );
  const pagy = useSelector((state: RootState) => state.userApplication.pagy);
  const activeTab = useSelector((state: RootState) => state.hr.activeTab);
  const loading = useSelector(
    (state: RootState) => state.userApplication.status === 'loading',
  );

  const {handleNameClick, AttachmentModal} = useViewerModal<any>({
    openDrawer,
    fetchItemThunk: UserApplicationThunks.show,
    fetchDocumentTemplateThunk: DocumentTemplateSolutionThunks.index,
  });

  interface VisibleColumnsType {
    documentTemplate: boolean;
    status: boolean;
    createdAt: boolean;
  }

  const [visibleColumns, setVisibleColumns] = useState<VisibleColumnsType>({
    documentTemplate: true,
    status: true,
    createdAt: true,
  });

  const handleNewApplication = () => {
    dispatch(setUserApplication(null));
    dispatch(setIsEditing(true));
    openDrawer();
  };

  const searchByOptions = [
    {
      label: 'Document',
      value: 'documentTemplate.name',
      operator: 'like',
    },
    {label: 'Status', value: 'status', operator: '=='},
  ];

  const columnOptions = [
    {
      label: 'Document',
      value: 'documentTemplate.name',
      isVisible: visibleColumns.documentTemplate,
    },
    {label: 'Status', value: 'status', isVisible: visibleColumns.status},
    {
      label: 'Created At',
      value: 'createdAt',
      isVisible: visibleColumns.createdAt,
    },
  ];

  const fetchData = async ({
    page,
    search,
    sortField,
    sortDirection,
    rowsPerPage,
  }: {
    page: number;
    search: SearchType<{[key: string]: {operator: string; value: string}}>;
    sortField: string;
    sortDirection: string;
    rowsPerPage: number;
  }): Promise<void> => {
    const {userId} = params;

    search.where.user_id = {
      operator: '==',
      value: userId as string,
    };

    await dispatch(
      UserApplicationThunks.index({
        page,
        search,
        sortField,
        sortDirection,
        rowsPerPage,
      }),
    );
  };

  const handleColumnToggle = (column: string) => {
    if (column in visibleColumns) {
      setVisibleColumns(prev => ({
        ...prev,
        [column]: !prev[column as keyof VisibleColumnsType],
      }));
    }
  };

  const handleEdit = async (id: string) => {
    await dispatch(UserApplicationThunks.show(id));
    dispatch(setIsEditing(true));
    openDrawer();
  };

  return (
    <>
      <Table
        tabs={[
          'HR Documents',
          'Certifications',
          'Trainings',
          'Background Documents',
          'Medical Documents',
          'Applications',
          'Roles',
          'Evaluations',
        ]}
        recordsName={'Documents'}
        activeTab={activeTab}
        fetchData={fetchData}
        pagy={pagy}
        searchByOptions={searchByOptions}
        searchable={true}
        pageable={true}
        loading={loading}
        columnOptions={columnOptions}
        onColumnToggle={handleColumnToggle}
        showColumnsButton={true}
        setActiveTab={(tab: string) => dispatch(setActiveTab(tab))}
        blueButton={
          <BlueButton
            onClick={handleNewApplication}
            label={''}
            icon={<PlusIcon />}
            inputId="new-application-button"
          />
        }>
        <thead>
          <tr>
            <th scope="col" className="px-3 pt-3.5 flex justify-center">
              <input
                type="checkbox"
                className="shrink-0 border-stone-300 rounded disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500
                dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"
              />
            </th>
            {visibleColumns.documentTemplate && (
              <TableHeader
                label="Document"
                sortBy="documentTemplate.name"
              />
            )}
            {visibleColumns.status && (
              <TableHeader label="Status" sortBy="status" />
            )}
            {visibleColumns.createdAt && (
              <TableHeader label="Created At" sortBy="createdAt" />
            )}
            <TableHeader label="" />
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
          {userApplications?.map((application: UserApplication) => {
            const userOptions = [
              {
                label: 'Edit',
                onClick: () => handleEdit(application.id),
                hoverClass: 'hover:bg-cyan-100',
              },
            ];

            return (
              <TableRow key={application.id}>
                <td className="pt-3.5 flex justify-center">
                  <input
                    type="checkbox"
                    className="border border-gray-300 rounded text-white disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600
                    dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                    onClick={e => e.stopPropagation()}
                  />
                </td>
                {visibleColumns.documentTemplate && (
                  <TableCell>
                    <div className=" relative group">
                      <div className="w-full flex items-center gap-x-3">
                        <div className="flex-grow">
                          <span
                            className={`text-sm  font-medium dark:text-neutral-200 ${
                              application.status !== 'Missing'
                                ? 'underline text-cyan-600 cursor-pointer'
                                : ''
                            }`}
                            onClick={
                              application.status !== 'Missing'
                                ? () =>
                                    handleNameClick({
                                      id: application.id,
                                      name:
                                        application.documentTemplate?.name ||
                                        '',
                                      status: application.status || 'Unknown',
                                      attachment: application.attachment,
                                      docType: 'template',
                                    })
                                : undefined
                            }>
                            {application.documentTemplate?.name}
                          </span>
                        </div>
                      </div>
                    </div>
                  </TableCell>
                )}
                {visibleColumns.status && (
                  <TableCell>
                    <StatusBadge status={application.status ?? 'Unknown'} />
                  </TableCell>
                )}
                {visibleColumns.createdAt && (
                  <TableCell>
                    {new Date(application.createdAt).toLocaleDateString()}
                  </TableCell>
                )}

                <TableCell>
                  <DotsMenu options={userOptions} />
                </TableCell>
              </TableRow>
            );
          })}
        </tbody>
      </Table>
      <AttachmentModal />
    </>
  );
};

export default UserApplicationTable;
