import React, {useEffect, useRef, useState} from 'react';
import XIcon from 'assets/images/icons/XIcon';
import SignatureInput from 'components/inputs/SignatureInput';
import SubmitButton from 'components/SubmitButton';
import SignatureCanvas from 'react-signature-canvas';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from 'store/store';
import {UserThunks} from './userThunks';
import {showToast} from 'components/ToastContainer';

interface UpdateSignatureProps {
  onClose: () => void;
  isDrawerOpen?: boolean;
}

const UpdateSignature: React.FC<UpdateSignatureProps> = ({
  onClose,
  isDrawerOpen,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: RootState) => state.auth.user);
  const signatureCanvasRef = useRef<SignatureCanvas>(null);
  const [signatureError, setSignatureError] = useState<string | undefined>();
  const [, setIsFormValid] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!signatureCanvasRef.current || signatureCanvasRef.current.isEmpty()) {
      setSignatureError('Signature is required.');
      return;
    }

    const signatureImage = signatureCanvasRef.current.toDataURL();

    try {
      await dispatch(
        UserThunks.updateSignature({
          id: user?.id || '',
          signature: signatureImage,
        }),
      ).unwrap();
      handleClose();
    } catch (error) {
      showToast('Error updating signature.', 'error');
    }
    onClose();
  };

  const handleClose = () => {
    clearSignature();
    onClose();
  };

  const handleEnd = () => {
    if (signatureCanvasRef.current && !signatureCanvasRef.current.isEmpty()) {
      setIsFormValid(true);
      setIsSaveDisabled(false);
      setSignatureError(undefined);
    } else {
      setIsFormValid(false);
      setIsSaveDisabled(true);
    }
  };

  useEffect(() => {
    const canvas = signatureCanvasRef.current?.getCanvas();
    if (canvas) {
      canvas.addEventListener('mouseup', handleEnd);
      canvas.addEventListener('touchend', handleEnd);
    }

    return () => {
      if (canvas) {
        canvas.removeEventListener('mouseup', handleEnd);
        canvas.removeEventListener('touchend', handleEnd);
      }
    };
  }, []);

  const clearSignature = (e?: React.MouseEvent<HTMLButtonElement>) => {
    if (e) e.preventDefault();
    signatureCanvasRef.current?.clear();
    setSignatureError(undefined);
    setIsFormValid(false);
    setIsSaveDisabled(true);
  };

  useEffect(() => {
    clearSignature();
  }, [isDrawerOpen]);

  return (
    <div className="flex flex-col h-screen">
      <div className="flex-1 flex flex-col">
        <div className="h-auto p-6 bg-sky-50 border-b border-sky-200 flex justify-between items-start gap-2 w-full">
          <div className="flex flex-col justify-start items-start gap-1">
            <div className="text-lg font-semibold tracking-normal text-cyan-800">
              Signature
            </div>
            <div className="text-sm font-light tracking-normal leading-5 text-zinc-400">
              Update your user signature
            </div>
          </div>
          <button
            onClick={handleClose}
            className="text-gray-500 hover:text-gray-700 focus:outline-none pt-2">
            <XIcon />
          </button>
        </div>
        <div className="h-full px-6 pb-6 ">
          <div className="mt-5">
            <form onSubmit={handleSubmit}>
              <div className="self-stretch w-full">
                <div className="flex flex-col space-y-6">
                  <div className="w-full">
                    <SignatureInput
                      signatureCanvasRef={signatureCanvasRef}
                      error={signatureError}
                      onEnd={handleEnd}
                      width={380}
                      height={120}
                    />
                    <div>
                      <button
                        type="submit"
                        onClick={clearSignature}
                        className=" w-full inline-flex justify-start items-center  text-sm font-light text-slate-400 font-['Inter'] bg-white disabled:pointer-events-none
                                                    disabled:bg-zinc-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
                        Clear
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <footer className="flex-shrink-0 px-6 pb-4 pt-2 flex justify-end gap-5">
        <div className="border-t border-slate-200 w-full">
          <div className="flex w-full justify-center items-center gap-x-2 pb-4">
            <SubmitButton
              onClick={handleSubmit}
              label="Update Signature"
              disabled={isSaveDisabled}
            />
          </div>
          <div className="flex w-full justify-center items-center gap-x-2 pb-4">
            <button
              type="submit"
              onClick={handleClose}
              className="self-center text-sm font-light tracking-normal leading-5 text-center text-zinc-400 hover:text-cyan-400">
              Never mind
            </button>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default UpdateSignature;
