import React, {useContext, useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from 'store/store';
import {DocumentTemplateThunks} from 'features/documentTemplate/documentTemplateThunks';
import {TitleContext} from 'components/TitleContext';
import {DocumentTemplateRow} from 'types/documentTemplateRow';
import {DocumentTemplateColumn} from 'types/documentTemplateColumn';
import LogoNewHope from 'assets/images/LogoNewHope.png';
import RenderElementView from 'components/documentTemplate/RenderElementView';
import {DocumentTemplateElement} from 'types/documentTemplateElement';
import {UserApplicationThunks} from '../userApplication/userApplicationThunks';
import {DocumentTemplateSolution} from 'types/documentTemplateSolution';
import {DocumentTemplateSolutionThunks} from '../documentTemplateSolution/documentTemplateSolutionThunks';
import FlagInputs from 'components/FlagInputs';
import {validateEmail, validateUrl} from 'utils/validators';
import {DocumentTemplate} from 'types/documentTemplate';
import {showToast} from 'components/ToastContainer';
import {isElementDisabled} from '../../utils/elementUtils';

interface DocumentTemplateFillOutComponentProps {
  mode: 'view' | 'preview' | 'edit' | 'admin';
  buttonText: string;
  documentTemplate: DocumentTemplate;
  solutionId?: string;
}

const DocumentTemplateFillOutComponent: React.FC<
  DocumentTemplateFillOutComponentProps
> = ({mode, buttonText, documentTemplate, solutionId}) => {
  const {id} = useParams<{id: string}>();
  const dispatch = useDispatch<AppDispatch>();
  const userApplication = useSelector(
    (state: RootState) => state.userApplication.userApplication,
  );
  const navigate = useNavigate();

  const [updatedDocumentTemplate, setUpdatedDocumentTemplate] = useState({
    ...documentTemplate,
  });
  const addRowButtonRef = useRef<HTMLButtonElement>(null);
  const user = useSelector((state: RootState) => state.auth.user);
  const base64Signature = useSelector(
    (state: RootState) => state.auth.base64Signature,
  );
  const {setTitle} = useContext(TitleContext);
  const [elementsCount, setElementsCount] = useState<number>(0);
  const [isStarted, setIsStarted] = useState(false);
  const [yellowButtonText, setYellowButtonText] = useState('Start');
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const nextEmptyElementRef = useRef<HTMLDivElement>(null);
  const [nextEmptyElement, setNextEmptyElement] =
    useState<DocumentTemplateElement | null>(null);

  useEffect(() => {
    setTitle(
      <div>
        <div className="text-slate-600 text-lg font-medium font-['Inter']">
          {updatedDocumentTemplate?.name}
        </div>
        <div className="text-slate-400 text-sm font-light font-['Inter'] leading-normal">
          by New Hope Human Resources
        </div>
      </div>,
    );
  }, [updatedDocumentTemplate, setTitle]);

  useEffect(() => {
    if (id) {
      dispatch(UserApplicationThunks.show(id)).then(action => {
        if (action.payload?.documentTemplateId) {
          dispatch(
            DocumentTemplateThunks.show(action.payload.documentTemplateId),
          );
        }
      });
    }
  }, [id, dispatch]);

  const findNextEmptyElement = () => {
    const rows = updatedDocumentTemplate.rows;
    const columns = rows?.map((row: DocumentTemplateRow) => row.columns).flat();
    const element = columns?.find(
      (column: DocumentTemplateColumn) =>
        isElementSummable(column.element as DocumentTemplateElement) &&
        ((column.element as DocumentTemplateElement).properties.value === '' ||
          (column.element as DocumentTemplateElement).properties.value ===
            undefined),
    );
    if (
      element &&
      (element.element as DocumentTemplateElement).elementType ===
        'SignatureInput'
    )
      return false;
    return element;
  };

  useEffect(() => {
    if (isStarted) {
      const element = findNextEmptyElement();
      if (element) {
        setNextEmptyElement(element.element as DocumentTemplateElement);
      } else {
        setNextEmptyElement(null);
      }
    }
  }, [updatedDocumentTemplate, isStarted]);

  const isElementSummable = (element: DocumentTemplateElement) => {
    if (element === null) return false;
    if (element === undefined) return false;

    if (mode === 'view') {
      return (
        !['TableInput', 'TextBlock'].includes(element.elementType) &&
        element.properties.adminOnly === false
      );
    } else if (mode === 'admin') {
      if (!element.properties.adminOnly) return false;
      if (
        element.properties.supervisorIds?.length &&
        !element.properties.supervisorIds.includes(user?.id)
      ) {
        return false;
      }
      return !['TableInput', 'TextBlock'].includes(element.elementType);
    }
  };

  useEffect(() => {
    setUpdatedDocumentTemplate({...documentTemplate});
    const elements = documentTemplate?.rows?.reduce(
      (sum: number, row: DocumentTemplateRow) => {
        return (
          sum +
          row.columns.filter((col: DocumentTemplateColumn) =>
            isElementSummable(col.element as DocumentTemplateElement),
          ).length
        );
      },
      0,
    );
    setElementsCount(elements || 0);
  }, [documentTemplate]);

  useEffect(() => {
    const isFormValid = updatedDocumentTemplate.rows?.every(
      (row: DocumentTemplateRow) => {
        return row.columns.every((col: DocumentTemplateColumn) => {
          const element = col.element as DocumentTemplateElement;
          if (!isElementSummable(element)) return true;
          if (element.properties.required === false) return true;
          if (element.elementType === 'SignatureInput') return true;
          const hasValue =
            element.properties.value !== undefined &&
            element.properties.value !== '';
          const isValidEmail =
            element.elementType !== 'EmailInput' ||
            (element.elementType === 'EmailInput' &&
              (!hasValue || validateEmail(element.properties.value as string)));
          const isValidUrl =
            element.elementType !== 'UrlInput' ||
            (element.elementType === 'UrlInput' &&
              (!hasValue || validateUrl(element.properties.value as string)));
          return hasValue && isValidEmail && isValidUrl;
        });
      },
    );
    setIsSaveDisabled(!isFormValid);
  }, [updatedDocumentTemplate]);

  const handleAction = () => {
    if (isSaveDisabled || !isStarted) {
      setIsStarted(true);
      setYellowButtonText('Finish');
      setTimeout(() => {
        nextEmptyElementRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }, 100);
    } else {
      handleSubmit();
    }
  };

  const replaceTemplateTags = (
    html: string,
    tagValues: Record<string, string>,
  ): string => {
    return html.replace(
      /<span([^>]*data-tag-value="(\{([^}]+)\})"[^>]*)>(.*?)<\/span>/g,
      (match, spanAttributes, fullTag, tagName, innerContent) => {
        const replacement = tagValues[tagName] || innerContent;
        return `<span${spanAttributes}>${replacement}</span>`;
      },
    );
  };

  const updateTextBlockElements = () => {
    const updatedRows = updatedDocumentTemplate?.rows?.map(
      (row: DocumentTemplateRow) => {
        return {
          ...row,
          columns: row.columns.map((col: DocumentTemplateColumn) => {
            if (col.element?.elementType === 'TextBlock') {
              const tagValues = {
                EMPLOYEE_FIRST_NAME: userApplication?.user?.firstName || '',
                CURRENT_DATE: new Date().toLocaleDateString(),
                COMPANY_NAME: 'New Hope',
              };

              const processedContent = replaceTemplateTags(
                col.element?.properties.content || '',
                tagValues,
              );
              return {
                ...col,
                element: {
                  ...col.element,
                  properties: {
                    ...col.element.properties,
                    content: processedContent || '',
                  },
                },
              };
            }
            return col;
          }),
        };
      },
    );
    setUpdatedDocumentTemplate({
      ...updatedDocumentTemplate,
      rows: updatedRows as DocumentTemplateRow[],
    });
  };
  const handleSubmit = async () => {
    updateTextBlockElements();
    const updatedRows = updatedDocumentTemplate.rows?.map(
      (row: DocumentTemplateRow) => {
        return {
          ...row,
          columns: row.columns.map((col: DocumentTemplateColumn) => {
            if (
              col.element?.elementType === 'SignatureInput' &&
              !isElementDisabled(col.element, mode, user)
            ) {
              return {
                ...col,
                element: {
                  ...col.element,
                  properties: {
                    ...col.element.properties,
                    value: base64Signature,
                  },
                },
              };
            }
            return col;
          }),
        };
      },
    );
    const solution = new DocumentTemplateSolution({
      id: solutionId,
      documentTemplateId: documentTemplate.id,
      snapshot: {
        ...updatedDocumentTemplate,
        rows: updatedRows as DocumentTemplateRow[],
      },
      ownerId: id,
      ownerType: 'UserApplication',
    });
    const resultAction = await dispatch(
      solutionId
        ? DocumentTemplateSolutionThunks.complete(solution)
        : DocumentTemplateSolutionThunks.create(solution),
    );
    if (DocumentTemplateSolutionThunks.create.fulfilled.match(resultAction)) {
      navigate('/my-dashboard');
      showToast('Document saved successfully', 'success');
    }

    if (DocumentTemplateSolutionThunks.complete.fulfilled.match(resultAction)) {
      navigate('/my-dashboard');
      showToast('Document updated successfully', 'success');
    }
  };

  return (
    <div className="flex flex-col min-h-[94vh]  ">
      <div className="flex justify-center mt-8">
        <div className=" w-[820px] overflow-visible p-5 space-x-4 bg-white border border-slate-200 rounded-xl justify-between items-center gap-6 inline-flex ">
          <div className="text-slate-600 text-sm font-normal font-['Inter'] leading-tight tracking-tight">
            Please complete the {elementsCount} required fields
          </div>
          <div>
            <button
              ref={addRowButtonRef}
              onClick={handleAction}
              disabled={isStarted && isSaveDisabled}
              className="w-full flex items-center justify-center py-3.5 px-4 bg-yellow-300 text-yellow-950 disabled:opacity-50 font-semibold rounded-lg">
              {yellowButtonText}
            </button>
          </div>
        </div>
      </div>
      <div className="flex justify-center pt-8">
        <div
          className={` w-[820px] min-h-[80vh] p-5 bg-white border border-gray-200 rounded-xl overflow-visible relative`}>
          <div className="flex justify-start">
            <div className=" max-w-[820px] px-5 pt-5">
              {updatedDocumentTemplate.enableHeader && (
                <div className="flex mb-8">
                  <div className={`flex-1 px-2 relative`}>
                    <div className="w-full h-full min-h-20  flex items-center justify-start">
                      <div className="text-slate-600 text-lg font-medium font-['Inter']">
                        <img
                          className="pl-3 w-24 h-auto"
                          src={LogoNewHope}
                          alt="Logo"
                        />
                      </div>
                      <div className="text-slate-400 text-md font-light font-['Inter'] leading-normal ml-1 mr-1">
                        |
                      </div>
                      <div className="text-slate-400 text-md font-light font-['Inter'] leading-normal">
                        {updatedDocumentTemplate.name}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {updatedDocumentTemplate.rows?.map((row: DocumentTemplateRow) => {
                if (row._destroy) {
                  return null;
                }
                return (
                  <div key={row.id} className="flex mb-4 overflow-visible">
                    {row.columns.map((col: DocumentTemplateColumn) => (
                      <div key={col.id} className={`flex-1 px-2`}>
                        {col.element && col.element._destroy !== true ? (
                          <div className="relative w-full h-full min-h-20 flex items-start justify-start overflow-visible">
                            {col.element?.id === nextEmptyElement?.id && (
                              <div
                                ref={nextEmptyElementRef}
                                className="absolute top-8 left-0 z-10"
                                style={{transform: 'translateX(-100%)'}}>
                                <FlagInputs />
                              </div>
                            )}
                            <RenderElementView
                              element={col.element}
                              mode={mode}
                              updateElement={updatedElement => {
                                setUpdatedDocumentTemplate(
                                  (prevTemplate: any) => {
                                    const updatedRows = prevTemplate.rows.map(
                                      (rowItem: DocumentTemplateRow) => {
                                        if (rowItem.id !== row.id)
                                          return rowItem;
                                        return {
                                          ...rowItem,
                                          columns: rowItem.columns.map(
                                            (
                                              column: DocumentTemplateColumn,
                                            ) => {
                                              if (column.id !== col.id)
                                                return column;
                                              return {
                                                ...column,
                                                element: updatedElement,
                                              };
                                            },
                                          ),
                                        };
                                      },
                                    );
                                    return {
                                      ...prevTemplate,
                                      rows: updatedRows,
                                    };
                                  },
                                );
                              }}
                            />
                          </div>
                        ) : (
                          <div className="w-full h-full min-h-20 flex items-center justify-center text-slate-400"></div>
                        )}
                      </div>
                    ))}
                  </div>
                );
              })}
              <div className="mx-6  text-slate-400 text-sm font-normal font-['Inter'] leading-tight tracking-tight">
                By signing this document with an electronic signature, I agree
                that such signature will be as valid as handwritten signatures
                and considered originals to the extent allowed by applicable
                law.
              </div>
            </div>
          </div>
          <div className=" py-6 mx-10 ">
            <button
              className="w-full flex items-center justify-center py-3.5 mx-1 bg-cyan-400 text-white hover:bg-cyan-500 font-semibold rounded-lg disabled:pointer-events-none disabled:bg-zinc-400"
              onClick={handleSubmit}
              disabled={isSaveDisabled}>
              {buttonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentTemplateFillOutComponent;
