import { createEntitySlice } from 'utils/createEntitySlice';
import { UserJobRole } from 'types/userJobRole';
import { UserJobRoleThunks } from './userJobRoleThunks';

interface UserJobRoleState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    userJobRoles: [];
    pagy: any;
    userJobRole: null;
    isEditing: boolean;
    activeTab: string;
}

const initialState: UserJobRoleState = {
    status: 'idle',
    error: null,
    userJobRoles: [],
    pagy: null,
    userJobRole: null,
    isEditing: false,
    activeTab: 'JobRoles'
};

const userJobRoleSlice = createEntitySlice<UserJobRole>({
    name: 'userJobRole',
    initialState,
    entityKey: 'userJobRole',
    entitiesKey: 'userJobRoles',
    thunks: UserJobRoleThunks,
    reducers: {}
});
export const {
    setEntity: setUserJobRole,
    setIsEditing,
    setActiveTab,
} = userJobRoleSlice.actions;

export {initialState as userJobRoleInitialState};
export default userJobRoleSlice.reducer; 