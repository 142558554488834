import {createEntitySlice} from 'utils/createEntitySlice';
import {JobRole} from 'types/jobRole';
import {JobRoleThunks} from 'features/jobRole/jobRoleThunks';

export interface JobRoleState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  jobRoles?: any;
  pagy?: any;
  jobRole?: any;
  activeTab?: string;
}

const initialState: JobRoleState = {
  status: 'idle',
  error: null,
  jobRoles: [],
  jobRole: null,
  pagy: {},
  activeTab: 'All',
};

const jobRoleSlice = createEntitySlice<JobRole>({
  name: 'role',
  initialState,
  thunks: JobRoleThunks,
  entityKey: 'jobRole',
  entitiesKey: 'jobRoles',
  reducers: {},
});

export const {setEntity: setJobRole, setActiveTab} = jobRoleSlice.actions;
export {initialState as jobRoleInitialState};
export default jobRoleSlice.reducer;
