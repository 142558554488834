import React from 'react';
import TextInput from 'components/inputs/TextInput';
import CustomSelect from 'components/inputs/CustomSelect';
import SubmitButton from 'components/SubmitButton';
import {DocumentTemplate} from 'types/documentTemplate';

interface DocumentConfigurationFormProps {
  documentTemplate: DocumentTemplate;
  onChange: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => void;
  onPublish: () => void;
  onEnableHeaderChange: (value: boolean) => void;
  onRequiresReviewChange: (value: boolean) => void;
}

const DocumentConfigurationForm: React.FC<DocumentConfigurationFormProps> = ({
  documentTemplate,
  onChange,
  onPublish,
  onEnableHeaderChange,
  onRequiresReviewChange,
}) => {
  return (
    <div className="relative">
      <div className="relative">
        <div className="p-5 space-x-4 flex bg-white border border-gray-200 rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
          <div className="flex-grow">
            <TextInput
              id="name"
              type="text"
              label="Document Name"
              value={documentTemplate.name || ''}
              onChange={onChange}
            />
          </div>
          <div className="w-40">
            <div className="flex flex-col gap-[0.625rem] w-full">
              <label
                htmlFor="enableHeader"
                className="w-full block text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
                Enable header
              </label>
              <CustomSelect
                menuPortalTarget={document.body}
                options={[
                  {value: true, label: 'Yes'},
                  {value: false, label: 'No'},
                ]}
                value={documentTemplate.enableHeader}
                onChange={selectedOption =>
                  onEnableHeaderChange(selectedOption.value)
                }
              />
            </div>
          </div>
          <div className="w-40">
            <div className="flex flex-col gap-[0.625rem] w-full">
              <label
                htmlFor="requiresReview"
                className="w-full block text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
                Requires review
              </label>
              <CustomSelect
                menuPortalTarget={document.body}
                options={[
                  {value: true, label: 'Yes'},
                  {value: false, label: 'No'},
                ]}
                value={documentTemplate.requiresReview}
                onChange={selectedOption =>
                  onRequiresReviewChange(selectedOption.value)
                }
              />
            </div>
          </div>
          <div className="w-40 mt-1.5">
            <SubmitButton
              onClick={onPublish}
              label="Publish"
              disabled={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentConfigurationForm;
