import React from 'react';
import {TableInputElement} from 'types/documentTemplateElement';
import TextInput from '../../inputs/TextInput';
import {DocumentTemplate} from 'types/documentTemplate';
import BaseInputProperties from './BaseInputProperties';

interface TableInputPropertiesProps {
  element: TableInputElement;
  updateElement: (updatedElement: TableInputElement) => void;
  template: DocumentTemplate;
}

const TableInputProperties: React.FC<TableInputPropertiesProps> = ({
  element,
  updateElement,
  template,
}) => {
  const {rows, columns} = element.properties;

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const {id, value} = e.target;
    const parsedValue =
      id === 'rows' || id === 'columns' ? parseInt(value, 10) || 0 : value;

    updateElement({
      ...element,
      properties: {
        ...element.properties,
        [id]: parsedValue,
      },
    });
  };

  const additionalFields = (
    <div className="flex gap-4">
      <TextInput
        id="rows"
        type="number"
        label="Rows"
        value={rows.toString()}
        onChange={handleChange}
      />
      <TextInput
        id="columns"
        type="number"
        label="Columns"
        value={columns.toString()}
        onChange={handleChange}
      />
    </div>
  );

  return (
    <BaseInputProperties
      element={element}
      template={template}
      updateElement={updateElement}
      additionalFields={additionalFields}
      hiddePlaceholder={true}
    />
  );
};

export default TableInputProperties;
