import React, {useEffect, useState} from 'react';
import useAppDispatch from "hooks/useAppDispatch";
import {useNavigate} from 'react-router-dom';
import {logout} from "features/auth/authSlice";
import {validateEmail} from "utils/validators";
import TextInput from "components/inputs/TextInput";
import {useSelector} from "react-redux";
import {RootState} from "store/store";
import {ArrowRightIcon} from "assets/images/icons/ArrowRightIcon";
import {showToast} from "components/ToastContainer";
import SubmitButton from "components/SubmitButton";
import {AuthThunks} from "features/auth/authThunks";


const ForgotPassword : React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const status = useSelector((state: RootState) => state.auth.status);

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState<string | undefined>();

    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        dispatch(logout());
    }, [dispatch]);

    useEffect(() => {
        const isEmailValid = validateEmail(email);

        setIsFormValid(isEmailValid  && email !== '');
    }, [email]);

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setEmail(value);

        if (validateEmail(value)) {
            setEmailError(undefined);
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
            e.preventDefault();

            const resultAction = await dispatch(AuthThunks.forgotPassword({email: email.trim()}));
            if (AuthThunks.forgotPassword.fulfilled.match(resultAction)) {
                showToast('If the email exists, you will receive an email with instructions to reset your password', 'success')
                navigate('/login');
            }


    };


    return (
        <form className="mt-12 needs-validation self-stretch w-80" onSubmit={handleSubmit} noValidate>
            <div className="flex flex-col ">
                <div className="mb-3.5">
                <TextInput
                    data-testid="email-field"
                    id="email"
                    type="text"
                    label="Email"
                    value={email}
                    onChange={handleEmailChange}
                    error={emailError}
                    placeholder="you@email.com"
                    tabIndex={1}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            handleSubmit(e);
                        }
                    }}
                />
                </div>
                <div className="mt-24">
                    <SubmitButton
                        onClick={handleSubmit}
                        label={<>{'Continue'} <ArrowRightIcon/></>}
                        disabled={status === 'loading' || !isFormValid}
                    />
                </div>
            </div>
        </form>
    );
};

export default ForgotPassword
