import React from 'react';
import {DateTimeInputElementClass} from 'types/documentTemplateElement';
import {ElementProps} from 'types/documentTemplateElement';
import DateTimeInput from '../../inputs/DateTimeInput';
import {buildLabel, checkError} from 'utils/elementUtils';
import {useIsDocumentElementDisabled} from 'hooks/useIsDocumentElementDisabled';

const DateTimeInputElementComponent: React.FC<
  ElementProps<DateTimeInputElementClass>
> = ({element, updateElement, mode}) => {
  const handleChange = (value: string) => {
    if (mode === 'view' || mode === 'admin') {
      const newUpdatedElement = new DateTimeInputElementClass({
        ...element,
        properties: {
          ...element.properties,
          value: value,
        },
      });
      updateElement(newUpdatedElement);
    }
  };

  const disabled = useIsDocumentElementDisabled(element, mode);

  let containerClass = 'w-full m-8';
  if (mode === 'view' || mode === 'preview' || mode === 'admin') {
    containerClass = element.properties.showLabel
      ? 'w-full mx-3 mb-2'
      : 'w-full mt-5 mx-3 mb-2';
  }

  return (
    <div className={containerClass}>
      <DateTimeInput
        id={element.id}
        error={checkError(element, mode)}
        label={buildLabel(element)}
        value={element.properties?.value || ''}
        placeholder={element.properties.placeholder}
        required={element.properties.required}
        isClickDisabled={mode === 'preview'}
        onChange={handleChange}
        allowPastDates={element.properties.allowPastDates}
        disabled={disabled}
      />
    </div>
  );
};

export default DateTimeInputElementComponent;
