import { createEntityService } from 'utils/createEntityService';
import { UserApplication } from 'types/userApplication';

export const UserApplicationService = createEntityService<UserApplication>({
    baseUrl: 'api/v1/user_applications',
    entityName: 'userApplication',
    methodConfigs: {
        create: {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
        update: {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    }
});
