import {createEntitySlice} from 'utils/createEntitySlice';
import {Location} from 'types/location';
import {LocationThunks} from 'features/location/locationThunks';
import {Training} from 'types/training';

export interface LocationState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  locations: Location[];
  pagy: any;
  location: Training | null;
  isEditing: boolean;
  activeTab: string;
}

const initialState: LocationState = {
  status: 'idle',
  error: null,
  locations: [],
  location: null,
  pagy: {},
  isEditing: false,
  activeTab: 'All',
};
const locationSlice = createEntitySlice<Location>({
  name: 'location',
  initialState,
  thunks: LocationThunks,
  entityKey: 'location',
  entitiesKey: 'locations',
  reducers: {},
});

export const {
  setEntity: setLocation,
  setIsEditing,
  setActiveTab,
} = locationSlice.actions;
export {initialState as locationInitialState};
export default locationSlice.reducer;
