import React, { useState, useRef, useEffect } from "react";
import ClockIcon from "assets/images/icons/ClockIcon";

interface TimeInputProps {
    label?: string;
    id: string;
    error?: string | undefined;
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
    className?: string;
    optional?: string;
    required?: boolean;
    isClickDisabled?: boolean;
    disabled?: boolean;
}

const TimeInput: React.FC<TimeInputProps> = ({
                                                 label,
                                                 id,
                                                 error,
                                                 value,
                                                 onChange,
                                                 className = "w-full",
                                                 optional,
                                                 required,
                                                 disabled ,
                                                 isClickDisabled,
                                             }) => {
    const [selectedHour, setSelectedHour] = useState<number | null>(null);
    const [selectedMinute, setSelectedMinute] = useState<number | null>(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const timeDropdownRef = useRef<HTMLDivElement | null>(null);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleHourClick = (hour: number) => {
        setSelectedHour(hour);
        if (selectedMinute !== null) {
            onChange(`${hour.toString().padStart(2, "0")}:${selectedMinute.toString().padStart(2, "0")}`);
            setIsDropdownOpen(false);
        }
    };

    const handleMinuteClick = (minute: number) => {
        setSelectedMinute(minute);
        if (selectedHour !== null) {
            onChange(`${selectedHour.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}`);
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (timeDropdownRef.current && !timeDropdownRef.current.contains(event.target as Node)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="flex flex-col items-start gap-[0.625rem]">
            <div className="flex justify-between w-full">
                {label && (
                    <div className="flex justify-between w-full">
                        <label
                            htmlFor={id}
                            className="block grow shrink basis-0 text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white"
                        >
                            {label}
                        </label>
                        <span className="text-gray-400 text-sm">{optional}</span>
                    </div>
                )}
            </div>
            <div className="relative w-full">
                <button
                    type="button"
                    className={`absolute inset-y-0 left-0 flex items-center px-3 text-gray-400 ${isClickDisabled ? "pointer-events-none" : ""}`}
                    onClick={!isClickDisabled ? toggleDropdown : undefined  }

                >
                    <ClockIcon />
                </button>
                <input
                    type="text"
                    id={id}
                    value={selectedHour !== null && selectedMinute !== null ? `${selectedHour.toString().padStart(2, "0")}:${selectedMinute.toString().padStart(2, "0")}` : value}
                    onChange={(e) => onChange(e.target.value)}
                    required={required}
                    placeholder={"HH:MM"}
                    className={`py-2.5 px-3 pl-10 w-full block border  rounded-lg text-sm text-gray-500 disabled:bg-slate-100  disabled:text-slate-300 disabled:border-0 disabled:pointer-events-none disabled:cursor-not-allowed 
                    ${error !== undefined ? "border-red-500" : "border-slate-200"} ${className}`}
                    aria-describedby={`${id}-error`}
                    readOnly
                    onClick={!isClickDisabled ? toggleDropdown : undefined}
                    disabled={disabled}
                />
                {isDropdownOpen && (
                    <div
                        ref={timeDropdownRef}
                        className="absolute bg-white border shadow rounded-lg mt-2 w-60 z-20 left-0"
                    >
                        <div className="p-3 grid grid-cols-2 gap-4">
                            <div className="space-y-1">
                                <h4 className="text-gray-600 text-sm font-semibold">Hours</h4>
                                <div className="max-h-48 overflow-y-auto grid grid-cols-1 gap-1">
                                    {Array.from({ length: 24 }).map((_, hour) => (
                                        <button
                                            key={hour}
                                            type="button"
                                            className={`py-1 px-2 text-sm rounded ${
                                                selectedHour === hour ? "bg-cyan-400 text-white" : "hover:bg-cyan-100 text-gray-800"
                                            }`}
                                            onClick={() => handleHourClick(hour)}
                                        >
                                            {hour.toString().padStart(2, "0")}
                                        </button>
                                    ))}
                                </div>
                            </div>
                            <div className="space-y-1">
                                <h4 className="text-gray-600 text-sm font-semibold">Minutes</h4>
                                <div className="max-h-48 overflow-y-auto grid grid-cols-1 gap-1">
                                    {Array.from({ length: 60 }).map((_, minute) => (
                                        <button
                                            key={minute}
                                            type="button"
                                            className={`py-1 px-2 text-sm rounded ${
                                                selectedMinute === minute ? "bg-cyan-400 text-white" : "hover:bg-cyan-100 text-gray-800"
                                            }`}
                                            onClick={() => handleMinuteClick(minute)}
                                        >
                                            {minute.toString().padStart(2, "0")}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {error && <p className="text-red-500 text-xs">{error}</p>}
        </div>
    );
};

export default TimeInput;
