import React, {useEffect, useRef, useState} from 'react';
import {SignatureInputElementClass} from 'types/documentTemplateElement';
import {ElementProps} from 'types/documentTemplateElement';
import SignatureInput from '../../inputs/SignatureInput';
import SignatureCanvas from 'react-signature-canvas';
import {buildLabel, checkError} from 'utils/elementUtils';
import {useIsDocumentElementDisabled} from 'hooks/useIsDocumentElementDisabled';

const SignatureInputElementComponent: React.FC<
  ElementProps<SignatureInputElementClass>
> = ({element, updateElement, mode}) => {
  const signatureCanvasRef = useRef<SignatureCanvas>(null);

  const containerRef = useRef<HTMLDivElement>(null);
  const [signatureWidth, setSignatureWidth] = useState<number>(0);
  const initialValueRef = useRef(element.properties.value || '');
  useEffect(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const width80 = Math.floor(containerWidth * 0.95);
      setSignatureWidth(width80);
    }
  }, []);

  const handleEnd = () => {
    if (mode === 'view' || mode === 'admin') {
      const dataURL = signatureCanvasRef.current?.toDataURL('image/png') || '';
      const newElement = new SignatureInputElementClass({
        ...element,
        properties: {
          ...element.properties,
          value: dataURL,
        },
      });
      updateElement(newElement);
    }
  };

  const clearSignature = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    signatureCanvasRef.current?.clear();

    const newElement = new SignatureInputElementClass({
      ...element,
      properties: {
        ...element.properties,
        value: undefined,
      },
    });
    updateElement(newElement);
  };

  const disabled = useIsDocumentElementDisabled(element, mode);

  let containerClass = 'm-4 pt-4';
  if (mode === 'view' || mode === 'preview' || mode === 'admin') {
    containerClass = element.properties.showLabel
      ? 'w-full mb-2'
      : 'w-full mt-5 mb-2';
  }
  const signatureValue = element.properties.value || '';
  let finalValue = signatureValue;
  if (!signatureValue.startsWith('data:image')) {
    finalValue = `data:image/png;base64,${signatureValue}`;
  }

  return (
    <div ref={containerRef} className={containerClass}>
      {!initialValueRef.current && (
        <>
          <SignatureInput
            width={signatureWidth}
            error={checkError(element, mode)}
            label={buildLabel(element)}
            signatureCanvasRef={signatureCanvasRef}
            disabled={disabled}
            onEnd={handleEnd}
            isClickDisabled={mode === 'preview'}
          />
          {element.properties.subText && (
              <div className="flex justify-between items-center text-sm text-slate-800 mt-1">
                <div>{element.properties.subText}
                </div>

              {(mode === 'view' || mode === 'admin') && (
                <button
                  type="submit"
                  onClick={clearSignature}
                  className="inline-flex items-center text-sm font-light text-slate-400
                       font-['Inter'] bg-white disabled:pointer-events-none disabled:bg-zinc-400
                       dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
                  Clear
                </button>
              )}
            </div>
          )}
        </>
      )}
      {initialValueRef.current && (
          <div className="prose max-w-none w-full px-3 pb-2">
            <label
                className="block grow shrink basis-0  text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
              {buildLabel(element)}
            </label>
            <div className="px-3 pb-2 border-b border-slate-200">
              <img src={finalValue} alt="Signature" />
            </div>
            {element.properties.subText && (
                <div className="flex justify-between items-center text-sm text-slate-800 mt-1">
                  <div>
                    {element.properties.subText}
                  </div>
                </div>
            )}
          </div>
      )}
    </div>
  );
};

export default SignatureInputElementComponent;
