import {UserTrainingService} from 'services/userTrainingService';
import {createEntityThunks} from 'utils/createEntityThunks';
import {UserTraining} from 'types/userTraining';

const userTrainingFactory = (data: any) => new UserTraining(data);

export const UserTrainingThunks = createEntityThunks<UserTraining>(
  'userTraining',
  UserTrainingService,
  {activate: false, deactivate: false},
  userTrainingFactory,
);
