import { createEntityService } from 'utils/createEntityService';
import { UserEvaluation } from 'types/userEvaluation';

export const UserEvaluationService = createEntityService<UserEvaluation>({
    baseUrl: 'api/v1/user_Evaluations',
    entityName: 'userEvaluation',
    methodConfigs: {
        create: {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
        update: {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    }
});
