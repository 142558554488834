import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from 'store/store';
import {UserJobRoleThunks} from './userJobRoleThunks';
import {DocumentTemplateThunks} from 'features/documentTemplate/documentTemplateThunks';
import CustomSelect from 'components/inputs/CustomSelect';
import SubmitButton from 'components/SubmitButton';
import {UserJobRole} from 'types/userJobRole';
import {DocumentTemplate} from 'types/documentTemplate';
import XIcon from 'assets/images/icons/XIcon';
import TextInput from "../../components/inputs/TextInput";

interface UserJobRoleFormProps {
    closeDrawer: () => void;
    isDrawerOpen: boolean;
    userId: string;
}

const UserJobRoleForm: React.FC<UserJobRoleFormProps> = ({
                                                                     closeDrawer,
                                                                     userId,
                                                                     isDrawerOpen,
                                                                 }) => {
    const dispatch = useDispatch<AppDispatch>();
    const userJobRole = useSelector(
        (state: RootState) => state.userJobRole.userJobRole,
    );
    const documentTemplates = useSelector(
        (state: RootState) => state.documentTemplate.documentTemplates,
    );
    const [updatedUserJobRole, setUpdatedUserJobRole] = useState<
        Partial<UserJobRole>
    >({
        ...userJobRole,
        userId,
        status: 'missing',
    });
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);

    useEffect(() => {
        const loadDocumentTemplates = async () => {
            try {
                await dispatch(
                    DocumentTemplateThunks.index({
                        page: 1,
                        search: {where: {}},
                        sortField: '',
                        sortDirection: '',
                        rowsPerPage: 100,
                    }),
                );
            } catch (err) {
                console.error(err);
            }
        };
        loadDocumentTemplates();
    }, [dispatch]);

    useEffect(() => {
        const isFormValid =
            updatedUserJobRole.documentTemplateId &&
            (updatedUserJobRole.deadline === false ||
                updatedUserJobRole.deadlineDate);
        setIsSaveDisabled(!isFormValid);
    }, [
        updatedUserJobRole.documentTemplateId,
        updatedUserJobRole.deadline,
        updatedUserJobRole.deadlineDate,
    ]);

    useEffect(() => {
        setUpdatedUserJobRole({
            ...userJobRole,
            userId,
            status: userJobRole?.status || 'missing',
        });
        setIsSaveDisabled(!userJobRole?.documentTemplateId);
    }, [userJobRole, userId]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        console.log(e.currentTarget);
        if (userJobRole?.id) {
            await dispatch(
                UserJobRoleThunks.update(updatedUserJobRole as UserJobRole),
            ).then(() => closeDrawer());
        } else {
            await dispatch(
                UserJobRoleThunks.create(updatedUserJobRole as UserJobRole),
            ).then(() => closeDrawer());
        }
    };


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUpdatedUserJobRole({
            ...updatedUserJobRole,
            [e.target.id]: e.target.value,
        });
    };

    useEffect(() => {
        if (!isDrawerOpen) handleClose();
    }, [isDrawerOpen]);

    const handleClose = () => {
        setUpdatedUserJobRole({
            deadlineDate: '',
            documentTemplateId: '',
        });
    };

    return (
        <div className="flex flex-col h-screen">
            <div className="flex-1 flex flex-col">
                <div className="h-auto p-6 bg-sky-50 border-b border-sky-200 flex justify-between items-start gap-2 w-full">
                    <div className="flex flex-col justify-start items-start gap-1">
                        <div className="text-lg font-semibold tracking-normal text-cyan-800">
                            {userJobRole?.id
                                ? 'Edit Role'
                                : 'Create new Role'}
                        </div>
                        <div className="text-sm font-light tracking-normal leading-5 text-zinc-400">
                            {userJobRole?.id ? 'Edit' : 'Create'} an Role for a
                            document
                        </div>
                    </div>
                    <button
                        onClick={closeDrawer}
                        className="text-gray-500 hover:text-gray-700 focus:outline-none pt-2">
                        <XIcon />
                    </button>
                </div>
                <div className="h-full px-6 pb-6">
                    <div className="mt-5">
                        <form onSubmit={handleSubmit}>
                            <div className="flex flex-col space-y-6 items-center mt-7">
                                <div className="w-full">
                                    <div className="flex flex-col gap-[0.625rem]">
                                        <div className="w-full">
                                            <TextInput
                                                id="name"
                                                type="text"
                                                label="Name"
                                                value={updatedUserJobRole.name || ''}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <label
                                            htmlFor="documentTemplateId"
                                            className="w-full block grow shrink basis-0 text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
                                            Document
                                        </label>
                                        <CustomSelect
                                            options={documentTemplates.map(
                                                (template: DocumentTemplate) => ({
                                                    label: template.name,
                                                    value: template.id,
                                                }),
                                            )}
                                            onChange={selectedOption => {
                                                setUpdatedUserJobRole({
                                                    ...updatedUserJobRole,
                                                    documentTemplateId: selectedOption.value,
                                                });
                                            }}
                                            value={updatedUserJobRole?.documentTemplateId}
                                        />

                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <footer className="flex-shrink-0 px-6 pb-4 pt-2 flex justify-end gap-5">
                <div className="border-t border-slate-200 w-full">
                    <div className="flex w-full justify-center items-center gap-x-2 pb-4">
                        <SubmitButton
                            onClick={handleSubmit}
                            label="Submit"
                            disabled={isSaveDisabled}
                        />
                    </div>
                    <div className="flex w-full justify-center items-center gap-x-2 pb-4">
                        <button
                            type="submit"
                            onClick={closeDrawer}
                            className="self-center text-sm font-light tracking-normal leading-5 text-center text-zinc-400 hover:text-cyan-400">
                            Never mind
                        </button>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default UserJobRoleForm;
