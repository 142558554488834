import React, {useEffect, useState} from 'react';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from 'store/store';
import {UserInvitation} from 'types/userInvitation';
import TableCell from 'components/table/TableCell';
import {UserInvitationThunks} from 'features/userInvitation/userInvitationThunk';
import Table from 'components/table/Table';
import {SearchType} from 'types/search';
import SSNDisplay from 'components/SSNDisplay';
import PhoneNumberDisplay from 'components/PhoneNumberDisplay';
import {setActiveTab, setIsEditing} from 'features/user/userSlice';
import BlueButton from 'components/BlueButton';
import PlusIcon from 'assets/images/icons/PlusIcon';
import {useDropdownActionMenu} from 'hooks/useDropdownActionMenu';
import AlertIcon from 'assets/images/icons/AlertIcon';
import DotsMenu from 'components/DotsMenu';
import TrashIcon from 'assets/images/icons/TrashIcon';
import {UserThunks} from '../user/userThunks';
import StatusBadge from 'components/StatusBadge';

const StaffInvitationTable: React.FC<{
  openDrawer: () => void;
  openNewDrawer: () => void;
}> = ({openNewDrawer}) => {
  const userInvitations = useSelector(
    (state: RootState) => state.userInvitation.userInvitations,
  );
  const dispatch = useDispatch<AppDispatch>();
  const pagy = useSelector((state: RootState) => state.userInvitation.pagy);
  const loading = useSelector(
    (state: RootState) => state.userInvitation.status === 'loading',
  );
  const activeTab = useSelector((state: RootState) => state.user.activeTab);
  const {dotsMenuRef, setDotsMenuIsOpen} = useDropdownActionMenu([]);

  interface VisibleColumnsType {
    invitationId: boolean;
    name: boolean;
    workEmail: boolean;
    personalEmail: boolean;
    ssn: boolean;
    phoneNumber: boolean;
    roles: boolean;
    status: boolean;
    supervisors: boolean;
  }

  const [visibleColumns, setVisibleColumns] = useState<VisibleColumnsType>({
    invitationId: true,
    name: true,
    workEmail: true,
    personalEmail: true,
    ssn: true,
    phoneNumber: true,
    roles: true,
    status: true,
    supervisors: true,
  });

  const columnOptions = [
    {
      label: 'Invitation ID',
      value: 'invitationId',
      isVisible: visibleColumns.invitationId,
    },
    {label: 'Name', value: 'name', isVisible: visibleColumns.name},
    {
      label: 'Work email',
      value: 'workEmail',
      isVisible: visibleColumns.workEmail,
    },
    {
      label: 'Personal email',
      value: 'personalEmail',
      isVisible: visibleColumns.personalEmail,
    },
    {label: 'SSN', value: 'ssn', isVisible: visibleColumns.ssn},
    {
      label: 'Phone number',
      value: 'phoneNumber',
      isVisible: visibleColumns.phoneNumber,
    },
    {label: 'Roles', value: 'roles', isVisible: visibleColumns.roles},
    {label: 'Status', value: 'status', isVisible: visibleColumns.status},
    {
      label: 'Supervisors',
      value: 'supervisors',
      isVisible: visibleColumns.supervisors,
    },
  ];

  const fetchUserData = async ({
    page,
    search,
    sortField,
    sortDirection,
    rowsPerPage,
  }: {
    page: number;
    search: SearchType<{[key: string]: {operator: string; value: string}}>;
    sortField: string;
    sortDirection: string;
    rowsPerPage: number;
  }): Promise<void> => {
    await dispatch(
      UserInvitationThunks.index({
        page,
        search,
        sortField,
        sortDirection,
        rowsPerPage,
      }),
    );
  };

  const handleColumnToggle = (column: string) => {
    if (column in visibleColumns) {
      setVisibleColumns(prev => ({
        ...prev,
        [column as keyof VisibleColumnsType]:
          !prev[column as keyof VisibleColumnsType],
      }));
    }
  };
  
  const handleResend = (id: string) => {
    dispatch(UserInvitationThunks.resend(id));
  };
  
  const handleCancelInvitation = (id: string) => {
    dispatch(UserInvitationThunks.cancel(id));
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dotsMenuRef.current &&
      dotsMenuRef.current.contains(event.target as Node) !== undefined &&
      (event.target as HTMLElement).id !== 'resend' &&
      (event.target as HTMLElement).id !== 'cancel'
    ) {
      setDotsMenuIsOpen(null);
    }
  };
  
  const handleEdit = (userId: string) => {
    dispatch(setIsEditing(true));
    dispatch(UserThunks.show(userId));
    openNewDrawer?.();
  };

  const handleDelete = (userId: string) => {
    dispatch(UserThunks.delete(userId) as any);
    setDotsMenuIsOpen(null);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const searchByOptions = [
    {label: 'Invitation ID', value: 'id', operator: '=='},
    {label: 'Name', value: 'name', operator: 'like'},
    {label: 'Email', value: 'email', operator: 'like'},
    {label: 'SSN', value: 'ssn', operator: 'like'},
  ];

  return (
    <Table
      fetchData={fetchUserData}
      pagy={pagy}
      activeTab={activeTab}
      setActiveTab={(tab: string) => dispatch(setActiveTab(tab))}
      tabs={['All', 'Active', 'Inactive', 'Hidden', 'Invitations']}
      recordsName={'Invitations'}
      loading={loading}
      searchByOptions={searchByOptions}
      blueButton={
        <BlueButton onClick={openNewDrawer} label={''} icon={<PlusIcon />} />
      }
      columns={true}
      columnOptions={columnOptions}
      onColumnToggle={handleColumnToggle}>
      <thead>
        <tr>
          <th scope="col" className="px-3 py-2.5 text-start">
            <input
              type="checkbox"
              className="shrink-0 border-gray-300 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50
                           disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
            />
          </th>
          {visibleColumns.invitationId && (
            <TableHeader label="Invitation ID" sortBy="id" />
          )}
          {visibleColumns.name && <TableHeader label="Name" sortBy="name" />}
          {visibleColumns.workEmail && (
            <TableHeader label="Work email" sortBy="email" />
          )}
          {visibleColumns.personalEmail && (
            <TableHeader label="Personal email" sortBy="personalEmail" />
          )}
          {visibleColumns.ssn && <TableHeader label="SSN" sortBy="ssn" />}
          {visibleColumns.phoneNumber && (
            <TableHeader label="Phone number" sortBy="phoneNumber" />
          )}
          {visibleColumns.roles && <TableHeader label="Role" sortBy="role" />}
          {visibleColumns.status && (
            <TableHeader label="Status" sortBy="status" />
          )}
          <TableHeader label="" />
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
        {userInvitations.length > 0 &&
          userInvitations.map((userInvitation: UserInvitation) => {
            const userOptions = [];
            userOptions.push({
              label: 'Edit user',
              onClick: () => handleEdit(userInvitation.userId!),
              hoverClass: 'hover:bg-cyan-100',
            });
            if (userInvitation.status === 'Pending') {
              userOptions.push({
                label: 'Cancel invitation',
                id: 'cancel',
                icon: <AlertIcon />,
                onClick: () => handleCancelInvitation(userInvitation.id),
                hoverClass: 'hover:bg-red-100 hover:text-red-800',
              });
            }
            if (
              userInvitation.status === 'Pending' ||
              userInvitation.status === 'Expired'
            ) {
              userOptions.push({
                label: 'Resend invitation',
                id: 'resend',
                onClick: () => handleResend(userInvitation.id),
                hoverClass: 'hover:bg-cyan-100',
              });
            }
            userOptions.push({
              label: 'Delete user',
              onClick: () => handleDelete(userInvitation.userId!),
              icon: <TrashIcon />,
              hoverClass: 'hover:bg-red-100 hover:text-red-800',
            });
            return (
              <TableRow key={userInvitation.id}>
                <td scope="col" className="pt-3.5 flex justify-center">
                  <input
                    type="checkbox"
                    className="border border-gray-300 rounded text-white disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600
                                                   dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                    onClick={e => e.stopPropagation()}
                  />
                </td>
                {visibleColumns.invitationId && (
                  <TableCell>{userInvitation.id}</TableCell>
                )}
                {visibleColumns.name && (
                  <TableCell>
                    {userInvitation.firstName} {userInvitation.lastName}
                  </TableCell>
                )}
                {visibleColumns.workEmail && (
                  <TableCell>{userInvitation.email}</TableCell>
                )}
                {visibleColumns.personalEmail && (
                  <TableCell>{userInvitation.personalEmail}</TableCell>
                )}
                {visibleColumns.ssn && (
                  <TableCell>
                    <SSNDisplay value={userInvitation.ssn || ''} />
                  </TableCell>
                )}
                {visibleColumns.phoneNumber && (
                  <TableCell>
                    <PhoneNumberDisplay
                      value={userInvitation.phoneNumber || ''}
                    />
                  </TableCell>
                )}
                {visibleColumns.roles && (
                  <TableCell>{userInvitation.role?.nameAlias}</TableCell>
                )}
                {visibleColumns.status && (
                  <TableCell>
                    <StatusBadge status={userInvitation.status} />
                  </TableCell>
                )}
                <TableCell>
                  {userInvitation.status !== 'Accepted' &&
                    userOptions.length > 0 && (
                      <DotsMenu options={userOptions} />
                    )}
                </TableCell>
              </TableRow>
            );
          })}
      </tbody>
    </Table>
  );
};

export default StaffInvitationTable;
