import React from 'react';
import DateInput from 'components/inputs/DateInput';
import {
  ElementProps,
  DateInputElementClass,
} from 'types/documentTemplateElement';
import {buildLabel, checkError} from 'utils/elementUtils';
import {useIsDocumentElementDisabled} from 'hooks/useIsDocumentElementDisabled';

interface DateInputElementProps extends ElementProps<DateInputElementClass> {}

const DateInputElementComponent: React.FC<DateInputElementProps> = ({
  element,
  updateElement,
  mode,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (mode === 'view' || mode === 'admin') {
      const newUpdatedElement = new DateInputElementClass({
        ...element,
        properties: {
          ...element.properties,
          value: e.target.value,
        },
      });
      updateElement(newUpdatedElement);
    }
    return;
  };

  const disabled = useIsDocumentElementDisabled(element, mode);

  let containerClass = 'w-full m-8';
  if (mode === 'view' || mode === 'preview' || mode === 'admin') {
    containerClass = element.properties.showLabel
      ? 'w-full mx-3 mb-2'
      : 'w-full mt-5 mx-3 mb-2';
  }

  return (
    <div className={containerClass}>
      <DateInput
        id={element.id}
        error={checkError(element, mode)}
        label={buildLabel(element)}
        type="date"
        value={element.properties?.value || ''}
        isClickDisabled={mode === 'preview'}
        onChange={handleChange}
        required={element.properties.required}
        placeholder="MM/DD/YYYY"
        allowPastDates={element.properties.allowPastDates}
        disabled={disabled}
      />
    </div>
  );
};

export default DateInputElementComponent;
