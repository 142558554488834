import React from 'react';
import { SignatureInputElement } from 'types/documentTemplateElement';
import { DocumentTemplate } from '../../../types/documentTemplate';
import BaseInputProperties from './BaseInputProperties';
import RadioInputHorizontal from "../../inputs/RadioInputHorizontal";
import TextInput from "../../inputs/TextInput";

interface SignatureInputPropertiesProps {
    element: SignatureInputElement;
    template: DocumentTemplate;
    updateElement: (updatedElement: SignatureInputElement) => void;
}

const SignatureInputProperties: React.FC<SignatureInputPropertiesProps> = ({
    element,
    template,
    updateElement,
}) => {
    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const {id, value} = e.target;
        updateElement({
            ...element,
            properties: {
                ...element.properties,
                [id]: value
            },
        });
    };

    const additionalFields = (
        <>
            <RadioInputHorizontal
                label="Sub-text"
                options={[
                    {id: 'show-subText-yes', label: 'Yes', value: true},
                    {id: 'show-subText-no', label: 'No', value: false},
                ]}
                selectedValue={element.properties.showSubText}
                onChange={(value) => updateElement({...element, properties: {...element.properties, showSubText: value}})}
            />
            {element.properties.showSubText && (
                <TextInput
                    id="subText"
                    type="input"
                    label=""
                    value={element.properties.subText || ''}
                    onChange={handleChange}
                />
            )}
        </>
    );

    return (
        <BaseInputProperties
            element={element}
            template={template}
            updateElement={updateElement}
            additionalFields={additionalFields}
            hiddePlaceholder={true}
        />
    );
};

export default SignatureInputProperties;
