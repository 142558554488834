import React, {useContext, useEffect, useRef} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from 'store/store';
import {DocumentTemplateThunks} from 'features/documentTemplate/documentTemplateThunks';
import PlusIcon from 'assets/images/icons/PlusIcon';
import {DocumentTemplate} from 'types/documentTemplate';
import {TitleContext} from 'components/TitleContext';
import {DocumentTemplateRow} from 'types/documentTemplateRow';
import {
  AddressInputElementClass,
  CurrencyInputElementClass,
  DateInputElementClass,
  DateTimeInputElementClass,
  DocumentTemplateElement,
  DropdownElementClass,
  EmailInputElementClass,
  MultiLineTextInputElementClass,
  PhoneNumberInputElementClass,
  RatingInputElementClass,
  SignatureInputElementClass,
  SsnInputElementClass,
  TableInputElementClass,
  TextBlockElementClass,
  TextInputElementClass,
  TimeInputElementClass,
  UrlInputElementClass,
  YesNoInputElementClass,
} from 'types/documentTemplateElement';
import LogoNewHope from 'assets/images/LogoNewHope.png';
import PageTabs from 'components/documentTemplate/PageTabs';
import DocumentConfigurationForm from 'components/documentTemplate/DocumentConfigurationForm';
import DocumentRow from 'components/documentTemplate/DocumentRow';
import DocumentPreview from 'components/documentTemplate/DocumentPreview';

const DocumentTemplateFormPage: React.FC = () => {
  const {id} = useParams<{id: string}>();
  const dispatch = useDispatch<AppDispatch>();
  const tabs = ['Builder', 'Preview'];
  const [activeTab, setActiveTab] = React.useState('Builder');
  const documentTemplate = useSelector(
    (state: RootState) => state.documentTemplate.documentTemplate,
  );
  const [updatedDocumentTemplate, setUpdatedDocumentTemplate] = React.useState({
    ...documentTemplate,
  });
  const [addRowIsOpen, setAddRowIsOpen] = React.useState(false);
  const [, setMenuPosition] = React.useState({top: 0, left: 0});
  const addRowButtonRef = React.useRef<HTMLButtonElement>(null);
  const addRowDropdownRef = React.useRef<HTMLDivElement>(null);
  const {setTitle} = useContext(TitleContext);
  const selectedElementRef = useRef<HTMLDivElement>(null);
  const propertiesElementRef = useRef<HTMLDivElement>(null);
  const [selectedElement, setSelectedElement] = React.useState<{
    element: DocumentTemplateElement;
    rowId: string;
    colId: string;
  } | null>(null);

  const [openDropdown, setOpenDropdown] = React.useState<{
    rowId: string;
    colId: string;
  } | null>(null);

  const columnDropdownRef = useRef<HTMLDivElement>(null);

  const inputOptions: any = {
    default: [{name: 'Text Block', value: 'TextBlock'}],
    advanced: [
      {name: 'Phone Number', value: 'PhoneNumberInput', category: 'advanced'},
      {name: 'Date', value: 'DateInput', category: 'advanced'},
      {name: 'Social security number', value: 'SsnInput', category: 'advanced'},
      {name: 'Email', value: 'EmailInput', category: 'advanced'},
      {name: 'Address', value: 'AddressInput', category: 'advanced'},
      {name: 'Currency', value: 'CurrencyInput', category: 'advanced'},
      {name: 'Date & Time', value: 'DateTimeInput', category: 'advanced'},
      {name: 'Time', value: 'TimeInput', category: 'advanced'},
      {name: 'Rating', value: 'RatingInput', category: 'advanced'},
      {name: 'Signature', value: 'SignatureInput', category: 'advanced'},
      {name: 'Website (URL)', value: 'UrlInput', category: 'advanced'},
    ],
    basic: [
      {name: 'Single-line text', value: 'TextInput', category: 'basic'},
      {name: 'Multi-line text', value: 'MultiLineTextInput', category: 'basic'},
      {name: 'Yes/No', value: 'YesNoInput', category: 'basic'},
      {name: 'Dropdown', value: 'Dropdown'},
      {name: 'Table', value: 'TableInput'},
    ],
  };

  useEffect(() => {
    setTitle(
      <div>
        <div className="text-slate-600 text-lg font-medium font-['Inter']">
          Document Builder
        </div>
        <div className="text-slate-400 text-sm font-light font-['Inter'] leading-normal">
          Manage custom document templates
        </div>
      </div>,
    );
  }, [setTitle]);

  useEffect(() => {
    if (id) {
      dispatch(DocumentTemplateThunks.show(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    setUpdatedDocumentTemplate({...documentTemplate});
  }, [documentTemplate]);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const {id, value} = e.target;
    setUpdatedDocumentTemplate({...updatedDocumentTemplate, [id]: value});
  };

  const toggleDropdown = (
    dropdown: 'addRow',
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    if (dropdown === 'addRow') {
      setAddRowIsOpen(!addRowIsOpen);
    }

    const buttonRect = event.currentTarget.getBoundingClientRect();
    setMenuPosition({
      top: buttonRect.top + buttonRect.height,
      left: buttonRect.left - buttonRect.width,
    });
  };

  const handlePublish = () => {
    if (updatedDocumentTemplate.id) {
      dispatch(
        DocumentTemplateThunks.update(
          new DocumentTemplate(updatedDocumentTemplate),
        ),
      );
    } else {
      dispatch(
        DocumentTemplateThunks.create(
          new DocumentTemplate(updatedDocumentTemplate),
        ),
      );
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      addRowDropdownRef.current &&
      !addRowDropdownRef.current.contains(event.target as Node) &&
      addRowButtonRef.current &&
      !addRowButtonRef.current.contains(event.target as Node)
    ) {
      setAddRowIsOpen(false);
    }
    if (
      (columnDropdownRef.current &&
        !columnDropdownRef.current.contains(event.target as Node)) ||
      !columnDropdownRef.current
    ) {
      setOpenDropdown(null);
    }
    if (
      selectedElementRef.current &&
      !selectedElementRef.current.contains(event.target as Node) &&
      propertiesElementRef.current &&
      !propertiesElementRef.current.contains(event.target as Node) &&
      // validates if target has a class name
      event.target &&
      !(event.target as Element).classList.contains('border-dashed')
    ) {
      setSelectedElement(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleAddRow = (columnsCount: number) => {
    if (!updatedDocumentTemplate.rows) {
      setUpdatedDocumentTemplate((prevTemplate: any) => ({
        ...prevTemplate,
        rows: [],
      }));
    }
    const newRow = {
      id: `row-${Date.now()}`,
      columns: Array.from({length: columnsCount}).map((_, index) => ({
        id: `col-${Date.now()}-${index}`,
        width: 1 / columnsCount,
        element: null,
      })),
    };

    setUpdatedDocumentTemplate((prevTemplate: any) => ({
      ...prevTemplate,
      rows: [...prevTemplate.rows, newRow],
    }));
    setAddRowIsOpen(false);
  };

  const handleDeleteRow = (rowIndex: number) => {
    setUpdatedDocumentTemplate((prevTemplate: any) => {
      const newRows = [...prevTemplate.rows];
      if (/^\d+$/.test(newRows[rowIndex].id)) {
        newRows[rowIndex] = {...newRows[rowIndex], _destroy: true};
      } else {
        newRows.splice(rowIndex, 1);
      }
      return {...prevTemplate, rows: newRows};
    });
  };
  const handleDuplicateRow = (rowIndex: number) => {
    setUpdatedDocumentTemplate((prevTemplate: any) => {
      const newRows = [...prevTemplate.rows];
      const newRow = {
        ...newRows[rowIndex],
        id: `row-${Date.now()}`,
        columns: newRows[rowIndex].columns.map((col: any, index: number) => ({
          ...col,
          id: `col-${Date.now()}-${index}`,
          element: col.element
            ? {
                ...col.element,
                id: `element-${Date.now()}`,
              }
            : null,
        })),
      };
      newRows.splice(rowIndex + 1, 0, newRow);
      return {...prevTemplate, rows: newRows};
    });
  };
  const handleUpRow = (rowIndex: number) => {
    if (rowIndex === 0) return;
    setUpdatedDocumentTemplate((prevTemplate: any) => {
      const newRows = prevTemplate.rows.map((row: any) => ({...row}));

      [newRows[rowIndex], newRows[rowIndex - 1]] = [
        newRows[rowIndex - 1],
        newRows[rowIndex],
      ];

      newRows[rowIndex].order = rowIndex;
      newRows[rowIndex - 1].order = rowIndex - 1;
      return {...prevTemplate, rows: newRows};
    });
  };
  const handleDownRow = (rowIndex: number) => {
    setUpdatedDocumentTemplate((prevTemplate: any) => {
      if (rowIndex >= prevTemplate.rows.length - 1) return prevTemplate;
      const newRows = prevTemplate.rows.map((row: any) => ({...row}));

      [newRows[rowIndex], newRows[rowIndex + 1]] = [
        newRows[rowIndex + 1],
        newRows[rowIndex],
      ];

      newRows[rowIndex].order = rowIndex;
      newRows[rowIndex + 1].order = rowIndex + 1;
      return {...prevTemplate, rows: newRows};
    });
  };
  const handleDeleteItem = (rowIndex: number, colIndex: number) => {
    setUpdatedDocumentTemplate((prevTemplate: any) => {
      const newRows = [...prevTemplate.rows];
      const rowToUpdate = {...newRows[rowIndex]};
      const newColumns = [...rowToUpdate.columns];
      const colToUpdate = {...newColumns[colIndex]};

      if (colToUpdate.element && /^\d+$/.test(colToUpdate.element.id)) {
        colToUpdate.element = {...colToUpdate.element, _destroy: true};
      } else {
        colToUpdate.element = null;
      }
      newColumns[colIndex] = colToUpdate;
      rowToUpdate.columns = newColumns;
      newRows[rowIndex] = rowToUpdate;
      return {...prevTemplate, rows: newRows};
    });
  };
  const handleDuplicateItem = (rowIndex: number, colIndex: number) => {
    setUpdatedDocumentTemplate((prevTemplate: any) => {
      const newRows = [...prevTemplate.rows];
      const rowToUpdate = {...newRows[rowIndex]};
      const newColumns = rowToUpdate.columns.map((col: any) => ({...col}));
      const colToUpdate = {...newColumns[colIndex]};
      const newElement = colToUpdate.element;
      const newElementId = `element-${Date.now()}`;
      const duplicatedElement = {
        ...newElement,
        id: newElementId,
      };
      colToUpdate.element = duplicatedElement;
      newColumns.splice(colIndex + 1, 0, {
        id: `col-${Date.now()}`,
        width: colToUpdate.width,
        element: duplicatedElement,
      });
      rowToUpdate.columns = newColumns;
      newRows[rowIndex] = rowToUpdate;
      return {...prevTemplate, rows: newRows};
    });
  };

  const handleLeftItem = (rowIndex: number, colIndex: number) => {
    setUpdatedDocumentTemplate((prevTemplate: any) => {
      const newRows = [...prevTemplate.rows];

      const rowToUpdate = {...newRows[rowIndex]};

      const newColumns = rowToUpdate.columns.map((col: any) => ({...col}));

      [newColumns[colIndex - 1], newColumns[colIndex]] = [
        newColumns[colIndex],
        newColumns[colIndex - 1],
      ];

      newColumns[colIndex - 1].order = colIndex - 1;
      newColumns[colIndex].order = colIndex;

      rowToUpdate.columns = newColumns;
      newRows[rowIndex] = rowToUpdate;
      return {...prevTemplate, rows: newRows};
    });
  };

  const handleRightItem = (rowIndex: number, colIndex: number) => {
    setUpdatedDocumentTemplate((prevTemplate: any) => {
      const newRows = [...prevTemplate.rows];

      const rowToUpdate = {...newRows[rowIndex]};

      const newColumns = rowToUpdate.columns.map((col: any) => ({...col}));

      [newColumns[colIndex], newColumns[colIndex + 1]] = [
        newColumns[colIndex + 1],
        newColumns[colIndex],
      ];

      newColumns[colIndex].order = colIndex;
      newColumns[colIndex + 1].order = colIndex + 1;

      rowToUpdate.columns = newColumns;
      newRows[rowIndex] = rowToUpdate;
      return {...prevTemplate, rows: newRows};
    });
  };

  const handleSelectElement = (
    rowId: string,
    colId: string,
    elementType: string,
  ) => {
    elementType = elementType.replace(' ', '');
    let newElement: DocumentTemplateElement;

    switch (elementType) {
      case 'TextBlock':
        newElement = new TextBlockElementClass({
          id: `element-${Date.now()}`,
          elementType: 'TextBlock',
          properties: {
            adminOnly: false,
            supervisorIds: [],
            content: '',
          },
        });
        break;
      case 'TextInput':
        newElement = new TextInputElementClass({
          id: `element-${Date.now()}`,
          elementType: 'TextInput',
          properties: {
            label: '',
            required: false,
            showLabel: true,
            placeholder: '',
            adminOnly: false,
            supervisorIds: [],
          },
        });
        break;
      case 'MultiLineTextInput':
        newElement = new MultiLineTextInputElementClass({
          id: `element-${Date.now()}`,
          elementType: 'MultiLineTextInput',
          properties: {
            label: '',
            required: false,
            showLabel: true,
            placeholder: '',
            adminOnly: false,
            supervisorIds: [],
          },
        });
        break;
      case 'PhoneNumberInput':
        newElement = new PhoneNumberInputElementClass({
          id: `element-${Date.now()}`,
          elementType: 'PhoneNumberInput',
          properties: {
            label: '',
            required: false,
            showLabel: true,
            placeholder: '',
            phoneNumber: '',
            adminOnly: false,
            supervisorIds: [],
          },
        });
        break;
      case 'YesNoInput':
        newElement = new YesNoInputElementClass({
          id: `element-${Date.now()}`,
          elementType: 'YesNoInput',
          properties: {
            label: '',
            required: false,
            showLabel: true,
            adminOnly: false,
            supervisorIds: [],
          },
        });
        break;
      case 'DateInput':
        newElement = new DateInputElementClass({
          id: `element-${Date.now()}`,
          elementType: 'DateInput',
          properties: {
            label: '',
            required: false,
            showLabel: true,
            placeholder: 'Placeholder',
            allowPastDates: false,
            adminOnly: false,
            supervisorIds: [],
          },
        });
        break;
      case 'Dropdown':
        newElement = new DropdownElementClass({
          id: `element-${Date.now()}`,
          elementType: 'Dropdown',
          properties: {
            label: '',
            isMulti: false,
            required: false,
            options: [],
            showLabel: true,
            adminOnly: false,
            supervisorIds: [],
          },
        });
        break;
      case 'SsnInput':
        newElement = new SsnInputElementClass({
          id: `element-${Date.now()}`,
          elementType: 'SsnInput',
          properties: {
            label: 'Label',
            required: false,
            showLabel: true,
            adminOnly: false,
            supervisorIds: [],
          },
        });
        break;
      case 'EmailInput':
        newElement = new EmailInputElementClass({
          id: `element-${Date.now()}`,
          elementType: 'EmailInput',
          properties: {
            label: '',
            required: false,
            showLabel: true,
            placeholder: '',
            adminOnly: false,
            supervisorIds: [],
          },
        });
        break;
      case 'AddressInput':
        newElement = new AddressInputElementClass({
          id: `element-${Date.now()}`,
          elementType: 'AddressInput',
          properties: {
            label: '',
            required: false,
            showLabel: true,
            placeholder: '',
            adminOnly: false,
            supervisorIds: [],
          },
        });
        break;
      case 'CurrencyInput':
        newElement = new CurrencyInputElementClass({
          id: `element-${Date.now()}`,
          elementType: 'CurrencyInput',
          properties: {
            label: '',
            required: false,
            showLabel: true,
            placeholder: '',
            currencySymbol: '$',
            adminOnly: false,
            supervisorIds: [],
          },
        });
        break;
      case 'DateTimeInput':
        newElement = new DateTimeInputElementClass({
          id: `element-${Date.now()}`,
          elementType: 'DateTimeInput',
          properties: {
            label: '',
            required: false,
            showLabel: true,
            placeholder: 'Placeholder',
            allowPastDates: false,
            adminOnly: false,
            supervisorIds: [],
          },
        });
        break;
      case 'TimeInput':
        newElement = new TimeInputElementClass({
          id: `element-${Date.now()}`,
          elementType: 'TimeInput',
          properties: {
            label: '',
            required: false,
            showLabel: true,
            placeholder: '',
            adminOnly: false,
            supervisorIds: [],
          },
        });
        break;
      case 'RatingInput':
        newElement = new RatingInputElementClass({
          id: `element-${Date.now()}`,
          elementType: 'RatingInput',
          properties: {
            label: '',
            required: false,
            showLabel: true,
            maxRating: 5,
            initialRating: 0,
            adminOnly: false,
            supervisorIds: [],
          },
        });
        break;
      case 'SignatureInput':
        newElement = new SignatureInputElementClass({
          id: `element-${Date.now()}`,
          elementType: 'SignatureInput',
          properties: {
            label: '',
            required: false,
            showLabel: true,
            adminOnly: false,
            supervisorIds: [],
          },
        });
        break;
      case 'UrlInput':
        newElement = new UrlInputElementClass({
          id: `element-${Date.now()}`,
          elementType: 'UrlInput',
          properties: {
            label: '',
            required: false,
            showLabel: true,
            placeholder: '',
            adminOnly: false,
            supervisorIds: [],
          },
        });
        break;
      case 'TableInput':
        newElement = new TableInputElementClass({
          id: `element-${Date.now()}`,
          elementType: 'TableInput',
          properties: {
            label: '',
            rows: 2,
            columns: 2,
            showLabel: true,
            data: [
              ['', ''],
              ['', ''],
            ],
            adminOnly: false,
            supervisorIds: [],
          },
        });
        break;
      default:
        return;
    }

    setUpdatedDocumentTemplate((prevTemplate: any) => {
      const updatedRows = prevTemplate.rows.map(
        (row: {id: string; columns: {id: string}[]}) => {
          if (row.id !== rowId) return row;
          return {
            ...row,
            columns: row.columns.map((col: {id: string}) => {
              if (col.id !== colId) return col;
              return {
                ...col,
                element: newElement,
              };
            }),
          };
        },
      );
      return {...prevTemplate, rows: updatedRows};
    });
    setOpenDropdown(null);
    setSelectedElement({
      element: newElement,
      rowId,
      colId,
    });
  };

  return (
    <div className="flex flex-col min-h-[94vh]">
      <PageTabs tabs={tabs} activeTab={activeTab} onTabChange={setActiveTab} />
      {activeTab === 'Builder' && (
        <div>
          <DocumentConfigurationForm
            documentTemplate={updatedDocumentTemplate}
            onChange={handleChange}
            onPublish={handlePublish}
            onEnableHeaderChange={value => {
              setUpdatedDocumentTemplate((prevData: any) => ({
                ...prevData,
                enableHeader: value,
              }));
            }}
            onRequiresReviewChange={value => {
              setUpdatedDocumentTemplate((prevData: any) => ({
                ...prevData,
                requiresReview: value,
              }));
            }}
          />
          <div className="flex justify-center pt-8">
            <div className="w-10/12 min-h-[70vh] mr-4 p-5 bg-white border border-gray-200 rounded-xl dark:bg-neutral-800 dark:border-neutral-700 overflow-visible">
              <>
                {updatedDocumentTemplate.enableHeader && (
                  <div className="flex mb-4">
                    <div className={`flex-1 px-2 relative`}>
                      <div className="w-full h-full min-h-20 border-2 border-dashed border-gray-300 flex items-center justify-start">
                        <div className="text-slate-600 text-lg font-medium font-['Inter']">
                          <img
                            className="pl-3 w-24 h-auto"
                            src={LogoNewHope}
                            alt="Logo"
                          />
                        </div>
                        <div className="text-slate-400 text-md font-light font-['Inter'] leading-normal ml-1 mr-1">
                          |
                        </div>
                        <div className="text-slate-400 text-md font-light font-['Inter'] leading-normal">
                          {updatedDocumentTemplate.name}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {updatedDocumentTemplate.rows?.map(
                  (row: DocumentTemplateRow, rowIndex: number) => (
                    <DocumentRow
                      key={row.id}
                      row={row}
                      rowIndex={rowIndex}
                      template={updatedDocumentTemplate}
                      selectedElement={selectedElement}
                      selectedElementRef={selectedElementRef}
                      onSelectElement={(
                        element: DocumentTemplateElement,
                        rowId: string,
                        colId: string,
                      ) => {
                        setSelectedElement({
                          element,
                          rowId,
                          colId,
                        });
                      }}
                      onDeleteRow={handleDeleteRow}
                      onDuplicateRow={handleDuplicateRow}
                      onUpRow={handleUpRow}
                      onDownRow={handleDownRow}
                      onDeleteItem={handleDeleteItem}
                      onDuplicateItem={handleDuplicateItem}
                      onLeftItem={handleLeftItem}
                      onRightItem={handleRightItem}
                      onUpdateTemplate={setUpdatedDocumentTemplate}
                      onOpenDropdown={(rowId: string, colId: string) => {
                        setOpenDropdown({
                          rowId,
                          colId,
                        });
                      }}
                      openDropdown={openDropdown}
                      dropdownRef={columnDropdownRef}
                      inputOptions={inputOptions}
                      onSelectElementType={handleSelectElement}
                      setSelectedElement={setSelectedElement}
                      propertiesElementRef={propertiesElementRef}
                    />
                  ),
                )}
                <div className="flex justify-center">
                  <div className="hs-dropdown [--auto-close:true] relative inline-flex">
                    <button
                      id="hs-dropdown-menu"
                      type="button"
                      onClick={event => toggleDropdown('addRow', event)}
                      className="py-4 px-5 inline-flex items-center gap-x-1.5 text-xs rounded-lg border border-slate-200 bg-white text-gray-800
                                                       hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700
                                                       dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 relative"
                      ref={addRowButtonRef}>
                      <PlusIcon />
                      Add Row
                    </button>
                    {addRowIsOpen && (
                      <div
                        className="top-full mt-1 absolute hs-dropdown-menu shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)] hs-dropdown-open:opacity-100 w-[110px] transition-[opacity,margin] duration opacity-100 z-10 bg-white rounded-xl
                                                             dark:bg-neutral-900 block"
                        ref={addRowDropdownRef}
                        aria-labelledby="hs-dropdown-menu">
                        <div className="p-1">
                          {[1, 2, 3, 4].map(columns => (
                            <button
                              key={columns}
                              type="button"
                              className="w-full flex gap-x-3 py-1.5 px-3 rounded-lg text-[13px] text-gray-800 hover:bg-cyan-100 disabled:opacity-50
                                                                        disabled:pointer-events-none dark:text-neutral-300 focus:outline-none focus:bg-gray-100 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                              onClick={() => handleAddRow(columns)}>
                              {columns} column{columns > 1 ? 's' : ''}
                            </button>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      )}
      {activeTab === 'Preview' && (
        <DocumentPreview template={updatedDocumentTemplate} />
      )}
    </div>
  );
};

export default DocumentTemplateFormPage;
