import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from 'store/store';
import React, {useEffect, useState} from 'react';
import TextInput from 'components/inputs/TextInput';
import {Training} from 'types/training';
import SubmitButton from 'components/SubmitButton';
import {TrainingThunks} from 'features/training/trainingThunks';
import {setIsEditing} from 'features/training/trainingSlice';
import DescriptionInput from 'components/inputs/DescriptionInput';
import RadioInput from 'components/inputs/RadioInput';
import GroupInput from 'components/inputs/GroupInput';
import XIcon from 'assets/images/icons/XIcon';

interface TrainingFormProps {
  id?: string;
  closeDrawer: () => void;
}

const TrainingForm: React.FC<TrainingFormProps> = ({closeDrawer}) => {
  const training = useSelector((state: RootState) => state.training.training);
  const dispatch = useDispatch<AppDispatch>();
  const [updatedTraining, setUpdatedTraining] = useState({...training});
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  useEffect(() => {
    const isFormValid =
      updatedTraining.name &&
      updatedTraining.expires !== undefined &&
      (!updatedTraining.expires ||
        (updatedTraining.remindHr !== undefined &&
          (!updatedTraining.remindHr ||
            updatedTraining.hrReminderDays !== undefined) &&
          updatedTraining.remindUser !== undefined &&
          (!updatedTraining.remindUser ||
            updatedTraining.userReminderDays !== undefined)));

    setIsSaveDisabled(!isFormValid);
  }, [
    updatedTraining.name,
    updatedTraining.description,
    updatedTraining.expires,
    updatedTraining.remindHr,
    updatedTraining.hrReminderDays,
    updatedTraining.remindUser,
    updatedTraining.userReminderDays,
  ]);

  useEffect(() => {
    setUpdatedTraining({...training});
    setIsSaveDisabled(!training?.nameAlias);
  }, [training]);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const {id, value} = e.target;
    setUpdatedTraining({...updatedTraining, [id]: value});
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (training?.id) {
      await dispatch(TrainingThunks.update(new Training(updatedTraining))).then(
        () => closeDrawer(),
      );
    } else {
      await dispatch(TrainingThunks.create(new Training(updatedTraining))).then(
        () => closeDrawer(),
      );
    }
  };

  const handleRadioChange = (field: string, value: boolean) => {
    setUpdatedTraining((prevState: Training) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleClose = () => {
    dispatch(setIsEditing(false));
    closeDrawer();
  };

  return (
    <div className="flex flex-col h-screen">
      <div className="flex-1 flex flex-col">
        <div className="h-auto p-6 bg-sky-50 border-b border-sky-200 flex justify-between items-start gap-2 w-full">
          <div className=" self-stretch flex-col justify-start items-start gap-1 flex">
            <div className="w-full text-lg font-semibold tracking-normal text-cyan-800">
              {training?.id ? 'Edit training' : 'Create new training'}
            </div>
            <div className="text-sm font-light tracking-normal leading-5 text-zinc-400">
              {training?.id ? 'Edit' : 'Create '} trainings, associates licenses
              and reminders
            </div>
          </div>
          <button
            onClick={closeDrawer}
            className="text-gray-500 hover:text-gray-700 focus:outline-none pt-2">
            <XIcon />
          </button>
        </div>
        <div className="h-full px-6 pb-6 ">
          <div className="mt-5">
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col space-y-6 items-center mt-7">
                <div className="w-full">
                  <TextInput
                    id="name"
                    type="text"
                    label="Name"
                    value={updatedTraining?.name}
                    placeholder="Name"
                    onChange={handleChange}
                  />
                </div>
                <div className="w-full">
                  <DescriptionInput
                    id="description"
                    type="text"
                    label="Description"
                    value={updatedTraining?.description}
                    placeholder="Description"
                    onChange={handleChange}
                    optional="optional"
                  />
                  <div className="flex flex-col space-y-6 items-center mt-7">
                    <RadioInput
                      label="Expires"
                      options={[
                        {id: 'expiresYes', label: 'Yes', value: true},
                        {id: 'expiresNo', label: 'No', value: false},
                      ]}
                      selectedValue={updatedTraining.expires}
                      onChange={value => handleRadioChange('expires', value)}
                    />
                    {updatedTraining.expires && (
                      <>
                        <div className="flex flex-col w-full space-y-2">
                          <div className="flex items-start justify-between">
                            <div className="flex-1 w-60">
                              <RadioInput
                                label="Remind HR before expiration?"
                                options={[
                                  {
                                    id: 'remindHrYes',
                                    label: 'Yes',
                                    value: true,
                                  },
                                  {
                                    id: 'remindHrNo',
                                    label: 'No',
                                    value: false,
                                  },
                                ]}
                                selectedValue={updatedTraining.remindHr}
                                onChange={value =>
                                  handleRadioChange('remindHr', value)
                                }
                              />
                            </div>
                            {updatedTraining.remindHr && (
                              <div className="flex justify-end ml-4 w-40">
                                <GroupInput
                                  id="hrReminderDays"
                                  type="number"
                                  label="Remind"
                                  value={(
                                    updatedTraining.hrReminderDays ?? ''
                                  ).toString()}
                                  onChange={handleChange}
                                  addon="days before"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="flex flex-col w-full space-y-2">
                          <div className="flex items-start justify-between">
                            <div className="flex-1 w-60">
                              <RadioInput
                                label="Remind staff before expiration?"
                                options={[
                                  {
                                    id: 'remindUserYes',
                                    label: 'Yes',
                                    value: true,
                                  },
                                  {
                                    id: 'remindUserNo',
                                    label: 'No',
                                    value: false,
                                  },
                                ]}
                                selectedValue={updatedTraining.remindUser}
                                onChange={value =>
                                  handleRadioChange('remindUser', value)
                                }
                              />
                            </div>
                            {updatedTraining.remindUser && (
                              <div className="flex justify-end ml-4 w-40">
                                <GroupInput
                                  id="userReminderDays"
                                  type="number"
                                  label="Remind"
                                  value={(
                                    updatedTraining.userReminderDays ?? ''
                                  ).toString()}
                                  onChange={handleChange}
                                  addon="days before"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <footer className="flex-shrink-0 px-6 pb-4 pt-2 flex justify-end gap-5">
        <div className="border-t border-slate-200 w-full">
          <div className="flex w-full justify-center items-center gap-x-2 pb-4">
            <SubmitButton
              onClick={handleSubmit}
              label={(training?.id ? 'Save' : 'Create new') + ' training'}
              disabled={isSaveDisabled}
            />
          </div>
          <div className="flex w-full justify-center items-center gap-x-2 pb-4">
            <button
              type="submit"
              onClick={handleClose}
              className="self-center text-sm font-light tracking-normal leading-5 text-center text-zinc-400 hover:text-cyan-400">
              Never mind
            </button>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default TrainingForm;
