import React, {useEffect, useState} from 'react';
import TextInput from 'components/inputs/TextInput';
import RadioInputHorizontal from '../../inputs/RadioInputHorizontal';
import {DocumentTemplate} from 'types/documentTemplate';
import CustomSelect from '../../inputs/CustomSelect';
import {User} from 'types/user';
import {SearchType} from 'types/search';
import {UserService} from 'services/userService';

export interface BaseInputPropertiesProps {
  element: any;
  template: DocumentTemplate;
  updateElement: (updatedElement: any) => void;
  additionalFields?: React.ReactNode;
  hiddePlaceholder?: boolean;
}

const BaseInputProperties: React.FC<BaseInputPropertiesProps> = ({
  element,
  template,
  updateElement,
  additionalFields,
  hiddePlaceholder,
}) => {
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const {id, value} = e.target;
    updateElement({
      ...element,
      properties: {
        ...element.properties,
        [id]: value,
      },
    });
  };

  const [supervisorOptions, setSupervisorOptions] = useState([]);

  useEffect(() => {
    const loadSelectData = async () => {
      try {
        const search: SearchType<{[key: string]: any}> = {
          where: {
            is_supervisor: {
              operator: '==',
              value: 'true',
            },
          },
        };

        const response = await UserService.index(1, search, '', '', 1000);
        setSupervisorOptions(response.data);
      } catch (err) {}
    };
    loadSelectData();
  }, []);

  const supervisorSelectOptions = supervisorOptions.map((user: User) => ({
    value: user.id,
    label: `${user.firstName} ${user.lastName}`,
  }));

  return (
    <div className="w-full">
      <RadioInputHorizontal
        label="Label"
        options={[
          {id: 'show-label-yes', label: 'Yes', value: true},
          {id: 'show-label-no', label: 'No', value: false},
        ]}
        selectedValue={element.properties.showLabel ?? true}
        onChange={value =>
          updateElement({
            ...element,
            properties: {...element.properties, showLabel: value},
          })
        }
      />
      <TextInput
        id="label"
        type="input"
        label=""
        value={element.properties.label}
        onChange={handleChange}
      />
      <div className="space-y-5 mt-5">
        {!hiddePlaceholder && (
          <TextInput
            id="placeholder"
            label="Placeholder"
            type="input"
            value={element.properties.placeholder || ''}
            onChange={handleChange}
          />
        )}
        <RadioInputHorizontal
          label="Required"
          options={[
            {id: 'required-yes', label: 'Yes', value: true},
            {id: 'required-no', label: 'No', value: false},
          ]}
          selectedValue={element.properties.required}
          onChange={value =>
            updateElement({
              ...element,
              properties: {...element.properties, required: value},
            })
          }
        />
        {template.requiresReview && (
          <RadioInputHorizontal
            label="Admin Only"
            options={[
              {id: 'adminOnly-yes', label: 'Yes', value: true},
              {id: 'adminOnly-no', label: 'No', value: false},
            ]}
            selectedValue={element.properties.adminOnly}
            onChange={value =>
              updateElement({
                ...element,
                properties: {...element.properties, adminOnly: value},
              })
            }
          />
        )}
        {element.properties.adminOnly && (
          <div className="flex flex-col gap-[0.625rem] w-full">
            <label
              htmlFor="roleId"
              className="w-full block grow shrink basis-0 text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
              Admin
            </label>
            <CustomSelect
              options={supervisorSelectOptions}
              isMulti={true}
              onChange={selectedOptions => {
                const selectedValues = selectedOptions.map(
                  (option: any) => option.value,
                );
                updateElement({
                  ...element,
                  properties: {
                    ...element.properties,
                    supervisorIds: selectedValues,
                  },
                });
              }}
              value={element.properties.supervisorIds}
            />
          </div>
        )}
        {additionalFields}
      </div>
    </div>
  );
};

export default BaseInputProperties;
