import {UserApplicationService} from '../../services/userApplicationService';
import { createEntityThunks } from 'utils/createEntityThunks';
import { UserApplication } from 'types/userApplication';


const UserApplicationFactory = (data: any) => new UserApplication(data);

export const UserApplicationThunks = createEntityThunks<UserApplication>(
    'userApplication',
    UserApplicationService,
    {activate: false, deactivate: false},
    UserApplicationFactory,
); 