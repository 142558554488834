import React from 'react';
import {IconType} from 'react-icons';
import {Link} from 'react-router-dom';

interface SidebarItemProps {
  id: string;
  Icon?: IconType;
  Item?: {href: string};
  className?: string;
  onClick?: () => void;
}

const SidebarItemCompressed: React.FC<SidebarItemProps> = ({
  Icon,
  Item,
  onClick,
}) => {
  return (
    <div className="mb-1.5 ">
      {Item !== undefined ? (
        <Link
          to={Item.href}
          className="flex gap-x-3 py-2 px-3
                   text-slate-600 rounded-lg hover:bg-cyan-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700
                     focus:outline-none focus:bg-cyan-100 focus:text-slate-800 dark:hover:bg-neutral-700 dark:text-neutral-300 dark:focus:bg-neutral-700">
          {Icon && <Icon className="flex-shrink-0 mt-0.5 size-4" />}
        </Link>
      ) : (
        <button
          type="button"
          onClick={onClick}
          className="flex gap-x-3 py-2 px-3
                                text-slate-600 rounded-lg hover:bg-cyan-50 disabled:opacity-50 disabled:pointer-events-none
                               dark:bg-neutral-700 focus:outline-none focus:text-slate-800 focus:bg-cyan-100 dark:hover:bg-neutral-700 dark:text-neutral-300 dark:focus:bg-neutral-700">
          {Icon && <Icon className=" flex-shrink-0 mt-0.5 size-4" />}
        </button>
      )}
    </div>
  );
};

export default SidebarItemCompressed;
