import React, {useState} from 'react';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from 'store/store';
import TableCell from 'components/table/TableCell';
import Table from 'components/table/Table';
import {UserThunks} from 'features/user/userThunks';
import {User} from 'types/user';
import {SearchType} from 'types/search';
import {setActiveTab, setIsEditing} from 'features/user/userSlice';
import PhoneNumberDisplay from 'components/PhoneNumberDisplay';
import BlueButton from 'components/BlueButton';
import PlusIcon from 'assets/images/icons/PlusIcon';
import {useDropdownActionMenu} from 'hooks/useDropdownActionMenu';
import DotsMenu from 'components/DotsMenu';
import {Credential} from 'types/credential';
import TrashIcon from 'assets/images/icons/TrashIcon';
import {JobRole} from 'types/jobRole';
import StatusBadge from 'components/StatusBadge';

const UserTable: React.FC<{
  openDrawer?: () => void;
  openNewDrawer?: (source: string) => void;
  isNewDrawerOpen?: boolean;
  showBlueButton?: boolean;
  optionsByTab?: any;
  tabs?: string[];
  searchByOptions?: any[];
  customFetchUserData?: any;
}> = ({
  openDrawer,
  openNewDrawer,
  showBlueButton = true,
  tabs = undefined,
  searchByOptions = undefined,
  customFetchUserData,
  optionsByTab,
}) => {
  const users = useSelector((state: RootState) => state.user.users);
  const usersLoading = useSelector(
    (state: RootState) => state.user.status === 'loading',
  );
  const dispatch = useDispatch<AppDispatch>();
  const pagy = useSelector((state: RootState) => state.user.pagy);
  const activeTab = useSelector((state: RootState) => state.user.activeTab);
  const {setDotsMenuIsOpen} = useDropdownActionMenu();

  interface VisibleColumnsType {
    externalId: boolean;
    name: boolean;
    workEmail: boolean;
    personalEmail: boolean;
    phoneNumber: boolean;
    roles: boolean;
    status: boolean;
    location: boolean;
    credentials: boolean;
    lastLogin: boolean;
    supervisors: boolean;
  }

  const [visibleColumns, setVisibleColumns] = useState<VisibleColumnsType>({
    externalId: true,
    name: true,
    workEmail: true,
    personalEmail: true,
    phoneNumber: true,
    roles: true,
    status: true,
    location: true,
    credentials: true,
    lastLogin: true,
    supervisors: true,
  });

  const columnOptions = [
    {
      label: 'Ext ID',
      value: 'externalId',
      isVisible: visibleColumns.externalId,
    },
    {label: 'Name', value: 'name', isVisible: visibleColumns.name},
    {
      label: 'Work email',
      value: 'workEmail',
      isVisible: visibleColumns.workEmail,
    },
    {
      label: 'Personal email',
      value: 'personalEmail',
      isVisible: visibleColumns.personalEmail,
    },
    {label: 'Status', value: 'status', isVisible: visibleColumns.status},
    {
      label: 'Phone number',
      value: 'phoneNumber',
      isVisible: visibleColumns.phoneNumber,
    },
    {label: 'Roles', value: 'roles', isVisible: visibleColumns.roles},
    {label: 'Locations', value: 'location', isVisible: visibleColumns.location},
    {
      label: 'Certifications',
      value: 'credentials',
      isVisible: visibleColumns.credentials,
    },
    {
      label: 'Supervisors',
      value: 'supervisors',
      isVisible: visibleColumns.supervisors,
    },
    {
      label: 'Last login',
      value: 'lastLogin',
      isVisible: visibleColumns.lastLogin,
    },
  ];

  const fetchUserData = async ({
    page,
    search,
    sortField,
    sortDirection,
    rowsPerPage,
  }: {
    page: number;
    search: SearchType<{[key: string]: {operator: string; value: string}}>;
    sortField: string;
    sortDirection: string;
    rowsPerPage: number;
  }): Promise<void> => {
    if (activeTab === 'Active' || activeTab === 'Inactive') {
      search.where.status = {
        operator: '==',
        value: activeTab.toLowerCase(),
      };
    } else if (activeTab === 'Hidden') {
      search.where.hidden = {
        operator: '==',
        value: 'true',
      };
    } else {
      delete search.where.status;
      search.where.hidden = {
        operator: '==',
        value: 'false',
      };
    }
    search.where.user_type = {
      operator: '==',
      value: 'user',
    };

    await dispatch(
      UserThunks.index({page, search, sortField, sortDirection, rowsPerPage}),
    );
  };

  const handleColumnToggle = (column: string) => {
    if (column in visibleColumns) {
      setVisibleColumns(prev => ({
        ...prev,
        [column as keyof VisibleColumnsType]:
          !prev[column as keyof VisibleColumnsType],
      }));
    }
  };

  const handleOpenNewDrawer = () => {
    openNewDrawer?.('UserTable');
  };

  const handleOpenDrawer = (id?: string) => async () => {
    dispatch(setIsEditing(true));
    if (id) {
      await dispatch(UserThunks.show(id));
    }
    openDrawer?.();
  };

  const handleDeactivate = (userId: string) => {
    dispatch(UserThunks.deactivate(userId));
    setDotsMenuIsOpen(null);
  };

  const handleReactivate = (userId: string) => {
    dispatch(UserThunks.activate(userId));
    setDotsMenuIsOpen(null);
  };

  const handleUnlock = (userId: string) => {
    dispatch(UserThunks.unlock(userId) as any);
    setDotsMenuIsOpen(null);
  };

  const handleUnblock = (userId: string) => {
    dispatch(UserThunks.unblock(userId) as any);
    setDotsMenuIsOpen(null);
  };

  const handleEdit = (userId: string) => {
    dispatch(setIsEditing(true));
    dispatch(UserThunks.show(userId));
    openDrawer?.();
  };

  const handleDelete = (userId: string) => {
    dispatch(UserThunks.delete(userId) as any);
    setDotsMenuIsOpen(null);
  };

  return (
    <Table
      fetchData={customFetchUserData || fetchUserData}
      pagy={pagy}
      activeTab={activeTab}
      loading={usersLoading}
      setActiveTab={(tab: string) => dispatch(setActiveTab(tab))}
      tabs={
        tabs ? tabs : ['All', 'Active', 'Inactive', 'Hidden', 'Invitations']
      }
      optionsByTab={optionsByTab}
      recordsName={'Employees'}
      showCounter={true}
      searchByOptions={
        searchByOptions
          ? searchByOptions
          : [
              {label: 'Name', value: 'name', operator: 'like'},
              {label: 'Ext ID', value: 'external_id', operator: 'like'},
              {label: 'Email', value: 'email', operator: 'like'},
            ]
      }
      blueButton={
        showBlueButton ? (
          <BlueButton
            onClick={handleOpenNewDrawer}
            label={''}
            icon={<PlusIcon />}
          />
        ) : null
      }
      columns={true}
      columnOptions={columnOptions}
      onColumnToggle={handleColumnToggle}>
      <thead>
        <tr>
          <th scope="col" className="px-3  pt-3.5  flex justify-center">
            <input
              type="checkbox"
              className="shrink-0 border-stone-300 rounded disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"
            />
          </th>
          {visibleColumns.externalId && (
            <TableHeader label="Ext ID" sortBy="externalId" />
          )}
          {visibleColumns.name && <TableHeader label="Name" sortBy="name" />}
          {visibleColumns.workEmail && (
            <TableHeader label="Work email" sortBy="email" />
          )}
          {visibleColumns.personalEmail && (
            <TableHeader label="Personal email" sortBy="personalEmail" />
          )}
          {visibleColumns.status && (
            <TableHeader
              label="Status"
              sortBy={activeTab === 'All' ? 'status' : undefined}
            />
          )}
          {visibleColumns.phoneNumber && (
            <TableHeader label="Phone number" sortBy="phoneNumber" />
          )}
          {visibleColumns.roles && <TableHeader label="Roles" />}
          {visibleColumns.location && <TableHeader label="Locations" />}
          {visibleColumns.credentials && <TableHeader label="Certifications" />}
          {visibleColumns.lastLogin && (
            <TableHeader label="Last login" sortBy="lastLogin" />
          )}
          <TableHeader label="" />
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
        {users.length > 0 &&
          !usersLoading &&
          users.map((user: User) => {
            const userOptions = [];
            userOptions.push({
              label: 'Edit user',
              onClick: () => handleEdit(user.id),
              hoverClass: 'hover:bg-cyan-100',
            });
            if (user.status !== 'Inactive') {
              userOptions.push({
                label: 'Deactivate user',
                onClick: () => handleDeactivate(user.id),
                hoverClass: 'hover:bg-red-100 hover:text-red-800',
              });
            }
            if (user.status === 'Inactive') {
              userOptions.push({
                label: 'Activate user',
                onClick: () => handleReactivate(user.id),
                hoverClass: 'hover:bg-cyan-100',
              });
            }
            if (user.locked) {
              userOptions.push({
                label: 'Unlock user',
                onClick: () => handleUnlock(user.id),
                hoverClass: 'hover:bg-cyan-100',
              });
            }
            if (user.blocked) {
              userOptions.push({
                label: 'Unblock user',
                onClick: () => handleUnblock(user.id),
                hoverClass: 'hover:bg-cyan-100',
              });
            }
            userOptions.push({
              label: 'Delete user',
              onClick: () => handleDelete(user.id),
              icon: <TrashIcon />,
              hoverClass: 'hover:bg-red-100 hover:text-red-800',
            });

            return (
              <TableRow key={user.id}>
                <td className="pt-3.5 flex justify-center">
                  <input
                    type="checkbox"
                    className="border border-gray-300 rounded text-white disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                    onClick={e => e.stopPropagation()}
                  />
                </td>
                {visibleColumns.externalId && (
                  <TableCell>
                    <div className="flex justify-between items-center">
                      <div>{user.externalId}</div>
                    </div>
                  </TableCell>
                )}
                {visibleColumns.name && (
                  <TableCell>
                    <div className=" relative group">
                      <div className="w-full flex items-center gap-x-3">
                        <div className="flex-grow">
                          <span
                            className="text-sm cursor-pointer font-medium underline text-cyan-600 dark:text-neutral-200"
                            onClick={handleOpenDrawer(user.id)}>
                            {user.firstName} {user.lastName}
                          </span>
                        </div>
                      </div>
                    </div>
                  </TableCell>
                )}
                {visibleColumns.workEmail && (
                  <TableCell>{user.email}</TableCell>
                )}
                {visibleColumns.personalEmail && (
                  <TableCell>{user.personalEmail}</TableCell>
                )}
                {visibleColumns.status && (
                  <TableCell>
                    <StatusBadge
                      status={user.status}
                      locked={user.locked}
                      blocked={user.blocked}
                    />
                  </TableCell>
                )}
                {visibleColumns.phoneNumber && (
                  <TableCell>
                    <PhoneNumberDisplay value={user.phoneNumber || ''} />
                  </TableCell>
                )}
                {visibleColumns.roles && (
                  <TableCell className="whitespace-nowrap overflow-hidden text-ellipsis">
                    <div
                      style={{maxWidth: '150px'}}
                      className="whitespace-nowrap overflow-hidden text-ellipsis">
                      {user.jobRoles
                        .map((role: JobRole) => role.name)
                        .join(', ')}
                    </div>
                  </TableCell>
                )}
                {visibleColumns.location && (
                  <TableCell>
                    {user.locations.map(location => location.name).join(', ')}
                  </TableCell>
                )}
                {visibleColumns.credentials && (
                  <TableCell>
                    {user.credentials
                      .map((credential: Credential) => credential.name)
                      .join(', ')}
                  </TableCell>
                )}
                {visibleColumns.lastLogin && (
                  <TableCell>{user.lastLogin}</TableCell>
                )}
                <TableCell>
                  <DotsMenu options={userOptions} />
                </TableCell>
              </TableRow>
            );
          })}
      </tbody>
    </Table>
  );
};

export default UserTable;
