import React from 'react';
import LogoNewHope from 'assets/images/LogoNewHope.png';
import {DocumentTemplate} from 'types/documentTemplate';
import {DocumentTemplateRow} from 'types/documentTemplateRow';
import RenderElementView from './RenderElementView';

interface DocumentPreviewProps {
  template: DocumentTemplate;
}

const DocumentPreview: React.FC<DocumentPreviewProps> = ({template}) => {
  return (
    <div className="flex justify-center">
      <div className="w-[820px] min-h-[80vh] px-8 py-8 bg-white border border-gray-200 dark:bg-neutral-800 dark:border-neutral-700 overflow-auto">
        {template.enableHeader && (
          <div className="flex mb-4">
            <div className={`flex-1 px-5 relative`}>
              <div className="w-full h-full min-h-20 flex items-center justify-start">
                <div className="text-slate-600 text-lg font-medium font-['Inter']">
                  <img className="pl-3 w-24 h-auto" src={LogoNewHope} alt="Logo" />
                </div>
                <div className="text-slate-400 text-md font-light font-['Inter'] leading-normal ml-1 mr-1">
                  |
                </div>
                <div className="text-slate-400 text-md font-light font-['Inter'] leading-normal">
                  {template.name}
                </div>
              </div>
            </div>
          </div>
        )}
        {template.rows?.map((row: DocumentTemplateRow) => {
          if (row._destroy) {
            return null;
          }
          return (
            <div key={row.id} className="flex mb-4">
              {row.columns.map(col => (
                <div key={col.id} className={`flex-1 px-4`}>
                  {col.element && col.element._destroy !== true ? (
                    <div className="w-full h-full min-h-20 flex items-start justify-start">
                      <RenderElementView element={col.element} updateElement={() => {}} mode={'preview'} />
                    </div>
                  ) : (
                    <div className="w-full h-full min-h-20 flex items-center justify-center text-slate-400"></div>
                  )}
                </div>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DocumentPreview; 