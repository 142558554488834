import React, {useMemo} from 'react';
import {ElementProps, TableInputElement} from 'types/documentTemplateElement';

interface TableInputElementComponentProps
  extends ElementProps<TableInputElement> {}

const TableInputElementComponent: React.FC<TableInputElementComponentProps> = ({
  element,
  updateElement,
  mode,
}) => {
  const {label, rows, columns, showLabel, data} = element.properties;


  function handleCellChange(rowIndex: number, colIndex: number, value: string) {
    const newData = data.map(rowArr => [...rowArr]);

    if (!newData[rowIndex]) {
      newData[rowIndex] = Array(columns).fill('');
    }
    newData[rowIndex][colIndex] = value;

    updateElement({
      ...element,
      properties: {
        ...element.properties,
        data: newData,
      },
    });
  }
  const isDisabled = useMemo(() => {
    if (mode === 'edit') {
      return false;
    }
    return true
  }, [mode, element]);


  

  let containerClass = 'w-full m-8';
  if (mode === 'view' || mode === 'preview') {
    containerClass = element.properties.showLabel
      ? 'w-full mx-3 mb-2'
      : 'w-full mt-10 mx-3 mb-8';
  }

  return (
    <div className={containerClass}>
      {showLabel && (
        <p className="text-slate-800 text-sm font-semibold mb-2 ">{label}</p>
      )}

      <table className=" border border-gray-200 rounded-lg">
        <tbody>
          {Array.from({length: rows}).map((_, rowIndex) => {
            

            return (
              <tr key={rowIndex}>
                {Array.from({length: columns}).map((_, colIndex) => {
                  const rowArr = data[rowIndex] || [];
                  const cellValue = rowArr[colIndex] || '';

                  if (element.properties.adminOnly && mode === 'view')
                    return null;

                  return (
                    <td key={colIndex} className=" border border-b p-2">
                      <input
                        type="text"
                        value={cellValue}
                        onChange={e =>
                          handleCellChange(rowIndex, colIndex, e.target.value)
                        }
                        className={` px-2 py-1 w-full ${mode === 'view' || mode === 'preview' || mode === 'admin'  ? 'border-0 disabled:pointer-events-none disabled:cursor-not-allowed disabled:bg-white' : 'border'}`}
                        disabled={isDisabled}
                      />
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TableInputElementComponent;
