import {
  DocumentTemplateRow,
  DocumentTemplateRowInterface,
} from './documentTemplateRow';

export interface DocumentTemplateInterface {
  id: string;
  name: string;
  notes?: string;
  updatedAt?: string;
  updatedBy?: string;
  createdAt?: string;
  status?: string;
  createdBy?: string;
  enableHeader?: boolean;
  requiresReview?: boolean;
  rows?: DocumentTemplateRowInterface[];
  document_template_rows_attributes?: DocumentTemplateRowInterface[];
}

export class DocumentTemplate implements DocumentTemplateInterface {
  id: string;
  name: string;
  status?: any;
  notes?: string;
  updatedAt?: string;
  updatedBy?: string;
  createdBy?: string;
  createdAt?: string;
  enableHeader?: boolean;
  requiresReview?: boolean;
  rows?: DocumentTemplateRow[];

  constructor(data: Partial<DocumentTemplateInterface>) {
    this.id = data.id!;
    this.name = data.name!;
    this.enableHeader = data.enableHeader!;
    this.requiresReview = data.requiresReview!;
    this.status = data.status || 'active';
    this.notes = data.notes!;
    this.updatedAt = data.updatedAt!;
    this.rows = data.rows
      ? data.rows.map(rowData => new DocumentTemplateRow(rowData))
      : [];
    this.updatedBy = data.updatedBy!;
    this.createdAt = data.createdAt!;
    this.createdBy = data.createdBy!;
  }

  toJson(): DocumentTemplateInterface {
    return {
      id: this.id,
      name: this.name,
      status: this.status.toLowerCase(),
      enableHeader: this.enableHeader,
      requiresReview: this.requiresReview,
      document_template_rows_attributes: this.rows?.map(row => row.toJson()),
    };
  }
}
