import React from 'react';
import {
  ElementProps,
  EmailInputElementClass,
} from 'types/documentTemplateElement';
import TextInput from '../../inputs/TextInput';
import EmailIcon from 'assets/images/icons/EmailIcon';
import {buildLabel, checkError} from "utils/elementUtils";
import {useIsDocumentElementDisabled} from "../../../hooks/useIsDocumentElementDisabled";
interface EmailInputElementProps extends ElementProps<EmailInputElementClass> {}

const EmailInputElementComponent: React.FC<EmailInputElementProps> = ({
  element,
  updateElement,
  mode,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (mode === 'view') {
      const newUpdatedElement = new EmailInputElementClass({
        ...element,
        properties: {
          ...element.properties,
          value: e.target.value,
        },
      });
      updateElement(newUpdatedElement);
    }
    if (mode === 'admin') {
      const newUpdatedElement = new EmailInputElementClass({
        ...element,
        properties: {
          ...element.properties,
          value: e.target.value,
        },
      });
      updateElement(newUpdatedElement);
    }
    return;
  };
  const disabled = useIsDocumentElementDisabled(element, mode);

  


  let containerClass = 'w-full m-8';
  if (mode === 'view' || mode === 'preview' || mode === 'admin') {
    containerClass = element.properties.showLabel
      ? 'w-full mx-3 mb-2'
      : 'w-full mt-5 mx-3 mb-2';
  }


  return (
    <div className={containerClass}>
      <TextInput
        id={element.id}
        type="email"
        error={checkError(element, mode)}
        label={buildLabel(element)}
        value={element.properties?.value || ''}
        icon={<EmailIcon />}
        placeholder={element.properties.placeholder}
        required={element.properties.required}
        onChange={handleChange}
        disabled={disabled}
      />
    </div>
  );
};

export default EmailInputElementComponent;
