import {DocumentElement} from 'types/documentTemplateElement';
import React from 'react';

interface RenderElementContentProps {
  element: DocumentElement;
}

const RenderElementContent: React.FC<RenderElementContentProps> = ({
  element,
}) => {
  switch (element.elementType) {
    case 'TextBlock':
      return (
        <div
          className="prose max-w-none w-full px-3 pb-2"
          dangerouslySetInnerHTML={{__html: element.properties.value || ''}}
        />
      );
    case 'SignatureInput':
      const signatureValue = element.properties.value || '';
      let finalValue = signatureValue;
      if (!signatureValue.startsWith('data:image')) {
        finalValue = `data:image/png;base64,${signatureValue}`;
      }
      return (
        <div className="prose max-w-none w-full px-3 pb-2">
          {element.properties.showLabel && element.properties.label && (
            <label className="font-semibold">{element.properties.label}</label>
          )}
          <div className="px-3 pb-2 border-b border-slate-200">
            <img src={finalValue} alt="Signature" />
          </div>
          {element.properties.subText && (
              <div className="flex justify-between items-center text-sm text-slate-800 mt-1">
                <div>{element.properties.subText}</div>
              </div>
          )}
        </div>
      );
    case 'TableInput': {
      const tableData = Array.isArray(element.properties?.data)
        ? element.properties.data
        : [];

      const [headerRow, ...bodyRows] = tableData;

      return (
        <div className=" max-w-none w-full px-3 pb-2 space-y-3">
          {element.properties.showLabel && element.properties.label && (
            <label className="font-semibold">{element.properties.label}</label>
          )}

          <table className="w-full border-collapse border border-gray-300">
            <thead>
              <tr>
                {headerRow?.map((headerCell: string, idx: number) => (
                  <th
                    key={idx}
                    className="border border-gray-300 p-2 text-left font-normal">
                    {headerCell}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {bodyRows.map((row: string[], rowIndex: number) => (
                <tr key={rowIndex}>
                  {row.map((cell: string, cellIndex: number) => (
                    <td key={cellIndex} className="border border-gray-300 p-2">
                      {cell}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
    case 'YesNoInput': {
      const value = element.properties.value;

      return (
        <div className="prose max-w-none w-full px-3 pb-2">
          {element.properties.showLabel && element.properties.label && (
            <label className="font-semibold">{element.properties.label}</label>
          )}
          <div>{value === true ? 'Yes' : value === false ? 'No' : ''}</div>
        </div>
      );
    }

    default:
      return (
        <div className="prose max-w-none w-full px-3 pb-2">
          {element.properties.showLabel && element.properties.label ? (
            <label className="font-semibold">{element.properties.label}</label>
          ) : (
            <div className="h-7"></div>
          )}

          <div>{element.properties?.value}</div>
        </div>
      );
  }
};

export default RenderElementContent;
