import React from 'react';
import { SsnInputElement } from 'types/documentTemplateElement';
import { DocumentTemplate } from '../../../types/documentTemplate';
import BaseInputProperties from './BaseInputProperties';

interface SsnInputPropertiesProps {
    element: SsnInputElement;
    template: DocumentTemplate;
    updateElement: (updatedElement: SsnInputElement) => void;
}

const SsnInputProperties: React.FC<SsnInputPropertiesProps> = ({
    element,
    template,
    updateElement,
}) => {
    return (
        <BaseInputProperties
            element={element}
            template={template}
            updateElement={updateElement}
            hiddePlaceholder={true}
        />
    );
};

export default SsnInputProperties;
