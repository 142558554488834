import React from 'react';
import { UrlInputElement } from 'types/documentTemplateElement';
import { DocumentTemplate } from '../../../types/documentTemplate';
import BaseInputProperties from './BaseInputProperties';

interface UrlInputPropertiesProps {
    element: UrlInputElement;
    template: DocumentTemplate;
    updateElement: (updatedElement: UrlInputElement) => void;
}

const UrlInputProperties: React.FC<UrlInputPropertiesProps> = ({
    element,
    template,
    updateElement,
}) => {
    return (
        <BaseInputProperties
            element={element}
            template={template}
            updateElement={updateElement}
            hiddePlaceholder={true}
        />
    );
};

export default UrlInputProperties;
