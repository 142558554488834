import React from 'react';
import {DocumentElementType} from 'types/documentTemplateElement';

interface ElementDropdownProps {
  inputOptions: {
    default: Array<{name: string; value: string}>;
    advanced: Array<{name: string; value: string; category: string}>;
    basic: Array<{name: string; value: string; category: string}>;
  };
  rowId: string;
  colId: string;
  onSelectElement: (rowId: string, colId: string, elementType: string) => void;
  dropdownRef: React.RefObject<HTMLDivElement>;
}

const ElementDropdown: React.FC<ElementDropdownProps> = ({
  inputOptions,
  rowId,
  colId,
  onSelectElement,
  dropdownRef,
}) => {
  return (
    <div
      ref={dropdownRef}
      className="absolute mt-2 pb-3 w-60 bg-white rounded-xl shadow-lg z-10 dark:bg-neutral-900">
      <div className="p-1">
        {/* Basic Input Elements Section */}
        {inputOptions['default'].map(type => (
          <button
            key={type.value}
            type="button"
            onClick={() => onSelectElement(rowId, colId, type.value as DocumentElementType)}
            className="w-full flex items-center gap-x-3 py-2 px-3 rounded-lg text-[13px] text-gray-800 hover:bg-cyan-100">
            {type.name}
          </button>
        ))}
        <div className="border-t border-slate-200 py-2 mt-2 mx-3"></div>
        <div className="text-slate-400 text-xs font-semibold px-3 py-1">
          BASIC INPUT ELEMENTS
        </div>
        {inputOptions['basic'].map(type => (
          <button
            key={type.value}
            type="button"
            onClick={() => onSelectElement(rowId, colId, type.value as DocumentElementType)}
            className="w-full flex items-center gap-x-3 py-2 px-3 rounded-lg text-[13px] text-gray-800 hover:bg-cyan-100">
            {type.name}
          </button>
        ))}
        <div className="border-t border-slate-200 py-2 mt-2 mx-3"></div>
        <div className="text-slate-400 text-xs font-semibold px-3 py-1">
          ADVANCED INPUT ELEMENTS
        </div>
        {inputOptions['advanced'].map(type => (
          <button
            key={type.value}
            type="button"
            onClick={() => onSelectElement(rowId, colId, type.value as DocumentElementType)}
            className="w-full flex items-center gap-x-3 py-1.5 px-3 rounded-lg text-[13px] text-gray-800 hover:bg-cyan-100">
            {type.name}
          </button>
        ))}
        <div className="border-t border-slate-200 py-2 mt-2 mx-3"></div>
      </div>
    </div>
  );
};

export default ElementDropdown; 