import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from "store/store";
import {AuthThunks} from 'features/auth/authThunks';
import {UserThunks} from "features/user/userThunks";
import {User} from "types/user";

export interface AuthState {
    user: User | null
    userCredentials: { email: string; password: string; signature: string, credentials: any } | null;
    token: string | null;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    isLocked: boolean;
    hrLocked: boolean;
    showHrLockScreen: boolean;
    base64Signature: string | undefined;
}

const initialState: AuthState = {
    user: null,
    userCredentials: null,
    token: null,
    status: 'idle',
    error: null,
    isLocked: false,
    hrLocked: true,
    showHrLockScreen: false,
    base64Signature: undefined
};

const convertImageUrlToBase64 = async (url: string): Promise<string> => {
    const response = await fetch(url);
    const blob = await response.blob();
    const signature = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    }).then(result => {
        return result;
    })
    return signature as Promise<string>;
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCredentials: (state, action: PayloadAction<any>) => {
            if (state.userCredentials) {
                state.userCredentials.credentials = action.payload;
            } else {
                state.userCredentials = {email: '', password: '', signature: '', credentials: action.payload};
            }
        },
        setSignature: (state, action) => {
            if (state.userCredentials) {
                state.userCredentials.signature = action.payload;
            } else {
                state.userCredentials = {email: '', password: '', credentials: {}, signature: action.payload};
            }
        },

        setPassword: (state, action) => {
            if (state.userCredentials) {
                state.userCredentials.password = action.payload;
            } else {
                state.userCredentials = {email: '', password: action.payload, signature: '', credentials: {}};
            }
        },
        logout(state) {
            state.userCredentials = null;
            state.token = null;
            state.isLocked = false;
            localStorage.removeItem('token');
        },
        setAuthUser(state, action) {
            state.user = action.payload;
            convertImageUrlToBase64(action.payload.signature).then(signature => {
                state.base64Signature = signature;
            });
        },
        setHrLocked(state, action) {
            state.hrLocked = action.payload;
        },
        setShowHrLockScreen(state, action) {
            state.showHrLockScreen = action.payload;
        },
        setToken(state, action) {
            state.token = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(UserThunks.register.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userCredentials = action.payload.userCredentials;
            })
            .addCase(UserThunks.updateAvatar.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.user = action.payload;
            })
            .addCase(AuthThunks.login.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(AuthThunks.login.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.user = action.payload.user;
                state.hrLocked = true;
                state.userCredentials = action.payload.userCredentials;
            })
            .addCase(AuthThunks.login.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || null;
            })
            .addCase(AuthThunks.verifyCode.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(AuthThunks.verifyCode.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.token = action.payload.token;
                state.user = action.payload.user;
                state.base64Signature = action.payload.base64Signature;
                localStorage.setItem('token', action.payload.token);
                state.userCredentials = null;
            })
            .addCase(AuthThunks.verifyCode.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || null;
            })
            .addCase(AuthThunks.unlockScreen.fulfilled, (state) => {
                state.isLocked = false;
            })
            .addCase(AuthThunks.lockScreen.fulfilled, (state) => {
                state.isLocked = true;
            })
            .addCase(AuthThunks.unlockHrScreen.fulfilled, (state) => {
                state.hrLocked = false;
            })

    },
});


export const {
    logout,
    setToken,
    setPassword,
    setAuthUser,
    setSignature,
    setCredentials,
    setHrLocked,
    setShowHrLockScreen
} = authSlice.actions;

export const selectUserCredentials = (state: RootState) => state.auth.userCredentials;

export {initialState as authInitialState};

export default authSlice.reducer;


