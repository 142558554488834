import {ProgramService} from 'services/programService';
import {createEntityThunks} from 'utils/createEntityThunks';
import {Program} from 'types/program';

const programFactory = (data: any) => new Program(data);

export const ProgramThunks = createEntityThunks<Program>(
  'program',
  ProgramService,
  {activate: true, deactivate: true},
  programFactory,
);
