import React from 'react';
import {RatingInputElementClass} from 'types/documentTemplateElement';
import {ElementProps} from 'types/documentTemplateElement';
import CustomSelect from '../../inputs/CustomSelect';
import {buildLabel, checkError} from 'utils/elementUtils';
import {useIsDocumentElementDisabled} from '../../../hooks/useIsDocumentElementDisabled';

const RatingInputElementComponent: React.FC<
  ElementProps<RatingInputElementClass>
> = ({element, updateElement, mode}) => {
  const handleChange = (selected: any) => {
    if (mode === 'view' || mode === 'admin') {
      const newValue = selected ? selected.value : null;
      const newElement = new RatingInputElementClass({
        ...element,
        properties: {
          ...element.properties,
          value: newValue,
        },
      });
      updateElement(newElement);
    }
  };

  const options = Array.from(
    {length: element.properties.maxRating},
    (_, i) => i + 1,
  ).map(opt => ({label: String(opt), value: opt}));

  const disabled = useIsDocumentElementDisabled(element, mode);

  

  let containerClass = 'w-full m-8';
  if (mode === 'view' || mode === 'preview' || mode === 'admin') {
    containerClass = element.properties.showLabel
      ? 'w-full mx-3 mb-2'
      : 'w-full mt-[30px] mx-3 mb-2';
  }

  return (
    <div className={containerClass}>
      <div className="flex flex-col gap-[0.625rem] w-full">
        {element.properties.showLabel && (
          <label className="w-full block grow shrink basis-0 text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
            {buildLabel(element)}
          </label>
        )}
        <CustomSelect
          options={options}
          isMulti={false}
          onChange={handleChange}
          error={checkError(element, mode)}
          value={element.properties.value ?? ''}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default RatingInputElementComponent;
