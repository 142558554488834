import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import authReducer from 'features/auth/authSlice';
import userInvitationReducer from 'features/userInvitation/userInvitationSlice';
import userReducer from 'features/user/userSlice';
import roleReducer from 'features/role/roleSlice';
import sidebarReducer from 'features/sidebar/sidebarSlice';
import locationReducer from 'features/location/locationSlice';
import programReducer from 'features/program/programSlice';
import credentialReducer from 'features/credential/credentialSlice';
import userCredentialReducer from 'features/userCredential/userCredentialSlice';
import payerReducer from 'features/payer/payerSlice';
import hrReducer from 'features/hr/hrSlice';
import userTrainingReducer from 'features/userTraining/userTrainingSlice';
import userHrDocumentReducer from 'features/userHrDocument/userHrDocumentSlice';
import trainingReducer from 'features/training/trainingSlice';
import hrDocumentReducer from 'features/hrDocument/hrDocumentSlice';
import userBackgroundDocumentReducer from 'features/userBackgroundDocument/userBackgroundDocumentSlice';
import backgroundDocumentReducer from 'features/backgroundDocument/backgroundDocumentSlice';
import medicalDocumentReducer from 'features/medicalDocument/medicalDocumentSlice';
import userMedicalDocumentReducer from 'features/userMedicalDocument/userMedicalDocumentSlice';
import jobRoleReducer from 'features/jobRole/jobRoleSlice';
import documentTemplateReducer from 'features/documentTemplate/documentTemplateSlice';
import settingsReducer from 'features/settings/settingsSlice';
import userApplicationReducer from '../features/userApplication/userApplicationSlice';
import userJobRoleReducer from '../features/userJobRole/userJobRoleSlice';
import userEvaluationReducer from '../features/userEvaluation/userEvaluationSlice';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'settings']
};

const rootReducer = combineReducers({
    auth: authReducer,
    userInvitation: userInvitationReducer,
    user: userReducer,
    role: roleReducer,
    sidebar: sidebarReducer,
    location: locationReducer,
    program: programReducer,
    credential: credentialReducer,
    payer: payerReducer,
    userCredential: userCredentialReducer,
    userTraining: userTrainingReducer,
    userHrDocument: userHrDocumentReducer,
    userMedicalDocument: userMedicalDocumentReducer,
    userBackgroundDocument: userBackgroundDocumentReducer,
    hr: hrReducer,
    training: trainingReducer,
    hrDocument: hrDocumentReducer,
    documentTemplate: documentTemplateReducer,
    backgroundDocument: backgroundDocumentReducer,
    jobRole: jobRoleReducer,
    medicalDocument: medicalDocumentReducer,
    settings: settingsReducer,
    userApplication: userApplicationReducer,
    userJobRole: userJobRoleReducer,
    userEvaluation: userEvaluationReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
            },
        }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
