import {createAsyncThunk} from '@reduxjs/toolkit';
import {UserInvitation} from 'types/userInvitation';
import {UserInvitationService} from 'services/userInvitationService';
import {createEntityThunks} from 'utils/createEntityThunks';

const userInvitationFactory = (data: any) => new UserInvitation(data);

class BaseUserInvitationThunks extends createEntityThunks<UserInvitation>(
  'userInvitation',
  UserInvitationService,
  {activate: true, deactivate: true},
  userInvitationFactory,
) {}

export class UserInvitationThunks extends BaseUserInvitationThunks {
  static cancel = createAsyncThunk(
    'userInvitation/cancel',
    async (id: string, {rejectWithValue}) => {
      try {
        const response = await UserInvitationService.cancel(id);
        return response;
      } catch (error) {
        if (error instanceof Error) {
          return rejectWithValue(error.message);
        }
        return rejectWithValue('An error occurred');
      }
    },
  );
  static resend = createAsyncThunk(
    'userInvitation/resend',
    async (id: string, {rejectWithValue}) => {
      try {
        const response = await UserInvitationService.resend(id);
        return response;
      } catch (error) {
        if (error instanceof Error) {
          return rejectWithValue(error.message);
        }
        return rejectWithValue('An error occurred');
      }
    },
  );
  static showByToken = createAsyncThunk(
    'userInvitation/getByToken',
    async (token: string) => {
      return await UserInvitationService.showByToken(token);
    },
  );
}
