import React, {useState} from 'react';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from 'store/store';
import TableCell from 'components/table/TableCell';
import {UserBackgroundDocument} from 'types/userBackgroundDocument';
import Table from 'components/table/Table';
import {SearchType} from 'types/search';
import {UserBackgroundDocumentThunks} from './userBackgroundDocumentThunks';
import {setIsEditing} from 'features/userBackgroundDocument/userBackgroundDocumentSlice';
import {setActiveTab} from 'features/hr/hrSlice';
import {useDropdownActionMenu} from 'hooks/useDropdownActionMenu';
import {useParams} from 'react-router-dom';
import DotsMenu from 'components/DotsMenu';
import WhiteButton from 'components/WhiteButton';
import DownloadIcon from 'assets/images/icons/DownloadIcon';
import useViewerModal from 'hooks/useViewerModal';
import StatusBadge from 'components/StatusBadge';

const UserBackgroundDocumentTable: React.FC<{openDrawer: () => void}> = ({
  openDrawer,
}) => {
  const userBackgroundDocuments = useSelector(
    (state: RootState) => state.userBackgroundDocument.userBackgroundDocuments,
  );
  const dispatch = useDispatch<AppDispatch>();
  const pagy = useSelector(
    (state: RootState) => state.userBackgroundDocument.pagy,
  );
  const {setDotsMenuIsOpen} = useDropdownActionMenu();
  const [selectedAttachmentUrl] = useState<string | null>(null);
  const params = useParams<{userId: string}>();

  const {AttachmentModal, handleNameClick} =
    useViewerModal<UserBackgroundDocument>({
      openDrawer,
      fetchItemThunk: UserBackgroundDocumentThunks.show,
    });

  const [visibleColumns, setVisibleColumns] = useState({
    id: true,
    name: true,
    agency: true,
    submittedAt: true,
    expirationDate: true,
    notes: true,
    remindUser: true,
    status: true,
  });

  interface VisibleColumnsType {
    id: boolean;
    name: boolean;
    agency: boolean;
    submittedAt: boolean;
    expirationDate: boolean;
    notes: boolean;
    remindUser: boolean;
    status: boolean;
  }

  const fetchData = async ({
    page,
    search,
    sortField,
    sortDirection,
    rowsPerPage,
  }: {
    page: number;
    search: SearchType<{[key: string]: {operator: string; value: string}}>;
    sortField: string;
    sortDirection: string;
    rowsPerPage: number;
  }): Promise<void> => {
    const {userId} = params;

    search.where.user_id = {
      operator: '==',
      value: userId as string,
    };
    await dispatch(
      UserBackgroundDocumentThunks.index({
        page,
        search,
        sortField,
        sortDirection,
        rowsPerPage,
      }),
    );
  };

  const handleColumnToggle = (column: string) => {
    if (column in visibleColumns) {
      setVisibleColumns(prev => ({
        ...prev,
        [column]: !prev[column as keyof VisibleColumnsType],
      }));
    }
  };

  const searchByOptions = [
    {label: 'Document', value: 'name'},
    {label: 'Agency', value: 'agency'},
  ];

  const columnOptions = [
    {label: 'Document', value: 'name', isVisible: visibleColumns.name},
    {label: 'Agency', value: 'agency', isVisible: visibleColumns.agency},
    {
      label: 'Submission date',
      value: 'submittedAt',
      isVisible: visibleColumns.submittedAt,
    },
    {
      label: 'Exp date',
      value: 'expirationDate',
      isVisible: visibleColumns.expirationDate,
    },
    {
      label: 'Reminder',
      value: 'remindUser',
      isVisible: visibleColumns.remindUser,
    },
    {label: 'Notes', value: 'notes', isVisible: visibleColumns.notes},
  ];
  
  const handleDelete = async (id: string) => {
    const newBackgroundDocuments = new UserBackgroundDocument({
      id,
      attachment: null,
      deleteAttachment: true,
    });
    await dispatch(UserBackgroundDocumentThunks.update(newBackgroundDocuments));
    setDotsMenuIsOpen(null);
  };

  const handleEdit = async (id: string) => {
    await dispatch(UserBackgroundDocumentThunks.show(id));
    dispatch(setIsEditing(true));
    openDrawer();
  };

  const handleDownload = (attachmentUrl: string) => {
    if (attachmentUrl) {
      window.open(attachmentUrl, '_blank');
    }
  };

  const handleSelectDownload = () => {
    if (selectedAttachmentUrl) {
      window.open(selectedAttachmentUrl, '_blank');
    }
  };

  return (
    <>
      <Table
        tabs={[
          'HR Documents',
          'Certifications',
          'Trainings',
          'Background Documents',
          'Medical Documents',
          'Applications',
          'Roles',
          'Evaluations',
        ]}
        activeTab={'Background Documents'}
        fetchData={fetchData}
        setActiveTab={(tab: string) => dispatch(setActiveTab(tab))}
        pagy={pagy}
        pageable={true}
        recordsName={'Documents'}
        searchable={true}
        columns={true}
        columnOptions={columnOptions}
        onColumnToggle={handleColumnToggle}
        searchByOptions={searchByOptions}
        blueButton={
          <WhiteButton
            onClick={handleSelectDownload}
            label={''}
            icon={<DownloadIcon />}
          />
        }>
        <thead>
          <tr>
            <th scope="col" className="px-3  pt-3.5  flex justify-center">
              <input
                type="checkbox"
                className="shrink-0 border-stone-300 rounded disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500 dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"
              />
            </th>
            {visibleColumns.name && (
              <TableHeader label="Document" sortBy="name" />
            )}
            {visibleColumns.agency && (
              <TableHeader label="Agency" sortBy="agency" />
            )}
            {visibleColumns.status && (
              <TableHeader label="Status" sortBy="status" />
            )}
            {visibleColumns.submittedAt && (
              <TableHeader label="Submission date" sortBy="submittedAt" />
            )}
            {visibleColumns.expirationDate && (
              <TableHeader label="Exp date" sortBy="expDate" />
            )}
            {visibleColumns.remindUser && (
              <TableHeader label="Reminder" sortBy="remindUser" />
            )}
            {visibleColumns.notes && <TableHeader label="Notes" />}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
          {userBackgroundDocuments.map(
            (userBackgroundDocument: UserBackgroundDocument) => {
              const userOptions = [
                {
                  label: 'Edit',
                  onClick: () => handleEdit(userBackgroundDocument.id),
                  hoverClass: 'hover:bg-cyan-100 ',
                },
                ...(userBackgroundDocument.status === 'Complete'
                  ? [
                      {
                        label: 'Download',
                        onClick: () =>
                          handleDownload(
                            userBackgroundDocument.attachment?.url || '',
                          ),
                        hoverClass: 'hover:bg-cyan-100',
                      },
                      {
                        label: 'Remove attached document',
                        onClick: () => handleDelete(userBackgroundDocument.id),
                        hoverClass: 'hover:bg-red-100 hover:text-red-800',
                      },
                    ]
                  : []),
                ...(userBackgroundDocument.status === 'Missing'
                  ? [
                      {
                        label: 'Upload',
                        onClick: () => handleEdit(userBackgroundDocument.id),
                        hoverClass: 'hover:bg-cyan-100 ',
                      },
                    ]
                  : []),
              ];
              return (
                <TableRow key={userBackgroundDocument.id}>
                  <td scope="col" className="pt-3.5 flex justify-center">
                    <input
                      type="checkbox"
                      className="border border-gray-300 rounded text-white disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                      onClick={e => e.stopPropagation()}
                    />
                  </td>
                  {visibleColumns.name && (
                    <TableCell>
                      <div className=" relative group">
                        <div className="w-full flex items-center gap-x-3">
                          <div className="flex-grow">
                            <span
                              className={`text-sm  font-medium dark:text-neutral-200 ${
                                userBackgroundDocument.status === 'Complete'
                                  ? 'underline text-cyan-600 cursor-pointer'
                                  : ''
                              }`}
                              onClick={
                                userBackgroundDocument.status === 'Complete'
                                  ? () =>
                                      handleNameClick(userBackgroundDocument)
                                  : undefined
                              }>
                              {userBackgroundDocument.name}
                            </span>
                          </div>
                        </div>
                      </div>
                    </TableCell>
                  )}
                  {visibleColumns.agency && (
                    <TableCell>{userBackgroundDocument.agency}</TableCell>
                  )}
                  {visibleColumns.status && (
                    <TableCell>
                      <StatusBadge status={userBackgroundDocument.status} />
                    </TableCell>
                  )}
                  {visibleColumns.submittedAt && (
                    <TableCell>{userBackgroundDocument.submittedAt}</TableCell>
                  )}
                  {visibleColumns.expirationDate && (
                    <TableCell>
                      {userBackgroundDocument.expirationDate}
                    </TableCell>
                  )}
                  {visibleColumns.remindUser && (
                    <TableCell>
                      {userBackgroundDocument.remindUser ? 'Yes' : 'No'}
                    </TableCell>
                  )}
                  {visibleColumns.notes && (
                    <TableCell className="whitespace-nowrap overflow-hidden text-ellipsis">
                      <div
                        style={{maxWidth: '150px'}}
                        className="whitespace-nowrap overflow-hidden text-ellipsis">
                        {userBackgroundDocument.notes}
                      </div>
                    </TableCell>
                  )}
                  <TableCell>
                    <DotsMenu options={userOptions} />
                  </TableCell>
                </TableRow>
              );
            },
          )}
        </tbody>
      </Table>
      <AttachmentModal />
    </>
  );
};

export default UserBackgroundDocumentTable;
