import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '../../store/store';
import React, {useEffect, useState} from 'react';
import TextInput from 'components/inputs/TextInput';
import SubmitButton from 'components/SubmitButton';
import {HrDocumentThunks} from 'features/hrDocument/hrDocumentThunks';
import {setIsEditing} from 'features/hrDocument/hrDocumentSlice';
import {HrDocument} from 'types/hrDocument';
import DescriptionInput from '../../components/inputs/DescriptionInput';
import XIcon from '../../assets/images/icons/XIcon';
import RadioInputHorizontal from '../../components/inputs/RadioInputHorizontal';
import CustomSelect from '../../components/inputs/CustomSelect';
import {JobRole} from '../../types/jobRole';

interface HrDocumentFormProps {
  id?: string;
  closeDrawer: () => void;
}

const HrDocumentForm: React.FC<HrDocumentFormProps> = ({closeDrawer}) => {
  const hrDocument = useSelector(
    (state: RootState) => state.hrDocument.hrDocument,
  );
  const dispatch = useDispatch<AppDispatch>();
  const [updatedHrDocument, setUpdatedHrDocument] = useState({...hrDocument});
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const jobRoles = useSelector((state: RootState) => state.jobRole.jobRoles);

  const jobRolesOptions = jobRoles.map((jobRole: JobRole) => ({
    value: jobRole.id,
    label: jobRole.name,
  }));

  useEffect(() => {
    const isFormValid = updatedHrDocument.name;

    setIsSaveDisabled(!isFormValid);
  }, [updatedHrDocument]);

  useEffect(() => {
    setUpdatedHrDocument({...hrDocument});
    setIsSaveDisabled(!hrDocument?.nameAlias);
  }, [hrDocument]);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const {id, value} = e.target;
    setUpdatedHrDocument({...updatedHrDocument, [id]: value});
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (hrDocument?.id) {
      await dispatch(
        HrDocumentThunks.update(new HrDocument(updatedHrDocument)),
      ).then(() => closeDrawer());
    } else {
      await dispatch(
        HrDocumentThunks.create(new HrDocument(updatedHrDocument)),
      ).then(() => closeDrawer());
    }
  };

  const handleClose = () => {
    dispatch(setIsEditing(false));
    closeDrawer();
  };

  const handleRadioChange = (field: string, value: boolean) => {
    setUpdatedHrDocument((prevState: HrDocument) => ({
      ...prevState,
      [field]: value,
    }));
  };

  return (
    <div className="flex flex-col h-screen">
      <div className="flex-1 flex flex-col">
        <div className="h-auto p-6 bg-sky-50 border-b border-sky-200 flex justify-between items-start gap-2 w-full">
          <div className="flex flex-col justify-start items-start gap-1">
            <div className="text-lg font-semibold tracking-normal text-cyan-800">
              {hrDocument?.id ? 'Edit HR Documents' : 'Create HR Documents'}
            </div>
            <div className="text-sm font-light tracking-normal leading-5 text-zinc-400">
              {hrDocument?.id ? 'Edit' : 'Create '} HR Documents
            </div>
          </div>
          <button
            onClick={handleClose}
            className="text-gray-500 hover:text-gray-700 focus:outline-none pt-2">
            <XIcon />
          </button>
        </div>
        <div className="h-full px-6 pb-6 ">
          <div className="mt-5">
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col space-y-6 items-center mt-7">
                <div className="w-full">
                  <TextInput
                    id="name"
                    type="text"
                    label="Name"
                    value={updatedHrDocument?.name}
                    placeholder=""
                    onChange={handleChange}
                  />
                </div>

                <div className="w-full">
                  <DescriptionInput
                    id="description"
                    type="text"
                    label="Description"
                    value={updatedHrDocument?.description}
                    placeholder=""
                    onChange={handleChange}
                    optional="optional"
                  />
                </div>

                <div className="w-full">
                  <RadioInputHorizontal
                    label="Require issue date"
                    options={[
                      {id: 'requireYes', label: 'Yes', value: true},
                      {id: 'requireNo', label: 'No', value: false},
                    ]}
                    selectedValue={updatedHrDocument?.requireIssueDate}
                    onChange={value =>
                      handleRadioChange('requireIssueDate', value)
                    }
                  />
                </div>
                <div className="w-full">
                  <RadioInputHorizontal
                    label="Require expiration date"
                    options={[
                      {id: 'requireYes', label: 'Yes', value: true},
                      {id: 'requireNo', label: 'No', value: false},
                    ]}
                    selectedValue={updatedHrDocument?.requireExpirationDate}
                    onChange={value =>
                      handleRadioChange('requireExpirationDate', value)
                    }
                  />
                </div>
                <div className="w-full">
                  <RadioInputHorizontal
                    label="Applies to all roles?"
                    options={[
                      {id: 'appliesToAllRolesYes', label: 'Yes', value: true},
                      {id: 'appliesToAllRolesNo', label: 'No', value: false},
                    ]}
                    selectedValue={updatedHrDocument?.appliesToAllRoles}
                    onChange={value => handleRadioChange('appliesToAllRoles', value)}
                  />
                </div>
                {updatedHrDocument.appliesToAllRoles === false && (
                    <div className="flex flex-col gap-[0.625rem] w-full">
                      <label
                          htmlFor="roleId"
                          className="w-full block text-slate-800 text-sm font-semibold dark:text-white">
                        Role(s)
                      </label>
                      <CustomSelect
                          options={jobRolesOptions}
                          isMulti={true}
                          onChange={(selectedOptions: { value: string; label: string }[]) => {
                            const selectedValues = selectedOptions.map(option => option.value);
                            setUpdatedHrDocument({
                              ...updatedHrDocument,
                              jobRoleIds: selectedValues,
                            });
                          }}
                          value={updatedHrDocument.jobRoleIds}
                      />
                    </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <footer className="flex-shrink-0 px-6 pb-4 pt-2 flex justify-end gap-5">
        <div className="border-t border-slate-200 w-full">
          <div className="flex w-full justify-center items-center gap-x-2 pb-4">
            <SubmitButton
              onClick={handleSubmit}
              label={(hrDocument?.id ? 'Save' : 'Create new') + ' HR Document'}
              disabled={isSaveDisabled}
            />
          </div>
          <div className="flex w-full justify-center items-center gap-x-2 pb-4">
            <button
              type="submit"
              onClick={handleClose}
              className="self-center text-sm font-light tracking-normal leading-5 text-center text-zinc-400 hover:text-cyan-400">
              Never mind
            </button>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default HrDocumentForm;
