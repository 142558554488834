import {User} from './user';

export interface UserApplicationInterface {
  id: string;
  name: string;
  userId: string;
  deadline?: boolean;
  deadlineDate?: string;
  documentTemplateId: string;
  status?: string;
  notes?: string;
  createdAt: string;
  attachment?: any;
  user?: User;
  documentTemplate?: {
    id: string;
    name: string;
    description: string;
  };
}

export class UserApplication implements UserApplicationInterface {
  id: string;
  userId: string;
  name: string;
  deadline?: boolean;
  deadlineDate?: string;
  user?: User;
  notes?: string;
  documentTemplateId: string;
  status?: string;
  createdAt: string;
  attachment?: any;
  documentTemplate?: {
    id: string;
    name: string;
    description: string;
  };


  constructor(data: Partial<UserApplicationInterface>) {
    this.id = data.id!;
    this.userId = data.userId!;
    this.name = data.name!;
    this.deadline = data.deadline;
    this.notes = data.notes!;
    this.deadlineDate = data.deadlineDate;
    this.user = data.user;
    this.documentTemplateId = data.documentTemplateId!;
    this.status = data.status;
    this.createdAt = data.createdAt!;
    this.attachment = data.attachment;
    this.documentTemplate = data.documentTemplate;
  }

  toJson(): Partial<UserApplicationInterface> {
    return {
      id: this.id,
      userId: this.userId,
      user: this.user,
      notes: this.notes,
      documentTemplateId: this.documentTemplateId,
      deadline: this.deadline,
      deadlineDate: this.deadlineDate,
    };
  }
}
