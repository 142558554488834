// PhoneNumberInputProperties.tsx
import React from 'react';
import { PhoneNumberInputElement } from 'types/documentTemplateElement';
import { DocumentTemplate } from '../../../types/documentTemplate';
import BaseInputProperties from './BaseInputProperties';

interface PhoneNumberInputPropertiesProps {
    element: PhoneNumberInputElement;
    template: DocumentTemplate;
    updateElement: (updatedElement: PhoneNumberInputElement) => void;
}

const PhoneNumberInputProperties: React.FC<PhoneNumberInputPropertiesProps> = ({
    element,
    template,
    updateElement,
}) => {
    return (
        <BaseInputProperties
            element={element}
            template={template}
            updateElement={updateElement}
            hiddePlaceholder={true}
        />
    );
};

export default PhoneNumberInputProperties;
