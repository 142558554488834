import React, {forwardRef} from 'react';

interface TextInputProps {
    id: string;
    type: string;
    label: string;
    value: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void | undefined;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void | undefined;
    error?: string | undefined;
    required?: boolean;
    placeholder?: string;
    tabIndex?: number;
    disabled?: boolean;
    optional?: string;
    icon?: React.ReactNode;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void | undefined;
}

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
    (
        {
            id,
            type,
            label,
            value,
            onChange,
            onBlur,
            error,
            required,
            placeholder,
            tabIndex,
            disabled,
            optional,
            icon,
            onKeyDown,
        },
        ref,
    ) => {
        return (
            <div className="flex flex-col items-start gap-[0.625rem]">
                <div className="flex justify-between w-full">
                    <label
                        htmlFor={id}
                        className="block grow shrink basis-0 text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
                        {label}
                    </label>
                    <span className="text-gray-400 text-sm">{optional}</span>
                </div>
                {icon ? (
                    <div className="relative w-full">
                        <div className="absolute inset-y-0 left-0 pl-3 flex text-gray-400 items-center pointer-events-none">
                            {icon}
                        </div>
                        <input
                            type={type}
                            id={id}
                            ref={ref}
                            data-testid={`${id}-field`}
                            value={value}
                            onChange={onChange}
                            className={`w-full py-2.5 pl-10 pr-3 block focus:outline-cyan-400 border   text-gray-500 rounded-lg text-sm font-light placeholder:text-gray-400
                            disabled:bg-slate-100  disabled:text-slate-300 disabled:border-0 disabled:pointer-events-none disabled:cursor-not-allowed
                            dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60
                            dark:focus:ring-neutral-600 ${error !== undefined ? 'border-red-500' : 'border-slate-200'} `}
                            placeholder={placeholder}
                            required={required}
                            aria-describedby={`${id}-error`}
                            tabIndex={tabIndex}
                            disabled={disabled}
                            onBlur={onBlur}
                            onKeyDown={onKeyDown}
                        />
                    </div>
                ) : (
                    <input
                        type={type}
                        id={id}
                        ref={ref}
                        data-testid={`${id}-field`}
                        value={value}
                        onChange={onChange}
                        className={`w-full py-2.5 px-3 block focus:outline-cyan-400 border  text-gray-500 rounded-lg text-sm font-light placeholder:text-gray-400
                            disabled:bg-slate-100  disabled:text-slate-300 disabled:border-0 disabled:pointer-events-none
                            dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:placeholder:text-white/60
                            dark:focus:ring-neutral-600 ${error !== undefined ? 'border-red-500' : 'border-slate-200'}`}
                        placeholder={placeholder}
                        required={required}
                        aria-describedby={`${id}-error`}
                        tabIndex={tabIndex}
                        disabled={disabled}
                        onBlur={onBlur}
                    />
                )}

                {error && (
                    <p className="text-xs text-red-600 " id={`${id}-error`}>
                        {error}
                    </p>
                )}
            </div>
        );
    },
);
TextInput.displayName = 'TextInput';

export default TextInput;