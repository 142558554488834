import React, {useEffect, useState} from 'react';
import {FaUser} from 'react-icons/fa';
import {AppDispatch, RootState} from 'store/store';
import {useDispatch, useSelector} from 'react-redux';
import TextInput from 'components/inputs/TextInput';
import {User} from 'types/user';
import {validateEmail} from 'utils/validators';
import {UserThunks} from 'features/user/userThunks';
import PhoneNumberInput from 'components/inputs/PhoneNumberInput';
import {showToast} from 'components/ToastContainer';
import {setAuthUser} from 'features/auth/authSlice';
import {setIsEditing} from 'features/user/userSlice';
import XIcon from 'assets/images/icons/XIcon';
import CustomSelect from 'components/inputs/CustomSelect';
import {CredentialThunks} from '../credential/credentialThunks';
import {JobRole} from 'types/jobRole';
import SubmitButton from 'components/SubmitButton';
import {JobRoleThunks} from '../jobRole/jobRoleThunks';

interface ProfileEditProps {
  onClose: () => void;
}

const ProfileEdit: React.FC<ProfileEditProps> = ({onClose}) => {
  const user = useSelector((state: RootState) => state.auth.user);
  const [, setSSN] = useState(user?.ssn);
  const dispatch = useDispatch<AppDispatch>();
  const [emailError, setEmailError] = useState<string | undefined>();
  const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber);
  const jobRoles = useSelector((state: RootState) => state.jobRole.jobRoles);
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [avatarLoading, setAvatarLoading] = useState(false);

  const jobRolesOptions = jobRoles.map((jobRole: JobRole) => ({
    value: jobRole.id,
    label: jobRole.name,
  }));

  const [formData, setFormData] = useState({
    ...user,
  });

  useEffect(() => {
    setFormData({
      ...user,
    });
    setSSN(user?.ssn);
    setPhoneNumber(user?.phoneNumber);
  }, [user]);

  useEffect(() => {
    const loadRoles = async () => {
      try {
        await dispatch(CredentialThunks.index());
        await dispatch(JobRoleThunks.index());
      } catch (err) {
        console.error(err);
      }
    };
    loadRoles();
  }, [dispatch]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const {id, value} = e.target;
    if (id === 'email') {
      if (validateEmail(value)) {
        setEmailError(undefined);
      } else {
        setEmailError('Please enter a valid email address.');
      }
    }
    setFormData((prevData: any) => ({
      ...prevData,
      [id]: value,
    }));
  };
  const handleClose = () => {
    dispatch(setIsEditing(false));
    onClose();
  };
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        setAvatarLoading(true);
        await dispatch(UserThunks.updateAvatar({id: user?.id!, avatar: file}))
          .then((resultAction: any) => {
            setFormData((prevData: any) => ({
              ...prevData,
              avatarUrl: resultAction.payload.avatarUrl,
            }));
          })
          .finally(() => {
            setAvatarLoading(false);
          });
      } catch (error) {
        setAvatarLoading(false);
      }
    }
  };

  const handleDelete = async () => {
    try {
      await dispatch(UserThunks.deleteAvatar(user?.id!)).then(() => {
        setFormData((prevData: any) => ({
          ...prevData,
          avatarUrl: '',
        }));
      });
    } catch (error) {
      showToast('Failed to delete profile picture.', 'error');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (emailError) {
      showToast('Please enter a valid email address.', 'error');
      return;
    }

    try {
      await dispatch(UserThunks.update(new User(formData))).then(
        (resultAction: any) => {
          if (resultAction) {
            dispatch(setAuthUser(resultAction.payload));
            dispatch(setIsEditing(false));
          }
        },
      );
    } catch (error) {}
    onClose();
  };

  return (
    <div className="flex flex-col h-screen">
      <div className="flex-1 flex flex-col">
        <div className="h-auto p-6 bg-sky-50 border-b border-sky-200 flex justify-between items-start gap-2 w-full">
          <div className="flex flex-col justify-start items-start gap-1">
            <div className="text-lg font-semibold tracking-normal text-cyan-800">
              Profile
            </div>

            <div className="text-sm font-light tracking-normal leading-5 text-zinc-400">
              Update your user profile
            </div>
          </div>
          <button
            onClick={handleClose}
            className="text-gray-500 hover:text-gray-700 focus:outline-none pt-2">
            <XIcon />
          </button>
        </div>
        <input
          type="file"
          ref={fileInputRef}
          style={{display: 'none'}}
          accept="image/*"
          onChange={handleFileChange}
        />
        <div className="p-4 sm:p-7 px-4 sm:px-6 w-full">
          <form onSubmit={handleSubmit}>
            <div className="flex items-center gap-4">
              <div className="flex-shrink-0 w-14 h-14 mt-1 bg-slate-100 rounded-full flex justify-center items-center overflow-hidden">
                {formData?.avatarUrl ? (
                  <img
                    src={formData.avatarUrl}
                    alt="Profile"
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <FaUser className="text-gray-400 size-8" />
                )}
              </div>
              <button
                type="button"
                disabled={avatarLoading}
                className="py-2.5 px-3 w-auto inline-flex justify-center items-center gap-x-2 text-sm font-medium
                                            rounded-lg border border-transparent bg-cyan-400 text-white hover:bg-cyan-500
                                            disabled:opacity-50
                                            disabled:pointer-events-none  dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"
                onClick={() => fileInputRef.current?.click()}>
                Upload New Picture
              </button>
              <button
                type="button"
                className={`py-2.5 px-3 w-auto inline-flex justify-center items-center gap-x-2 text-sm font-medium
             rounded-lg text-slate-500 bg-white
             disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600 ${
               !user?.avatarUrl ? 'opacity-50 cursor-not-allowed' : ''
             }`}
                onClick={handleDelete}
                disabled={!user?.avatarUrl || avatarLoading}>
                Delete
              </button>
            </div>
            <div className="flex flex-col space-y-6 items-center">
              <div className="grid grid-cols-2 gap-4 mt-6 w-full ">
                <TextInput
                  id="firstName"
                  type="text"
                  label="First Name"
                  value={formData.firstName || ''}
                  onChange={handleChange}
                  disabled={true}
                />
                <TextInput
                  id="lastName"
                  type="text"
                  label="Last Name"
                  value={formData.lastName || ''}
                  onChange={handleChange}
                  disabled={true}
                />
              </div>
              <div className="flex flex-col gap-[0.625rem] w-full">
                <label
                  htmlFor="roleId"
                  className="w-full block grow shrink basis-0 text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
                  Role(s)
                </label>
                <CustomSelect
                  options={jobRolesOptions}
                  isMulti={true}
                  onChange={selectedOptions => {
                    const selectedValues = selectedOptions.map(
                      (option: any) => option.value,
                    );
                    setFormData((prevData: any) => ({
                      ...prevData,
                      jobRoleIds: selectedValues,
                    }));
                  }}
                  value={formData.jobRoleIds}
                  disabled={true}
                />
              </div>
              <div className="w-full">
                <TextInput
                  id="email"
                  type="text"
                  label="Company Email"
                  value={formData.email || ''}
                  onChange={handleChange}
                  error={emailError}
                  disabled={true}
                />
              </div>
              <div className="w-full">
                <TextInput
                  id="personalEmail"
                  type="text"
                  label="Personal Email"
                  value={formData.personalEmail || ''}
                  onChange={handleChange}
                  disabled={true}
                />
              </div>
              <div className="w-full">
                <PhoneNumberInput
                  id="phoneNumber"
                  label="Phone Number"
                  value={formData.phoneNumber || ''}
                  onChange={handleChange}
                  phoneNumber={phoneNumber}
                  setPhoneNumber={setPhoneNumber}
                  disabled={true}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <footer className="flex-shrink-0 px-6 pb-4 pt-2 flex justify-end gap-5">
        <div className="border-t border-slate-200 w-full">
          <div className="flex w-full justify-center items-center gap-x-2 pb-4">
            <SubmitButton
              onClick={handleSubmit}
              label={'Update profile'}
              disabled={false}
            />
          </div>
          <div className="flex w-full justify-center items-center gap-x-2 pb-4">
            <button
              type="submit"
              onClick={handleClose}
              className="self-center text-sm font-light tracking-normal leading-5 text-center text-zinc-400 hover:text-cyan-400">
              Never mind
            </button>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default ProfileEdit;
