import React, {useEffect, useState} from 'react';
import DocumentTemplateFillOutComponent from "features/documentTemplate/DocumentTemplateFillOutComponent";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../store/store";
import {DocumentTemplate} from "../../types/documentTemplate";
import {useParams} from "react-router-dom";
import {DocumentTemplateSolutionThunks} from "../../features/documentTemplateSolution/documentTemplateSolutionThunks";

const DocumentTemplateAdminFillOutPage: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [documentTemplate, setDocumentTemplate] = useState<DocumentTemplate >(new DocumentTemplate({}));
    const [solutionId, setSolutionId] = useState<string>('');
    const {id} = useParams<{id: string}>();


    useEffect(() => {
        const loadData  = async () => {
            const search = {
                where: {
                    owner_id: {
                        operator: '==',
                        value: id,
                    },
                    owner_type: {
                        operator: '==',
                        value: 'UserApplication',
                    },
                },
            };
            const response = await dispatch(
                DocumentTemplateSolutionThunks.index({search: search}),
            );
            if (response.payload) {
                const documentTemplate = new DocumentTemplate(response.payload.data[0].snapshot);
                setDocumentTemplate(documentTemplate);
                setSolutionId(response.payload.data[0].id);
            } else {
                alert('Could not fetch template data.');
            }
        }
        loadData();
    }, []);

    return (
        <div>
            <DocumentTemplateFillOutComponent
                mode={'admin'}
                buttonText={'Approve'}
                documentTemplate={documentTemplate}
                solutionId={solutionId}
            />
        </div>
    );
}
export default DocumentTemplateAdminFillOutPage;