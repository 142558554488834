import React, {useEffect, useRef, useState} from 'react';
import PlusIcon from 'assets/images/icons/PlusIcon';
import TrashIcon from 'assets/images/icons/TrashIcon';
import DuplicateIcon from 'assets/images/icons/DuplicateIcon';
import ArrowLeftIcon from 'assets/images/icons/ArrowLeftIcon';
import ArrowRightIcon from 'assets/images/icons/ArrowRightIcon';
import RenderElementView from './RenderElementView';
import ElementDropdown from './ElementDropdown';
import {DocumentTemplateColumn} from 'types/documentTemplateColumn';
import {DocumentTemplateElement} from 'types/documentTemplateElement';
import {DocumentTemplate} from 'types/documentTemplate';
import {DocumentTemplateRow} from 'types/documentTemplateRow';
import ElementPropertiesEditor from './ElementPropertiesEditor';

interface DocumentColumnProps {
  rowId: string;
  colId: string;
  column: DocumentTemplateColumn;
  isSelected: boolean;
  selectedElementRef: React.RefObject<HTMLDivElement>;
  onSelectElement: (
    element: DocumentTemplateElement,
    rowId: string,
    colId: string,
  ) => void;
  onDeleteItem: (rowIndex: number, colIndex: number) => void;
  onDuplicateItem: (rowIndex: number, colIndex: number) => void;
  onLeftItem: (rowIndex: number, colIndex: number) => void;
  onRightItem: (rowIndex: number, colIndex: number) => void;
  rowIndex: number;
  colIndex: number;
  template: DocumentTemplate;
  onUpdateTemplate: (updatedTemplate: DocumentTemplate) => void;
  onOpenDropdown: (rowId: string, colId: string) => void;
  isDropdownOpen: boolean;
  dropdownRef: React.RefObject<HTMLDivElement>;
  inputOptions: any;
  onSelectElementType: (
    rowId: string,
    colId: string,
    elementType: string,
  ) => void;
  selectedElement: {
    element: DocumentTemplateElement;
    rowId: string;
    colId: string;
  } | null;
  setSelectedElement: React.Dispatch<
    React.SetStateAction<{
      element: DocumentTemplateElement;
      rowId: string;
      colId: string;
    } | null>
  >;
  propertiesElementRef: React.RefObject<HTMLDivElement>;
}

const DocumentColumn: React.FC<DocumentColumnProps> = ({
  rowId,
  colId,
  column,
  isSelected,
  selectedElementRef,
  onSelectElement,
  onDeleteItem,
  onDuplicateItem,
  onLeftItem,
  onRightItem,
  rowIndex,
  colIndex,
  template,
  onUpdateTemplate,
  onOpenDropdown,
  isDropdownOpen,
  dropdownRef,
  inputOptions,
  onSelectElementType,
  setSelectedElement,
  selectedElement,
  propertiesElementRef,
}) => {
  const [isPropertiesEditorOpen, setIsPropertiesEditorOpen] = useState(false);
  const squareRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      selectedElement &&
      selectedElement.element &&
      selectedElement.rowId === rowId &&
      selectedElement.colId === colId
    ) {
      setIsPropertiesEditorOpen(true);
    } else {
      setIsPropertiesEditorOpen(false);
    }
  }, [selectedElement]);

  return (
    <div className={`flex-1 px-2 relative`} ref={squareRef}>
      {column.element && column.element._destroy !== true ? (
        <div
          ref={isSelected ? selectedElementRef : null}
          className={`w-full h-full min-h-20 border-2 border-dashed ${
            isSelected ? 'border-slate-500' : 'border-slate-200'
          } flex items-center justify-center`}
          onClick={() => {
            if (column.element) {
              onSelectElement(column.element, rowId, colId);
            }
          }}>
          {isSelected && (
            <div className="absolute top-0 left-4">
              <div className="grid grid-cols-4 mt-2 justify-items-center">
                <div>
                  <button
                    className="text-slate-400 hover:text-slate-800 mx-1 items-center"
                    onClick={() => onDeleteItem(rowIndex, colIndex)}>
                    <TrashIcon />
                  </button>
                </div>
                <div>
                  <button
                    className="text-slate-400 hover:text-slate-800 mx-1 items-center"
                    onClick={() => onDuplicateItem(rowIndex, colIndex)}>
                    <DuplicateIcon />
                  </button>
                </div>
                {colIndex > 0 && (
                  <div>
                    <button
                      className="text-slate-400 hover:text-slate-800 mx-1 justify-self-center items-center"
                      onClick={() => onLeftItem(rowIndex, colIndex)}>
                      <ArrowLeftIcon />
                    </button>
                  </div>
                )}
                {colIndex < template.rows![rowIndex].columns.length - 1 && (
                  <div>
                    <button
                      className="text-slate-400 hover:text-slate-800 justify-self-center items-center"
                      onClick={() => onRightItem(rowIndex, colIndex)}>
                      <ArrowRightIcon />
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
          <RenderElementView
            mode={'edit'}
            element={column.element}
            updateElement={updatedElement => {
              const updatedRows = template.rows!.map(rowItem => {
                if (rowItem.id !== rowId) return rowItem;
                return new DocumentTemplateRow({
                  ...rowItem,
                  columns: rowItem.columns.map(column => {
                    if (column.id !== colId) return column;
                    return {
                      ...column,
                      element: updatedElement,
                    };
                  }),
                });
              });
              onUpdateTemplate(
                new DocumentTemplate({
                  ...template,
                  rows: updatedRows,
                }),
              );
            }}
          />
        </div>
      ) : (
        <button
          className="w-full h-full min-h-20 border-2 border-dashed border-gray-300 flex items-center justify-center"
          onClick={() => onOpenDropdown(rowId, colId)}>
          <PlusIcon />
        </button>
      )}
      {isPropertiesEditorOpen && (
        <ElementPropertiesEditor
          selectedElement={selectedElement}
          template={template}
          onUpdateTemplate={onUpdateTemplate}
          onUpdateSelectedElement={updatedElement => {
            setSelectedElement(prev =>
              prev ? {...prev, element: updatedElement} : null,
            );
          }}
          propertiesElementRef={propertiesElementRef}
          onClose={() => setIsPropertiesEditorOpen(false)}
          parentRef={squareRef}
        />
      )}
      {isDropdownOpen && (
        <ElementDropdown
          inputOptions={inputOptions}
          rowId={rowId}
          colId={colId}
          onSelectElement={onSelectElementType}
          dropdownRef={dropdownRef}
        />
      )}
    </div>
  );
};

export default DocumentColumn;
