import {createEntitySlice} from 'utils/createEntitySlice';
import {Program} from 'types/program';
import {ProgramThunks} from 'features/program/programThunks';

export interface ProgramState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  programs?: any;
  pagy?: any;
  program?: any;
  isEditing?: boolean;
}

const initialState: ProgramState = {
  status: 'idle',
  error: null,
  programs: [],
  program: {name: '', nameAlias: ''},
  pagy: {},
  isEditing: false,
};

const programSlice = createEntitySlice<Program>({
  name: 'program',
  initialState,
  thunks: ProgramThunks,
  entityKey: 'program',
  entitiesKey: 'programs',
  reducers: {},
});

export const {setEntity: setProgram, setIsEditing} = programSlice.actions;
export {initialState as programInitialState};
export default programSlice.reducer;
