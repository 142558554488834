import {DocumentTemplateSolutionService} from 'services/documentTemplateSolutionService';
import {createEntityThunks} from 'utils/createEntityThunks';
import {DocumentTemplateSolution} from 'types/documentTemplateSolution';
import {createAsyncThunk} from '@reduxjs/toolkit';

const documentTemplateSolutionFactory = (data: any) =>
  new DocumentTemplateSolution(data);

class BaseDocumentTemplateSolutionThunks extends createEntityThunks<DocumentTemplateSolution>(
  'documentTemplateSolution',
  DocumentTemplateSolutionService,
  {activate: true, deactivate: true},
  documentTemplateSolutionFactory,
) {}

export class DocumentTemplateSolutionThunks extends BaseDocumentTemplateSolutionThunks {
  static complete = createAsyncThunk(
    'documentTemplateSolution/complete',
    async (data: DocumentTemplateSolution) => {
      return await DocumentTemplateSolutionService.complete(data);
    },
  );
}
