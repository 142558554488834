import React, {useEffect, useRef, useState} from 'react';
import {setSignature} from 'features/auth/authSlice';
import {useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from 'store/store';
import SignatureCanvas from 'react-signature-canvas';
import ArrowLeftIcon from 'assets/images/icons/ArrowLeftIcon';
import SubmitButton from 'components/SubmitButton';
import ArrowRightIcon from 'assets/images/icons/ArrowRightIcon';
import SignatureInput from 'components/inputs/SignatureInput';

const SignaturePage: React.FC = () => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [signatureError, setSignatureError] = useState<string | undefined>();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const signatureCanvasRef = useRef<SignatureCanvas>(null);
  const params = useParams();
  const status = useSelector((state: RootState) => state.auth.status);
  const invitation = useSelector(
    (state: RootState) => state.userInvitation.invitation,
  );

  const saveSignature = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (signatureCanvasRef.current?.isEmpty()) {
      setSignatureError('Signature is required.');
      return;
    }
    const signatureImage = signatureCanvasRef.current?.toDataURL();
    setSignatureError(undefined);
    dispatch(setSignature(signatureImage));
    navigate(`/pin-setup/${params.token}`);
  };

  const handleEnd = () => {
    if (signatureCanvasRef.current && !signatureCanvasRef.current.isEmpty()) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };
  useEffect(() => {
    const canvas = signatureCanvasRef.current?.getCanvas();
    if (canvas) {
      canvas.addEventListener('mouseup', handleEnd);
      canvas.addEventListener('touchend', handleEnd);
    }

    return () => {
      if (canvas) {
        canvas.removeEventListener('mouseup', handleEnd);
        canvas.removeEventListener('touchend', handleEnd);
      }
    };
  }, []);

  const clearSignature = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    signatureCanvasRef.current?.clear();
    setSignatureError(undefined);
    setIsFormValid(false);
  };

  useEffect(() => {
    if (invitation === null || invitation.status !== 'Pending') {
      navigate(`/accept-invitation/${params.token}`);
    }
  }, [invitation, navigate, params.token]);

  return (
    <div className="flex flex-col justify-between">
      <div className="flex-grow flex flex-col md:pr-20 md:pl-20 pt-20 max-w-120 md:min-w-96 sm:min-w-64">
        <div className="w-80">
          <div className="w-full text-xl font-semibold tracking-normal text-slate-800">
            Draw your signature
          </div>
          <div className="text-sm font-light text-slate-400 font-['Inter']">
            <span className="leading-tight">
              Use your mouse to sign your name as you’d like it to appear on all
              of your case notes.
            </span>
          </div>
          <div className="text-sm font-light text-slate-400 font-['Inter']">
            <span className="leading-[60px]">
              You can update your signature later.{' '}
            </span>
          </div>
          <form className="pt-12 needs-validation self-stretch w-full">
            <div className="flex flex-col space-y-2">
              <SignatureInput
                signatureCanvasRef={signatureCanvasRef}
                error={signatureError}
                onEnd={handleEnd}
              />
              <div>
                <button
                  type="submit"
                  onClick={clearSignature}
                  className=" w-full inline-flex justify-start items-center  text-sm font-light text-slate-400 font-['Inter'] bg-white disabled:pointer-events-none
                                                    disabled:bg-zinc-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600">
                  Clear
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <footer className="grid grid-rows-2 justify-center w-full ">
        <div className="flex justify-center items-center w-full gap-3 ">
          <button
            onClick={() => navigate(-1)}
            className="flex items-center  py-3 px-2.5  text-cyan-500 border border-cyan-400 rounded-lg hover:bg-cyan-50 focus:outline-none focus:ring-2 focus:ring-cyan-400">
            <span className="mx-1">
              <ArrowLeftIcon />
            </span>
          </button>
          <SubmitButton
            className={
              'w-[330px] py-2.5 px-3 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-cyan-400 text-white hover:bg-cyan-500 disabled:pointer-events-none disabled:bg-slate-100  disabled:text-slate-300 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600'
            }
            onClick={saveSignature}
            label={
              <>
                {'Continue'} <ArrowRightIcon />
              </>
            }
            disabled={status === 'loading' || !isFormValid}
          />
        </div>
        <div className="flex justify-center items-center">
          <div className="flex items-center gap-3">
            <div className="w-2 h-2 bg-slate-200 focus:bg-cyan-400 rounded-full"></div>
            <div className="w-2 h-2 bg-cyan-400 focus:bg-cyan-400 rounded-full"></div>
            <div className="w-2 h-2 bg-slate-200 focus:bg-cyan-400 rounded-full"></div>
            <div className="w-2 h-2 bg-slate-200 focus:bg-cyan-400 rounded-full"></div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default SignaturePage;
