import React from 'react';
import { MultiLineTextInputElement } from 'types/documentTemplateElement';
import { DocumentTemplate } from '../../../types/documentTemplate';
import BaseInputProperties from './BaseInputProperties';

interface MultiLineTextInputPropertiesProps {
    element: MultiLineTextInputElement;
    template: DocumentTemplate;
    updateElement: (updatedElement: MultiLineTextInputElement) => void;
}

const MultiLineTextInputProperties: React.FC<MultiLineTextInputPropertiesProps> = ({
    element,
    template,
    updateElement,
}) => {
    return (
        <BaseInputProperties
            element={element}
            template={template}
            updateElement={updateElement}
            hiddePlaceholder={true}
        />
    );
};

export default MultiLineTextInputProperties;
