import {UserCredentialService} from 'services/userCredentialService';
import {createEntityThunks} from 'utils/createEntityThunks';
import {UserCredential} from 'types/userCredential';

const userCredentialFactory = (data: any) => new UserCredential(data);

export const UserCredentialThunks = createEntityThunks<UserCredential>(
  'userCredential',
  UserCredentialService,
  {activate: true, deactivate: true},
  userCredentialFactory,
);
