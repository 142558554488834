import React, {useState} from 'react';
import Table from 'components/table/Table';
import TableHeader from 'components/table/TableHeader';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from 'store/store';
import {SearchType} from 'types/search';
import {useNavigate} from 'react-router-dom';
import {setActiveTab} from 'features/userTraining/userTrainingSlice';
import {UserTrainingThunks} from 'features/userTraining/userTrainingThunks';
import {setIsEditing} from 'features/userTraining/userTrainingSlice';
import TableRow from 'components/table/TableRow';
import TableCell from 'components/table/TableCell';
import DotsMenu from 'components/DotsMenu';
import {UserTraining} from 'types/userTraining';
import WhiteButton from 'components/WhiteButton';
import DownloadIcon from 'assets/images/icons/DownloadIcon';
import useViewerModal from 'hooks/useViewerModal';
import Skeleton from 'react-loading-skeleton';
import StatusBadge from 'components/StatusBadge';

const DashboardTrainingsTable: React.FC<{
  openDrawer: () => void;
  optionsByTab: any;
}> = ({openDrawer, optionsByTab}) => {
  const userTrainings = useSelector(
    (state: RootState) => state.userTraining.userTrainings,
  );
  const dispatch = useDispatch<AppDispatch>();
  const pagy = useSelector((state: RootState) => state.userTraining.pagy);
  const loading = useSelector(
    (state: RootState) => state.userTraining.status === 'loading',
  );
  const [selectedAttachmentUrl] = useState<string | null>(null);
  const activeTab = useSelector(
    (state: RootState) => state.userTraining.activeTab,
  );
  const navigate = useNavigate();

  const {AttachmentModal} = useViewerModal<UserTraining>({
    openDrawer,
    fetchItemThunk: UserTrainingThunks.show,
  });

  interface VisibleColumnsType {
    userName: boolean;
    training: boolean;
    status: boolean;
    expDate: boolean;
    licensingAuthority: boolean;
    supervisor: boolean;
    email: boolean;
  }

  const [visibleColumns, setVisibleColumns] = useState<VisibleColumnsType>({
    userName: true,
    training: true,
    status: true,
    expDate: true,
    licensingAuthority: true,
    supervisor: true,
    email: true,
  });

  const searchByOptions = [
    {label: 'Employee', value: 'employee', isVisible: visibleColumns.userName},
    {label: 'Training', value: 'training', isVisible: visibleColumns.training},
  ];

  const columnOptions = [
    {label: 'Employee', value: 'employee', isVisible: visibleColumns.userName},
    {label: 'Training', value: 'name', isVisible: visibleColumns.training},
    {label: 'Status', value: 'status', isVisible: visibleColumns.status},
    {label: 'Exp Date', value: 'expDate', isVisible: visibleColumns.expDate},
    {
      label: 'Licensing Authority',
      value: 'licensingAuthority',
      isVisible: visibleColumns.licensingAuthority,
    },
    {
      label: 'Supervisor',
      value: 'supervisor',
      isVisible: visibleColumns.supervisor,
    },
    {label: 'Email', value: 'email', isVisible: visibleColumns.email},
  ];
  const fetchData = async ({
    page,
    search,
    sortField,
    sortDirection,
    rowsPerPage,
  }: {
    page: number;
    search: SearchType<{[key: string]: {operator: string; value: string}}>;
    sortField: string;
    sortDirection: string;
    rowsPerPage: number;
  }): Promise<void> => {
    if (activeTab === 'All') {
      search.where.status = {
        operator: 'in',
        valueArray: ['missing', 'expired', 'expiring_soon'],
      };
    } else if (activeTab === 'Missing trainings') {
      search.where.status = {
        operator: '==',
        value: 'missing',
      };
    } else if (activeTab === 'Expiring trainings') {
      search.where.status = {
        operator: '==',
        value: 'expiring_soon',
      };
    } else if (activeTab === 'Expired trainings') {
      search.where.status = {
        operator: '==',
        value: 'expired',
      };
    } else {
      delete search.where.status;
    }

    search.where['user.is_test'] = {
      operator: '!=',
      value: 'true',
    };
    search.where['user.hidden'] = {
      operator: '==',
      value: 'false',
    };

    await dispatch(
      UserTrainingThunks.index({
        page,
        search,
        sortField,
        sortDirection,
        rowsPerPage,
      }),
    );
  };

  const handleColumnToggle = (column: string) => {
    if (column in visibleColumns) {
      setVisibleColumns(prev => ({
        ...prev,
        [column]: !prev[column as keyof VisibleColumnsType],
      }));
    }
  };

  const handleEdit = async (id: string) => {
    await dispatch(UserTrainingThunks.show(id));
    dispatch(setIsEditing(true));
    openDrawer();
  };

  const handleOpenUserPage = (id?: string) => async () => {
    navigate(`/hr/users/${id}`);
  };

  const handleSelectDownload = () => {
    if (selectedAttachmentUrl) {
      window.open(selectedAttachmentUrl, '_blank');
    }
  };

  return (
    <>
      <Table
        tabs={[
          'All',
          'Missing trainings',
          'Expiring trainings',
          'Expired trainings',
        ]}
        activeTab={activeTab}
        fetchData={fetchData}
        setActiveTab={(tab: string) => dispatch(setActiveTab(tab))}
        pagy={pagy}
        recordsName={'Trainings'}
        loading={loading}
        columns={true}
        columnOptions={columnOptions}
        onColumnToggle={handleColumnToggle}
        blueButton={
          <WhiteButton
            onClick={handleSelectDownload}
            label={''}
            icon={<DownloadIcon />}
          />
        }
        searchByOptions={searchByOptions}
        dateRange={true}
        optionsByTab={optionsByTab}
        showCounter={true}>
        <thead>
          <tr>
            <th scope="col" className="px-3 pt-3.5 flex justify-center">
              <input
                type="checkbox"
                className="shrink-0 border-stone-300 rounded disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500
                                       dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"
              />
            </th>
            {visibleColumns.userName && (
              <TableHeader label="Employee" sortBy="name" />
            )}
            {visibleColumns.training && (
              <TableHeader label="Training" sortBy="name" />
            )}
            {visibleColumns.status && <TableHeader label="Status" />}
            {visibleColumns.expDate && <TableHeader label="Exp Date" />}
            {visibleColumns.licensingAuthority && (
              <TableHeader label="Licensing Authority" />
            )}
            {visibleColumns.supervisor && <TableHeader label="Supervisor" />}
            {visibleColumns.email && <TableHeader label="Email" />}
            <TableHeader label="" />
          </tr>
        </thead>
        <tbody>
          {userTrainings.length !== 0 &&
            userTrainings.map((userTraining: UserTraining) => {
              const userOptions = [
                {
                  label: 'Edit',
                  onClick: () => handleEdit(userTraining.id),
                  hoverClass: 'hover:bg-cyan-100',
                },

                ...(userTraining.status === 'Missing'
                  ? [
                      {
                        label: 'Upload',
                        onClick: () => handleEdit(userTraining.id),
                        hoverClass: 'hover:bg-cyan-100',
                      },
                    ]
                  : []),
              ];
              if (loading) {
                return (
                  <TableRow key={userTraining.id}>
                    <td colSpan={6}>
                      <Skeleton count={1} height={40} />
                    </td>
                  </TableRow>
                );
              } else {
                return (
                  <TableRow
                      key={userTraining.id}
                      className="border-t border-slate-200 ">
                    <td scope="col" className="pt-3.5 flex justify-center">
                      <input
                        type="checkbox"
                        className="border border-gray-300 rounded text-white disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600
                                               dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                        onClick={e => e.stopPropagation()}
                      />
                    </td>
                    {visibleColumns.userName && (
                      <TableCell>
                        <div className=" relative group">
                          <div className="w-full flex items-center gap-x-3">
                            <div className="flex-grow">
                              <span
                                className="text-smfont-medium underline text-cyan-600 cursor-pointer"
                                onClick={handleOpenUserPage(
                                  userTraining.user?.id,
                                )}>
                                {userTraining.user?.name}
                              </span>
                            </div>
                          </div>
                        </div>
                      </TableCell>
                    )}
                    {visibleColumns.training && (
                      <TableCell>
                        <div className=" relative group">
                          <div className="w-full flex items-center gap-x-3">
                            <div className="flex-grow">
                              <span
                                className="text-smfont-medium underline text-cyan-600 cursor-pointer"
                                onClick={() => handleEdit(userTraining.id)}>
                                {userTraining.name}
                              </span>
                            </div>
                          </div>
                        </div>
                      </TableCell>
                    )}
                    {visibleColumns.status && (
                      <TableCell>
                        <StatusBadge status={userTraining.status} />
                      </TableCell>
                    )}
                    {visibleColumns.expDate && (
                      <TableCell>{userTraining.expirationDate}</TableCell>
                    )}
                    {visibleColumns.licensingAuthority && (
                      <TableCell>{userTraining.licensingAuthority}</TableCell>
                    )}
                    {visibleColumns.supervisor && (
                      <TableCell>{userTraining.user?.name}</TableCell>
                    )}
                    {visibleColumns.email && (
                      <TableCell>{userTraining.user?.email}</TableCell>
                    )}
                    <TableCell>
                      <DotsMenu options={userOptions} />
                    </TableCell>
                  </TableRow>
                );
              }
            })}
          {userTrainings.length < 10 &&
            !loading &&
            [...Array(10 - userTrainings.length)].map((_, index) => (
              <TableRow key={index}>
                <td
                  colSpan={
                    Object.entries(visibleColumns).filter(([, value]) => value)
                      .length
                  }
                  className="h-10"></td>
              </TableRow>
            ))}
        </tbody>
      </Table>
      <AttachmentModal />
    </>
  );
};

export default DashboardTrainingsTable;
