export interface JobRoleInterface {
    id: string;
    name: string;
    description: string;
    status?: string;
    supervisorIds?: string[];
    supervisors?: any[];
}

export class JobRole implements JobRoleInterface {
    id: string;
    name: string;
    description: string;
    status?: string;
    supervisorIds?: string[];
    supervisors?: any[];

    constructor(data: Partial<JobRoleInterface>) {
        this.id = data.id!;
        this.name = data.name!;
        this.status = data.status;
        this.description = data.description!;
        this.supervisorIds = data.supervisorIds || [];
        this.supervisors = data.supervisors || [];
    }

    toJson(): JobRoleInterface {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            status: this.status?.toLowerCase(),
            supervisorIds: this.supervisorIds,
            supervisors: this.supervisors,
        };
    }
}
