import {UserJobRoleService} from '../../services/userJobRoleService';
import { createEntityThunks } from 'utils/createEntityThunks';
import { UserJobRole } from 'types/userJobRole';


const UserJobRoleFactory = (data: any) => new UserJobRole(data);

export const UserJobRoleThunks = createEntityThunks<UserJobRole>(
    'userJobRole',
    UserJobRoleService,
    {activate: false, deactivate: false},
    UserJobRoleFactory,
); 