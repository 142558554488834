import React from "react";

interface ChevronRightProps {
    className?: string
}

const ChevronRightIcon = ({ className }: ChevronRightProps) => (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
         stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <polyline points="9 18 15 12 9 6"/>
    </svg>
);
export default ChevronRightIcon;
