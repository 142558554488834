import React from 'react';
import {
  ElementProps,
  MultiLineTextInputElementClass,
} from 'types/documentTemplateElement';
import DescriptionInput from '../../inputs/DescriptionInput';
import {buildLabel, checkError} from 'utils/elementUtils';
import {useIsDocumentElementDisabled} from "../../../hooks/useIsDocumentElementDisabled";


interface MultiLineTextInputElementProps
  extends ElementProps<MultiLineTextInputElementClass> {}

const MultiLineTextInputElementComponent: React.FC<
  MultiLineTextInputElementProps
> = ({element, updateElement, mode}) => {
  const disabled = useIsDocumentElementDisabled(element, mode);
  if (element.properties?.adminOnly && mode !== 'preview' && mode !== 'edit') {
    return null;
  }

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (mode === 'view') {
      const newUpdatedElement = new MultiLineTextInputElementClass({
        ...element,
        properties: {
          ...element.properties,
          value: e.target.value,
        },
      });
      updateElement(newUpdatedElement);
    }
    if (mode === 'admin') {
      const newUpdatedElement = new MultiLineTextInputElementClass({
        ...element,
        properties: {
          ...element.properties,
          value: e.target.value,
        },
      });
      updateElement(newUpdatedElement);
    }
    return;
  };


  


  let containerClass = 'w-full m-8';
  if (mode === 'view' || mode === 'preview' || mode === 'admin') {
    containerClass = element.properties.showLabel
      ? 'w-full mx-3 mb-2'
      : 'w-full mt-5 mx-3 mb-2';
  }

  return (
    <div className={containerClass}>
      <DescriptionInput
        id={element.id}
        type="text"
        error={checkError(element, mode)}
        label={buildLabel(element)}
        value={element.properties?.value || ''}
        placeholder={element.properties.placeholder}
        required={element.properties.required}
        onChange={handleChange}
        disabled={disabled}
      />
    </div>
  );
};

export default MultiLineTextInputElementComponent;
