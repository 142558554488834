import React from 'react';
import { TimeInputElementClass } from 'types/documentTemplateElement';
import { ElementProps } from 'types/documentTemplateElement';
import TimeInput from "../../inputs/TimeInput";
import {buildLabel, checkError} from "utils/elementUtils";
import {useIsDocumentElementDisabled} from "../../../hooks/useIsDocumentElementDisabled";

const TimeInputElementComponent: React.FC<ElementProps<TimeInputElementClass>> = ({ element, updateElement, mode }) => {
  const disabled = useIsDocumentElementDisabled(element, mode);
  const handleChange = (value: string) => {
    if (mode === 'view') {
      const newUpdatedElement = new TimeInputElementClass({
        ...element,
        properties: {
          ...element.properties,
          value: value,
        },
      });
      updateElement(newUpdatedElement);
    }
    if (mode === 'admin') {
      const newUpdatedElement = new TimeInputElementClass({
        ...element,
        properties: {
          ...element.properties,
          value: value,
        },
      });
      updateElement(newUpdatedElement);
    }
    return;
  }
    if(element.properties.adminOnly && mode === 'view') return null;

  let containerClass = "w-full m-8";
  if (mode === "view" || mode === "preview" || mode === "admin") {
    containerClass = element.properties.showLabel
        ? "w-full mx-3 mb-2"
        : "w-full mt-5 mx-3 mb-2";
  }

  return (
      <div className={containerClass}>
        <TimeInput
            id={element.id}
            error={checkError(element, mode)}
            label={buildLabel(element)}
            value={element.properties?.value || ''}
            placeholder={element.properties.placeholder}
            required={element.properties.required}
            onChange={handleChange}
            isClickDisabled={mode === 'preview'}
            disabled={disabled}
        />
      </div>
  );
};

export default TimeInputElementComponent;
