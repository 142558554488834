import React from 'react';
import { DateTimeInputElement } from 'types/documentTemplateElement';
import { DocumentTemplate } from 'types/documentTemplate';
import BaseInputProperties from './BaseInputProperties';
import RadioInputHorizontal from "../../inputs/RadioInputHorizontal";

interface DateTimeInputPropertiesProps {
    element: DateTimeInputElement;
    template: DocumentTemplate;
    updateElement: (updatedElement: DateTimeInputElement) => void;
}

const DateTimeInputProperties: React.FC<DateTimeInputPropertiesProps> = ({
    element,
    template,
    updateElement,
}) => {
    const additionalFields = (
        <RadioInputHorizontal
            label="Allow Past Dates"
            options={[
                {id: 'allow-past-dates-yes', label: 'Yes', value: true},
                {id: 'allow-past-dates-no', label: 'No', value: false},
            ]}
            selectedValue={element.properties.allowPastDates}
            onChange={(value) => updateElement({...element, properties: {...element.properties, allowPastDates: value}})}
        />
    );

    return (
        <BaseInputProperties
            element={element}
            template={template}
            updateElement={updateElement}
            additionalFields={additionalFields}
            hiddePlaceholder={true}
        />
    );
};

export default DateTimeInputProperties;
