import React, {useContext, useEffect, useState} from 'react';
import UserCredentialTable from 'features/userCredential/UserCredentialTable';
import Drawer from 'components/Drawer';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from 'store/store';
import {User} from 'types/user';
import CheckCircleIcon from 'assets/images/icons/CheckCircleIcon';
import XCircleIcon from 'assets/images/icons/XCircleIcon';
import BlockedIcon from 'assets/images/icons/BlockedIcon';
import LockedIcon from 'assets/images/icons/LockedIcon';
import {UserThunks} from 'features/user/userThunks';
import {useParams} from 'react-router-dom';
import UserCredentialForm from 'features/userCredential/UserCredentialForm';
import CircleDashedIcon from 'assets/images/icons/CircleDashedIcon';
import UserTrainingsTable from 'features/userTraining/UserTrainingsTable';
import HRDocumentsTable from 'features/userHrDocument/UserHrDocumentsTable';
import UserTrainingForm from 'features/userTraining/UserTrainingForm';
import UserHrDocumentForm from 'features/userHrDocument/UserHrDocumentForm';
import UserBackgroundDocumentTable from 'features/userBackgroundDocument/UserBackgroundDocumentTable';
import UserBackgroundDocumentForm from 'features/userBackgroundDocument/UserBackgroundDocumentForm';
import Skeleton from 'react-loading-skeleton';
import UserMedicalDocumentTable from '../../../features/userMedicalDocument/UserMedicalDocumentTable';
import UserMedicalDocumentForm from '../../../features/userMedicalDocument/UserMedicalDocumentForm';
import UserApplicationTable from '../../../features/userApplication/UserApplicationTable';
import UserApplicationForm from '../../../features/userApplication/UserApplicationForm';
import {TitleContext} from '../../../components/TitleContext';
import UserJobRoleTable from '../../../features/userJobRole/UserJobRoleTable';
import UserJobRoleForm from '../../../features/userJobRole/UserJobRoleForm';
import UserEvaluationTable from '../../../features/userEvaluation/UserEvaluationTable';
import UserEvaluationForm from '../../../features/userEvaluation/UserEvaluationForm';

const HrUserPage: React.FC = () => {
  const activeTab = useSelector((state: RootState) => state.hr.activeTab);
  const user = useSelector((state: RootState) => state.user.user as User);
  const userLoading = useSelector(
    (state: RootState) => state.user.entityStatus === 'loading',
  );
  const userHrDocumentLoading = useSelector(
    (state: RootState) => state.userHrDocument.entityStatus === 'loading',
  );
  const userCredentialLoading = useSelector(
    (state: RootState) => state.userCredential.entityStatus === 'loading',
  );
  const userTrainingLoading = useSelector(
    (state: RootState) => state.userTraining.entityStatus === 'loading',
  );
  const userBackgroundDocumentLoading = useSelector(
    (state: RootState) =>
      state.userBackgroundDocument.entityStatus === 'loading',
  );
  const userMedicalDocumentLoading = useSelector(
    (state: RootState) => state.userMedicalDocument.entityStatus === 'loading',
  );
  const userApplicationLoading = useSelector(
    (state: RootState) => state.userApplication.entityStatus === 'loading',
  );
  const dispatch = useDispatch<AppDispatch>();
  const params = useParams();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const openDrawer = () => setIsDrawerOpen(true);
  const closeDrawer = () => setIsDrawerOpen(false);
  const {setTitle} = useContext(TitleContext);

  useEffect(() => {
    setTitle(
      <>
        {userLoading && (
          <div>
            <div className="flex items-center space-x-4">
              <Skeleton width={200} height={30} />
            </div>
            <div className="flex items-center space-x-2 text-slate-400 text-sm font-light font-['Inter'] leading-normal">
              <Skeleton width={100} height={20} />
            </div>
          </div>
        )}
        {user && !userLoading && (
          <div>
            <div className="flex items-center space-x-4">
              <div className="text-slate-600 text-lg font-medium font-['Inter']">
                {user?.firstName} {user?.lastName}
              </div>
              <span
                className={`flex items-center text-xs font-medium px-2 py-0.5 rounded-full w-[123px]
                            ${user.status === 'Active' && !user.locked && !user.blocked ? 'bg-green-100 text-green-800' : ''}
                            ${user.status === 'Inactive' && !user.locked && !user.blocked ? 'bg-red-100 text-red-800' : ''}
                            ${user.status === 'Onboarding' && !user.locked && !user.blocked ? 'bg-yellow-100 text-yellow-800' : ''}
                            ${user.locked ? 'bg-gray-100 text-gray-700' : ''}
                            ${user.blocked ? 'bg-gray-100 text-gray-700' : ''}
                        `}>
                {user.status === 'Active' && !user.locked && !user.blocked && (
                  <CheckCircleIcon className="mr-2" />
                )}
                {user.status === 'Inactive' &&
                  !user.locked &&
                  !user.blocked && <XCircleIcon className="mr-2" />}
                {user.status === 'Onboarding' &&
                  !user.locked &&
                  !user.blocked && <CircleDashedIcon className="mr-2" />}
                {user.blocked && <BlockedIcon className="mr-2" />}
                {user.locked && <LockedIcon className="mr-2" />}
                <div className="pl-1">
                  {user.status === 'Active' &&
                    !user.locked &&
                    !user.blocked &&
                    user.status}
                  {user.status === 'Inactive' &&
                    !user.locked &&
                    !user.blocked &&
                    'Inactive'}
                  {user.status === 'Onboarding' &&
                    !user.locked &&
                    !user.blocked &&
                    'Onboarding'}
                  {user.blocked && 'Blocked'}
                  {user.locked && 'Locked'}
                </div>
              </span>
            </div>
            <div className="flex items-center space-x-2 text-slate-400 text-sm font-light font-['Inter'] leading-normal">
              <div>
                {user?.jobRoles[0] !== undefined
                  ? user?.jobRoles[0]?.name
                  : 'No Role'}
              </div>
              <div>|</div>
              <div>ID: {user?.id}</div>
            </div>
          </div>
        )}
      </>,
    );
  }, [setTitle, user, userLoading]);

  useEffect(() => {
    dispatch(UserThunks.show(params.userId as string));
  }, []);

  return (
    <div className="relative overflow-hidden">
      {activeTab === 'HR Documents' && (
        <div>
          <HRDocumentsTable openDrawer={openDrawer} />
          <Drawer
            isOpen={isDrawerOpen}
            onClose={closeDrawer}
            loading={userHrDocumentLoading}>
            <UserHrDocumentForm
              closeDrawer={closeDrawer}
              isDrawerOpen={isDrawerOpen}
            />
          </Drawer>
        </div>
      )}
      {activeTab === 'Certifications' && (
        <div>
          <UserCredentialTable openDrawer={openDrawer} />
          <Drawer
            isOpen={isDrawerOpen}
            onClose={closeDrawer}
            loading={userCredentialLoading}>
            <UserCredentialForm
              closeDrawer={closeDrawer}
              isDrawerOpen={isDrawerOpen}
            />
          </Drawer>
        </div>
      )}
      {activeTab === 'Trainings' && (
        <div>
          <UserTrainingsTable openDrawer={openDrawer} />
          <Drawer
            isOpen={isDrawerOpen}
            onClose={closeDrawer}
            loading={userTrainingLoading}>
            <UserTrainingForm
              closeDrawer={closeDrawer}
              isDrawerOpen={isDrawerOpen}
            />
          </Drawer>
        </div>
      )}
      {activeTab === 'Background Documents' && (
        <div>
          <UserBackgroundDocumentTable openDrawer={openDrawer} />
          <Drawer
            isOpen={isDrawerOpen}
            onClose={closeDrawer}
            loading={userBackgroundDocumentLoading}>
            <UserBackgroundDocumentForm
              closeDrawer={closeDrawer}
              isDrawerOpen={isDrawerOpen}
            />
          </Drawer>
        </div>
      )}
      {activeTab === 'Medical Documents' && (
        <div>
          <UserMedicalDocumentTable openDrawer={openDrawer} />
          <Drawer
            isOpen={isDrawerOpen}
            onClose={closeDrawer}
            loading={userMedicalDocumentLoading}>
            <UserMedicalDocumentForm
              closeDrawer={closeDrawer}
              isDrawerOpen={isDrawerOpen}
            />
          </Drawer>
        </div>
      )}
      {activeTab === 'Applications' && (
        <div>
          <UserApplicationTable openDrawer={openDrawer} />
          <Drawer
            isOpen={isDrawerOpen}
            onClose={closeDrawer}
            loading={userApplicationLoading}>
            <UserApplicationForm
              closeDrawer={closeDrawer}
              isDrawerOpen={isDrawerOpen}
              userId={params.userId as string}
            />
          </Drawer>
        </div>
      )}
      {activeTab === 'Roles' && (
        <div>
          <UserJobRoleTable openDrawer={openDrawer} />
          <Drawer
            isOpen={isDrawerOpen}
            onClose={closeDrawer}
            loading={userApplicationLoading}>
            <UserJobRoleForm
              closeDrawer={closeDrawer}
              isDrawerOpen={isDrawerOpen}
              userId={params.userId as string}
            />
          </Drawer>
        </div>
      )}
      {activeTab === 'Evaluations' && (
        <div>
          <UserEvaluationTable openDrawer={openDrawer} />
          <Drawer
            isOpen={isDrawerOpen}
            onClose={closeDrawer}
            loading={userApplicationLoading}>
            <UserEvaluationForm
              closeDrawer={closeDrawer}
              isDrawerOpen={isDrawerOpen}
              userId={params.userId as string}
            />
          </Drawer>
        </div>
      )}
    </div>
  );
};

export default HrUserPage;
