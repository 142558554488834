import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from 'store/store';
import {UserApplicationThunks} from './userApplicationThunks';
import {DocumentTemplateThunks} from 'features/documentTemplate/documentTemplateThunks';
import CustomSelect from 'components/inputs/CustomSelect';
import SubmitButton from 'components/SubmitButton';
import {UserApplication} from 'types/userApplication';
import {DocumentTemplate} from 'types/documentTemplate';
import XIcon from 'assets/images/icons/XIcon';
import RadioInputHorizontal from '../../components/inputs/RadioInputHorizontal';
import DateInput from '../../components/inputs/DateInput';

interface UserApplicationFormProps {
  closeDrawer: () => void;
  isDrawerOpen: boolean;
  userId: string;
}

const UserApplicationForm: React.FC<UserApplicationFormProps> = ({
  closeDrawer,
  userId,
  isDrawerOpen,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const userApplication = useSelector(
    (state: RootState) => state.userApplication.userApplication,
  );
  const documentTemplates = useSelector(
    (state: RootState) => state.documentTemplate.documentTemplates,
  );
  const [updatedUserApplication, setUpdatedUserApplication] = useState<
    Partial<UserApplication>
  >({
    ...userApplication,
    userId,
    status: 'missing',
  });
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  useEffect(() => {
    const loadDocumentTemplates = async () => {
      try {
        await dispatch(
          DocumentTemplateThunks.index({
            page: 1,
            search: {where: {}},
            sortField: '',
            sortDirection: '',
            rowsPerPage: 100,
          }),
        );
      } catch (err) {
        console.error(err);
      }
    };
    loadDocumentTemplates();
  }, [dispatch]);

  useEffect(() => {
    const isFormValid =
      updatedUserApplication.documentTemplateId &&
      (updatedUserApplication.deadline === false ||
        updatedUserApplication.deadlineDate);
    setIsSaveDisabled(!isFormValid);
  }, [
    updatedUserApplication.documentTemplateId,
    updatedUserApplication.deadline,
    updatedUserApplication.deadlineDate,
  ]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log(e.currentTarget);
    if (userApplication?.id) {
      await dispatch(
        UserApplicationThunks.update(updatedUserApplication as UserApplication),
      ).then(() => closeDrawer());
    } else {
      const newUserApplication = new UserApplication({
        ...updatedUserApplication,
        userId: userId,
        status: 'missing',
      });
      await dispatch(
        UserApplicationThunks.create(newUserApplication as UserApplication),
      ).then(() => closeDrawer());
    }
  };

  const handleRadioChange = (field: string, value: boolean) => {
    setUpdatedUserApplication({...updatedUserApplication, [field]: value});
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatedUserApplication({
      ...updatedUserApplication,
      [e.target.id]: e.target.value,
    });
  };

  useEffect(() => {
    if (!isDrawerOpen) handleClose();
  }, [isDrawerOpen]);

  const handleClose = () => {
    setUpdatedUserApplication({
      deadlineDate: '',
      documentTemplateId: '',
    });
  };

  return (
    <div className="flex flex-col h-screen">
      <div className="flex-1 flex flex-col">
        <div className="h-auto p-6 bg-sky-50 border-b border-sky-200 flex justify-between items-start gap-2 w-full">
          <div className="flex flex-col justify-start items-start gap-1">
            <div className="text-lg font-semibold tracking-normal text-cyan-800">
              {userApplication?.id
                ? 'Edit application'
                : 'Create new application'}
            </div>
            <div className="text-sm font-light tracking-normal leading-5 text-zinc-400">
              {userApplication?.id ? 'Edit' : 'Create'} an application for a
              document
            </div>
          </div>
          <button
            onClick={closeDrawer}
            className="text-gray-500 hover:text-gray-700 focus:outline-none pt-2">
            <XIcon />
          </button>
        </div>
        <div className="h-full px-6 pb-6">
          <div className="mt-5">
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col space-y-6 items-center mt-7">
                <div className="w-full">
                  <div className="flex flex-col gap-[0.625rem]">
                    <label
                      htmlFor="documentTemplateId"
                      className="w-full block grow shrink basis-0 text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
                      Document
                    </label>
                    <CustomSelect
                      options={documentTemplates.map(
                        (template: DocumentTemplate) => ({
                          label: template.name,
                          value: template.id,
                        }),
                      )}
                      onChange={selectedOption => {
                        setUpdatedUserApplication({
                          ...updatedUserApplication,
                          documentTemplateId: selectedOption.value,
                        });
                      }}
                      value={updatedUserApplication?.documentTemplateId}
                    />
                    <div className="space-y-6 items-center mt-7">
                      <RadioInputHorizontal
                        label="Deadline"
                        options={[
                          {id: 'DeadlineYes', label: 'Yes', value: true},
                          {id: 'DeadlineNo', label: 'No', value: false},
                        ]}
                        selectedValue={updatedUserApplication.deadline}
                        onChange={value => handleRadioChange('deadline', value)}
                      />
                      {updatedUserApplication.deadline && (
                        <DateInput
                          id="deadlineDate"
                          label="Deadline Date"
                          type="date"
                          value={updatedUserApplication.deadlineDate || ''}
                          onChange={handleChange}
                          placeholder="MM/DD/YYYY"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <footer className="flex-shrink-0 px-6 pb-4 pt-2 flex justify-end gap-5">
        <div className="border-t border-slate-200 w-full">
          <div className="flex w-full justify-center items-center gap-x-2 pb-4">
            <SubmitButton
              onClick={handleSubmit}
              label="Submit"
              disabled={isSaveDisabled}
            />
          </div>
          <div className="flex w-full justify-center items-center gap-x-2 pb-4">
            <button
              type="submit"
              onClick={closeDrawer}
              className="self-center text-sm font-light tracking-normal leading-5 text-center text-zinc-400 hover:text-cyan-400">
              Never mind
            </button>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default UserApplicationForm;
