import React, {useEffect} from 'react';
import {DocumentTemplateElement} from 'types/documentTemplateElement';
import {DocumentTemplate} from 'types/documentTemplate';
import {DocumentTemplateRow} from 'types/documentTemplateRow';
import RenderElementProperties from './RenderElementProperties';

interface ElementPropertiesEditorProps {
  selectedElement: {
    element: DocumentTemplateElement;
    rowId: string;
    colId: string;
  } | null;
  template: DocumentTemplate;
  onUpdateTemplate: (updatedTemplate: DocumentTemplate) => void;
  onUpdateSelectedElement: (updatedElement: DocumentTemplateElement) => void;
  propertiesElementRef: React.RefObject<HTMLDivElement>;
  onClose: () => void;
  parentRef: React.RefObject<HTMLDivElement>;
}

const ElementPropertiesEditor: React.FC<ElementPropertiesEditorProps> = ({
  selectedElement,
  template,
  onUpdateTemplate,
  onUpdateSelectedElement,
  propertiesElementRef,
  onClose,
  parentRef,
}) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        propertiesElementRef.current &&
        !propertiesElementRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [propertiesElementRef, onClose]);

  if (!selectedElement) {
    return null;
  }

  return (
    <div
      ref={propertiesElementRef}
      className={`absolute z-10 p-5 bg-slate-50 border border-gray-200 rounded-xl dark:bg-neutral-800 dark:border-neutral-700 overflow-y-auto`}
      style={{
        bottom: parentRef.current ? parentRef.current.clientTop + parentRef.current.clientHeight + 10 : 0,
        left: parentRef.current ? parentRef.current.clientLeft + 10: 0,
        width: parentRef.current ? parentRef.current.clientWidth - 20 : 0,
      }}>
      <>
        <RenderElementProperties
          element={selectedElement.element}
          template={template}
          updateElement={updatedElement => {
            const updatedRows = template.rows!.map(rowItem => {
              if (rowItem.id !== selectedElement.rowId) return rowItem;
              return new DocumentTemplateRow({
                ...rowItem,
                columns: rowItem.columns.map(column => {
                  if (column.id !== selectedElement.colId) return column;
                  return {
                    ...column,
                    element: updatedElement,
                  };
                }),
              });
            });
            onUpdateTemplate(
              new DocumentTemplate({
                ...template,
                rows: updatedRows,
              }),
            );
            onUpdateSelectedElement(updatedElement);
          }}
        />
      </>
    </div>
  );
};

export default ElementPropertiesEditor;
