import apiClient from "services/apiClient";
import {User} from "types/user";
import {createEntityService} from "../utils/createEntityService";

export const BaseUserService = createEntityService<User>({
    baseUrl: 'api/v1/users',
    entityName: 'user',
    methodConfigs: {}
});

export class UserService extends BaseUserService {

    static async register

    (user: User, token: string, password: string, pinCode: string, signature: string, credentials: any) {

        const isCredentialsObject = credentials && typeof credentials === 'object' && !Array.isArray(credentials);

        const credentialsArray = isCredentialsObject
            ? Object.keys(credentials).map(credentialId => ({
                credentialId: credentialId,
                expirationDate: credentials[credentialId]
            }))
            : [];

        const params = {
            user: {
                email: user.email,
                invitationToken: token,
                password: password,
                pinCode: pinCode,
                signature: signature,
                credentials: credentialsArray
            }
        }
        const response = await apiClient.post("api/v1/signup", params);
        return response.data;
    }

    static async updateSignature(id: string, signature: string) {
        const response = await apiClient.patch(`api/v1/users/${id}`, {
            user: {
                id,
                signature
            }
        }, {headers: {'Content-Type': 'multipart/form-data'}});
        return response.data;
    }

    static async updateAvatar(id: string, avatar: any) {
        const response = await apiClient.patch(`api/v1/users/${id}`, {
            user: {
                id,
                avatar
            }
        }, {headers: {'Content-Type': 'multipart/form-data'}});
        return response.data;
    }

    static async deleteAvatar(id: string) {
        const response = await apiClient.patch(`api/v1/users/${id}/delete_avatar`, {});
        return response.data;
    }

    static async unlock(id: string) {
        const response = await apiClient.patch(`api/v1/users/${id}/unlock`);
        return response.data;
    }

    static async unblock(id: string) {
        const response = await apiClient.patch(`api/v1/users/${id}/unblock`);
        return response.data;
    }

    static async checkEmailExists(email: string) {
        const response = await apiClient.get(`/api/v1/users/check_email`, {
            params: {email}
        });
        return response.data;
    }

    static async changePassword(id: string, oldPassword: string, newPassword: string) {
        const response = await apiClient.patch(`api/v1/users/${id}/change_password`, {
            oldPassword,
            newPassword,
        });
        return response.data;
    }

    static async changePIN(id: string, password: string, newPin: string) {
        const response = await apiClient.patch(`api/v1/users/${id}/change_pin`, {
            password,
            pinCode: newPin,
        });
        return response.data;
    }

}





