import React, {useCallback} from 'react';
import Wysiwyg from 'components/Wysiwyg';
import {
  ElementProps,
  TextBlockElementClass,
} from 'types/documentTemplateElement';

interface TextBlockElementProps extends ElementProps<TextBlockElementClass> {}

const TextBlockElementComponent: React.FC<TextBlockElementProps> = ({
  element,
  updateElement,
}) => {
  const handleContentChange = useCallback((content: string) => {
    const updatedElement = new TextBlockElementClass({
      ...element,
      properties: {
        ...element.properties,
        content,
      },
    });
    updateElement(updatedElement);
  }, [element, updateElement]);

  return (
    <div className="w-full m-8">
      <Wysiwyg
        content={element.properties.content}
        onContentChange={handleContentChange}
        key={element.id}
      />
    </div>
  );
};

export default TextBlockElementComponent;
