import {createAsyncThunk} from '@reduxjs/toolkit';
import {RootState} from "store/store";
import {AuthService} from "services/authService";

const convertImageUrlToBase64 = async (url: string): Promise<string> => {
    const response = await fetch(url);
    const blob = await response.blob();
    const signature = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    }).then(result => {
        return result;
    })
    return signature as Promise<string>;
};


export class AuthThunks {
    static resetPassword = createAsyncThunk(
        'auth/resetPassword',
        async ({password, token}: { password: string, token: string }) => {
            return await AuthService.resetPassword(token, password);
        }
    );
    static verifyCode = createAsyncThunk(
        'auth/verifyCode',
        async (code: string, {getState}) => {
            const state = getState() as RootState;
            const {userCredentials} = state.auth;
            if (!userCredentials) {
                throw new Error("User credentials are missing");
            }
            let data = await AuthService.verifyCode(code, userCredentials);
            if (data.user) {
                data.base64Signature = await convertImageUrlToBase64(data.user.signature);
            }
            return data;
        }
    );
    static login = createAsyncThunk(
        'auth/login',
        async (credentials: { email: string; password: string }) => {
            const response = await AuthService.login(credentials.email, credentials.password);
            return {...response, userCredentials: credentials};
        }
    );
    static forgotPassword = createAsyncThunk(
        'auth/forgotPassword',
        async (credentials: { email: string; }) => {
            return await AuthService.forgotPassword(credentials.email);
        }
    );
    static unlockScreen = createAsyncThunk(
        'auth/unLockScreen',
        async (pinCode: string) => {
            return await AuthService.unlockScreen(pinCode);
        }
    );
    static unlockHrScreen = createAsyncThunk(
        'auth/unLockHrScreen',
        async (pinCode: string) => {
            return await AuthService.unlockHrScreen(pinCode);
        }
    );
    static forgotPinCode = createAsyncThunk(
        'auth/forgotPinCode',
        async () => {
            return await AuthService.forgotPinCode();
        }
    );
    static forgotHrPinCode = createAsyncThunk(
        'auth/forgotHrPinCode',
        async () => {
            return await AuthService.forgotHrPinCode();
        }
    );
    static lockScreen = createAsyncThunk(
        'auth/lockScreen',
        async () => {
            return await AuthService.lockScreen();
        }
    );
}

