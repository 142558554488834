import React, {useState} from 'react';
import Table from 'components/table/Table';
import TableHeader from 'components/table/TableHeader';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from 'store/store';
import {SearchType} from 'types/search';
import {useParams} from 'react-router-dom';
import {setActiveTab} from 'features/hr/hrSlice';
import {useDropdownActionMenu} from 'hooks/useDropdownActionMenu';
import {UserTrainingThunks} from 'features/userTraining/userTrainingThunks';
import {setIsEditing} from 'features/userTraining/userTrainingSlice';
import TableRow from 'components/table/TableRow';
import TableCell from 'components/table/TableCell';
import DotsMenu from 'components/DotsMenu';
import {UserTraining} from 'types/userTraining';
import WhiteButton from 'components/WhiteButton';
import DownloadIcon from 'assets/images/icons/DownloadIcon';
import useViewerModal from 'hooks/useViewerModal';
import StatusBadge from 'components/StatusBadge';

const UserTrainingsTable: React.FC<{openDrawer: () => void}> = ({
  openDrawer,
}) => {
  const trainings = useSelector(
    (state: RootState) => state.userTraining.userTrainings,
  );
  const dispatch = useDispatch<AppDispatch>();
  const pagy = useSelector((state: RootState) => state.userTraining.pagy);
  const loading = useSelector(
    (state: RootState) => state.userTraining.status === 'loading',
  );
  const params = useParams<{userId: string}>();
  const {setDotsMenuIsOpen} = useDropdownActionMenu();
  const [selectedAttachmentUrl] = useState<string | null>(null);

  const {AttachmentModal, handleNameClick} = useViewerModal<UserTraining>({
    openDrawer,
    fetchItemThunk: UserTrainingThunks.show,
  });

  interface VisibleColumnsType {
    name: boolean;
    licensingAuthority: boolean;
    status: boolean;
    completedAt: boolean;
    expDate: boolean;
    userReminderDays: boolean;
    notes: boolean;
  }

  const [visibleColumns, setVisibleColumns] = useState<VisibleColumnsType>({
    name: true,
    licensingAuthority: true,
    status: true,
    completedAt: true,
    expDate: true,
    userReminderDays: true,
    notes: true,
  });

  const searchByOptions = [{label: 'Name', value: 'name', operator: 'like'}];

  const columnOptions = [
    {label: 'Name', value: 'name', isVisible: visibleColumns.name},
    {
      label: 'Licensing Authority',
      value: 'licensingAuthority',
      isVisible: visibleColumns.licensingAuthority,
    },
    {label: 'Status', value: 'status', isVisible: visibleColumns.status},
    {
      label: 'Completion Date',
      value: 'completionDate',
      isVisible: visibleColumns.completedAt,
    },
    {label: 'Exp Date', value: 'expDate', isVisible: visibleColumns.expDate},
    {
      label: 'Reminder',
      value: 'userReminderDays',
      isVisible: visibleColumns.userReminderDays,
    },
    {label: 'Notes', value: 'notes', isVisible: visibleColumns.notes},
  ];

  const fetchData = async ({
    page,
    search,
    sortField,
    sortDirection,
    rowsPerPage,
  }: {
    page: number;
    search: SearchType<{[key: string]: {operator: string; value: string}}>;
    sortField: string;
    sortDirection: string;
    rowsPerPage: number;
  }): Promise<void> => {
    const {userId} = params;

    search.where.user_id = {
      operator: '==',
      value: userId as string,
    };

    await dispatch(
      UserTrainingThunks.index({
        page,
        search,
        sortField,
        sortDirection,
        rowsPerPage,
      }),
    );
  };

  const handleColumnToggle = (column: string) => {
    if (column in visibleColumns) {
      setVisibleColumns(prev => ({
        ...prev,
        [column]: !prev[column as keyof VisibleColumnsType],
      }));
    }
  };

  const handleDelete = async (id: string) => {
    const newTrainings = new UserTraining({
      id,
      attachment: null,
      deleteAttachment: true,
    });
    await dispatch(UserTrainingThunks.update(newTrainings));
    setDotsMenuIsOpen(null);
  };

  const handleEdit = async (id: string) => {
    await dispatch(UserTrainingThunks.show(id));
    dispatch(setIsEditing(true));
    openDrawer();
  };

  const handleDownload = (attachmentUrl: string) => {
    if (attachmentUrl) {
      window.open(attachmentUrl, '_blank');
    }
  };

  const handleSelectDownload = () => {
    if (selectedAttachmentUrl) {
      window.open(selectedAttachmentUrl, '_blank');
    }
  };

  return (
    <>
      <Table
        tabs={[
          'HR Documents',
          'Certifications',
          'Trainings',
          'Background Documents',
          'Medical Documents',
          'Applications',
          'Roles',
          'Evaluations',
        ]}
        activeTab={'Trainings'}
        fetchData={fetchData}
        setActiveTab={(tab: string) => dispatch(setActiveTab(tab))}
        pagy={pagy}
        recordsName={'Trainings'}
        loading={loading}
        columns={true}
        columnOptions={columnOptions}
        onColumnToggle={handleColumnToggle}
        blueButton={
          <WhiteButton
            onClick={handleSelectDownload}
            label={''}
            icon={<DownloadIcon />}
          />
        }
        searchByOptions={searchByOptions}>
        <thead>
          <tr>
            <th scope="col" className="px-3 pt-3.5 flex justify-center">
              <input
                type="checkbox"
                className="shrink-0 border-stone-300 rounded disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500
                dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"
              />
            </th>
            {visibleColumns.name && <TableHeader label="Name" sortBy="name" />}
            {visibleColumns.licensingAuthority && (
              <TableHeader label="Licensing Authority" />
            )}
            {visibleColumns.status && <TableHeader label="Status" />}
            {visibleColumns.completedAt && (
              <TableHeader label="Completion Date" />
            )}
            {visibleColumns.expDate && <TableHeader label="Exp Date" />}
            {visibleColumns.userReminderDays && (
              <TableHeader label="Reminder" />
            )}
            {visibleColumns.notes && <TableHeader label="Notes" />}
            <TableHeader label="" />
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
          {trainings.map((training: UserTraining) => {
            const userOptions = [
              {
                label: 'Edit',
                onClick: () => handleEdit(training.id),
                hoverClass: 'hover:bg-cyan-100 ',
              },
              ...(training.status !== 'Missing'
                ? [
                    {
                      label: 'Download',
                      onClick: () =>
                        handleDownload(training.attachment?.url || ''),
                      hoverClass: 'hover:bg-cyan-100',
                    },
                    {
                      label: 'Remove attached document',
                      onClick: () => handleDelete(training.id),
                      hoverClass: 'hover:bg-red-100 hover:text-red-800',
                    },
                  ]
                : []),
              ...(training.status === 'Missing'
                ? [
                    {
                      label: 'Upload',
                      onClick: () => handleEdit(training.id),
                      hoverClass: 'hover:bg-cyan-100 ',
                    },
                  ]
                : []),
            ];
            return (
              <TableRow key={training.id}>
                <td className="pt-3.5 flex justify-center">
                  <input
                    type="checkbox"
                    className="border border-gray-300 rounded text-white disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600
                                               dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                    onClick={e => e.stopPropagation()}
                  />
                </td>
                {visibleColumns.name && (
                  <TableCell>
                    <div className="relative group">
                      <div className="w-full flex items-center gap-x-3">
                        <div className="flex-grow">
                          <span
                            className={`text-sm  font-medium dark:text-neutral-200 ${
                              training.status !== 'Missing'
                                ? 'underline text-cyan-600 cursor-pointer'
                                : ''
                            }`}
                            onClick={
                              training.status !== 'Missing'
                                ? () => handleNameClick(training)
                                : undefined
                            }>
                            {training.name}
                          </span>
                        </div>
                      </div>
                    </div>
                  </TableCell>
                )}
                {visibleColumns.licensingAuthority && (
                  <TableCell>{training.licensingAuthority}</TableCell>
                )}
                {visibleColumns.status && (
                  <TableCell>
                    <StatusBadge status={training.status} />
                  </TableCell>
                )}
                {visibleColumns.completedAt && (
                  <TableCell>{training.completedAt}</TableCell>
                )}
                {visibleColumns.expDate && (
                  <TableCell>{training.expirationDate}</TableCell>
                )}
                {visibleColumns.userReminderDays && (
                  <TableCell>{training.userReminderDays || 'None'}</TableCell>
                )}
                {visibleColumns.notes && (
                  <TableCell className="whitespace-nowrap overflow-hidden text-ellipsis">
                    <div
                      style={{maxWidth: '150px'}}
                      className="whitespace-nowrap overflow-hidden text-ellipsis">
                      {training.notes}
                    </div>
                  </TableCell>
                )}
                <TableCell>
                  <DotsMenu options={userOptions} />
                </TableCell>
              </TableRow>
            );
          })}
        </tbody>
      </Table>
      <AttachmentModal />
    </>
  );
};

export default UserTrainingsTable;
