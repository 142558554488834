import React, {useState} from 'react';
import Table from 'components/table/Table';
import TableHeader from 'components/table/TableHeader';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from 'store/store';
import {SearchType} from 'types/search';
import {useNavigate, useParams} from 'react-router-dom';
import {setActiveTab} from 'features/hr/hrSlice';
import {useDropdownActionMenu} from 'hooks/useDropdownActionMenu';
import {UserHrDocumentThunks} from 'features/userHrDocument/userHrDocumentThunks';
import TableRow from 'components/table/TableRow';
import TableCell from 'components/table/TableCell';
import DotsMenu from 'components/DotsMenu';
import {UserHrDocument} from 'types/userHrDocument';
import WhiteButton from 'components/WhiteButton';
import DownloadIcon from 'assets/images/icons/DownloadIcon';
import {setIsEditing} from 'features/userHrDocument/userHrDocumentSlice';
import Skeleton from 'react-loading-skeleton';
import useViewerModal from 'hooks/useViewerModal';
import {UploadRequestService} from 'services/uploadRequestService';
import {UploadRequest} from 'types/uploadRequest';
import {showToast} from 'components/ToastContainer';
import {DocumentTemplateSolutionThunks} from 'features/documentTemplateSolution/documentTemplateSolutionThunks';
import StatusBadge from 'components/StatusBadge';

const UserHrDocumentsTable: React.FC<{openDrawer: () => void}> = ({
  openDrawer,
}) => {
  const userHrDocuments = useSelector(
    (state: RootState) => state.userHrDocument.userHrDocuments,
  );
  const loading = useSelector(
    (state: RootState) => state.userHrDocument.status === 'loading',
  );
  const dispatch = useDispatch<AppDispatch>();
  const pagy = useSelector((state: RootState) => state.userHrDocument.pagy);
  const params = useParams<{userId: string}>();
  const {setDotsMenuIsOpen} = useDropdownActionMenu();
  const [selectedAttachmentUrl] = useState<string | null>(null);
  const navigate = useNavigate();

  const {AttachmentModal, handleNameClick} = useViewerModal<any>({
    openDrawer,
    fetchItemThunk: UserHrDocumentThunks.show,
    fetchDocumentTemplateThunk: DocumentTemplateSolutionThunks.index,
  });

  interface VisibleColumnsType {
    name: boolean;
    status: boolean;
    expiresAt: boolean;
    notes: boolean;
    completedBy: boolean;
  }

  const [visibleColumns, setVisibleColumns] = useState({
    name: true,
    status: true,
    expiresAt: true,
    issuedAt: true,
    completedBy: true,
    notes: true,
  });

  const searchByOptions = [
    {label: 'Document', value: 'name', operator: 'like'},
  ];

  const columnOptions = [
    {label: 'Document', value: 'name', isVisible: visibleColumns.name},
    {label: 'Status', value: 'status', isVisible: visibleColumns.status},
    {
      label: 'Issue Date',
      value: 'issuedAt',
      isVisible: visibleColumns.issuedAt,
    },
    {label: 'Exp Date', value: 'expDate', isVisible: visibleColumns.expiresAt},
    {
      label: 'Completed By',
      value: 'completedBy',
      isVisible: visibleColumns.completedBy,
    },
    {label: 'Notes', value: 'notes', isVisible: visibleColumns.notes},
  ];

  const fetchData = async ({
    page,
    search,
    sortField,
    sortDirection,
    rowsPerPage,
  }: {
    page: number;
    search: SearchType<{[key: string]: {operator: string; value: string}}>;
    sortField: string;
    sortDirection: string;
    rowsPerPage: number;
  }): Promise<void> => {
    const {userId} = params;

    search.where.user_id = {
      operator: '==',
      value: userId as string,
    };
    search.where['hr_document.document_type'] = {
      operator: '==',
      value: 'file',
    };

    await dispatch(
      UserHrDocumentThunks.index({
        page,
        search,
        sortField,
        sortDirection,
        rowsPerPage,
      }),
    );
  };

  const handleColumnToggle = (column: string) => {
    if (column in visibleColumns) {
      setVisibleColumns(prev => ({
        ...prev,
        [column]: !prev[column as keyof VisibleColumnsType],
      }));
    }
  };

  const handleSelectDownload = () => {
    if (selectedAttachmentUrl) {
      window.open(selectedAttachmentUrl, '_blank');
    }
  };

  const handleDelete = async (id: string) => {
    const newHrDocument = new UserHrDocument({
      id,
      attachment: null,
      deleteAttachment: true,
    });
    await dispatch(UserHrDocumentThunks.update(newHrDocument));
    setDotsMenuIsOpen(null);
  };

  const handleEditFile = async (id: string) => {
    await dispatch(UserHrDocumentThunks.show(id));
    dispatch(setIsEditing(true));
    openDrawer();
  };

  const handleDownload = (attachmentUrl: string) => {
    if (attachmentUrl) {
      window.open(attachmentUrl, '_blank');
    }
  };

  const handleEdit = (userHrDocument: UserHrDocument) => {
    if (userHrDocument.hrDocument?.documentType === 'file') {
      handleEditFile(userHrDocument.id);
    } else {
      navigate(`/document-fill/${userHrDocument.id}`);
    }
  };

  const handleRequestEmail = async (id: string) => {
    UploadRequestService.create({
      ownerType: 'UserHrDocument',
      ownerId: id,
      ownerField: 'attachment',
    } as UploadRequest).then(() => {
      showToast('Email sent successfully', 'success');
    });
  };

  return (
    <>
      <Table
        tabs={[
          'HR Documents',
          'Certifications',
          'Trainings',
          'Background Documents',
          'Medical Documents',
          'Applications',
          'Roles',
          'Evaluations',
        ]}
        activeTab={'HR Documents'}
        fetchData={fetchData}
        setActiveTab={(tab: string) => dispatch(setActiveTab(tab))}
        pagy={pagy}
        loading={loading}
        recordsName={'Documents'}
        columns={true}
        blueButton={
          <WhiteButton
            onClick={handleSelectDownload}
            label={''}
            icon={<DownloadIcon />}
          />
        }
        columnOptions={columnOptions}
        onColumnToggle={handleColumnToggle}
        searchByOptions={searchByOptions}>
        <thead>
          <tr>
            <th scope="col" className="px-3  pt-3.5  flex justify-center">
              <input
                type="checkbox"
                className="shrink-0 border-stone-300 rounded disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600 dark:checked:bg-green-500
                                      dark:checked:border-green-500 dark:focus:ring-offset-neutral-800"
              />
            </th>
            {visibleColumns.name && (
              <TableHeader label="Document" sortBy="name" />
            )}
            {visibleColumns.status && (
              <TableHeader label="Status" sortBy="status" />
            )}
            {visibleColumns.issuedAt && (
              <TableHeader label="Issue Date" sortBy="issuedAt" />
            )}
            {visibleColumns.expiresAt && (
              <TableHeader label="Exp Date" sortBy="expiresAt" />
            )}
            {visibleColumns.completedBy && <TableHeader label="Completed by" />}
            {visibleColumns.notes && <TableHeader label="Notes" />}
            <TableHeader label="" />
          </tr>
        </thead>
        <tbody>
          {userHrDocuments.length !== 0 &&
            userHrDocuments.map((userHrDocument: UserHrDocument) => {
              const userOptions = [
                ...(userHrDocument.hrDocument?.documentType === 'file' ||
                userHrDocument.status === 'Missing'
                  ? [
                      {
                        label: 'Edit',
                        onClick: () => handleEdit(userHrDocument),
                        hoverClass: 'hover:bg-cyan-100',
                      },
                    ]
                  : []),
                ...(userHrDocument.status !== 'Missing'
                  ? [
                      {
                        label: 'Download',
                        onClick: () =>
                          handleDownload(userHrDocument.attachment?.url || ''),
                        hoverClass: 'hover:bg-cyan-100',
                      },
                      {
                        label: 'Remove attached document',
                        onClick: () => handleDelete(userHrDocument.id),
                        hoverClass: 'hover:bg-red-100 hover:text-red-800',
                      },
                    ]
                  : []),

                ...(userHrDocument.status === 'Missing' &&
                userHrDocument.hrDocument?.documentType === 'file'
                  ? [
                      {
                        label: 'Upload',
                        onClick: () => handleEdit(userHrDocument),
                        hoverClass: 'hover:bg-cyan-100',
                      },
                      {
                        label: 'Send email reminder',
                        onClick: () => handleRequestEmail(userHrDocument.id),
                        hoverClass: 'hover:bg-cyan-100',
                      },
                    ]
                  : []),
              ];
              if (loading) {
                return (
                  <TableRow key={userHrDocument.id}>
                    <td colSpan={6}>
                      <Skeleton count={1} height={40} />
                    </td>
                  </TableRow>
                );
              } else {
                return (
                  <TableRow
                    key={userHrDocument.id}
                    className="border-t border-slate-200">
                    <td scope="col" className="pt-3.5 flex justify-center">
                      <input
                        type="checkbox"
                        className="border border-gray-300 rounded text-white disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-600
                                           dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                        onClick={e => e.stopPropagation()}
                      />
                    </td>
                    {visibleColumns.name && (
                      <TableCell>
                        <div className=" relative group">
                          <div className="w-full flex items-center gap-x-3">
                            <div className="flex-grow">
                              <span
                                className={`text-sm  font-medium dark:text-neutral-200 ${
                                  userHrDocument.status !== 'Missing'
                                    ? 'underline text-cyan-600 cursor-pointer'
                                    : ''
                                }`}
                                onClick={
                                  userHrDocument.status !== 'Missing'
                                    ? () =>
                                        handleNameClick({
                                          id: userHrDocument.id,
                                          name: userHrDocument.name,
                                          status: userHrDocument.status,
                                          attachment: userHrDocument.attachment,
                                          docType:
                                            userHrDocument.hrDocument
                                              ?.documentType,
                                        })
                                    : undefined
                                }>
                                {userHrDocument.name}
                              </span>
                            </div>
                          </div>
                        </div>
                      </TableCell>
                    )}
                    {visibleColumns.status && (
                      <TableCell>
                        <StatusBadge status={userHrDocument.status} />
                      </TableCell>
                    )}
                    {visibleColumns.issuedAt && (
                      <TableCell>{userHrDocument.issuedAt || 'NA'}</TableCell>
                    )}
                    {visibleColumns.expiresAt && (
                      <TableCell>{userHrDocument.expiresAt || 'NA'}</TableCell>
                    )}
                    {visibleColumns.completedBy && (
                      <TableCell>{userHrDocument.completedBy}</TableCell>
                    )}
                    {visibleColumns.notes && (
                      <TableCell className="whitespace-nowrap overflow-hidden text-ellipsis">
                        <div
                          style={{maxWidth: '150px'}}
                          className="whitespace-nowrap overflow-hidden text-ellipsis">
                          {userHrDocument.notes}
                        </div>
                      </TableCell>
                    )}
                    <TableCell>
                      <DotsMenu options={userOptions} />
                    </TableCell>
                  </TableRow>
                );
              }
            })}
        </tbody>
      </Table>
      <AttachmentModal />
    </>
  );
};

export default UserHrDocumentsTable;
