import React from 'react';
import {AddressInputElement} from 'types/documentTemplateElement';
import {DocumentTemplate} from 'types/documentTemplate';
import BaseInputProperties from './BaseInputProperties';

interface AddressInputPropertiesProps {
  element: AddressInputElement;
  updateElement: (updatedElement: AddressInputElement) => void;
  template: DocumentTemplate;
}

const AddressInputProperties: React.FC<AddressInputPropertiesProps> = ({
  element,
  updateElement,
  template,
}) => {
  return (
    <BaseInputProperties
      element={element}
      template={template}
      updateElement={updateElement}
      hiddePlaceholder={true}
    />
  );
};

export default AddressInputProperties;
