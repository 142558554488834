import { createEntitySlice } from 'utils/createEntitySlice';
import { UserApplication } from 'types/userApplication';
import { UserApplicationThunks } from './userApplicationThunks';

interface UserApplicationState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    userApplications: [];
    pagy: any;
    userApplication: null;
    isEditing: boolean;
    activeTab: string;
}

const initialState: UserApplicationState = {
    status: 'idle',
    error: null,
    userApplications: [],
    pagy: null,
    userApplication: null,
    isEditing: false,
    activeTab: 'Applications'
};

const userApplicationSlice = createEntitySlice<UserApplication>({
    name: 'userApplication',
    initialState,
    entityKey: 'userApplication',
    entitiesKey: 'userApplications',
    thunks: UserApplicationThunks,
    reducers: {}
});
export const {
    setEntity: setUserApplication,
    setIsEditing,
    setActiveTab,
} = userApplicationSlice.actions;

export {initialState as userApplicationInitialState};
export default userApplicationSlice.reducer; 