import {User} from "./user";
import {HrDocument} from "./hrDocument";

export interface UserHrDocumentInterface {
    id: string;
    name?: string;
    status?: string;
    completedBy?: string;
    expirationDate?: string;
    notes?: string;
    attachment?: any;
    deleteAttachment?: boolean;
    deleteAttachmentId?: string;
    user?: User;
    expiresAt?: string;
    issuedAt?: string;
    requireDate?: boolean;
    hrDocument?: HrDocument;
    previousAttachments?: any[];
}

export class UserHrDocument implements UserHrDocumentInterface {
    id: string;
    name: string;
    status: string;
    completedBy?: string;
    expirationDate?: string;
    notes?: string;
    attachment?: any;
    deleteAttachment?: boolean;
    deleteAttachmentId?: string;
    user?: User;
    expiresAt?: string;
    issuedAt?: string;
    requireDate?: boolean;
    hrDocument?: HrDocument;
    previousAttachments?: any[];

    constructor(data: Partial<UserHrDocumentInterface>) {
        this.id = data.id!;
        this.name = data.name!;
        this.status = data.status!;
        this.expirationDate = data.expirationDate!;
        this.completedBy = data.completedBy!;
        this.notes = data.notes!;
        this.attachment = data.attachment!;
        this.deleteAttachment = data.deleteAttachment!;
        this.deleteAttachmentId = data.deleteAttachmentId!;
        this.user = data.user!;
        this.expiresAt = data.expiresAt!;
        this.issuedAt = data.issuedAt!;
        this.requireDate = data.requireDate!;
        this.hrDocument = data.hrDocument!;
        this.previousAttachments = data.previousAttachments!;
    }

    toJson(): UserHrDocumentInterface {
        return {
            id: this.id,
            notes: this.notes,
            attachment: this.attachment,
            deleteAttachment: this.deleteAttachment,
            deleteAttachmentId: this.deleteAttachmentId,
            expiresAt: this.expiresAt,
            issuedAt: this.issuedAt,

        };
    }
}
