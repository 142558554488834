import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '../../store/store';
import React, {useEffect, useState} from 'react';
import TextInput from 'components/inputs/TextInput';
import SubmitButton from 'components/SubmitButton';
import {JobRoleThunks} from 'features/jobRole/jobRoleThunks';
import {JobRole, JobRoleInterface} from 'types/jobRole';
import DescriptionInput from '../../components/inputs/DescriptionInput';
import XIcon from '../../assets/images/icons/XIcon';
import CustomSelect from 'components/inputs/CustomSelect';
import {User} from 'types/user';
import {UserService} from 'services/userService';
import {SearchType} from 'types/search';

interface JobRoleFormProps {
  id?: string;
  closeDrawer: () => void;
}

const JobRoleForm: React.FC<JobRoleFormProps> = ({closeDrawer}) => {
  const jobRole = useSelector((state: RootState) => state.jobRole.jobRole);
  const dispatch = useDispatch<AppDispatch>();
  const [updatedJobRole, setUpdatedJobRole] = useState({...jobRole});
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [supervisorOptions, setSupervisorOptions] = useState([]);

  useEffect(() => {
    const loadSelectData = async () => {
      try {
        const search: SearchType<{[key: string]: any}> = {
          where: {
            is_supervisor: {
              operator: '==',
              value: 'true',
            },
          },
        };

        const response = await UserService.index(1, search, '', '', 1000);
        setSupervisorOptions(response.data);
      } catch (err) {}
    };
    loadSelectData();
  }, []);

  const supervisorSelectOptions = supervisorOptions.map((user: User) => ({
    value: user.id,
    label: `${user.firstName} ${user.lastName}`,
  }));

  useEffect(() => {
    const isFormValid = updatedJobRole.name;

    setIsSaveDisabled(!isFormValid);
  }, [updatedJobRole]);

  useEffect(() => {
    setUpdatedJobRole({...jobRole});
    setIsSaveDisabled(!jobRole?.name);
  }, [jobRole]);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const {id, value} = e.target;
    setUpdatedJobRole({...updatedJobRole, [id]: value});
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (jobRole?.id) {
      await dispatch(JobRoleThunks.update(new JobRole(updatedJobRole))).then(
        () => closeDrawer(),
      );
    } else {
      await dispatch(JobRoleThunks.create(new JobRole(updatedJobRole))).then(
        () => closeDrawer(),
      );
    }
  };

  const handleClose = () => {
    closeDrawer();
  };

  return (
    <div className="flex flex-col h-screen">
      <div className="flex-1 flex flex-col">
        <div className="h-auto p-6 bg-sky-50 border-b border-sky-200 flex justify-between items-start gap-2 w-full">
          <div className="flex flex-col justify-start items-start gap-1">
            <div className="text-lg font-semibold tracking-normal text-cyan-800">
              {jobRole?.id ? 'Edit Role' : 'Create a new Role'}
            </div>
            <div className="text-sm font-light tracking-normal leading-5 text-zinc-400">
              {jobRole?.id ? 'Edit' : 'Create a new '} Role
            </div>
          </div>
          <button
            onClick={handleClose}
            className="text-gray-500 hover:text-gray-700 focus:outline-none pt-2">
            <XIcon />
          </button>
        </div>
        <div className="h-full px-6 pb-6 ">
          <div className="mt-5">
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col space-y-6 items-center mt-7">
                <div className="w-full">
                  <TextInput
                    id="name"
                    type="text"
                    label="Name"
                    value={updatedJobRole?.name}
                    placeholder=""
                    onChange={handleChange}
                  />
                </div>
                <div className="w-full">
                  <DescriptionInput
                    id="description"
                    type="text"
                    label="Description"
                    value={updatedJobRole?.description}
                    placeholder=""
                    onChange={handleChange}
                    optional="optional"
                  />
                </div>
                <div className="flex flex-col gap-[0.625rem] w-full">
                  <label
                    htmlFor="supervisorIds"
                    className="w-full block grow shrink basis-0 text-slate-800 text-sm font-semibold font-['Inter'] dark:text-white">
                    Supervisor(s)
                  </label>
                  <CustomSelect
                    options={supervisorSelectOptions}
                    isMulti={true}
                    onChange={selectedOptions => {
                      const selectedValues = selectedOptions.map(
                        (option: any) => option.value,
                      );
                      setUpdatedJobRole((prevData: JobRoleInterface) => ({
                        ...prevData,
                        supervisorIds: selectedValues,
                      }));
                    }}
                    value={updatedJobRole?.supervisorIds}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <footer className="flex-shrink-0 px-6 pb-4 pt-2 flex justify-end gap-5">
        <div className="border-t border-slate-200 w-full">
          <div className="flex w-full justify-center items-center gap-x-2 pb-4">
            <SubmitButton
              onClick={handleSubmit}
              label={(jobRole?.id ? 'Save' : 'Create new') + ' Role'}
              disabled={isSaveDisabled}
            />
          </div>
          <div className="flex w-full justify-center items-center gap-x-2 pb-4">
            <button
              type="submit"
              onClick={handleClose}
              className="self-center text-sm font-light tracking-normal leading-5 text-center text-zinc-400 hover:text-cyan-400">
              Never mind
            </button>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default JobRoleForm;
