import { createEntitySlice } from 'utils/createEntitySlice';
import { UserEvaluation } from 'types/userEvaluation';
import { UserEvaluationThunks } from './userEvaluationThunks';

interface UserEvaluationState {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    userEvaluations: [];
    pagy: any;
    userEvaluation: null;
    isEditing: boolean;
    activeTab: string;
}

const initialState: UserEvaluationState = {
    status: 'idle',
    error: null,
    userEvaluations: [],
    pagy: null,
    userEvaluation: null,
    isEditing: false,
    activeTab: 'Evaluations'
};

const userEvaluationSlice = createEntitySlice<UserEvaluation>({
    name: 'userEvaluation',
    initialState,
    entityKey: 'userEvaluation',
    entitiesKey: 'userEvaluations',
    thunks: UserEvaluationThunks,
    reducers: {}
});
export const {
    setEntity: setUserEvaluation,
    setIsEditing,
    setActiveTab,
} = userEvaluationSlice.actions;

export {initialState as userEvaluationInitialState};
export default userEvaluationSlice.reducer; 