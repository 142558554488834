import {User} from './user';

export interface UserEvaluationInterface {
    id: string;
    name: string;
    userId: string;
    deadline?: boolean;
    deadlineDate?: string;
    documentTemplateId: string;
    status?: string;
    createdAt: string;
    attachment?: any;
    user?: User;
    documentTemplate?: {
        id: string;
        name: string;
        description: string;
    };
    reviewedBy?: {
        id: string;
        name: string;
    };
}

export class UserEvaluation implements UserEvaluationInterface {
    id: string;
    userId: string;
    name: string;
    deadline?: boolean;
    deadlineDate?: string;
    user?: User;
    documentTemplateId: string;
    status?: string;
    createdAt: string;
    attachment?: any;
    documentTemplate?: {
        id: string;
        name: string;
        description: string;
    };
    reviewedBy?: {
        id: string;
        name: string;
    };

    constructor(data: Partial<UserEvaluationInterface>) {
        this.id = data.id!;
        this.userId = data.userId!;
        this.name = data.name!;
        this.deadline = data.deadline;
        this.deadlineDate = data.deadlineDate;
        this.user = data.user;
        this.documentTemplateId = data.documentTemplateId!;
        this.status = data.status;
        this.createdAt = data.createdAt!;
        this.attachment = data.attachment;
        this.documentTemplate = data.documentTemplate;
        this.reviewedBy = data.reviewedBy;
    }

    toJson(): Partial<UserEvaluationInterface> {
        return {
            id: this.id,
            userId: this.userId,
            user: this.user,
            documentTemplateId: this.documentTemplateId,
            deadline: this.deadline,
            deadlineDate: this.deadlineDate,
        };
    }
}
