import React from 'react';
import CheckCircleIcon from 'assets/images/icons/CheckCircleIcon';
import XCircleIcon from 'assets/images/icons/XCircleIcon';
import CircleAlertIcon from 'assets/images/icons/CircleAlertIcon';
import BlockedIcon from 'assets/images/icons/BlockedIcon';
import LockedIcon from 'assets/images/icons/LockedIcon';
import CircleDashedIcon from 'assets/images/icons/CircleDashedIcon';

export interface GenericStatusProps {
  status: string;
  locked?: boolean;
  blocked?: boolean;
}

const StatusBadge: React.FC<GenericStatusProps> = ({
  status,
  locked,
  blocked,
}) => {
  const baseClasses =
    'flex items-center text-xs font-medium px-2 py-0.5 rounded-full w-32';
  const colorClasses =
    locked || blocked
      ? 'bg-red-100 text-red-800'
      : {
          Active: 'bg-green-100 text-green-800',
          Complete: 'bg-green-100 text-green-800',
          Inactive: 'bg-slate-100 text-slate-800',
          Missing: 'bg-red-100 text-red-800',
          'Non Compliant': 'bg-red-100 text-red-800',
          Expired: 'bg-red-100 text-red-800',
          'Expiring soon': 'bg-yellow-100 text-yellow-800',
          Onboarding: 'bg-yellow-100 text-yellow-800',
          'Pending Review': 'bg-cyan-100 text-cyan-800',
          Draft: 'bg-cyan-100 text-cyan-800',
        }[status] || 'bg-gray-100 text-gray-800';

  const spanClasses = `${baseClasses} ${colorClasses}`;
  const icon = locked ? (
    <LockedIcon className="mr-2" />
  ) : blocked ? (
    <BlockedIcon className="mr-2" />
  ) : (
    {
      Active: <CheckCircleIcon className="mr-1" />,
      Complete: <CheckCircleIcon className="mr-1" />,
      Inactive: <XCircleIcon className="mr-1" />,
      Missing: <XCircleIcon className="mr-1" />,
      'Non Compliant': <XCircleIcon className="mr-1" />,
      Expired: <XCircleIcon className="mr-1" />,
      'Expiring soon': <CircleAlertIcon className="mr-1" />,
      Onboarding: <CircleDashedIcon className="mr-1" />,
      'Pending Review': <CircleAlertIcon className="mr-1" />,
      Draft: <CircleDashedIcon className="mr-1" />,
    }[status] || null
  );

  const text = locked ? 'Locked' : blocked ? 'Blocked' : status || '';

  return (
    <span className={spanClasses}>
      {icon}
      <div className="pl-1">{text}</div>
    </span>
  );
};

export default StatusBadge;
