import {MedicalDocumentService} from 'services/medicalDocumentService';
import {createEntityThunks} from 'utils/createEntityThunks';
import {MedicalDocument} from 'types/medicalDocument';

const medicalDocumentFactory = (data: any) => new MedicalDocument(data);

export const MedicalDocumentThunks = createEntityThunks<MedicalDocument>(
  'medicalDocument',
  MedicalDocumentService,
  {activate: true, deactivate: true},
  medicalDocumentFactory,
);
